import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { BaseCard } from '@lattice/common/components'
import { useTimer } from '@lattice/common/hooks/useTimer'

import styles from './component.module.scss'

type EndStake = {
  endDate: Date | undefined
  endLabel: string
  formatedEndDate: string | undefined
}

const ProgramDetailsTimerCard = ({
  dateInfo,
  children,
  variants,
}: {
  dateInfo: EndStake
  children?: React.ReactNode
  variants?: (
    | 'narrow'
    | 'wide'
    | 'medium'
    | 'section-bar'
    | 'full-width'
    | 'bordered'
  )[]
}) => {
  const { t } = useTranslation()
  const { days, hours, minutes, seconds } = useTimer(dayjs(dateInfo.endDate))
  return (
    <BaseCard variants={variants} className={{ root: styles.baseCardRoot }}>
      <div className={styles.cardContainer}>
        <div className={styles.cardSection}>
          <div className={styles.infoLabel}>{dateInfo.endLabel}</div>
          {dateInfo.endDate && (
            <>
              <div className={styles.flexContainer}>
                <div className={styles.infoDataGroup}>
                  <div className={styles.numberTimeLabel}>{days}</div>
                  <div className={styles.timeLabel}>{t('', 'days')}</div>
                </div>
                <div className={styles.infoDataGroup}>
                  <div className={styles.numberTimeLabel}>
                    {hours < 10 ? '0' + hours : hours}
                  </div>
                  <div className={styles.timeLabel}>{t('', 'hours')}</div>
                </div>
                <div className={styles.infoDataGroup}>
                  <div className={styles.numberTimeLabel}>
                    {minutes < 10 ? '0' + minutes : minutes}
                  </div>
                  <div className={styles.timeLabel}>{t('', 'mins')}</div>
                </div>
                <div className={styles.infoDataGroup}>
                  <div className={styles.numberTimeLabel}>
                    {seconds < 10 ? '0' + seconds : seconds}
                  </div>
                  <div className={styles.timeLabel}>{t('', 'secs')}</div>
                </div>
              </div>
            </>
          )}
          {dateInfo.formatedEndDate && (
            <div className={styles.flexContainer}>
              <div className={styles.infoDataGroup}>
                <div className={styles.numberTimeLabel}>
                  {dateInfo.formatedEndDate}
                </div>
              </div>
            </div>
          )}
        </div>
        {children && <div className={styles.cardSection}>{children}</div>}
      </div>
    </BaseCard>
  )
}

export { ProgramDetailsTimerCard }
