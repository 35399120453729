import React from 'react'
import cls from 'classnames'

import { ReactComponent as ArrowRightIcon } from '@lattice/assets/icons/custom/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from '@lattice/assets/icons/custom/arrow-left.svg'

import styles from './component.module.scss'

const StepArrowButton = ({
  direction,
  selected,
  onClick,
}: {
  direction: 'left' | 'right'
  selected?: boolean
  onClick?: () => void
}) => {
  return (
    <div
      onClick={onClick}
      className={cls(
        styles.main,
        onClick && styles.clickable,
        selected && styles.selected
      )}
    >
      {direction === 'right' && <ArrowRightIcon />}
      {direction === 'left' && <ArrowLeftIcon />}
    </div>
  )
}

export { StepArrowButton }
