import React from 'react'
import cls from 'classnames'

import { BaseCard } from '@lattice/common/components'

import styles from './component.module.scss'

const NodeOperatorNextStepsCard = () => {
  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <div className={styles.titleText}>Next steps</div>
        <ol className={cls(styles.subText, styles.stepsList)}>
          <li>
            Join our Discord Dor Operators channel for guidance on how to set to
            setup a Node. <br />
            <a
              href="https://discord.gg/Z399suSszW"
              target="_blank"
              rel="noreferrer"
            >
              https://discord.gg/Z399suSszW
            </a>
          </li>
          <li>
            For more information on how to setup a Node, follow this link to our
            documentation site.
            <br />
            <a
              href="https://docs.constellationnetwork.io/validate/"
              target="_blank"
              rel="noreferrer"
            >
              https://docs.constellationnetwork.io/validate/
            </a>
          </li>
        </ol>
      </div>
    </BaseCard>
  )
}

export { NodeOperatorNextStepsCard }
