import React from 'react'

import styles from './component.module.scss'

const InfoRowsContainer = ({
  children,
  title,
  icon,
}: {
  children: React.ReactNode
  title?: string
  icon?: JSX.Element
}) => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoTitle}>
        {title} {icon}
      </div>
      {children}
    </div>
  )
}

export { InfoRowsContainer }
