import React from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { IUserDbData } from '@lattice/common/providers'

import { KycStatus } from './components'

const ResubmissionIsAvailableStatuses = [
  'missing_data',
  'resubmission_requested',
  'expired',
  'abandoned',
] as const

const getGeneralStatusFromKycStatus = (
  status: IUserDbData['kycStatus']
): React.ComponentProps<typeof KycStatus>['status'] => {
  if (status === 'started') {
    return 'pending'
  }

  if (status === 'submitted') {
    return 'pending'
  }

  if (status === 'missing_data') {
    return 'declined'
  }

  if (status === 'approved') {
    return 'approved'
  }

  if (status === 'resubmission_requested') {
    return 'declined'
  }

  if (status === 'declined') {
    return 'declined'
  }

  if (status === 'expired') {
    return 'declined'
  }

  if (status === 'abandoned') {
    return 'declined'
  }

  if (status === 'review') {
    return 'pending'
  }

  if (status === null) {
    return 'pending'
  }

  throw new Error('Unable to classify KYC state')
}

const isResubmissionAvailableFromKycStatus = (
  status: IUserDbData['kycStatus'],
  lastKycEvent: Dayjs | null
): boolean => {
  return !!(
    ResubmissionIsAvailableStatuses.includes(status as any) ||
    (status === 'started' &&
      lastKycEvent &&
      dayjs().isAfter(lastKycEvent.add(1, 'hour')))
  )
}

export {
  ResubmissionIsAvailableStatuses,
  getGeneralStatusFromKycStatus,
  isResubmissionAvailableFromKycStatus,
}
