import React from 'react'

import { ReactComponent as DiceIcon } from '@lattice/assets/icons/custom/dice.svg'

import styles from './component.module.scss'

const NoContent = ({
  title = 'Oops, no dice',
  text = "We couldn't find anything in this category \n Try another?",
  icon = <DiceIcon width={40} height={40} />,
  children,
}: {
  title?: string
  text?: string
  icon?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <div className={styles.main}>
      {icon}
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{text}</div>
      {children}
    </div>
  )
}

export { NoContent }
