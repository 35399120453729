import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, DropdownMulti } from '@lattice/common/components'
import {
  useNodeManagerProvider,
  useUserOAuthProvider,
} from '@lattice/common/providers'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { FetchStatus, OAuthProviderURI } from '@lattice/common/consts'

import styles from './component.module.scss'

type DropDownOptions = {
  label: string
  value: string
}

const DtmNodeDeviceSelect = () => {
  const params = useParams()
  const {
    userNode,
    requestUserNode,
    integrations: {
      dorapi: { userDevices, requestUserDevices, registerUserDevice },
    },
  } = useNodeManagerProvider()
  const { userOAuthProviderStatuses } = useUserOAuthProvider()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const linkDeviceOperation = useFetchableOperation()
  const linkDeviceOperationProgressToasts = useProgressToasts()

  const dropdownOptions: DropDownOptions[] =
    userDevices.resource.map((device) => ({
      label: device.serial_number,
      value: device.serial_number,
    })) ?? []

  useEffect(() => {
    requestUserDevices()
  }, [userOAuthProviderStatuses.resource[OAuthProviderURI.DOR_DTM]?.status])

  const handleClick = linkDeviceOperation.wrappedFetch(
    linkDeviceOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (!userNode.resource) {
        throw new Error('Invalid user node state')
      }

      if (!userDevices.resource) {
        throw new Error('Invalid user devices state')
      }

      linkDeviceOperationProgressToasts.progress('Adding device', 'info', null)
      await registerUserDevice(userNode.resource.slug, selectedOptions[0])

      linkDeviceOperationProgressToasts.progress(
        'Successfully added device',
        'success',
        15000
      )

      requestUserDevices()
      params.slug && requestUserNode(params.slug)
    })
  )

  return (
    <div className={styles.main}>
      <h3>Link a device</h3>
      <DropdownMulti
        placeholder="Select device"
        noOptionsMessage="No devices found"
        options={dropdownOptions}
        setOptionSelected={setSelectedOptions}
      />
      <Button
        variants={['primary', 'full-width']}
        disabled={
          selectedOptions.length === 0 ||
          linkDeviceOperation.status === FetchStatus.PENDING
        }
        loading={linkDeviceOperation.status === FetchStatus.PENDING}
        className={styles.optIntoBounty}
        onClick={
          selectedOptions.length === 0 ||
          linkDeviceOperation.status === FetchStatus.PENDING
            ? () => void 0
            : handleClick
        }
      >
        Link this device
      </Button>
    </div>
  )
}

export { DtmNodeDeviceSelect }
