// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iEg4QanmPbtMpWWhW_rh{border-bottom:.5px solid #1b1e29}.iEg4QanmPbtMpWWhW_rh.yuOdvUwOgWMSjVzxaNuQ img{object-position:0px -150px}.iEg4QanmPbtMpWWhW_rh img{opacity:.66}.tab7s6_0DnvwwtuhaAOl{border:.5px solid rgba(255,255,255,.12)}`, "",{"version":3,"sources":["webpack://./src/views/NodeManagerView/components/UserWalletSoftNodeCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CAEA,+CACE,0BAAA,CAGF,0BACE,WAAA,CAIJ,sBACE,uCAAA","sourcesContent":[".header {\n  border-bottom: 0.5px solid #1b1e29;\n\n  &.nftImageHeader img {\n    object-position: 0px -150px;\n  }\n\n  img {\n    opacity: 0.66;\n  }\n}\n\n.root {\n  border: 0.5px solid rgba(255, 255, 255, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `iEg4QanmPbtMpWWhW_rh`,
	"nftImageHeader": `yuOdvUwOgWMSjVzxaNuQ`,
	"root": `tab7s6_0DnvwwtuhaAOl`
};
export default ___CSS_LOADER_EXPORT___;
