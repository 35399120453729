import React from 'react'

import styles from './component.module.scss'

const RequiredCondition = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.main}>
      <span>{children}</span>
    </div>
  )
}

export { RequiredCondition }
