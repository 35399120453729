import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const PoolTabs = ({
  tabs,
  selectTab,
  activeTab,
}: {
  tabs: string[]
  selectTab: (idx: number) => void
  activeTab: number
}) => {
  return (
    <div className={styles.tabGroup}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={cls(styles.tab, activeTab === index && styles.active)}
          onClick={() => selectTab(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  )
}

export { PoolTabs }
