import React from 'react'

import { HeroCard } from '@lattice/common/components'
import PlaceholderImage from '@lattice/assets/images/pngs/bannerbg.png'
import { IUserWalletSoftNodeAndPrograms } from '@lattice/common/providers'
import { formatNumber, NumberFormat, shortenAddress } from '@lattice/utils'

import { formatFracPercentage, getNodeLifetimeRewards } from '../../utils'

import styles from './component.module.scss'

const UserWalletSoftNodeCard = ({
  node,
}: {
  node: IUserWalletSoftNodeAndPrograms
}) => {
  const initialFracPercentage = (
    (node.programs[0].frac[0] / node.programs[0].nodePrice) *
    100
  ).toFixed(2)

  const currentFracPercentage = (
    (node.programs[0].frac[1] / node.programs[0].nodePrice) *
    100
  ).toFixed(2)

  return (
    <HeroCard
      headerImage={PlaceholderImage}
      headerTitle={node.alias}
      headerInfo={{
        label: 'Soft node',
        value: shortenAddress(node.walletAddress, 5, 5, '•••'),
      }}
      className={{
        root: styles.root,
        header: styles.header,
      }}
      data={{
        displayInfo: [
          {
            label: 'Active shards',
            text: `${node.programs[0].shards[0]} of ${
              node.programs[0].shards[0] + node.programs[0].shards[1]
            }`,
          },
          {
            label: 'Active frac. shard',
            text: formatFracPercentage(
              initialFracPercentage,
              currentFracPercentage
            ),
          },
          {
            label: 'Lifetime rewards',
            text:
              formatNumber(getNodeLifetimeRewards(node), NumberFormat.MILLIFY) +
              ' DAG',
          },
          {
            label: 'Active Program',
            text: `${node.programs[0].name} →`,
            linkTo: '/rewards/soft-nodes/' + node.programs[0].slug,
          },
        ],
        nodeType: 'softnode',
        buttonLabel: 'View details',
        to: 'soft-node/' + node.alias,
      }}
    />
  )
}
export { UserWalletSoftNodeCard }
