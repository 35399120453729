// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uEQmLXl2Z4UYY2_YD25L{display:flex;width:100%;padding:0px 24px;flex-flow:column nowrap;row-gap:22px}.uEQmLXl2Z4UYY2_YD25L>span{font-family:"TWK Lausanne";font-style:normal;font-weight:300;font-size:14px;line-height:20px;display:flex;align-items:center;letter-spacing:.02em;color:rgba(255,255,255,.66)}.ihIbiuRbllcYCJNjaYcF{width:157px}.Mf9KytHrooB3Q_C_auGX{font-family:"TWK Lausanne";font-style:normal;font-weight:200;font-size:16px;line-height:24px;display:flex;align-items:center;letter-spacing:.015em;color:rgba(255,255,255,.96)}.sWO9PHaZeCLCy3BCxfGr{display:flex;width:100%}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/DtmNodesDetailsView/views/RedeemNftView/components/NftSelectionSection/component.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,UAAA,CACA,gBAAA,CACA,uBAAA,CACA,YAAA,CAEA,2BACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CACA,oBAAA,CAEA,2BAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CACA,qBAAA,CAEA,2BAAA,CAGF,sBACE,YAAA,CACA,UAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n\n.selectNftsSectionContainer {\n  display: flex;\n  width: 100%;\n  padding: 0px 24px;\n  flex-flow: column nowrap;\n  row-gap: 22px;\n\n  > span {\n    font-family: 'TWK Lausanne';\n    font-style: normal;\n    font-weight: 300;\n    font-size: 14px;\n    line-height: 20px;\n\n    display: flex;\n    align-items: center;\n    letter-spacing: 0.02em;\n\n    color: rgba(255, 255, 255, 0.66);\n  }\n}\n\n.verifyButton {\n  width: 157px;\n}\n\n.nftsNeeded {\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 200;\n  font-size: 16px;\n  line-height: 24px;\n\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.015em;\n\n  color: rgba(255, 255, 255, 0.96);\n}\n\n.manageDtm {\n  display: flex;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectNftsSectionContainer": `uEQmLXl2Z4UYY2_YD25L`,
	"verifyButton": `ihIbiuRbllcYCJNjaYcF`,
	"nftsNeeded": `Mf9KytHrooB3Q_C_auGX`,
	"manageDtm": `sWO9PHaZeCLCy3BCxfGr`
};
export default ___CSS_LOADER_EXPORT___;
