import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BaseCard, Button } from '@lattice/common/components'
import { useToastProvider, useUserProvider } from '@lattice/common/providers'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './view.module.scss'

const VerifyEmailView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { addToast } = useToastProvider()
  const { user, userAttributes } = useUserProvider()
  const signInUrl = useSignInRedirect()

  const doSendEmailVerificationCode = () => {
    if (user) {
      user.getAttributeVerificationCode('email', {
        onSuccess: () => {
          addToast(
            t('views.User.views.VerifyEmail.messageSent', 'Message sent'),
            'success'
          )
        },
        onFailure: (err) => {
          console.error(err)
          addToast(
            t(
              'views.User.views.VerifyEmail.unableToSend',
              'Unable to send confirmation link: ' + err
            ),
            'error',
            30000
          )
        },
      })
    }
  }

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }

    if (userAttributes.resource) {
      const emailVerified = userAttributes.resource.find(
        (attr) => attr.Name === 'email_verified'
      )

      if (emailVerified && emailVerified.Value === 'true') {
        navigate('/dashboard')
      }
    }
  })

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t(
        'views.User.views.VerifyEmail.title.checkYourEmail',
        'Check your email'
      )}
    >
      <div className={styles.message}>
        {t(
          'views.User.views.VerifyEmail.pleaseFollowThe',
          'Please follow the verification link in your email to complete your registration.'
        )}
        <Button
          variants={['primary', 'full-width']}
          onClick={doSendEmailVerificationCode}
        >
          {t(
            'views.User.views.VerifyEmail.resendConfirmationLink',
            'Resend confirmation link'
          )}
        </Button>
      </div>
    </BaseCard>
  )
}

export { VerifyEmailView }
