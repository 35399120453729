import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const SpacedGroup = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <div className={cls(styles.main, className)}>{children}</div>
}

export { SpacedGroup }
