import { useEffect, useState } from 'react'

const isWindowVisible = () => document.visibilityState === 'visible'

const useIsWindowVisible = () => {
  const [visible, setVisible] = useState(isWindowVisible)

  useEffect(() => {
    const listener = () => {
      setVisible(isWindowVisible())
    }

    document.addEventListener('visibilitychange', listener)
    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  }, [])

  return visible
}

export { useIsWindowVisible }
