import {
  BaseButton,
  ButtonLink,
  ButtonLinkExternal,
  ButtonNavLink,
} from './components'

const Button = Object.assign(BaseButton, {
  Link: ButtonLink,
  LinkExternal: ButtonLinkExternal,
  NavLink: ButtonNavLink,
})

export { Button }
