// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ERed5g3DHuuISXoAByna{display:flex;flex-direction:column;align-items:center;width:100%;gap:14px;padding:30px}.ON_EsBCo77eItvPMZlyh{display:flex;flex-direction:column;gap:20px;width:100%}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/TestnetValidatorProgramDetailView/components/ManageTestnetRewardsCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 14px;\n  padding: 30px;\n}\n\n.testnetForm {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ERed5g3DHuuISXoAByna`,
	"testnetForm": `ON_EsBCo77eItvPMZlyh`
};
export default ___CSS_LOADER_EXPORT___;
