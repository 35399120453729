// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cD8FqcGEelGCVSuVBjpA{display:grid;width:100%;height:48px;grid-template-columns:min-content 1fr min-content;justify-content:flex-start;align-items:center;column-gap:12px;padding:0px 24px;border-radius:24px;border:1px solid rgba(255,255,255,.24);cursor:pointer}.cD8FqcGEelGCVSuVBjpA span{color:#fff;font-size:14px;line-height:20px}.cD8FqcGEelGCVSuVBjpA img{justify-self:flex-end;height:24px}.cD8FqcGEelGCVSuVBjpA.zdUHlx_q_jS4ZnHPfwps{border:1px solid #fc0}.cD8FqcGEelGCVSuVBjpA:hover{background-color:rgba(255,255,255,.08)}.S4Ddt2IwMfYkhHJeM7D9._82xT0rFHPJsp8AbnCgq{color:#5a5d6b}.S4Ddt2IwMfYkhHJeM7D9.bqlAsMNYzpD4m5wGgg6S{color:#fc0}.S4Ddt2IwMfYkhHJeM7D9.zdUHlx_q_jS4ZnHPfwps{color:#19bd39}`, "",{"version":3,"sources":["webpack://./src/common/components/ConnectWalletCard/components/WalletItem/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CAEA,iDAAA,CACA,0BAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,kBAAA,CACA,sCAAA,CACA,cAAA,CAEA,2BACE,UAAA,CACA,cAAA,CACA,gBAAA,CAGF,0BACE,qBAAA,CACA,WAAA,CAGF,2CACE,qBAAA,CAGF,4BACE,sCAAA,CAKF,2CACE,aAAA,CAGF,2CACE,UAAA,CAGF,2CACE,aAAA","sourcesContent":[".main {\n  display: grid;\n  width: 100%;\n  height: 48px;\n\n  grid-template-columns: min-content 1fr min-content;\n  justify-content: flex-start;\n  align-items: center;\n  column-gap: 12px;\n  padding: 0px 24px;\n\n  border-radius: 24px;\n  border: 1px solid rgba(#fff, 0.24);\n  cursor: pointer;\n\n  span {\n    color: #fff;\n    font-size: 14px;\n    line-height: 20px;\n  }\n\n  img {\n    justify-self: flex-end;\n    height: 24px;\n  }\n\n  &.connected {\n    border: 1px solid #ffcc00;\n  }\n\n  &:hover {\n    background-color: rgba(#fff, 0.08);\n  }\n}\n\n.statusDot {\n  &.disconnected {\n    color: #5a5d6b;\n  }\n\n  &.connecting {\n    color: #ffcc00;\n  }\n\n  &.connected {\n    color: #19bd39;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `cD8FqcGEelGCVSuVBjpA`,
	"connected": `zdUHlx_q_jS4ZnHPfwps`,
	"statusDot": `S4Ddt2IwMfYkhHJeM7D9`,
	"disconnected": `_82xT0rFHPJsp8AbnCgq`,
	"connecting": `bqlAsMNYzpD4m5wGgg6S`
};
export default ___CSS_LOADER_EXPORT___;
