import * as ethers from 'ethers'
import type { Event, EventFilter, providers } from 'ethers'

import {
  AvailableNetwork,
  ChainInfo,
  DeploymentStageMasterChain,
  DeploymentStageMasterNetworkChains,
  EthereumInterfaces,
} from '@lattice/common/consts'

const getNetworkEthersProvider = (
  network: AvailableNetwork
): ethers.providers.JsonRpcProvider => {
  const chaininfo = ChainInfo[DeploymentStageMasterNetworkChains[network]]

  return new ethers.providers.JsonRpcProvider(
    chaininfo.rpcEndpoint,
    chaininfo.rpcChainId
  )
}

const getNetworkTypedContract = <
  T extends keyof EthereumInterfaces.ContractTypes,
>(
  interfaceName: T,
  address: string,
  network: AvailableNetwork
): EthereumInterfaces.ContractTypes[T] => {
  const contractABI = EthereumInterfaces.ContractABIs[interfaceName]
  if (contractABI) {
    return new ethers.Contract(
      address,
      contractABI,
      getNetworkEthersProvider(network)
    ) as EthereumInterfaces.ContractTypes[T]
  } else {
    throw new Error(
      `Unable to find contract with interface name '${String(interfaceName)}'`
    )
  }
}

const getEthersProvider = (): ethers.providers.JsonRpcProvider => {
  const chaininfo = ChainInfo[DeploymentStageMasterChain]

  return new ethers.providers.JsonRpcProvider(
    chaininfo.rpcEndpoint,
    chaininfo.rpcChainId
  )
}

const getTypedContract = <T extends keyof EthereumInterfaces.ContractTypes>(
  interfaceName: T,
  address: string
): EthereumInterfaces.ContractTypes[T] => {
  const contractABI = EthereumInterfaces.ContractABIs[interfaceName]
  if (contractABI) {
    return new ethers.Contract(
      address,
      contractABI,
      getEthersProvider()
    ) as EthereumInterfaces.ContractTypes[T]
  } else {
    throw new Error(
      `Unable to find contract with interface name '${String(interfaceName)}'`
    )
  }
}

const parseTypedEventLogs = <TEvent extends Event>(
  rawEventLogs: any[],
  eventFilter: EventFilter,
  contract: ethers.BaseContract
): TEvent[] => {
  if (!(contract.provider instanceof ethers.providers.BaseProvider)) {
    throw new Error('Contract provider is not instance of BaseProvider')
  }

  const formattedLogs = ethers.providers.Formatter.arrayOf(
    contract.provider.formatter.filterLog.bind(contract.provider.formatter)
  )(rawEventLogs)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const runningEvent = contract._getRunningEvent(eventFilter)
  return formattedLogs.map((log) =>
    contract._wrapEvent(runningEvent, log, () => void 0)
  )
}

const requestProviderChainUpdateIfNeeded = async (
  provider: providers.Web3Provider,
  targetChain: number
) => {
  const currentNetwork = await provider.getNetwork()

  if (currentNetwork.chainId === targetChain) {
    return
  }

  await provider.send('wallet_switchEthereumChain', [
    {
      chainId: '0x' + targetChain.toString(16),
    },
  ])
}

export {
  EthereumInterfaces,
  getEthersProvider,
  getTypedContract,
  getNetworkEthersProvider,
  getNetworkTypedContract,
  parseTypedEventLogs,
  requestProviderChainUpdateIfNeeded,
}
