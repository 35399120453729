import React, { useImperativeHandle, useMemo, useRef } from 'react'

const useImperativeProxiedInputValueRef = <T extends HTMLElement, V>(
  ref: React.ForwardedRef<T>,
  initialValue: V
) => {
  const internalRef = useRef<T>(null)

  const internalTargetData = useMemo(() => {
    const internalTargetValue = { value: initialValue }
    const internalTargetProxy = internalRef.current
      ? (new Proxy(internalRef.current, {
          get: (target, property) => {
            if (property === 'value') {
              return internalTargetValue.value
            }

            const value = target[property]

            return typeof value === 'function' ? value.bind(target) : value
          },
          set: (target, property, value) => {
            if (property === 'value') {
              internalTargetValue.value = value
              return true
            }

            target[property] = value
            return true
          },
        }) as T)
      : null

    return { internalTargetValue, internalTargetProxy }
  }, [internalRef.current])

  useImperativeHandle(
    ref,
    () => internalTargetData.internalTargetProxy as any,
    [internalTargetData]
  )

  return { internalRef, ...internalTargetData }
}

export { useImperativeProxiedInputValueRef }
