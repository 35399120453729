const shortenAddress = (
  address: string,
  startChars = 4,
  endChars = 4,
  ellipsis = '...'
) => {
  return `${address.substring(0, startChars)}${ellipsis}${address.substring(
    address.length - endChars
  )}`
}

export { shortenAddress }
