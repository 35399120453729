import React, { useState, useEffect } from 'react'

import {
  RewardProgramCard,
  Content,
  Typography,
  FilterRow,
  FilterDropdown,
  GridContainer,
  PendingContent,
  NoContent,
} from '@lattice/common/components'
import {
  RewardProgramStatus,
  RewardProgramType,
  useRewardsProgramsProvider,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

const programTypeOptions: {
  value: RewardProgramType | 'all'
  content: string
}[] = [
  { value: 'all', content: 'All Programs' },
  { value: RewardProgramType.STAKING, content: 'Staking' },
  { value: RewardProgramType.SOFT_NODE, content: 'Soft Nodes' },
  { value: RewardProgramType.BOUNTY, content: 'Commissions' },
]

const statusOptions: { value: RewardProgramStatus | 'all'; content: string }[] =
  [
    { value: 'all', content: 'All statuses' },
    { value: RewardProgramStatus.COMMING_SOON, content: 'Soon' },
    { value: RewardProgramStatus.ENROLLING, content: 'Enrolling' },
    { value: RewardProgramStatus.LIVE, content: 'Live' },
    { value: RewardProgramStatus.DISTRIBUTING, content: 'Distributing' },
    { value: RewardProgramStatus.COMPLETE, content: 'Complete' },
  ]

const RewardProgramStatusOrder: Record<RewardProgramStatus, number> = {
  [RewardProgramStatus.COMMING_SOON]: 0,
  [RewardProgramStatus.SOON]: 0,
  [RewardProgramStatus.ENROLLING]: 1,
  [RewardProgramStatus.LIVE]: 2,
  [RewardProgramStatus.DISTRIBUTING]: 3,
  [RewardProgramStatus.COMPLETE]: 4,
  [RewardProgramStatus.DRAFT]: 100,
}

const ProgramsView = () => {
  const {
    programs: { resource: programs, ...fetchStatus },
    requestPrograms,
  } = useRewardsProgramsProvider()

  const [selectedProgramType, setSelectedProgramType] = useState(
    programTypeOptions[0]
  )
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0])
  const [filteredPrograms, setFilteredPrograms] = useState(programs)

  useEffect(() => {
    requestPrograms()
  }, [])

  useEffect(() => {
    const filtered = programs
      .filter((program) => {
        if (selectedProgramType.value === 'all') return true

        return program.type === selectedProgramType.value
      })
      .filter((program) => {
        if (selectedStatus.value === 'all') return true

        return program.status === selectedStatus.value
      })

    setFilteredPrograms(filtered)
  }, [selectedProgramType, selectedStatus, programs])

  return (
    <Content>
      <Typography.PageTitle>Rewards Programs</Typography.PageTitle>
      <section>
        <FilterRow>
          <FilterDropdown
            options={programTypeOptions}
            onOptionClick={(val: string | number) => {
              const selectedOption =
                programTypeOptions.find((option) => option.value === val) ??
                programTypeOptions[0]
              setSelectedProgramType(selectedOption)
            }}
          >
            {selectedProgramType.content}
          </FilterDropdown>
          <FilterDropdown
            options={statusOptions}
            onOptionClick={(val: string | number) => {
              const selectedOption =
                statusOptions.find((option) => option.value === val) ??
                statusOptions[0]
              setSelectedStatus(selectedOption)
            }}
          >
            {selectedStatus.content}
          </FilterDropdown>
        </FilterRow>
      </section>
      {filteredPrograms.length ? (
        <GridContainer>
          {filteredPrograms
            .sort(
              (a, b) =>
                RewardProgramStatusOrder[a.status] -
                RewardProgramStatusOrder[b.status]
            )
            .filter(
              (program) => program.url !== '/rewards/bounties/dtm-baseline'
            )
            .map((program) => (
              <RewardProgramCard key={program.url} rewardProgram={program} />
            ))}
        </GridContainer>
      ) : fetchStatus.status === FetchStatus.PENDING ? (
        <PendingContent />
      ) : (
        <NoContent />
      )}
    </Content>
  )
}

export { ProgramsView }
