import React, { useEffect, useState } from 'react'

import {
  Button,
  HorizontalBar,
  InlineStat,
  InlineStats,
  MainProjectCard,
  NoContentContainer,
  PendingContent,
  Tabs,
  Typography,
} from '@lattice/common/components'
import DtmBanner from '@lattice/assets/images/pngs/dtmBanner.png'
import { useDtmNodesProvider, useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import { HowToParticipateContent } from '../../content'
import { DtmNodeCardGrid } from '../../components'

import styles from './view.module.scss'

const GeneralDetailsView = () => {
  const { user } = useUserProvider()
  const {
    dtmUser,
    dtmStats,
    dtmNftsInfo,
    requestDtmUser,
    requestUserDtmNftData,
  } = useDtmNodesProvider()
  const [userRedeemedNfts, setUserRedeemedNfts] = useState<string[]>([])

  useEffect(() => {
    if (dtmNftsInfo.status === FetchStatus.DONE && dtmNftsInfo.resource) {
      const redeemedNfts: string[] = []
      dtmNftsInfo.resource.nfts.map((nft) => {
        const redeemedAttribute = nft.attributes.find(
          (attribute) => attribute.traitType === 'Redeemed'
        )
        if (redeemedAttribute?.jsonValue === 'Yes') {
          redeemedNfts.push(nft.serial.toString())
        }
      })
      setUserRedeemedNfts(redeemedNfts)
    }
  }, [dtmNftsInfo])

  useEffect(() => {
    if (user) {
      requestDtmUser()
      requestUserDtmNftData()
    }
  }, [user])

  return dtmUser.status === FetchStatus.PENDING ||
    dtmStats.status === FetchStatus.PENDING ||
    dtmNftsInfo.status === FetchStatus.PENDING ? (
    <PendingContent />
  ) : (
    <MainProjectCard title="Dor Traffic Miner" bannerUrl={DtmBanner}>
      <Tabs>
        <Tabs.Tab
          label="Overview"
          content={
            <Typography.MarkdownContent>
              {HowToParticipateContent}
            </Typography.MarkdownContent>
          }
        />
        <Tabs.Tab
          label="Manage DTMs"
          isDefault={
            (dtmUser.resource && dtmUser.resource.nodes.length > 0) ?? undefined
          }
          content={
            <div className={styles.manageDtm}>
              <InlineStats>
                <InlineStat
                  label="Active DTMs"
                  value={dtmUser.resource ? dtmUser.resource.nodes.length : '0'}
                />
                <InlineStat
                  label="Total DTMs Circulating"
                  value={dtmStats.resource?.registeredDTMs}
                />
              </InlineStats>
              <HorizontalBar />
              {dtmUser.resource && dtmUser.resource.nodes.length > 0 ? (
                <>
                  <DtmNodeCardGrid
                    nodes={dtmUser.resource.nodes}
                    redeemedNfts={userRedeemedNfts}
                  />
                  <div className={styles.redeemButtonFlexContainer}>
                    <Button.Link
                      variants={['primary']}
                      className={styles.redeemButton}
                      to={'redeem'}
                    >
                      Redeem NFTs
                    </Button.Link>
                  </div>
                </>
              ) : (
                <NoContentContainer>
                  <div>
                    Connect your wallet to add existing NFTs or purchase a DTM
                    below
                  </div>
                  <Button.LinkExternal
                    href="https://constellation-network.myshopify.com/"
                    target="_blank"
                    variants={['outlined', 'right-icon']}
                  >
                    Get a DTM
                  </Button.LinkExternal>
                </NoContentContainer>
              )}
            </div>
          }
        />
      </Tabs>
    </MainProjectCard>
  )
}

export { GeneralDetailsView }
