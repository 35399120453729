import React from 'react'
import cls from 'classnames'

import { FlexCenteredContainer } from '@lattice/common/components'

import { ExolixNetwork } from '../../../types'
import commonStyles from '../../styles.module.scss'

import styles from './component.module.scss'

const TokenRow = ({
  code,
  icon,
  name,
  network,
  selectToken,
}: {
  code: string
  icon: string
  name: string
  network: ExolixNetwork
  selectToken: (code: string, icon: string, network: ExolixNetwork) => void
}) => {
  return (
    <div
      className={styles.tokenRow}
      onClick={() => selectToken(code, icon, network)}
    >
      <FlexCenteredContainer>
        <div
          className={cls(commonStyles.currencySelection, styles.iconContainer)}
        >
          <img
            className={commonStyles.currencyIcon}
            src={icon}
            width={'24px'}
            height={'24px'}
          />
        </div>
        <div className={cls(styles.textInfo, styles.alignLeft)}>
          <div className={styles.mainText}>{code}</div>
          <div className={styles.subText}>{name}</div>
        </div>
      </FlexCenteredContainer>
      <div className={cls(styles.textInfo, styles.alignRight)}>
        <div className={styles.mainText}>
          {network.shortName || network.network}
        </div>
        <div className={styles.subText}>{network.name}</div>
      </div>
    </div>
  )
}

export { TokenRow }
