// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DzFCSrM8LpNLB700u34w:hover{background-color:#313644;border-radius:48px;cursor:pointer}.c51hIR06fUP5YrwT1yod{font-family:"TWK Lausanne";font-style:normal;font-weight:400;font-size:14px;line-height:20px}`, "",{"version":3,"sources":["webpack://./src/common/components/NavTab/NavItems/NavButton/component.module.scss"],"names":[],"mappings":"AACE,4BACE,wBAAA,CACA,kBAAA,CACA,cAAA,CAIJ,sBACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\n  &:hover {\n    background-color: #313644;\n    border-radius: 48px;\n    cursor: pointer;\n  }\n}\n\n.text {\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DzFCSrM8LpNLB700u34w`,
	"text": `c51hIR06fUP5YrwT1yod`
};
export default ___CSS_LOADER_EXPORT___;
