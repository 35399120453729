import React, { useState, useEffect, useRef } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import dayjs from 'dayjs'

import {
  RewardsHistoryGraphPeriod,
  useTokenPrices,
} from '@lattice/common/providers'
import { formatNumberAndCurrency, NumberFormat } from '@lattice/utils'
import {
  GeneralColor,
  ProjectStatusColor,
} from '@lattice/components/common/Consts'

import styles from './graph.module.scss'

const RewardsHistoryGraphTooltip = ({
  active,
  payload,
}: {
  active?: boolean
  payload?: {
    payload: {
      currency: string
      amount: number
      rewardDate: dayjs.Dayjs
      graphPeriod: RewardsHistoryGraphPeriod
    }
  }[]
  label?: string
}) => {
  const tokenPrices = useTokenPrices()

  if (active && payload && payload.length) {
    const payloadData = payload[0].payload

    return (
      <div className={styles.tooltip}>
        <span>
          {payloadData.rewardDate.format(
            payloadData.graphPeriod === RewardsHistoryGraphPeriod.month
              ? 'MMM YYYY'
              : 'DD MMM YYYY'
          )}
        </span>
        <span>
          {formatNumberAndCurrency(
            payloadData.amount,
            payloadData.currency === 'constellation:dag' ? 'DAG' : 'DOR',
            NumberFormat.DECIMALS
          )}
        </span>
        {payloadData.currency === 'constellation:dag' &&
          tokenPrices.dag?.usd && (
            <span>
              $
              {formatNumberAndCurrency(
                payloadData.amount * tokenPrices.dag.usd,
                'USD',
                NumberFormat.DECIMALS
              )}
            </span>
          )}
      </div>
    )
  }

  return null
}

const RewardsHistoryGraphTick = ({
  x,
  y,
  payload,
}: {
  x?: number
  y?: number
  payload?: { value: string }
}) => {
  const svgTextRef = useRef<SVGTextElement>(null)
  const svgTextBBox = svgTextRef?.current?.getBBox()

  return (
    <g transform={`translate(${x},${y})`}>
      {svgTextBBox && (
        <rect
          x={svgTextBBox.x}
          y={svgTextBBox.y}
          width={svgTextBBox.width}
          height={svgTextBBox.height}
          fill={'currentColor'}
        />
      )}
      <text
        style={{ fontFamily: 'IBM Plex Mono' }}
        x={0}
        y={0}
        dy={0}
        textAnchor="end"
        fill={GeneralColor.WHITE as unknown as string}
        ref={svgTextRef}
      >
        {payload?.value}
      </text>
    </g>
  )
}

const RewardsHistoryGraph = ({
  data,
}: {
  data: any[]
  graphPeriod: RewardsHistoryGraphPeriod
}): JSX.Element => {
  const [dataKey, setDataKey] = useState(Math.random())

  const maxLabelLength = data.reduce((prevLength, row) => {
    const nextLength = String(row.amount).length
    return prevLength > nextLength ? prevLength : nextLength
  }, 0)

  useEffect(() => {
    setDataKey(Math.random())
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={240}>
      <LineChart
        width={500}
        height={240}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid
          vertical={false}
          width={3000}
          x={100}
          strokeOpacity={1}
          stroke={GeneralColor.BOLTGUN_METAL as unknown as string}
          strokeWidth={1}
        />
        {data.length > 0 && (
          <XAxis
            dataKey="rewardDate_formatted"
            axisLine={false}
            tickLine={false}
            tickMargin={15}
            enableBackground={GeneralColor.NERO as unknown as string}
            color={GeneralColor.WHITE as unknown as string}
            tick={<RewardsHistoryGraphTick />}
            padding={{ right: 50, left: 50 }}
          />
        )}
        <YAxis
          width={maxLabelLength * 10 + 100}
          minTickGap={10}
          axisLine={false}
          tickLine={false}
          tick={<RewardsHistoryGraphTick />}
        />
        <Tooltip
          cursor={{
            stroke: ProjectStatusColor.BLUE_COLA as unknown as string,
            strokeDasharray: '3 3',
            strokeWidth: 1,
          }}
          content={<RewardsHistoryGraphTooltip />}
        />
        <Line
          key={dataKey}
          type="linear"
          dataKey="amount"
          stroke={ProjectStatusColor.BLUE_COLA as unknown as string}
          dot={{
            stroke: ProjectStatusColor.BLUE_COLA as unknown as string,
            strokeWidth: 2,
            fill: GeneralColor.NERO as unknown as string,
            fillOpacity: 1,
            r: 4,
          }}
          activeDot={{
            stroke: ProjectStatusColor.BLUE_COLA as unknown as string,
            strokeWidth: 2,
            fill: ProjectStatusColor.BLUE_COLA as unknown as string,
            fillOpacity: 1,
            r: 4,
          }}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export { RewardsHistoryGraph }
