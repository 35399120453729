const makeTaggedTemplate = <T extends Record<any, any>>(
  parts: TemplateStringsArray,
  ...replacements: ((ctx: T) => string)[]
) => {
  return (ctx: T) => {
    let result = ''
    for (const [index, part] of parts.entries()) {
      const replacement = replacements[index]
      result +=
        part + (typeof replacement === 'function' ? replacement(ctx) : '')
    }
    return result
  }
}

export { makeTaggedTemplate }
