import { createProviderStateHook } from '@lattice/utils'

import {
  LiquidityStakingProvider,
  LiquidityStakingProviderContext,
} from './provider'

const useLiquidityStakingProvider = createProviderStateHook(
  LiquidityStakingProvider,
  LiquidityStakingProviderContext
)

export { useLiquidityStakingProvider }
