import React from 'react'
import clsx from 'classnames'

import styles from './component.module.scss'

const Text = ({
  variants,
  size,
  children,
}: {
  variants?: ('span' | 'bright')[]
  size?: number
  children?: React.ReactNode
}) => {
  const TextComponent = (
    variants?.includes('span') ? 'span' : 'p'
  ) as keyof JSX.IntrinsicElements

  size = size ?? 16

  return (
    <TextComponent
      className={clsx(
        styles.main,
        styles[`size${size}`],
        variants?.includes('bright') && styles.bright
      )}
    >
      {children}
    </TextComponent>
  )
}

export { Text }
