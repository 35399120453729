import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ReactGA from 'react-ga4'

import { ReactComponent as ViewOffIcon } from '@lattice/assets/icons/carbon/view-off.svg'
import {
  BaseCard,
  Button,
  CheckboxField,
  InputRow,
} from '@lattice/common/components'
import { emailValidator, yupSchemaToFormValidate } from '@lattice/utils'
import { useLocalizedValues, useSignInRedirect } from '@lattice/common/hooks'
import {
  isUserMfaRequiredFunction,
  IUserMfaRequiredFunction,
  useUserProvider,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import styles from './view.module.scss'

type ISignInFormData = {
  username: string
  password: string
  mfaCode: string
  rememberUser: boolean
}

const SignInView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const redirectUrl = useSignInRedirect.Consume()
  const { operations, user, doUserSignIn } = useUserProvider()

  const [mfaRequiredFn, setMfaRequiredFn] =
    useState<IUserMfaRequiredFunction | null>(null)
  const tfn = useLocalizedValues({ emailValidator })

  const userSignInForm = useForm<ISignInFormData>({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
      mfaCode: '',
      rememberUser: false,
    },
  })

  const [showPassword, setShowPassword] = useState(false)

  const doSignIn = userSignInForm.handleSubmit(async (data) => {
    if (!mfaRequiredFn) {
      const result = await doUserSignIn(data.username, data.password)
      if (isUserMfaRequiredFunction(result)) {
        setMfaRequiredFn(() => result)
      } else {
        await ReactGA.event('login')
      }
    } else {
      await mfaRequiredFn(data.mfaCode)
      await ReactGA.event('login')
    }
  })

  useEffect(() => {
    if (user) {
      navigate(redirectUrl ?? '/')
    }
  }, [user])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t('views.User.views.SignIn.title.signIn', 'Sign in')}
    >
      <form onSubmit={doSignIn}>
        <div className={styles.formSection}>
          <InputRow
            variants={['full-width']}
            label={t('views.User.views.SignIn.emailAddress', 'Email address')}
            error={userSignInForm.formState.errors.username?.message}
            {...userSignInForm.register('username', {
              required: true,
              validate: yupSchemaToFormValidate(tfn.emailValidator),
            })}
          />
          <InputRow
            variants={['full-width']}
            label={t('views.User.views.SignIn.password', 'Password')}
            type={showPassword ? 'text' : 'password'}
            icon={
              <ViewOffIcon
                width={16}
                height={16}
                className={styles.viewOffIcon}
                onClick={() => setShowPassword((s) => !s)}
              />
            }
            error={userSignInForm.formState.errors.password?.message}
            {...userSignInForm.register('password', { required: true })}
          />
          {mfaRequiredFn && (
            <Controller
              name="mfaCode"
              rules={{
                required: true,
                validate: (value) =>
                  value.length === 6 ||
                  String(
                    t(
                      'views.User.views.SignIn.mfaCodeError',
                      'Code is a 6 digit number'
                    )
                  ),
              }}
              control={userSignInForm.control}
              render={({ field: { ref: __, ...rest }, fieldState }) => (
                <InputRow.Numeric
                  variants={['full-width']}
                  label={t('views.User.views.SignIn.mfaCode', 'MFA Code')}
                  error={fieldState.error?.message}
                  valueType="value"
                  format="### ###"
                  {...rest}
                />
              )}
            />
          )}
          <span>
            <CheckboxField {...userSignInForm.register('rememberUser')} />
            <span>
              {t('views.User.views.SignIn.rememberMe', 'Remember me?')}
            </span>
          </span>
          {operations.signIn.error && (
            <span className={styles.error}>
              {String(
                operations.signIn.error.message.match(
                  /Incorrect username or password/i
                )
                  ? t(
                      'views.User.views.SignIn.notAuthorized',
                      'Incorrect username or password'
                    )
                  : operations.signIn.error.message.match(
                        /Invalid code received for user/i
                      )
                    ? t(
                        'views.User.views.SignIn.notValidCode',
                        'Invalid MFA Code'
                      )
                    : operations.signIn.error
              )}
            </span>
          )}
        </div>
        <div className={styles.actionSection}>
          <Button
            variants={['primary', 'full-width']}
            type="submit"
            loading={operations.signIn.status === FetchStatus.PENDING}
            disabled={
              !userSignInForm.formState.isValid ||
              operations.signIn.status === FetchStatus.PENDING
            }
          >
            {t('views.User.views.SignIn.button.signIn', 'Sign In')}
          </Button>
          <Trans i18nKey={'views.User.views.SignIn.notRegistered'}>
            <span>
              Not registered? <Link to="../signup">Create an account</Link>
            </span>
          </Trans>
          <Link to="../resetpassword">
            {t(
              'views.User.views.SignIn.notRegistered',
              'Forgot your password?'
            )}
          </Link>
        </div>
      </form>
    </BaseCard>
  )
}

export { SignInView }
