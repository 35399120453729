import { IERC20 } from '@lattice/common/types'

import { getTypedContract } from './ethers'

const requestERC20Token = async (contractAddress: string) => {
  const contract = getTypedContract('ERC20', contractAddress)

  const symbol = await contract.symbol()
  const decimals = await contract.decimals()

  const token: IERC20 = { address: contractAddress, contract, decimals, symbol }
  return token
}

export { requestERC20Token }
