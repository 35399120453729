import Decimal from 'decimal.js'

import { FetchStatus } from '@lattice/common/consts'
import { FetchableOperation, FetchableResource } from '@lattice/common/hooks'

import { IWalletOwnershipToken } from '../WalletProvider'

import { requestUserNodes } from './utils'

enum ISoftNodesProjectStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  UNLISTED = 'unlisted',
  COMING_SOON = 'coming_soon',
  STAKING_OPEN = 'staking_open',
  IN_PROGRESS = 'in_progress',
  DISTRIBUTING = 'distributing',
  COMPLETE = 'complete',
}

type ISoftNodesProject = {
  id: string
  bannerUrl: string | null
  logoUrl: string | null
  name: string
  slug: string
  status: ISoftNodesProjectStatus
  rewardAmount: number | null
  isDistributed: boolean
  rewardCurrency: string
  summaryText: string
  projectUrl: string
  nodePrice: number
  listingStartsAt: IISODate
  stakingStartsAt: IISODate
  stakingWindowEndsAt: IISODate
  stakingEndsAt: IISODate
  rewardsTaxPercentage: number
  grantsCreatedAt: IISODate | null
  stakingWindowEndNotifiedAt: IISODate | null
  createdAt: IISODate
  updatedAt: IISODate
}

type ISoftNodesStakeStats = {
  projectId: string
  /**
   * Full actual amount staked
   * => Does not transform any values
   */
  actualAmountStakedFullRaw: IDecimalString
  /**
   * Actual amount staked by shards
   * => Does not transform any values
   */
  actualAmountStakedByShardsRaw: IDecimalString
  /**
   * Actual amount staked by fractionals
   * => Does not transform any values
   */
  actualAmountStakedByFracsRaw: IDecimalString
  /**
   * Full effective amount staked
   * => Transforms frac amounts according to FRACTIONAL_NODE_VALUE
   */
  effectiveAmountStakedFullRaw: IDecimalString
  /**
   * Effective amount staked by shards
   * => Transforms frac amounts according to FRACTIONAL_NODE_VALUE
   * => Should be the same as actualAmountStakedByShardsRaw
   */
  effectiveAmountStakedByShardsRaw: IDecimalString
  /**
   * Effective amount staked by fractionals
   * => Transforms frac amounts according to FRACTIONAL_NODE_VALUE
   */
  effectiveAmountStakedByFracsRaw: IDecimalString
  /**
   * Actual (valid) shards count
   */
  actualShardsCount: IDecimalString
  /**
   * Effective amount distributed
   * => Transforms frac amounts according to FRACTIONAL_NODE_VALUE
   * => Distributed as in distributed rewards
   */
  effectiveDistributedAmountStakedRaw: IDecimalString
  /**
   * Effective amount undistributed
   * => Transforms frac amounts according to FRACTIONAL_NODE_VALUE
   * => Distributed as in distributed rewards
   */
  effectiveUndistributedAmountStakedRaw: IDecimalString
}

type ISoftNodesProjectWithStats = ISoftNodesProject &
  ISoftNodesStakeStats & {
    taxedRewardAmountRaw: IDecimalString
  }

type ISoftNodesWalletNode = {
  id: string
  snProjectId: string
  snProjectUserId: string
  walletAddress: string
  rewardedAt: IISODate | null
  rewardedTransactionId: string | null
  walletVendorTrace: string | null
  initialFracBalance: IDecimalString
  currentFracBalance: IDecimalString
  createdAt: IISODate
  updatedAt: IISODate
  totalShards: number
  validShards: number
  invalidShards: number
  alias: string
}

type ISoftNodesWalletNodeWithRewards = {
  node: ISoftNodesWalletNode
  project: ISoftNodesProjectWithStats
  rewardAmountShardsRaw: IDecimalString
  rewardAmountFracsRaw: IDecimalString
  rewardAmountFullRaw: IDecimalString
}

type ISoftNodesUserData = {
  nodes: ISoftNodesWalletNodeWithRewards[]

  totalShards: number
  totalValidShards: number
  totalInvalidShards: number

  totalInitialFracAmountStaked: IDecimalString
  totalCurrentFracAmountStaked: IDecimalString

  totalRewardAmountFullRaw: IDecimalString
  totalRewardAmountShardsRaw: IDecimalString
  totalRewardAmountFracsRaw: IDecimalString
}

type IUserSoftNodeProgram = {
  frac: [number, number]
  id: string
  name: string
  nodePrice: number
  shards: [number, number]
  slug: string
  programRewardsAmount: number | null
  userTotalAccruedRewards: number
  userFracShardsAccruedRewards: number
  userFullShardsAccruedRewards: number
  totalFullShardsStaked: number
  totalFullShardsAmountStaked: number
  totalFracAmountStaked: number
  status: string
  bannerUrl: string
  stakingEndsAt: string
  stakingStartsAt: string
  stakingWindowEndsAt: string
}

type IUserWalletSoftNode = {
  alias: string
  address: string
}

type IUserWalletSoftNodeAndPrograms = {
  alias: string
  walletAddress: string
  programs: IUserSoftNodeProgram[]
}

type ISoftNodesProviderContext = {
  projects: FetchableResource<ISoftNodesProjectWithStats[]>
  project: FetchableResource<ISoftNodesProjectWithStats | null>
  projectUser: FetchableResource<ISoftNodesUserData | null>
  stakeWalletOperation: FetchableOperation
  MINIMUM_FRACTIONAL_STAKE_DAG: Decimal
  userHistoryProjects: FetchableResource<IUserWalletSoftNodeAndPrograms[]>
  userNodes: FetchableResource<IUserWalletSoftNode[]>
  requestProjects: () => Promise<ISoftNodesProjectWithStats[]>
  requestProject: (
    projectId: string
  ) => Promise<ISoftNodesProjectWithStats | FetchStatus.NOT_FOUND>
  requestProjectUser: (
    projectId: string
  ) => Promise<ISoftNodesUserData | FetchStatus.NOT_FOUND>
  requestUserHistoryProjects: () => Promise<
    IUserWalletSoftNodeAndPrograms[] | FetchStatus.NOT_FOUND
  >
  requestUserNodes: typeof requestUserNodes
  stakeWallet: (
    projectId: string,
    address: string,
    nodesToStake: number,
    walletOwnershipToken: IWalletOwnershipToken
  ) => Promise<void>
}

export {
  ISoftNodesWalletNodeWithRewards,
  ISoftNodesProviderContext,
  ISoftNodesProjectStatus,
  ISoftNodesProject,
  ISoftNodesStakeStats,
  ISoftNodesProjectWithStats,
  ISoftNodesUserData,
  IUserWalletSoftNode,
  IUserWalletSoftNodeAndPrograms,
  IUserSoftNodeProgram,
}
