import React from 'react'

import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'

import { IBounty, IBountiesProviderContext, IBountyWithNodes } from './types'
import {
  requestBounty,
  requestBounties,
  joinBounty,
  requestBountyWithNodes,
} from './utils'

const BountiesProviderContext =
  React.createContext<IBountiesProviderContext | null>(null)

const BountiesProvider = ({ children }: { children: React.ReactNode }) => {
  const operations = useFetchableOperations(['joinBounty'])
  const bounties = useFetchableResource<IBounty[]>([])
  const bounty = useFetchableResource<IBounty | null>(null)
  const bountyWithNodes = useFetchableResource<IBountyWithNodes | null>(null)

  const ctx: IBountiesProviderContext = {
    operations,
    bounties,
    bounty,
    bountyWithNodes,
    requestBounties: bounties.wrappedFetch(requestBounties),
    requestBounty: bounty.wrappedFetch(requestBounty),
    requestBountyWithNodes: bountyWithNodes.wrappedFetch(
      requestBountyWithNodes
    ),
    joinBounty: operations.joinBounty.wrappedFetch(joinBounty),
  }

  return (
    <BountiesProviderContext.Provider value={ctx}>
      {children}
    </BountiesProviderContext.Provider>
  )
}

export { BountiesProvider, BountiesProviderContext }
