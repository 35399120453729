import React from 'react'
import cls from 'classnames'

import { ReactComponent as Checkmark } from '@lattice/assets/images/svgs/checkmark.svg'

import { PoolBaseCard } from '../PoolBaseCard'
import { StakeDetails } from '../types'

import styles from './component.module.scss'

const StakingDetailsCard = ({
  stakeDetails,
}: {
  stakeDetails: StakeDetails
}) => {
  return (
    <PoolBaseCard label={stakeDetails.label} showHelp={stakeDetails.helpInfo}>
      <div className={styles.walletContainer}>
        <div className={styles.addressContainer}>
          <div className={styles.addressStatus} />
          <div className={styles.addressText}>{stakeDetails.address}</div>
        </div>
      </div>
      <div className={styles.flexRow}>
        <div className={styles.indicatorLabel}>Available balance</div>
        <div className={styles.indicatorLabel}>Stake and % allocation</div>
      </div>
      {stakeDetails.stakeStatuses.map((status, index) => (
        <div className={styles.flexRow} key={index}>
          <div className={styles.informationLabel}>
            {status.balance} {status.ticker}
          </div>
          <div className={cls(styles.informationLabel, styles.withStatus)}>
            {status.stakedAmount} {status.ticker} ({status.stakedPercentage}%)
            {
              <div className={styles.statusContainer}>
                <Checkmark
                  className={styles.status}
                  fill={'#000'}
                  stroke={'#000'}
                />
              </div>
            }
          </div>
        </div>
      ))}
    </PoolBaseCard>
  )
}

export { StakingDetailsCard }
