// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bwj4eoxLV2sN_3oyiPWV{height:.5px;width:100%;background-color:rgba(255,255,255,.12)}`, "",{"version":3,"sources":["webpack://./src/common/components/HorizontalBar/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,sCAAA","sourcesContent":[".main {\n  height: 0.5px;\n  width: 100%;\n  background-color: rgba(#fff, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Bwj4eoxLV2sN_3oyiPWV`
};
export default ___CSS_LOADER_EXPORT___;
