import React from 'react'
import Decimal from 'decimal.js'

import { Button } from '@lattice/common/components'
import {
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import { ReactComponent as StatusDotIcon } from '@lattice/assets/icons/custom/statusdot.svg'
import { FetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import {
  useWalletProvider,
  OnChainVendorProviders,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import {
  ProgramCard,
  ProgramCardTitle,
  RoundedFocusBox,
  Stat,
  StatsList,
} from '../../../SoftNodeProgramDetailView/components'

import styles from './component.module.scss'

const AlkimiStakingActions = ({
  userActionOperation,
  userActionOperationProgressToasts,
}: {
  userActionOperation: FetchableOperation
  userActionOperationProgressToasts: ReturnType<typeof useProgressToasts>
}) => {
  const { activeWallet } = useWalletProvider()
  const {
    project,
    projectUser,
    doUserWithdraw,
    doUserWithdrawRewards,
    requestProjectUser,
    requestProject,
  } = OnChainVendorProviders.useAlkimiStakingProvider()

  if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
    return null
  }

  const doUnstakeFunds = userActionOperation.wrappedFetch(
    userActionOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (!project.resource) {
        throw new Error('Invalid alkimi project state')
      }

      if (!projectUser.resource) {
        throw new Error('Invalid alkimi project user state')
      }

      await doUserWithdraw(
        project.resource,
        projectUser.resource,
        activeWallet.ethereum.library.getSigner(),
        userActionOperationProgressToasts
      )

      await requestProjectUser(project.resource, activeWallet.ethereum.account)
      await requestProject(project.resource.contract.address)
    })
  )

  const doWithdrawRewards = userActionOperation.wrappedFetch(
    userActionOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (!project.resource) {
        throw new Error('Invalid alkimi project state')
      }

      if (!projectUser.resource) {
        throw new Error('Invalid alkimi project user state')
      }

      await doUserWithdrawRewards(
        project.resource,
        projectUser.resource,
        activeWallet.ethereum.library.getSigner(),
        userActionOperationProgressToasts
      )

      await requestProjectUser(project.resource, activeWallet.ethereum.account)
      await requestProject(project.resource.contract.address)
    })
  )

  return (
    <>
      <ProgramCard>
        <ProgramCardTitle>Connected Wallet</ProgramCardTitle>
        <RoundedFocusBox>
          <StatusDotIcon width={8} height={8} style={{ color: '#03BC3D' }} />
          {shortenAddress(activeWallet.ethereum.account, 5, 5, '•••')}
        </RoundedFocusBox>
        {project.resource && projectUser.resource && (
          <StatsList>
            <Stat
              title="Total staked"
              value={formatNumberAndCurrency(
                projectUser.resource.balanceOfStake,
                project.resource?.token.symbol,
                NumberFormat.DECIMALS
              )}
            />
            <Stat
              title="Your pool allocation"
              value={`${formatNumber(
                projectUser.resource.balanceOfStake
                  .div(project.resource.totalStaked)
                  .mul(100),
                NumberFormat.DECIMALS
              )}%`}
            />
            <Stat
              title="User total rewards"
              value={formatNumberAndCurrency(
                projectUser.resource.balanceOfRewards,
                project.resource?.token.symbol,
                NumberFormat.DECIMALS
              )}
            />
            <Stat
              title="User available rewards"
              value={formatNumberAndCurrency(
                Decimal.max(
                  0,
                  projectUser.resource.balanceOfRewards.minus(
                    projectUser.resource.balanceOfRewardsReceived
                  )
                ),
                project.resource?.token.symbol,
                NumberFormat.DECIMALS
              )}
            />
            <Stat
              title="User claimed rewards"
              value={formatNumberAndCurrency(
                projectUser.resource.balanceOfRewardsReceived,
                project.resource?.token.symbol,
                NumberFormat.DECIMALS
              )}
            />
          </StatsList>
        )}
        {userActionOperation.error && (
          <span className={styles.error}>
            {String(userActionOperation.error.message)}
          </span>
        )}
        <Button
          variants={['full-width', 'primary']}
          loading={userActionOperation.status === FetchStatus.PENDING}
          disabled={
            userActionOperation.status === FetchStatus.PENDING ||
            !projectUser.resource ||
            Decimal.max(
              0,
              projectUser.resource.balanceOfRewards.minus(
                projectUser.resource.balanceOfRewardsReceived
              )
            ).isZero()
          }
          onClick={
            userActionOperation.status === FetchStatus.PENDING ||
            !projectUser.resource ||
            Decimal.max(
              0,
              projectUser.resource.balanceOfRewards.minus(
                projectUser.resource.balanceOfRewardsReceived
              )
            ).isZero()
              ? undefined
              : doWithdrawRewards
          }
        >
          Withdraw available rewards
        </Button>
        <Button
          variants={['full-width', 'primary']}
          loading={userActionOperation.status === FetchStatus.PENDING}
          disabled={
            userActionOperation.status === FetchStatus.PENDING ||
            !projectUser.resource ||
            projectUser.resource.balanceOfStake.isZero()
          }
          onClick={
            userActionOperation.status === FetchStatus.PENDING ||
            !projectUser.resource ||
            projectUser.resource.balanceOfStake.isZero()
              ? undefined
              : doUnstakeFunds
          }
        >
          Unstake funds
        </Button>
      </ProgramCard>
    </>
  )
}

export { AlkimiStakingActions }
