import React from 'react'

import styles from './component.module.scss'

const StatCard = ({
  title,
  value,
  children,
}: {
  title: string
  value: string
  children: React.ReactNode
}) => {
  return (
    <div className={styles.actionCardContainer}>
      <div className={styles.actionCardInfo}>
        <div className={styles.actionCardTitle}>{title}</div>
        <div className={styles.actionCardValue}>{value}</div>
      </div>
      <div className={styles.actionCardActions}>{children}</div>
    </div>
  )
}

export { StatCard }
