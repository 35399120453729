// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fASRuM6KpPB68SVohw9M{box-shadow:none}.tRFhZTsvHq8H9B00XwFP{display:flex;width:100%;flex-flow:row nowrap;justify-content:center;align-items:center;column-gap:90px;padding:30px 30px}`, "",{"version":3,"sources":["webpack://./src/common/components/InlineStatsCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,oBAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".root {\n  box-shadow: none;\n}\n\n.main {\n  display: flex;\n  width: 100%;\n\n  flex-flow: row nowrap;\n  justify-content: center;\n  align-items: center;\n  column-gap: 90px;\n  padding: 30px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `fASRuM6KpPB68SVohw9M`,
	"main": `tRFhZTsvHq8H9B00XwFP`
};
export default ___CSS_LOADER_EXPORT___;
