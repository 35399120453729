import React from 'react'
import cls from 'classnames'

import styles from './index.module.scss'

const GridContainer = ({
  children,
  relative,
}: {
  children: React.ReactNode
  relative?: boolean
}) => {
  return (
    <div className={cls(styles.gridContainer, relative && styles.relative)}>
      {children}
    </div>
  )
}

export { GridContainer }
