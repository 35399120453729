import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const RoundedIcon = ({
  tooltip,
  icon,
  className,
}: {
  tooltip?: string
  icon: string | React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cls(styles.main, className)}
      {...(tooltip
        ? {
            'data-tooltip-id': 'lattice',
            'data-tooltip-content': tooltip,
            'data-tooltip-place': 'bottom',
          }
        : {})}
    >
      {typeof icon === 'string' ? <img src={icon} /> : <div>{icon}</div>}
    </div>
  )
}

export { RoundedIcon }
