import React, { useEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import Decimal from 'decimal.js'
import dayjs from 'dayjs'

import {
  ILiquidityStakingProgramAndContext,
  useLiquidityStakingProvider,
} from '@lattice/common/providers/LiquidityStakingProvider'
import {
  formatNumberAndCurrency,
  getNetworkEthersProvider,
  NumberFormat,
  requestProviderChainUpdateIfNeeded,
} from '@lattice/utils'
import {
  useERC20CurrencyBalance,
  useLockFetchableOperation,
  useProgressToasts,
} from '@lattice/common/hooks'
import { useWalletProvider } from '@lattice/common/providers'
import {
  AvailableChain,
  ChainInfo,
  DeploymentStageMasterNetworkChains,
  FetchStatus,
  getAvailableChainByChainId,
} from '@lattice/common/consts'

import { BaseCard } from '../../../../common/components/BaseCard'
import { Button } from '../../../../common/components/Button'
import { BaseModal } from '../../../../common/components/BaseModal'

import styles from './component.module.scss'
import {
  StatCard,
  ActionCard,
  Header,
  LiFiWidgetModal,
  RequiredCondition,
  RoundedIcon,
} from './components'

const StakingProgramCard = Object.assign(
  ({
    program,
    actionFinished,
  }: {
    program: ILiquidityStakingProgramAndContext
    actionFinished: () => void
  }) => {
    const { activeWallet, requestConnectorActivation } = useWalletProvider()
    const { getProgramStrategy } = useLiquidityStakingProvider()
    const unboundProvider = useMemo(
      () => getNetworkEthersProvider(program.programContractNetwork),
      []
    )

    const [activeWalletChain, setActiveWalletChain] =
      useState<AvailableChain | null>(null)

    const [rowOpen, setRowOpen] = useState(false)
    const [lifiModalOpen, setLifiModalOpen] = useState(false)

    const [modalAction, setModalAction] = useState<'stake' | 'withdraw' | null>(
      null
    )
    const stakeOperationLock = useLockFetchableOperation({ skipErrors: true })

    const stakingTokenBalance = useERC20CurrencyBalance(
      activeWallet.status === 'connected' && activeWallet.ethereum
        ? activeWallet.ethereum.account
        : null,
      program.stakingTokenAddress,
      program.stakingTokenSymbol,
      program.programContractNetwork
    )

    const programStrategy = getProgramStrategy(program)
    const programStrategyActionProgressToasts = useProgressToasts()

    const programTargetChain =
      DeploymentStageMasterNetworkChains[program.programContractNetwork]
    const programTargetChainInfo = ChainInfo[programTargetChain]

    const isValidActiveWalletChain = activeWalletChain === programTargetChain
    const isValidActiveWallet =
      activeWallet.status === 'connected' && activeWallet.ethereum
    const isValidStakingDate =
      (program.programContext.startsAt === null ||
        dayjs().isAfter(dayjs(program.programContext.startsAt))) &&
      (program.programContext.endsAt === null ||
        dayjs().isBefore(dayjs(program.programContext.endsAt)))

    const doStake = stakeOperationLock.operationWrapper(
      programStrategyActionProgressToasts.wrappedErrorsAsync(
        /**
         * @param amount denormalized amount (not raw)
         * @param claimRewards whether the user wants to claim existing rewards while staking again
         */
        async (amount: Decimal, claimRewards: boolean) => {
          if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
            throw new Error('Invalid wallet state')
          }

          if (!stakingTokenBalance.currencyUtil.resource) {
            throw new Error('Invalid staking token balance state')
          }

          if (
            amount
              .plus(program.addressContext?.totalStaked ?? 0)
              .lt(new Decimal(program.programContext.minStakingAmount ?? 0))
          ) {
            throw new Error(
              `A minimum of ${program.programContext.minRewardAmount} ${program.stakingTokenSymbol} is needed to stake`
            )
          }

          const signer = activeWallet.ethereum.library.getSigner()

          const walletChainId = await signer.getChainId()

          if (walletChainId !== programTargetChainInfo.rpcChainId) {
            throw new Error(
              `Bad wallet chain id => 0x${walletChainId.toString(
                16
              )} must be 0x${programTargetChainInfo.rpcChainId.toString(16)} (${
                programTargetChainInfo.chainName
              })`
            )
          }

          const allowance = new Decimal(
            (
              await stakingTokenBalance.currencyContract.allowance(
                activeWallet.ethereum.account,
                program.programContractAddress
              )
            ).toString()
          ).div(stakingTokenBalance.currencyUtil.resource.decimalFactor)

          if (allowance.lt(amount)) {
            // We need to approve the staking pool for spending the tokens in behalf the user

            await stakingTokenBalance.currencyContract.estimateGas.approve(
              program.programContractAddress,
              amount
                .times(stakingTokenBalance.currencyUtil.resource.decimalFactor)
                .toFixed(),
              { from: activeWallet.ethereum.account }
            )

            programStrategyActionProgressToasts.progress(
              'Requesting allowance transaction approval',
              'info',
              null
            )

            const trx = await stakingTokenBalance.currencyContract
              .connect(signer)
              .approve(
                program.programContractAddress,
                amount
                  .times(
                    stakingTokenBalance.currencyUtil.resource.decimalFactor
                  )
                  .toFixed()
              )

            programStrategyActionProgressToasts.progress(
              'Awaiting network confirmation',
              'info',
              null
            )

            await trx.wait(3)

            programStrategyActionProgressToasts.progress(
              'Successfully approved token spend',
              'info',
              5000
            )
          }

          programStrategyActionProgressToasts.progress(
            'Preparing transaction',
            'info',
            null
          )

          const stakeTrxData = await programStrategy.userStake(
            activeWallet.ethereum.account,
            amount,
            claimRewards
          )

          await unboundProvider.call({
            from: activeWallet.ethereum.account,
            gasLimit: 1_000_000,
            ...stakeTrxData,
          })

          console.log('x1')

          programStrategyActionProgressToasts.progress(
            'Requesting transaction approval',
            'info',
            null
          )

          const trx = await signer.sendTransaction(stakeTrxData)

          programStrategyActionProgressToasts.progress(
            'Awaiting network confirmation',
            'info',
            null
          )

          await trx.wait(3)

          programStrategyActionProgressToasts.progress(
            [
              'Your tokens have been staked!',
              formatNumberAndCurrency(
                amount,
                program.stakingTokenSymbol,
                NumberFormat.DECIMALS_OR_8_ON_TINY
              ),
            ].join('\n'),
            'success',
            15000
          )

          stakingTokenBalance.fetchBalance()
          actionFinished()
        }
      )
    )

    const doWithdraw = stakeOperationLock.operationWrapper(
      programStrategyActionProgressToasts.wrappedErrorsAsync(
        /**
         * @param amount denormalized amount (not raw)
         * @param claimRewards whether the user wants to claim existing rewards while withdrawing
         * @param ignoreRewards whether the user wants to waive existing rewards in the case the user is withdrawing from the program entirely
         */
        async (
          amount: Decimal,
          claimRewards: boolean,
          ignoreRewards: boolean
        ) => {
          if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
            throw new Error('Invalid wallet state')
          }

          const signer = activeWallet.ethereum.library.getSigner()

          const walletChainId = await signer.getChainId()

          if (walletChainId !== programTargetChainInfo.rpcChainId) {
            throw new Error(
              `Bad wallet chain id => 0x${walletChainId.toString(
                16
              )} must be 0x${programTargetChainInfo.rpcChainId.toString(16)} (${
                programTargetChainInfo.chainName
              })`
            )
          }

          programStrategyActionProgressToasts.progress(
            'Preparing transaction',
            'info',
            null
          )

          try {
            const stakeTrxData = await programStrategy.userWithdraw(
              activeWallet.ethereum.account,
              amount,
              claimRewards,
              ignoreRewards
            )

            await unboundProvider.estimateGas({
              from: activeWallet.ethereum.account,
              ...stakeTrxData,
            })

            programStrategyActionProgressToasts.progress(
              'Requesting transaction approval',
              'info',
              null
            )

            const trx = await signer.sendTransaction(stakeTrxData)

            programStrategyActionProgressToasts.progress(
              'Awaiting network confirmation',
              'info',
              null
            )

            await trx.wait(3)
          } catch (e: any) {
            const json: {
              id: number
              jsonrpc: string
              error: { code: number; data: string; message: string }
            } = JSON.parse(e.error.body)
            if (json.error.message.includes('Not enough rewards')) {
              throw new Error(json.error.message.split(':')[1])
            }
          }

          programStrategyActionProgressToasts.progress(
            [
              'Your tokens have been withdrawn!',
              formatNumberAndCurrency(
                amount,
                program.stakingTokenSymbol,
                NumberFormat.DECIMALS_OR_8_ON_TINY
              ),
            ].join('\n'),
            'success',
            15000
          )

          stakingTokenBalance.fetchBalance()
          actionFinished()
        }
      )
    )

    const doClaimRewards = stakeOperationLock.operationWrapper(
      programStrategyActionProgressToasts.wrappedErrorsAsync(
        /**
         * @param amount denormalized amount (not raw), some programs do not support this option (e.g. LatticeFixedRewardStaking)
         */
        async (amount: Decimal) => {
          if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
            throw new Error('Invalid wallet state')
          }

          if (
            new Decimal(program.addressContext?.availableRewards ?? 0).lt(
              new Decimal(program.programContext.minRewardAmount)
            )
          ) {
            throw new Error(
              `A minimum of ${program.programContext.minRewardAmount} ${program.stakingTokenSymbol} is needed to claim rewards`
            )
          }

          const signer = activeWallet.ethereum.library.getSigner()

          const walletChainId = await signer.getChainId()

          if (walletChainId !== programTargetChainInfo.rpcChainId) {
            throw new Error(
              `Bad wallet chain id => 0x${walletChainId.toString(
                16
              )} must be 0x${programTargetChainInfo.rpcChainId.toString(16)} (${
                programTargetChainInfo.chainName
              })`
            )
          }

          programStrategyActionProgressToasts.progress(
            'Preparing transaction',
            'info',
            null
          )

          const stakeTrxData = await programStrategy.userClaimRewards(
            activeWallet.ethereum.account,
            amount
          )
          try {
            await unboundProvider.estimateGas({
              from: activeWallet.ethereum.account,
              ...stakeTrxData,
            })

            programStrategyActionProgressToasts.progress(
              'Requesting transaction approval',
              'info',
              null
            )

            const trx = await signer.sendTransaction(stakeTrxData)

            programStrategyActionProgressToasts.progress(
              'Awaiting network confirmation',
              'info',
              null
            )

            await trx.wait(3)
          } catch (e: any) {
            const json: {
              id: number
              jsonrpc: string
              error: { code: number; data: string; message: string }
            } = JSON.parse(e.error.body)
            if (json.error.message.includes('Not enough rewards')) {
              throw new Error(json.error.message.split(':')[1])
            }
          }

          programStrategyActionProgressToasts.progress(
            'Successfully claimed rewards!',
            'success',
            15000
          )
          stakingTokenBalance.fetchBalance()
          actionFinished()
        }
      )
    )

    useEffect(() => {
      if (rowOpen) {
        stakingTokenBalance.fetchBalance()
        actionFinished()
      }
    }, [rowOpen])

    useEffect(() => {
      ;(async () => {
        if (activeWallet.status === 'connected' && activeWallet.ethereum) {
          const activeWalletChainId = await activeWallet.ethereum.library
            .getSigner()
            .getChainId()

          setActiveWalletChain(getAvailableChainByChainId(activeWalletChainId))
        } else {
          setActiveWalletChain(null)
        }
      })()
    }, [activeWallet])

    return (
      <BaseCard
        variants={['full-width']}
        header={
          <Header
            stakedInfo={{
              networkName: programTargetChainInfo.chainName,
              networkIcon: programTargetChainInfo.chainLogoUrl,
              tokenToStake: program.stakingTokenSymbol,
              tokenToStakeIcon: program.stakingTokenLogoUrl,
              tokenToReward: program.rewardTokenSymbol,
              tokenToRewardIcon: program.rewardTokenLogoUrl,
              totalStakedInPool: new Decimal(
                program.programContext.totalStaked
              ),
              userTotalStaked: new Decimal(
                program.addressContext?.totalStaked ?? 0
              ),
              apy: new Decimal(program.programContext.apy),
            }}
            isWalletConnected={
              !!(activeWallet.status === 'connected' && activeWallet.ethereum)
            }
            onToggle={() => setRowOpen((prev) => !prev)}
            opened={rowOpen}
          />
        }
        className={{ header: cls(styles.header, !rowOpen && styles.closed) }}
      >
        {rowOpen && (
          <>
            {!isValidActiveWalletChain && activeWalletChain && (
              <RequiredCondition>
                Connect your wallet to{' '}
                <b>{ChainInfo[programTargetChain].chainName}</b> to interact
                with this staking pool
              </RequiredCondition>
            )}
            {!isValidStakingDate && (
              <RequiredCondition>
                This staking pool has ended. Withdraw your rewards
              </RequiredCondition>
            )}
            <div className={styles.stakingPoolContainer}>
              <StatCard
                title={'Your wallet balance'}
                value={
                  stakingTokenBalance.balance.status === FetchStatus.DONE &&
                  stakingTokenBalance.balance.resource !== null
                    ? formatNumberAndCurrency(
                        stakingTokenBalance.balance.resource,
                        program.stakingTokenSymbol,
                        NumberFormat.DECIMALS_OR_8_ON_TINY
                      )
                    : 'Connect your wallet'
                }
              >
                <div className={styles.doubleButton}>
                  {isValidActiveWallet && isValidActiveWalletChain && (
                    <>
                      <div className={styles.button}>
                        {program.stakingTokenExchangeUrl ? (
                          <Button.LinkExternal
                            target="_blank"
                            href={
                              stakeOperationLock.inLockState ||
                              !isValidActiveWalletChain
                                ? '#'
                                : program.stakingTokenExchangeUrl
                            }
                            variants={['primary-outlined', 'full-width']}
                            disabled={
                              stakeOperationLock.inLockState ||
                              !isValidActiveWalletChain
                            }
                          >
                            Get {program.stakingTokenSymbol}
                          </Button.LinkExternal>
                        ) : (
                          <Button
                            variants={['primary-outlined', 'full-width']}
                            onClick={() => {
                              isValidActiveWalletChain &&
                                !stakeOperationLock.inLockState &&
                                setLifiModalOpen((prev) => !prev)
                            }}
                            disabled={
                              stakeOperationLock.inLockState ||
                              !isValidActiveWalletChain
                            }
                          >
                            Get {program.stakingTokenSymbol}
                          </Button>
                        )}
                      </div>
                      <div className={styles.button}>
                        <Button
                          variants={['primary', 'full-width']}
                          onClick={() =>
                            isValidActiveWallet &&
                            isValidActiveWalletChain &&
                            isValidStakingDate &&
                            stakingTokenBalance.balance.status ===
                              FetchStatus.DONE &&
                            stakingTokenBalance.balance.resource &&
                            !stakeOperationLock.inLockState &&
                            setModalAction('stake')
                          }
                          loading={
                            stakingTokenBalance.balance.status ===
                            FetchStatus.PENDING
                          }
                          disabled={
                            !isValidActiveWalletChain ||
                            stakeOperationLock.inLockState ||
                            !isValidStakingDate
                          }
                        >
                          {!stakeOperationLock.inLockState
                            ? 'Stake tokens'
                            : 'Staking...'}
                        </Button>
                      </div>
                    </>
                  )}
                  {!isValidActiveWallet && !isValidActiveWalletChain && (
                    <div className={styles.button}>
                      <Button
                        variants={['primary', 'full-width']}
                        onClick={() => requestConnectorActivation()}
                      >
                        Connect wallet
                      </Button>
                    </div>
                  )}
                  {isValidActiveWallet && !isValidActiveWalletChain && (
                    <div className={styles.button}>
                      <Button
                        variants={['primary', 'full-width']}
                        onClick={() =>
                          requestProviderChainUpdateIfNeeded(
                            activeWallet.ethereum.library,
                            programTargetChainInfo.rpcChainId
                          )
                        }
                      >
                        Change wallet network
                      </Button>
                    </div>
                  )}
                </div>
              </StatCard>
              <div className={styles.totalCardStaked}>
                <StatCard
                  title={'Total Staked'}
                  value={formatNumberAndCurrency(
                    new Decimal(program.addressContext?.totalStaked ?? 0),
                    program.stakingTokenSymbol,
                    NumberFormat.DECIMALS_OR_8_ON_TINY
                  )}
                >
                  <Button
                    variants={['primary', 'full-width']}
                    onClick={() =>
                      isValidActiveWallet &&
                      isValidActiveWalletChain &&
                      program.addressContext?.totalStaked &&
                      !new Decimal(
                        program.addressContext?.totalStaked
                      ).isZero() &&
                      stakingTokenBalance.balance.status === FetchStatus.DONE &&
                      stakingTokenBalance.balance.resource &&
                      !stakeOperationLock.inLockState &&
                      setModalAction('withdraw')
                    }
                    disabled={
                      !isValidActiveWalletChain ||
                      !program.addressContext?.totalStaked ||
                      new Decimal(
                        program.addressContext?.totalStaked
                      ).isZero() ||
                      stakeOperationLock.inLockState
                    }
                  >
                    Withdraw {program.stakingTokenSymbol} tokens
                  </Button>
                </StatCard>
              </div>
              <div className={styles.totalCardEarned}>
                <StatCard
                  title={'Total Earned'}
                  value={formatNumberAndCurrency(
                    new Decimal(program.addressContext?.availableRewards ?? 0),
                    program.rewardTokenSymbol,
                    NumberFormat.DECIMALS_OR_8_ON_TINY
                  )}
                >
                  <Button
                    variants={['primary', 'full-width']}
                    onClick={() =>
                      new Decimal(
                        program.addressContext?.availableRewards ?? 0
                      ).gt(0) &&
                      !stakeOperationLock.inLockState &&
                      isValidActiveWalletChain &&
                      doClaimRewards(
                        new Decimal(
                          program.addressContext?.availableRewards ?? 0
                        )
                      )
                    }
                    disabled={
                      !isValidActiveWalletChain ||
                      !program.addressContext?.availableRewards ||
                      new Decimal(
                        program.addressContext?.availableRewards
                      ).isZero() ||
                      stakeOperationLock.inLockState
                    }
                  >
                    Claim rewards
                  </Button>
                </StatCard>
              </div>
              {modalAction && (
                <BaseModal
                  variants={['primary']}
                  onClickOutside={() =>
                    !stakeOperationLock.inLockState && setModalAction(null)
                  }
                >
                  {modalAction === 'stake' &&
                    isValidActiveWallet &&
                    isValidActiveWalletChain &&
                    stakingTokenBalance.balance.resource && (
                      <ActionCard.Stake
                        address={activeWallet.ethereum.account}
                        stakingTokenBalance={
                          stakingTokenBalance.balance.resource
                        }
                        program={program}
                        onCancel={() =>
                          !stakeOperationLock.inLockState &&
                          setModalAction(null)
                        }
                        onSubmit={async (values) => {
                          await doStake(
                            new Decimal(values.amount),
                            values.claimRewards
                          )
                          setModalAction(null)
                        }}
                      />
                    )}

                  {modalAction === 'withdraw' &&
                    isValidActiveWallet &&
                    isValidActiveWalletChain &&
                    stakingTokenBalance.balance.resource && (
                      <ActionCard.Withdraw
                        address={activeWallet.ethereum.account}
                        stakingTokenBalance={
                          stakingTokenBalance.balance.resource
                        }
                        program={program}
                        onCancel={() =>
                          !stakeOperationLock.inLockState &&
                          setModalAction(null)
                        }
                        onSubmit={async (values) => {
                          await doWithdraw(
                            new Decimal(values.amount),
                            values.claimRewards,
                            false
                          )
                          setModalAction(null)
                        }}
                      />
                    )}
                </BaseModal>
              )}
              {lifiModalOpen && (
                <LiFiWidgetModal
                  onClickOutside={() => setLifiModalOpen(false)}
                  targetChain={programTargetChain}
                  targetTokenAddress={program.stakingTokenAddress}
                />
              )}
            </div>
          </>
        )}
      </BaseCard>
    )
  },
  { RoundedIcon }
)

export { StakingProgramCard }
