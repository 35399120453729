// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o_HO4A1bmS1rSe3y62X1{display:flex;justify-content:center;align-items:center;box-sizing:border-box;width:40px;height:40px;border:1px solid #6e717f;border-radius:48px;overflow:hidden;padding:2px;background-color:#313644}.o_HO4A1bmS1rSe3y62X1>img{width:100%;height:100%;object-fit:contain;border-radius:100%}.o_HO4A1bmS1rSe3y62X1>div{width:100%;height:100%;object-fit:contain;border-radius:100%}`, "",{"version":3,"sources":["webpack://./src/views/StakingPoolsView/components/StakingProgramCard/components/RoundedIcon/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,sBAAA,CACA,kBAAA,CACA,qBAAA,CAEA,UAAA,CACA,WAAA,CAEA,wBAAA,CACA,kBAAA,CACA,eAAA,CACA,WAAA,CACA,wBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CAGF,0BACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":[".main {\n  display: flex;\n\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n\n  width: 40px;\n  height: 40px;\n\n  border: 1px solid #6e717f;\n  border-radius: 48px;\n  overflow: hidden;\n  padding: 2px;\n  background-color: #313644;\n\n  > img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    border-radius: 100%;\n  }\n\n  > div {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    border-radius: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `o_HO4A1bmS1rSe3y62X1`
};
export default ___CSS_LOADER_EXPORT___;
