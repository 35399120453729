import React from 'react'
import cls from 'classnames'
import { Link } from 'react-router-dom'

import { BaseCard } from '../BaseCard'
import { HeaderCardTitle } from '../Typography/components/HeaderCardTitle'
import { Button } from '../Button'
import { Badge, BadgeColors, BadgeColorsType } from '../Badge'

import styles from './component.module.scss'

type InfoData = {
  label: string
  text?: string
  badge?: { text: string; color: BadgeColors | BadgeColorsType }
  linkTo?: string
}

type FeaturedProgram = {
  title?: string
  subTitle?: string
  nodeType: string
  buttonLabel?: string
  displayInfo: InfoData[]
} & ({ buttonAction?: () => void } | { to?: string })

type HeaderInfo = {
  label: string
  value: string
}

const HeroCard = ({
  headerImage,
  headerLogo,
  headerTitle,
  headerInfo,
  data,
  variant = 'flexContent',
  className,
}: {
  headerImage: string
  headerLogo?: string
  headerTitle?: string
  headerInfo?: HeaderInfo
  data: FeaturedProgram
  variant?: 'flexContent' | 'gridContent'
  className?: {
    header?: string
    footer?: string
    body?: string
    content?: string
    root?: string
  }
}) => {
  const Header = (
    <div className={styles.banner}>
      <img
        src={headerImage}
        className={cls(styles.curvedTopBorder, styles.backgroundImage)}
      />
      {headerLogo && <img src={headerLogo} className={styles.innerLogo} />}
      {headerTitle && (
        <div className={styles.headerInfoContainer}>
          <HeaderCardTitle>{headerTitle}</HeaderCardTitle>
          <div className={styles.headerInfoValues}>
            <div>{headerInfo?.label}</div>
            <div>{headerInfo?.value}</div>
          </div>
        </div>
      )}
    </div>
  )

  const contentRows = data.displayInfo.reduce(
    (rows, key, index) => {
      index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)
      return rows
    },
    [] as Array<Array<InfoData>>
  )

  const Children = (
    <section
      className={cls(
        styles.body,
        variant === 'flexContent' ? styles.flexContentBody : null
      )}
    >
      <div
        className={cls(
          styles.content,
          variant === 'flexContent' ? styles.flexContent : null
        )}
      >
        {data.title && (
          <div className={styles.contentRow}>
            <div className={styles.title}>{data.title}</div>
          </div>
        )}
        {data.subTitle && (
          <div className={styles.contentRow}>
            <div className={styles.subTitle}>{data.subTitle}</div>
          </div>
        )}
        {contentRows.map((row, index) => (
          <div className={styles.contentRow} key={index}>
            <div className={styles.innerSpacedRow}>
              {row.map((data, index) => (
                <div className={styles.innerColumn} key={index}>
                  <div className={styles.cardLabel}>{data.label}</div>
                  {data.badge && (
                    <Badge text={data.badge.text} color={data.badge.color} />
                  )}
                  {data.text && !data.linkTo && (
                    <div className={styles.cardText}>{data.text}</div>
                  )}
                  {data.text && data.linkTo && (
                    <Link to={data.linkTo} className={styles.linkStyle}>
                      {data.text}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className={cls(styles.contentRow, styles.buttonMargin)}>
          {data.buttonLabel &&
            ('to' in data && data.to ? (
              <Button.Link
                variants={['full-width', 'secondary', 'right-icon']}
                to={data.to}
              >
                {data.buttonLabel}
              </Button.Link>
            ) : (
              'buttonAction' in data &&
              data.buttonAction && (
                <Button
                  variants={['full-width', 'secondary', 'right-icon']}
                  onClick={data.buttonAction}
                >
                  {data.buttonLabel}
                </Button>
              )
            ))}
        </div>
      </div>
    </section>
  )

  return (
    <BaseCard
      header={Header}
      variants={['narrow']}
      className={{
        ...className,
        header: cls(styles.bountyHeader, className?.header),
      }}
    >
      {Children}
    </BaseCard>
  )
}

export { HeroCard }
