import React from 'react'
import { default as ReactSelect, components } from 'react-select'

import styles from './component.module.scss'
import { customReactSelectStyles } from './customReactSelectStyles'

const NoOptionsMessage = (noOptionsMessage: string) => (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>{noOptionsMessage}</span>
    </components.NoOptionsMessage>
  )
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className={styles.container}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <span className={styles.checkmark}></span>
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  )
}

const DropdownMulti = ({
  placeholder,
  noOptionsMessage,
  setOptionSelected,
  options,
}: {
  placeholder: string
  noOptionsMessage: string
  setOptionSelected: React.Dispatch<React.SetStateAction<string[]>>
  options: { label: string; value: string }[]
}) => {
  const handleChange = (selectedOptions) => {
    const options = selectedOptions.map((option) => option.value)
    setOptionSelected([...options])
  }
  return (
    <div className={styles.main}>
      <ReactSelect
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        placeholder={placeholder}
        components={{
          Option,
          NoOptionsMessage: NoOptionsMessage(noOptionsMessage),
          IndicatorSeparator: () => null,
          MultiValueRemove: () => null,
        }}
        onChange={handleChange}
        styles={customReactSelectStyles}
      />
    </div>
  )
}

export { DropdownMulti }
