import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const DetailsCardWrapper = ({
  children,
  variants,
}: {
  children: React.ReactNode
  variants?: 'as-header'[]
}) => {
  return (
    <div
      className={cls(
        styles.cardContainer,
        variants?.includes('as-header') && styles.borderless
      )}
    >
      {children}
    </div>
  )
}

export { DetailsCardWrapper }
