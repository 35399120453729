import { FetchableResource } from '@lattice/common/hooks'

import { requestPrograms } from './utils'

enum RewardProgramType {
  SOFT_NODE = 'soft_node',
  BOUNTY = 'bounty',
  AIRDROP = 'airdrop',
  STAKING = 'staking',
}

enum RewardProgramStatus {
  DRAFT = 'draft',
  COMMING_SOON = 'comming_soon',
  ENROLLING = 'enrolling',
  LIVE = 'live',
  DISTRIBUTING = 'distributing',
  COMPLETE = 'complete',
  SOON = 'soon',
}

type RewardProgram = {
  type: RewardProgramType
  status: RewardProgramStatus
  title: string
  rewardsType: string
  requirements: string
  extraInfo: string
  url: string
  imageUrl: string | null
  timeline: { startsAt: IISODate; endsAt: IISODate } | 'ongoing'
}

type IRewardsProgramsProviderContext = {
  programs: FetchableResource<RewardProgram[]>
  requestPrograms: typeof requestPrograms
}

export {
  RewardProgramType,
  RewardProgramStatus,
  RewardProgram,
  IRewardsProgramsProviderContext,
}
