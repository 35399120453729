import React, { useRef, useState } from 'react'
import cls from 'classnames'
import { PlacesType } from 'react-tooltip'

import { useOnClickOutside } from '@lattice/common/hooks'

import styles from '../../component.module.scss'

import istyles from './component.module.scss'

type SelectInputRowOption =
  | string
  | {
      value: any
      content?: React.ReactNode
      leftContent?: React.ReactNode
      rightContent?: React.ReactNode
    }

const SelectInputOption = ({
  option,
  onOptionClick,
}: {
  option: SelectInputRowOption
  onOptionClick: (option: SelectInputRowOption) => void
}) => {
  const leftContent =
    (typeof option === 'string'
      ? option
      : option.content ?? option.leftContent) ?? null
  const rightContent =
    (typeof option === 'string' ? null : option.rightContent) ?? null

  return (
    <div className={istyles.option} onClick={() => onOptionClick(option)}>
      {leftContent && <span>{leftContent}</span>}
      {rightContent && <span>{rightContent}</span>}
    </div>
  )
}

const SelectInputRow = ({
  options,
  variants,
  label,
  placeholder,
  error,
  icon,
  labelIcon,
  iconTooltip,
  tooltipPlace,
  value,
  onChange,
  onFocus,
  onBlur,
  onIconClick,
  classNames,
}: {
  options: SelectInputRowOption[]
  variants?: ('full-width' | 'disabled')[]
  label?: string
  placeholder?: string
  error?: string
  icon?: React.ReactNode
  labelIcon?: React.ReactNode
  iconTooltip?: string
  tooltipPlace?: PlacesType
  value?: any
  onChange?: (value: any) => void
  onFocus?: () => void
  onBlur?: () => void
  onIconClick?: () => void
  classNames?: {
    root?: string
    label?: string
    input?: string
    inputWrapper?: string
    error?: string
    icon?: string
    labelIcon?: string
  }
}) => {
  const [selectOpen, setSelectOpen] = useState(false)

  const mainElementRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(mainElementRef, () => {
    onBlur && onBlur()
    setSelectOpen(false)
  })

  const selectedOption = options.find((option) =>
    typeof option === 'string' ? option === value : option.value === value
  )

  return (
    <div
      className={cls(
        styles.root,
        variants?.includes('full-width') && styles.fullWidth,
        classNames?.root
      )}
    >
      {label && (
        <label className={cls(styles.label, classNames?.label)}>
          {label}
          {labelIcon && (
            <p
              data-tooltip-id="lattice"
              data-tooltip-content={iconTooltip}
              data-tooltip-place={tooltipPlace}
              className={styles.noMargin}
            >
              {labelIcon}
            </p>
          )}
        </label>
      )}
      <div
        className={cls(
          styles.inputWrapper,
          istyles.inputWrapper,
          classNames?.inputWrapper
        )}
        ref={mainElementRef}
      >
        <div
          onClick={
            variants?.includes('disabled')
              ? () => void 0
              : () => {
                  setSelectOpen(!selectOpen)
                  if (selectOpen) {
                    onBlur && onBlur()
                  } else {
                    onFocus && onFocus()
                  }
                }
          }
          className={cls(
            styles.input,
            istyles.input,
            selectOpen && istyles.open,
            variants?.includes('disabled') && styles.disabled,
            error && cls(styles.error, classNames?.error),
            classNames?.input
          )}
        >
          {typeof selectedOption === 'string'
            ? selectedOption
            : selectedOption?.content ??
              selectedOption?.leftContent ??
              selectedOption?.rightContent ??
              placeholder}
        </div>
        <div
          className={cls(styles.icon, classNames?.icon)}
          onClick={(e) => {
            e.stopPropagation()
            onIconClick && onIconClick()
          }}
        >
          {icon}
        </div>
        {selectOpen && (
          <div className={istyles.options}>
            {options.map((option) => (
              <SelectInputOption
                key={typeof option === 'string' ? option : option.value}
                option={option}
                onOptionClick={(option) => {
                  onChange &&
                    onChange(typeof option === 'string' ? option : option.value)
                  onBlur && onBlur()
                  setSelectOpen(false)
                }}
              />
            ))}
          </div>
        )}
      </div>
      {error && (
        <span className={cls(styles.error, classNames?.error)}>{error}</span>
      )}
    </div>
  )
}

export { SelectInputRow }
