import React, { useEffect, useState } from 'react'

import { useIsWindowVisible } from '@lattice/common/hooks'

import { ITokenPriceProviderContext, ITokenPrices } from './types'
import { fetchTokenPrices } from './utils'

const TokenPriceProviderContext =
  React.createContext<ITokenPriceProviderContext | null>(null)

const TokenPriceProvider = ({ children }: { children: React.ReactNode }) => {
  const isWindowVisible = useIsWindowVisible()
  const [tokenPrices, setTokenPrices] = useState<ITokenPrices>({})

  useEffect(() => {
    if (!isWindowVisible) {
      return
    }

    ;(async () => {
      setTokenPrices(await fetchTokenPrices())
    })()

    const intervalId = window.setInterval(async () => {
      setTokenPrices(await fetchTokenPrices())
    }, 20 * 1000)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [isWindowVisible])

  return (
    <TokenPriceProviderContext.Provider value={{ tokenPrices }}>
      {children}
    </TokenPriceProviderContext.Provider>
  )
}

export { TokenPriceProvider, TokenPriceProviderContext }
