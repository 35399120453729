// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bsErDdqncu15S8de44BM{text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/common/components/Dropdown/components/DropdownListItem/components/DropdownListItemLink/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA","sourcesContent":[".main {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `bsErDdqncu15S8de44BM`
};
export default ___CSS_LOADER_EXPORT___;
