// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FfoSX4Ad5pnsscoq0BEL{display:flex;gap:16px}.cM5yJyHq97As23FONnoY{display:flex;width:100%;height:100%;flex-wrap:wrap;justify-content:center;align-items:center;gap:24px;padding-bottom:64px}.YYBMKMWYWu6yKWqctHvI{display:flex;width:100%;justify-content:center;align-items:center;padding-top:68px}.uz49GMJJnpfIUn3XSgPA{margin-top:16px;width:auto}`, "",{"version":3,"sources":["webpack://./src/views/NodeManagerView/views/NodesDashboardView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,eAAA,CACA,UAAA","sourcesContent":[".addNodeButton {\n  display: flex;\n  gap: 16px;\n}\n\n.addNodeCardsContainer {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  padding-bottom: 64px;\n}\n\n.addNodeContainer {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  padding-top: 68px;\n}\n\n.button {\n  margin-top: 16px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addNodeButton": `FfoSX4Ad5pnsscoq0BEL`,
	"addNodeCardsContainer": `cM5yJyHq97As23FONnoY`,
	"addNodeContainer": `YYBMKMWYWu6yKWqctHvI`,
	"button": `uz49GMJJnpfIUn3XSgPA`
};
export default ___CSS_LOADER_EXPORT___;
