import { DeploymentStage } from '@lattice/common/consts'
import { getEnvOrError } from '@lattice/utils'

const makeEnvironmentContext = <E extends Record<string, () => any>>(
  envDefinition: E
) => {
  return new Proxy(
    {},
    {
      get: (t, p) => {
        if (typeof p !== 'string') {
          throw new Error('Inconsistency error')
        }

        return envDefinition[p]()
      },
    }
  ) as { [K in keyof E]: ReturnType<E[K]> }
}

const expandByPrefixes = (prefixes: string[]) => (term: string) =>
  prefixes.map((prefix) => `${prefix}${term}`)

const expandByCommonClientPrefixes = expandByPrefixes([
  'REACT_APP_',
  'NEXT_PUBLIC_',
])

const EnvironmentContext = makeEnvironmentContext({
  nodeEnv: () => getEnvOrError<string>('NODE_ENV'),
  stage: () =>
    getEnvOrError<DeploymentStage>(
      'SST_STAGE',
      ...expandByCommonClientPrefixes('STAGE')
    ),
  EcosystemApiUrl: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('ECOSYSTEM_API_URL')),
  WalletConnectProjectId: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('WALLET_CONNECT_PROJECT_ID')
    ),
})

export { EnvironmentContext }
