import React from 'react'

import styles from './component.module.scss'

const ProgramDetailProjectContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <section className={styles.projectContainer}>{children}</section>
}

export { ProgramDetailProjectContainer }
