import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const StatsList = ({
  keysTitle,
  valuesTitle,
  children,
}: {
  keysTitle?: string
  valuesTitle?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={styles.main}>
      {(keysTitle || valuesTitle) && (
        <div className={cls(styles.contentRow, styles.titles)}>
          <span>{keysTitle}</span>
          <span>{valuesTitle}</span>
        </div>
      )}
      {React.Children.map(children, (child) => (
        <div className={styles.contentRow}>{child}</div>
      ))}
    </div>
  )
}

export { StatsList }
