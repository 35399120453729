/* TODO: Missing Translation */
const text = `
_Last Modified: June 6th, 2022_

**Lattice Gateway** (“Lattice” or “we” or “us” or “our”) recognizes the need to protect the privacy of the personal information you provide to Lattice with respect to your access and use of our website (https://lattice.is) and related services (the “Platform”).

Lattice is a product provided to you by Constellation Network, inc. This Privacy Policy therefore constitutes an agreement between you and Constellation Network, Inc.

Therefore, we have adopted this privacy policy (the “Privacy Policy”), which sets forth, among other things, the type of information that will be collected, the purpose and use of the collected information, and your rights with regard to the collected information. This Privacy Policy governs how we collect and use your personal information wherever you use our Platform. By accessing the Platform, you are consenting to the collection and the use of your information by us, but only to the extent described herein. Should you wish to revoke your consent, you may do so by contacting us, information on which is set out in the Privacy Policy below.

Lattice may make modifications, deletions and/or additions to this Privacy Policy (“Chang-es”) at any time. Changes will be effective: (i) thirty (30) days after Lattice provides notice of the Changes, whether such notice is provided through the Platform user interface, is sent to the email address associated with your account or otherwise; or (ii) when you opt-in or otherwise expressly agree to the Changes or a version of this Privacy Policy incorporating the Changes, whichever comes first.

## Protection of Your Information
We are accountable for the information in our custody. Therefore, when we collect or use your in-formation, we will utilize commercially reasonable safeguards to ensure its protection. It should be noted that no security procedure is currently 100% effective. Should any breach of your Personal Information occur, we will inform you as soon as reasonably possible, as required by applicable law.

## Type and Purpose of Collection
We collect information at various points in the Platform to facilitate its use by our users. Specifically, two types of information are collected:

1. **Non-Personal Information:** Upon accessing the Platform, certain non-personal information will be automatically collected without your knowledge or consent, such as your IP address, location data and the referring website (“Non-Personal Information”). We use Non-Personal Information to examine our traffic and to view how our customers use the Platform. This type of information will not allow you to be personally identified although we might be able to associate it with your account. For example, we use “cookies,” which con-tain only certain statistical information. For more information on cookies please find more information below.
2. **Identifying Personal Information:** To utilize some portions of the Platform, you must first provide personal information that will allow you to be identified, such as your name and e-mail address (“Personal Information”). This type of information will not be collected with-out your consent. We expressly acknowledge that we will not use your Personal Information for any other purposes without your consent. The Personal Information we collect will vary depending on how you are using the Platform, but may include, without limitation: (i) your name, mailing address, phone number and email address; (ii) unique identifiers such as user name, account number and password; (iii) preferences information such as product or con-tent interests, communication or marketing preferences; (iv) device information and data; and (v) Google, Twitter and Facebook identity.

Additionally, Personal Information may be used and disclosed to the extent Lattice may deem reasonably necessary to enforce the terms of any agreement between you and Lattice, or to protect the rights, property or safety of any person or entity.

## Right to Examine Information
You have the right to examine any of your Personal Information that we collect. Should you wish to examine such information, please send us a written request to info@lattice.exchange. We reserve the right to charge you a reasonable administrative fee to access your information, as permitted by applicable law. In certain cases, we may not be able to provide you with access to all of your Personal Information (example: if the information also pertains to the Personal Information of another user).

## Withdrawal of Consent
You may withdraw your consent to the collection of Personal Information at any time by sending an email to us. Upon receiving notice that you have revoked your consent, we will stop using your Personal Information within a reasonable time, which will vary depending on what information we have collected and for what purpose. Please note that we will send you an email confirmation upon receipt of your request. Therefore, if you do not receive a confirmation email, please contact us again with your request. If you do choose to withdraw such consent, your access to the Platform may be diminished, or your ability to choose some of the options on the Platform may be limited.

Contact email: info@lattice.exchange

##Sharing Information
We will not sell, rent or disclose to outside parties the information we collect and save except that we may share the collected information with other parties as follows:

1. **Affiliated Service Providers:** We have agreements with various affiliated service providers to facilitate the functioning of the Platform, with whom we may share the information we have collected. For example, we may share your information with services providers for purposed of conducting Know Your Customer and Anti-Money Laundering compliance. Additionally, for example, we may use analytic services.
2. **Where required by law:** We may share the collected information where required by law, spe-cifically in response to a demand from government authorities where such demand meets the legal requirements.
3. **Statistical Analysis:** We may share Non-Personal Information and aggregated information with third parties, including but not limited to for advertising or marketing purposes. No Personal Information will be shared in this manner.
4. **Transactions:** In connection with, or during negotiations of, any merger, sale of company as-sets, reorganization, financing or acquisition, or in any other situation where Personal In-formation may be disclosed or transferred as one of our business assets.

## External Links
The Platform contains links and references to other websites. We are not responsible for the collection, use and disclosure of information, or the privacy practices of such websites, and we expressly disclaim any liability relating thereto.

## International Visitors
The Platform is hosted in the United States and is intended for visitors located within the United States. If you choose to use the Service from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Platform. By providing any information, including personal information, on or to the Service, you consent to such transfer, storage, and processing.

## International Transfer
Both Personal Information and Non-Personal Information you submit via the Platform is sent to Lattice and will be processed in the USA and stored on secure servers located in the USA. Such information may also be transferred by us to our offices and third parties. The countries concerned may not have similar data protection laws to your country. Where we transfer your information out of your jurisdiction we will take reasonable steps to ensure that your privacy rights continue to be protected. By submitting your information to the Platform, you agree to this storing, processing and transfer.

## Children’s Privacy
Protecting the privacy of young children is especially important. Our Platform is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13 without obtaining parental consent. If you are under 13 years of age, then please do not use or access the Platform at any time or in any manner. If we learn that personally identifiable information has been collected on the Platform from persons under 13 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under 13 years of age has obtained an account on the Platform, then you may alert us at info@lattice.exchange and request that we delete that child’s personally identifiable information from our systems.

## Cookies, Remarketing & Similar Technologies
When you interact with the Platform, we try to make that experience simple and meaningful. When you use our Platform, our web server sends a cookie to your computer or mobile device (as the case may be). Cookies are small pieces of information which are issued to your computer or mobile device (as the case may be) when you visit a website or access or use a mobile application and which store and sometimes track information about your use of a website or application (as the case may be). A number of cookies we use last only for the duration of your web session or Platform session and expire when you close your browser or exit the Platform. Other cookies are used to remember you when you return to the Platform and will last for longer.

Some of the cookies used by the Platform are set by us, and some are set by third parties who are delivering services on our behalf.

Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting All About Cookies which includes additional useful information on cookies and how to block cookies using different types of browser or mobile device. Please note, however, that by blocking or deleting cookies used on the Platform, you may not be able to take full advantage of the Platform.

In addition to using cookies and related technologies as described above, we also may permit certain third-party companies to help us tailor advertising that we think may be of interest to users and to collect and use other data about user activities on the Platform (e.g., to allow them to tailor ads on third-party services). These companies may deliver ads that might also place cookies and otherwise track user behavior.

## Our Contact Information
Please contact us with any questions or comments about this Policy, your personal information, our use and disclosure practices, or your consent choices by email at info@lattice.exchange.
`

export { text }
