import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const Stat = ({
  title,
  value,
  variants = ['primary-key', 'primary-value'],
}: {
  title: React.ReactNode
  value: React.ReactNode
  variants?: `${'primary' | 'secondary' | 'highlight'}-${'key' | 'value'}`[]
}) => {
  return (
    <>
      <span
        className={cls(
          styles.main,
          variants?.includes('primary-key') && styles.primary,
          variants?.includes('secondary-key') && styles.secondary,
          variants?.includes('highlight-key') && styles.highlight
        )}
      >
        {title}
      </span>
      <span
        className={cls(
          styles.main,
          variants?.includes('primary-value') && styles.primary,
          variants?.includes('secondary-value') && styles.secondary,
          variants?.includes('highlight-value') && styles.highlight
        )}
      >
        {value}
      </span>
    </>
  )
}

export { Stat }
