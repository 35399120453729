// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uACjkN7ElJsddBR1CwDp{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center}.uACjkN7ElJsddBR1CwDp>span:nth-child(1){color:#03bc3d;font-family:"IBM Plex Mono";font-weight:400;font-size:28px;line-height:36px}.uACjkN7ElJsddBR1CwDp>span:nth-child(2){color:rgba(255,255,255,.66);font-weight:300;font-size:10px;line-height:18px;letter-spacing:.05em}`, "",{"version":3,"sources":["webpack://./src/common/components/InlineStat/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,uBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,wCACE,aAAA,CACA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,wCACE,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".main {\n  display: flex;\n\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n\n  > span:nth-child(1) {\n    color: #03bc3d;\n    font-family: 'IBM Plex Mono';\n    font-weight: 400;\n    font-size: 28px;\n    line-height: 36px;\n  }\n\n  > span:nth-child(2) {\n    color: rgba(#fff, 0.66);\n    font-weight: 300;\n    font-size: 10px;\n    line-height: 18px;\n    letter-spacing: 0.05em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `uACjkN7ElJsddBR1CwDp`
};
export default ___CSS_LOADER_EXPORT___;
