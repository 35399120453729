import React from 'react'

import {
  useFetchableResource,
  usePaginatedFetchableResource,
} from '@lattice/common/hooks'

import { IAirdrop, IAirdropAllocation, IAirdropsProviderContext } from './types'
import {
  requestAirdrop,
  requestAirdrops,
  requestAirdropAllocation,
  requestAirdropAllocationProof,
  requestAirdropAllocationClaim,
  requestAirdropAllocationClaimStatus,
  requestAirdropsAndAvailableAmounts,
} from './utils'

const AirdropsProviderContext =
  React.createContext<IAirdropsProviderContext | null>(null)

const AirdropsProvider = ({ children }: { children: React.ReactNode }) => {
  const airdrops = usePaginatedFetchableResource<IAirdrop[]>([], 25)
  const airdrop = useFetchableResource<IAirdrop | null>(null)
  const airdropAllocation = useFetchableResource<IAirdropAllocation | null>(
    null
  )

  const ctx: IAirdropsProviderContext = {
    airdrops,
    airdrop,
    airdropAllocation,
    requestAirdropsPage: airdrops.createGetPage(requestAirdrops),
    requestAirdropsAndAvailableAmountsPage: airdrops.createGetPage(
      requestAirdropsAndAvailableAmounts
    ),
    requestAirdrop: airdrop.wrappedFetch(requestAirdrop),
    requestAirdropAllocation: airdropAllocation.wrappedFetch(
      requestAirdropAllocation
    ),
    requestAirdropAllocationProof,
    requestAirdropAllocationClaim,
    requestAirdropAllocationClaimStatus,
  }

  return (
    <AirdropsProviderContext.Provider value={ctx}>
      {children}
    </AirdropsProviderContext.Provider>
  )
}

export { AirdropsProvider, AirdropsProviderContext }
