import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'

import {
  BaseCard,
  Button,
  CheckboxField,
  InputRow,
} from '@lattice/common/components'
import { formatNumberAndCurrency, NumberFormat } from '@lattice/utils'

import { ActionCardProps } from '../../types'

import styles from './component.module.scss'

type IActionWithdrawFormValues = {
  amount: number
  claimRewards: boolean
}

const Withdraw = ({
  program,
  stakingTokenBalance,
  onSubmit,
  onCancel,
}: ActionCardProps & {
  onSubmit: (values: IActionWithdrawFormValues) => Promise<any>
}) => {
  const stakeForm = useForm<IActionWithdrawFormValues>({
    defaultValues: { amount: 0, claimRewards: false },
  })

  const onFormSubmit = stakeForm.handleSubmit(async (values) => {
    await onSubmit(values)
    stakeForm.reset()
  })

  return (
    <BaseCard
      className={{ root: styles.root, body: styles.body }}
      variants={['bordered']}
    >
      <div className={styles.title}>Withdraw tokens</div>
      <form className={styles.form} onSubmit={onFormSubmit}>
        <div className={styles.walletBalance}>
          <span>Wallet balance</span>
          <span>
            {formatNumberAndCurrency(
              stakingTokenBalance,
              program.stakingTokenSymbol,
              NumberFormat.DECIMALS
            )}
          </span>
        </div>
        <Controller
          name="amount"
          rules={{
            required: true,
            validate: {
              notZero: (value) => value > 0 || 'Amount must be greater than 0',
              notUnderMin: (value) =>
                new Decimal(program.addressContext?.totalStaked ?? 0)
                  .minus(value)
                  .gte(program.programContext.minStakingAmount) ||
                new Decimal(program.addressContext?.totalStaked ?? 0).eq(
                  value
                ) ||
                `Final staked amount must be greater than program min limit`,
              notAboveStaked: (value) =>
                new Decimal(program.addressContext?.totalStaked ?? 0).gte(
                  value
                ) || 'Amount is greater than staked',
            },
          }}
          control={stakeForm.control}
          render={({ field: { ref: __, ...rest }, fieldState }) => (
            <InputRow.Numeric
              variants={['full-width']}
              icon={
                <span
                  className={styles.inputMax}
                  onClick={() =>
                    stakeForm.setValue(
                      'amount',
                      new Decimal(
                        program.addressContext?.totalStaked ?? 0
                      ).toNumber()
                    )
                  }
                >
                  MAX
                </span>
              }
              placeholder={'0 ' + program.stakingTokenSymbol}
              error={fieldState.error?.message}
              allowNegative={false}
              suffix={' ' + program.stakingTokenSymbol}
              disabled={stakeForm.formState.isSubmitting}
              {...rest}
            />
          )}
        />
        <div className={styles.field}>
          <CheckboxField
            {...stakeForm.register('claimRewards')}
            disabled={stakeForm.formState.isSubmitting}
          />
          <span>Claim rewards?</span>
        </div>
        <div className={styles.actionsContainer}>
          <Button variants={['secondary', 'full-width']} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variants={['primary', 'full-width']}
            type="submit"
            loading={stakeForm.formState.isSubmitting}
            disabled={stakeForm.formState.isSubmitting}
          >
            Withdraw tokens
          </Button>
        </div>
      </form>
    </BaseCard>
  )
}

export { Withdraw }
