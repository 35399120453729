// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j2xrDujs_JEgZL3FroDZ{align-items:center;align-self:center;color:#fff;font-size:20px;line-height:28px;padding:0px 32px}`, "",{"version":3,"sources":["webpack://./src/common/components/TitledHeaderCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CAEA,gBAAA","sourcesContent":[".header {\n  align-items: center;\n  align-self: center;\n  color: #fff;\n  font-size: 20px;\n  line-height: 28px;\n\n  padding: 0px 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `j2xrDujs_JEgZL3FroDZ`
};
export default ___CSS_LOADER_EXPORT___;
