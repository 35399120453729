// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qOyxVOJ6EiNxxrcZoP9l{display:flex;flex-direction:column;align-items:center;width:100%;gap:14px;padding:30px}.tqkk7Qi7aDO1SGA190e2{color:#fc0}.TdpNFb8P14IsVWQE28gg{color:rgba(255,255,255,.9215686275)}.r5H_15k5VSm21M8bIawz{text-align:center}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/NodeOperatorProgramDetailView/components/NodeOperatorInfoCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,UAAA,CAGF,sBACE,mCAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 14px;\n  padding: 30px;\n}\n\n.yellowText {\n  color: #fc0;\n}\n\n.whiteText {\n  color: #ffffffeb;\n}\n\n.centeredText {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qOyxVOJ6EiNxxrcZoP9l`,
	"yellowText": `tqkk7Qi7aDO1SGA190e2`,
	"whiteText": `TdpNFb8P14IsVWQE28gg`,
	"centeredText": `r5H_15k5VSm21M8bIawz`
};
export default ___CSS_LOADER_EXPORT___;
