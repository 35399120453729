import Markdown from 'markdown-to-jsx'
import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const MarkdownContent = (
  props: React.ComponentProps<typeof Markdown> & { className?: string }
) => {
  return (
    <div className={cls(styles.main, props.className)}>
      <Markdown {...props} />
    </div>
  )
}

export { MarkdownContent }
