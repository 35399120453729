import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import { IOnChainStakingProject, IOnChainStakingProviderContext } from './types'
import { requestProject, requestProjects } from './utils'

const OnChainStakingProviderContext =
  React.createContext<IOnChainStakingProviderContext | null>(null)

const OnChainStakingProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const projects = useFetchableResource<IOnChainStakingProject[]>([])
  const project = useFetchableResource<IOnChainStakingProject | null>(null)

  const ctx: IOnChainStakingProviderContext = {
    projects,
    project,
    requestProjects: projects.wrappedFetch(requestProjects),
    requestProject: project.wrappedFetch(requestProject),
  }

  return (
    <OnChainStakingProviderContext.Provider value={ctx}>
      {children}
    </OnChainStakingProviderContext.Provider>
  )
}

export { OnChainStakingProvider, OnChainStakingProviderContext }
