import React from 'react'
import clsx from 'classnames'

import { Typography } from '@lattice/common/components/Typography'
import { ReactComponent as RadioButton } from '@lattice/assets/icons/carbon/radio-button.svg'
import { ReactComponent as RadioButtonChecked } from '@lattice/assets/icons/carbon/radio-button-checked.svg'

import styles from './component.module.scss'

const OptionCard = ({
  title,
  titleExtra,
  subTitle,
  imgSrc,
  selected,
  className,
  onClick,
}: {
  title?: string
  titleExtra?: string
  subTitle?: string
  imgSrc?: string
  selected?: boolean
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}) => {
  return (
    <div
      className={clsx(styles.main, selected && styles.selected, className)}
      onClick={onClick}
    >
      <div className={styles.logoHolder}>{imgSrc && <img src={imgSrc} />}</div>
      <div className={styles.detailsHolder}>
        <div>
          {title && (
            <Typography.Text variants={['bright', 'span']}>
              {title}{' '}
            </Typography.Text>
          )}
          {titleExtra && (
            <Typography.Text variants={['span']}>{titleExtra}</Typography.Text>
          )}
        </div>
        {subTitle && (
          <Typography.Text variants={['bright', 'span']} size={14}>
            {subTitle}
          </Typography.Text>
        )}
      </div>
      <div className={styles.selectedIconHolder}>
        {selected ? <RadioButtonChecked /> : <RadioButton />}
      </div>
    </div>
  )
}

export { OptionCard }
