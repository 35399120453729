import { createProviderStateHook } from '@lattice/utils'

import {
  RewardsHistoryProvider,
  RewardsHistoryProviderContext,
} from './provider'

const useRewardsHistoryProvider = createProviderStateHook(
  RewardsHistoryProvider,
  RewardsHistoryProviderContext
)

export { useRewardsHistoryProvider }
