import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const BaseCard = ({
  variants,
  header,
  footer,
  children,
  className,
}: {
  variants?: (
    | 'narrow'
    | 'wide'
    | 'medium'
    | 'section-bar'
    | 'full-width'
    | 'bordered'
    | 'header-title'
  )[]
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  className?: {
    header?: string
    footer?: string
    body?: string
    root?: string
  }
}) => {
  return (
    <div
      className={cls(
        styles.root,
        variants?.includes('narrow') && styles.narrow,
        variants?.includes('wide') && styles.wide,
        variants?.includes('medium') && styles.medium,
        variants?.includes('full-width') && styles.fullWidth,
        variants?.includes('bordered') && styles.bordered,
        className?.root
      )}
    >
      {header && (
        <div
          className={cls(
            styles.header,
            styles.curvedTopBorder,
            variants?.includes('section-bar') && styles.sectionBar,
            variants?.includes('header-title') && styles.headerTitle,
            className?.header
          )}
        >
          {header}
        </div>
      )}
      {children && (
        <div
          className={cls(
            styles.body,
            className?.body,
            !header && styles.curvedTopBorder,
            !footer && styles.curvedBottomBorder
          )}
        >
          {children}
        </div>
      )}
      {footer && (
        <div
          className={cls(
            styles.footer,
            styles.curvedBottomBorder,
            variants?.includes('section-bar') && styles.sectionBar,
            className?.footer
          )}
        >
          {footer}
        </div>
      )}
    </div>
  )
}

export { BaseCard }
