import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ILocalizedValueFactory<F> = (t: TFunction) => F
type ILocalizedValue<T> = T extends ILocalizedValueFactory<infer F> ? F : never

const useLocalizedValues = <
  T extends Record<string, ILocalizedValueFactory<any>>,
>(
  factories: T
): { [K in keyof T]: ILocalizedValue<T[K]> } => {
  const { t, i18n } = useTranslation()

  const localizedFns = useMemo<{ [K in keyof T]: ILocalizedValue<T[K]> }>(
    () =>
      Object.entries(factories).reduce(
        (prev, [key, factory]) => {
          prev[key as keyof T] = factory(t)
          return prev
        },
        {} as { [K in keyof T]: ILocalizedValue<T[K]> }
      ),
    [i18n.resolvedLanguage]
  )

  return localizedFns
}

export { useLocalizedValues }
