import React, { useReducer } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import { ReactComponent as ChevronRight } from '@lattice/assets/images/svgs/chevronRight.svg'
import { ReactComponent as Logo } from '@lattice/assets/images/svgs/logo.svg'
import { ReactComponent as LatticeLogo } from '@lattice/assets/images/svgs/Lattice.svg'
import { routes } from '@lattice/views'

import styles from './component.module.scss'

/**
 * @uses z-index
 * @key Sidebar
 * @value 1
 */
const Sidebar = () => {
  const { t } = useTranslation()
  const [opened, toggleOpen] = useReducer((s) => !s, false)

  return (
    <div
      className={cls(
        styles.flexColumn,
        styles.sidebar,
        opened ? styles.opened : styles.closed
      )}
    >
      <div
        className={cls(styles.flexColumn, styles.upperSide, styles.stickyTop)}
      >
        <Link to={'/'} className={cls(styles.tile, styles.logos)}>
          <Logo />
          <div
            className={cls(styles.latticeLogo, styles.tooltip, {
              [styles.show]: opened,
              [styles.opened]: opened,
            })}
          >
            <LatticeLogo />
          </div>
        </Link>
        <div className={cls(styles.flexColumn, styles.tabGroup)}>
          {routes
            .filter((route) => !route.hidden)
            .map((route) => {
              return (
                <NavLink
                  to={route.path}
                  key={route.id}
                  className={({ isActive }) =>
                    cls(
                      styles.tile,
                      styles.usableTile,
                      isActive && styles.active
                    )
                  }
                >
                  <div className={cls(styles.icon)}>
                    {route.icon && route.icon}
                  </div>
                  <div
                    className={cls(styles.tooltip, {
                      [styles.opened]: opened,
                    })}
                  >
                    {route.name && route.name(t)}
                  </div>
                </NavLink>
              )
            })}
        </div>
      </div>
      <div className={cls(styles.flexColumn, styles.lowerSide)}></div>
      <div className={cls(styles.flexColumn, styles.stickyBottom)}>
        <div
          className={cls(styles.toggle, styles.usableTile, {
            [styles.opened]: opened,
          })}
          onClick={() => toggleOpen()}
        >
          <div className={cls(styles.tile)}>
            <ChevronRight
              style={{ transform: opened ? 'rotate(180deg)' : '' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Sidebar }
