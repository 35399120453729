import React, { useState } from 'react'
import * as ReactIs from 'react-is'
import flattenChildren from 'react-keyed-flatten-children'
import cls from 'classnames'

import styles from './component.module.scss'

const Tab = ({}: {
  id?: number | string | null
  label?: React.ReactNode
  content?: React.ReactNode
  isDefault?: boolean
}) => {
  return null
}

const Tabs = ({
  variants,
  activeTabId,
  onTabClick,
  children,
}: {
  variants?: 'no-padded-content'[]
  activeTabId?: number | string | null
  onTabClick?: (tabId: number | string | null) => void
  children?: React.ReactNode
}) => {
  const tabs = flattenChildren(children)
    .filter(
      (value): value is React.ReactElement<React.ComponentProps<typeof Tab>> =>
        ReactIs.isElement(value) && value.type === Tab
    )
    .map((tabElement, index) => {
      return {
        id: tabElement.props.id ?? tabElement.key ?? index,
        label: tabElement.props.label,
        content: tabElement.props.content,
        isDefault: tabElement.props.isDefault,
      }
    })

  const defaultTab = tabs.find((tab) => tab.isDefault === true)
  const [selectedTabId, setSelectedTabId] = useState<number | string | null>(
    tabs.length === 0 ? null : defaultTab?.id ? defaultTab?.id : tabs[0].id
  )

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsLabels}>
        {tabs.map((tab) => (
          <div
            className={cls(
              styles.tabLabel,
              (activeTabId
                ? tab.id === activeTabId
                : tab.id === selectedTabId) && styles.selected
            )}
            key={tab.id}
            onClick={() => {
              onTabClick && onTabClick(tab.id)
              setSelectedTabId(tab.id)
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={styles.horizontalBar}></div>
      <div
        className={cls(
          styles.tabContent,
          variants?.includes('no-padded-content') && styles.noPaddedContent
        )}
      >
        {
          tabs.find((tab) =>
            activeTabId ? tab.id === activeTabId : tab.id === selectedTabId
          )?.content
        }
      </div>
    </div>
  )
}

const TabsUtils = Object.assign(Tabs, { Tab })

export { TabsUtils as Tabs }
