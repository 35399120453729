import React from 'react'
import cls from 'classnames'
import { Decimal } from 'decimal.js'

import {
  Badge,
  BadgeColors,
  BaseCard,
  BlockStat,
  FlexCenteredContainer,
} from '@lattice/common/components'
import { ReactComponent as ArrowSquareIcon } from '@lattice/assets/images/svgs/arrow-square.svg'
import { StatusColors } from '@lattice/common/consts/programStatus'
import { formatNumber, NumberFormat, shortenAddress } from '@lattice/utils'

import styles from './component.module.scss'

const NodeProgramsCard = ({
  bannerUrl,
  lifetimeRewards,
  variants,
  badge,
  title,
  logoUrl,
  walletAddress,
  children,
}: {
  bannerUrl: string
  lifetimeRewards: string | number | Decimal
  walletAddress: string
  variants?: 'full-width'[]
  badge?: { text: string; color: BadgeColors | StatusColors }
  title?: string
  logoUrl?: string
  children?: React.ReactNode
}) => {
  return (
    <BaseCard
      variants={['bordered']}
      header={
        <>
          {logoUrl && <img src={logoUrl} />}
          {title && (
            <>
              <BlockStat
                key={1}
                variants={['align-start']}
                label={'Soft node'}
                className={{
                  labelFont: styles.labelFont,
                }}
                value={
                  <FlexCenteredContainer>
                    <span className={styles.blockFont}>{title}</span>
                  </FlexCenteredContainer>
                }
              />
              <BlockStat
                key={2}
                variants={['align-start']}
                className={{
                  labelFont: styles.labelFont,
                }}
                label={'Lifetime rewards'}
                value={
                  <FlexCenteredContainer>
                    <span className={styles.blockFontRewards}>
                      {formatNumber(lifetimeRewards, NumberFormat.DECIMALS)}
                    </span>
                    <Badge
                      text={'DAG'}
                      color={BadgeColors.green}
                      className={{ container: styles.greenBadgeContainer }}
                    />
                  </FlexCenteredContainer>
                }
              />
            </>
          )}

          {badge && (
            <Badge
              text={badge.text}
              color={badge.color}
              className={{ text: styles.badgeText }}
            />
          )}
        </>
      }
      className={{ body: styles.body, header: styles.header }}
    >
      <div className={styles.imgHolder}>
        <img src={bannerUrl} className={styles.imgHolder} />
        <div className={styles.badgeRow}>
          <span>Wallet</span>
          <div className={styles.walletAddress}>
            <a
              className={styles.links}
              rel="noreferrer"
              target="_blank"
              href={`https://www.dagexplorer.io/search?term=${walletAddress}`}
            >
              <p className={styles.resetMargins}>
                {shortenAddress(walletAddress, 5, 5, '•••')}
              </p>
              <ArrowSquareIcon
                height={'12px'}
                width={'12px'}
                className={styles.icon}
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className={cls(
          styles.contentContainer,
          variants?.includes('full-width') && styles.fullWidth
        )}
      >
        <div className={styles.mainContent}>{children}</div>
      </div>
    </BaseCard>
  )
}

export { NodeProgramsCard }
