import React from 'react'
import dayjs from 'dayjs'

import { InfoRowsContainer } from '@lattice/common/components'
import { CardSectionContainer } from '@lattice/common/components/CardSectionContainer'
import { InfoRow } from '@lattice/views/SwapBuyView/views/SwapExolixView/components/common/InfoRow'
import { CardLabelContainer } from '@lattice/common/components/CardLabelContainer'
import {
  BountyStatUri,
  getBountyStat,
  useBountiesProvider,
} from '@lattice/common/providers'
import { dateRange, formatNumber, NumberFormat } from '@lattice/utils'

import styles from './component.module.scss'

const BountyInformation = () => {
  const { bounty } = useBountiesProvider()

  const timeline = bounty.resource
    ? getBountyStat(bounty.resource, BountyStatUri.TIMELINE, null)
    : null

  return (
    <>
      <CardSectionContainer>
        <div className={styles.totalRewardsContainer}>
          <div className={styles.bountyAmountContainer}>
            <span>
              {formatNumber(
                bounty.resource
                  ? getBountyStat(
                      bounty.resource,
                      BountyStatUri.TOTAL_REWARDS_DISTRIBUTED,
                      0
                    )
                  : '0',
                NumberFormat.MILLIFY
              )}
            </span>
            <div>
              <span>DAG</span>
            </div>
          </div>
          <span className={styles.totalRewardsTitle}>
            Total Rewards Distributed
          </span>
        </div>
      </CardSectionContainer>
      <CardSectionContainer>
        <CardLabelContainer>Commission details</CardLabelContainer>
        <InfoRowsContainer>
          {bounty.resource?.stats && (
            <>
              <InfoRow
                className={{
                  leftSide: styles.leftSideFont,
                  rightSide: styles.rightSideFont,
                }}
                leftSide="Timeline"
                rightSide={
                  timeline === null
                    ? 'unknown'
                    : timeline === 'ongoing'
                      ? 'Ongoing'
                      : dateRange(
                          dayjs(timeline.startsAt).toDate(),
                          dayjs(timeline.endsAt).toDate()
                        )
                }
              />
              {bounty.resource.stats
                .filter(
                  (stat) =>
                    ![
                      BountyStatUri.TOTAL_REWARDS_DISTRIBUTED,
                      BountyStatUri.TIMELINE,
                    ].includes(stat.uri as any)
                )
                .map((stat) => (
                  <InfoRow
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                    key={stat.uri}
                    leftSide={stat.name}
                    rightSide={stat.value}
                  />
                ))}
            </>
          )}
        </InfoRowsContainer>
      </CardSectionContainer>
    </>
  )
}

export { BountyInformation }
