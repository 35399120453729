import React from 'react'
import Decimal from 'decimal.js'

import { InfoCard } from '@lattice/common/components'
import { ISoftNodesProjectWithStats } from '@lattice/common/providers'
import {
  ProgramStatus,
  StatusColors,
} from '@lattice/common/consts/programStatus'
import {
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
} from '@lattice/utils'
import {
  NetworkCurrencies,
  NetworkCurrency,
} from '@lattice/utils/networkCurrencies'

const SoftNodeStakingCard = ({
  projects,
  circSupply,
}: {
  projects: ISoftNodesProjectWithStats[]
  circSupply?: number
}) => {
  return (
    <InfoCard
      headerText={'Soft Node Staking'}
      linkTo={'/rewards'}
      rowsData={projects.map((project) => {
        const shards =
          formatNumber(project.actualShardsCount, NumberFormat.WHOLE) +
          ' shards'
        const staked = formatNumberAndCurrency(
          new Decimal(project.actualAmountStakedFullRaw).div(
            NetworkCurrencies[NetworkCurrency.CONSTELLATION__DAG].decimalFactor
          ),
          NetworkCurrencies[NetworkCurrency.CONSTELLATION__DAG].symbol,
          NumberFormat.MILLIFY_EXPANDED
        )

        const fracShards =
          formatNumber(
            new Decimal(project.actualAmountStakedByFracsRaw).div(
              project.nodePrice
            ),
            NumberFormat.DECIMALS
          ) + ' frac. shards'

        const supplyPercentage = circSupply
          ? `${formatNumber(
              new Decimal(project.actualAmountStakedFullRaw)
                .div(circSupply)
                .mul(100),
              NumberFormat.MILLIFY
            )}% of circ. supply`
          : ''

        return {
          title: project.name,
          subData: [shards, staked, fracShards, supplyPercentage],
          subDataIcons: true,
          badge: {
            text: ProgramStatus[project.status],
            color: StatusColors[project.status],
          },
          linkTo: '/rewards/soft-nodes/' + project.slug,
        }
      })}
    />
  )
}

export { SoftNodeStakingCard }
