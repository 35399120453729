import { createProviderStateHook } from '@lattice/utils'

import { SoftNodeProvider, SoftNodeProviderContext } from './provider'

const useSoftNodesProvider = createProviderStateHook(
  SoftNodeProvider,
  SoftNodeProviderContext
)

export { useSoftNodesProvider }
