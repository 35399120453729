import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import cls from 'classnames'

import { ReactComponent as UserAvatarIcon } from '@lattice/assets/icons/custom/useravatar.svg'
import { ReactComponent as CircleWavyCheckIcon } from '@lattice/assets/icons/custom/wavy-circle-check.svg'
import { ReactComponent as SignOutIcon } from '@lattice/assets/icons/custom/sign-out.svg'
import { ReactComponent as RewardsIcon } from '@lattice/assets/images/svgs/rewardsIcon.svg'
import { useUserProvider, useWalletProvider } from '@lattice/common/providers'
import { Button } from '@lattice/common/components'
import {
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './component.module.scss'

const MobileUserMenu = () => {
  const { user, doUserSignOut } = useUserProvider()
  const { t } = useTranslation()
  const signInUrl = useSignInRedirect()

  const { requestConnectorActivation, activeWallet, accountsNetworkAssets } =
    useWalletProvider()

  const routes = [
    {
      to: 'user/myaccount',
      icon: <UserAvatarIcon width={16} height={16} />,
      text: t('commmon.components.Header.account', 'Account'),
    },
    {
      to: 'user/kyc',
      icon: <CircleWavyCheckIcon width={16} height={16} />,
      text: t('commmon.components.Header.account', 'KYC'),
    },
    {
      to: 'user/rewards',
      icon: <RewardsIcon width={16} height={16} />,
      text: t('commmon.components.Header.account', 'Rewards'),
    },
    {
      to: '/',
      icon: <SignOutIcon width={16} height={16} />,
      text: t('commmon.components.Header.account', 'Sign out'),
      onClick: () => doUserSignOut(),
    },
  ]

  const walletList: {
    tickerName: keyof typeof accountsNetworkAssets
    account: string
  }[] = []

  if (activeWallet.status === 'connected' && activeWallet.constellation) {
    walletList.push({
      tickerName: 'constellation:dag',
      account: activeWallet.constellation.account,
    })
  }

  if (activeWallet.status === 'connected' && activeWallet.ethereum) {
    walletList.push({
      tickerName: 'ethereum:eth',
      account: activeWallet.ethereum.account,
    })
  }

  return (
    <div className={styles.root}>
      <div className={styles.upperTile}>
        {!user && (
          <Button.NavLink
            to={signInUrl}
            className={{ main: styles.button }}
            variants={['right-icon']}
          >
            Sign in or register
          </Button.NavLink>
        )}
        {activeWallet.status === 'connected' && (
          <div
            className={styles.accountsBalances}
            onClick={() => requestConnectorActivation()}
          >
            {walletList.map(({ tickerName, account }, index, array) => (
              <div className={styles.balanceButton} key={index}>
                {shortenAddress(account)}:{' '}
                <span>
                  {accountsNetworkAssets[tickerName]
                    ? formatNumberAndCurrency(
                        accountsNetworkAssets[tickerName]!.balance,
                        accountsNetworkAssets[tickerName]!.symbol,
                        NumberFormat.MILLIFY
                      )
                    : '--'}
                </span>
                {index === array.length - 1 ? '' : ' | '}
              </div>
            ))}
          </div>
        )}
        {activeWallet.status !== 'connected' && (
          <Button
            variants={['outlined']}
            onClick={() => requestConnectorActivation()}
            className={styles.button}
          >
            {t('commmon.components.Header.connectWallet', 'Connect wallet')}
          </Button>
        )}
      </div>
      <div className={styles.navWrapper}>
        {user &&
          routes.map((route) => {
            return (
              <NavLink
                to={route.to}
                key={route.to}
                className={({ isActive }) =>
                  cls(
                    styles.navTile,
                    isActive && route.to !== '/' && styles.active
                  )
                }
                onClick={route.onClick && route.onClick}
              >
                <div className={cls(styles.icon)}>
                  {route.icon && route.icon}
                </div>
                <div className={cls(styles.routeName)}>
                  {route.text && route.text}
                </div>
              </NavLink>
            )
          })}
      </div>
    </div>
  )
}

export { MobileUserMenu }
