import { FetchStatus } from '../consts'

import { useFetchableOperation } from './useFetchableOperation'

const useLockFetchableOperation = (options?: { skipErrors?: boolean }) => {
  const operation = useFetchableOperation()
  const readyStates = [FetchStatus.DONE, FetchStatus.IDLE]

  if (options?.skipErrors) {
    readyStates.push(FetchStatus.ERROR, FetchStatus.SERVER_ERROR)
  }

  const inLockState = !readyStates.includes(operation.status)

  const lockFetchableOperationState = {
    operation,
    status: operation.status,
    error: operation.error,
    inLockState,
    operationWrapper: <T extends (...args: any[]) => Promise<any>>(
      fn: T
    ): T => {
      return (async (...args) => {
        if (inLockState) {
          throw new Error(
            'useLockFetchableOperation: Unable to execute operation while on pending state'
          )
        }
        return operation.wrappedFetch(fn)(...args)
      }) as T
    },
  }

  return lockFetchableOperationState
}

export { useLockFetchableOperation }
