import EthereumLogoImg from '@lattice/assets/images/pngs/ethereum_logo.png'
import EthereumGoerliLogoImg from '@lattice/assets/images/pngs/ethereum_goerli_logo.png'
import PolygonLogoImg from '@lattice/assets/images/pngs/polygon_logo.png'
import PolygonMumbaiLogoImg from '@lattice/assets/images/pngs/polygon_mumbai_logo.png'
import ConstellationLogo from '@lattice/assets/images/pngs/constellation_logo.png'
import ConstellationTestnetLogo from '@lattice/assets/images/pngs/constellation_testnet_logo.png'

import {
  AvailableChain,
  AvailableNetwork,
  AvailableProtocol,
} from './available'

type ChainInfo = {
  protocol: AvailableProtocol
  network: AvailableNetwork
  chainName: string
  rpcAvailable: boolean
  rpcEndpoint: string
  rpcChainId: number
  chainLogoUrl: string
}

const ChainInfo: Record<AvailableChain, ChainInfo> = {
  [AvailableChain.ETHEREUM_MAINNET]: {
    protocol: AvailableProtocol.EVM,
    network: AvailableNetwork.ETHEREUM,
    chainName: 'Ethereum Mainnet',
    rpcAvailable: true,
    rpcEndpoint:
      'https://wandering-small-snow.quiknode.pro/872d98bc000a9f8fb576b0e7590b28aed29eb169',
    rpcChainId: 1,
    chainLogoUrl: EthereumLogoImg,
  },
  [AvailableChain.ETHEREUM_SEPOLIA]: {
    protocol: AvailableProtocol.EVM,
    network: AvailableNetwork.ETHEREUM,
    chainName: 'Ethereum Sepolia',
    rpcAvailable: true,
    rpcEndpoint:
      'https://boldest-falling-uranium.ethereum-sepolia.quiknode.pro/fce4c7e3c739767d6a04885a02e74b1c2dc04461',
    rpcChainId: 11155111,
    chainLogoUrl: EthereumGoerliLogoImg,
  },
  [AvailableChain.POLYGON_MAINNET]: {
    protocol: AvailableProtocol.EVM,
    network: AvailableNetwork.POLYGON,
    chainName: 'Polygon Mainnet',
    rpcAvailable: true,
    rpcEndpoint:
      'https://polished-withered-morning.matic.quiknode.pro/e37913ca108d809f0b8d47bfb61d0f4a2cfaaaff',
    rpcChainId: 137,
    chainLogoUrl: PolygonLogoImg,
  },
  [AvailableChain.POLYGON_MUMBAI]: {
    protocol: AvailableProtocol.EVM,
    network: AvailableNetwork.POLYGON,
    chainName: 'Polygon Mumbai',
    rpcAvailable: true,
    rpcEndpoint:
      'https://solitary-morning-water.matic-testnet.quiknode.pro/7ed5d822ef2587e127ffddb6854b0d02a3c83054',
    rpcChainId: 80001,
    chainLogoUrl: PolygonMumbaiLogoImg,
  },
  [AvailableChain.CONSTELLATION_MAINNET]: {
    protocol: AvailableProtocol.CONSTELLATION,
    network: AvailableNetwork.CONSTELLATION,
    chainName: 'Constellation Mainnet',
    rpcAvailable: false,
    get rpcEndpoint(): string {
      throw new Error(
        'RPC endpoint is not available for ' + this.protocol + ' protocol'
      )
    },
    get rpcChainId(): number {
      throw new Error(
        'RPC chain id is not available for ' + this.protocol + ' protocol'
      )
    },
    chainLogoUrl: ConstellationLogo,
  },
  [AvailableChain.CONSTELLATION_TESTNET]: {
    protocol: AvailableProtocol.CONSTELLATION,
    network: AvailableNetwork.CONSTELLATION,
    chainName: 'Constellation Testnet',
    rpcAvailable: false,
    get rpcEndpoint(): string {
      throw new Error(
        'RPC endpoint is not available for ' + this.protocol + ' protocol'
      )
    },
    get rpcChainId(): number {
      throw new Error(
        'RPC chain id is not available for ' + this.protocol + ' protocol'
      )
    },
    chainLogoUrl: ConstellationTestnetLogo,
  },
}

export { ChainInfo }
