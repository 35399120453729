import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'

import {
  ILaunchpadProject,
  ILaunchpadProjectPool,
  ILaunchpadProjectUser,
  ILaunchpadUserAllocation,
  useLaunchpadProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { formatNumber, NumberFormat, shortenAddress } from '@lattice/utils'
import { useFetchableOperation } from '@lattice/common/hooks'
import { useProgressToasts } from '@lattice/common/hooks/useProgressToasts'

import { PoolTabs } from '../PoolTabs/component'
import { StakeActionCard } from '../StakeActionCard'
import { StakedInfoCard } from '../StakedInfoCard'
import { StakingDetailsCard } from '../StakingDetailsCard'
import { IStakeFormData } from '../types'

import styles from './component.module.scss'

type IStakingPool = {
  limit: number | null
  currencyPools: {
    [Key in ILaunchpadProjectPool['stakingCurrency']]?: ILaunchpadProjectPool
  }
}

const StakingPool = ({
  project,
  projectUser,
  userAllocation,
}: {
  project: ILaunchpadProject
  projectUser: ILaunchpadProjectUser | null
  userAllocation: ILaunchpadUserAllocation
}) => {
  const { t } = useTranslation()
  const {
    stakeInProjectPool,
    requestProjectUser,
    requestProject,
    requestUserAllocation,
  } = useLaunchpadProvider()
  const { activeWallet, accountsNetworkAssets } = useWalletProvider()
  const stakeInPoolOperation = useFetchableOperation()
  const stakeInPoolOperationProgressToasts = useProgressToasts()

  const [activePool, setActivePool] = useState(0)
  const pools = useMemo(() => {
    const collections: Set<IStakingPool> = new Set()
    for (const pool of project.pools) {
      const collection = [...collections].find(
        (collection) => collection.limit === pool.stakingLimit
      ) ?? { limit: pool.stakingLimit, currencyPools: {} }

      collection.currencyPools[pool.stakingCurrency] = pool
      collections.add(collection)
    }
    return [...collections].sort(
      (a, b) => -(a.limit ?? Infinity) + (b.limit ?? Infinity)
    )
  }, [project.pools])

  if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
    return null
  }

  const allocatedLtxBalanceInPool = parseInt(
    String(
      projectUser
        ?.find(
          (wallet) =>
            wallet.address === activeWallet.ethereum.account &&
            wallet.currency === 'ltx'
        )
        ?.allocations.find(
          (allocation) =>
            allocation.lpProjectPoolId ===
            pools[activePool].currencyPools.ltx?.id
        )?.currentAmount ?? 0
    )
  )

  const allocatedLtxlockedBalanceInPool = parseInt(
    String(
      projectUser
        ?.find(
          (wallet) =>
            wallet.address === activeWallet.ethereum.account &&
            wallet.currency === 'ltx-locked'
        )
        ?.allocations.find(
          (allocation) =>
            allocation.lpProjectPoolId ===
            pools[activePool].currencyPools['ltx-locked']?.id
        )?.currentAmount ?? 0
    )
  )

  const availableLtxBalance = Math.max(
    0,
    (accountsNetworkAssets['ethereum:ltx']?.balance.toNumber() ?? 0) -
      (parseFloat(userAllocation.ltx) ?? 0)
  )

  const availableLtxlockedBalance = Math.max(
    0,
    (accountsNetworkAssets['ethereum:ltx-locked']?.balance.toNumber() ?? 0) -
      (parseFloat(userAllocation['ltx-locked']) ?? 0)
  )

  const stakeInPool = stakeInPoolOperation.wrappedFetch(
    stakeInPoolOperationProgressToasts.wrappedErrorsAsync(
      async (tokens: IStakeFormData) => {
        if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
          throw new Error('Invalid wallet state')
        }

        const [ltxPool, ltxlockedPool] = [
          pools[activePool].currencyPools.ltx,
          pools[activePool].currencyPools['ltx-locked'],
        ]

        if (!ltxPool || !ltxlockedPool) {
          throw new Error('Invalid pools state')
        }

        stakeInPoolOperationProgressToasts.progress(
          'Requesting wallet ownership signature',
          'info',
          null
        )

        const walletOwnershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()

        if (tokens.ltx) {
          stakeInPoolOperationProgressToasts.progress(
            'Staking LTX tokens',
            'info',
            null
          )

          await stakeInProjectPool(
            project.slug,
            ltxPool.id,
            activeWallet.ethereum.account,
            new Decimal(tokens.ltx).plus(allocatedLtxBalanceInPool),
            walletOwnershipToken
          )
        }

        if (tokens['ltx-locked']) {
          stakeInPoolOperationProgressToasts.progress(
            'Staking Locked LTX tokens',
            'info',
            null
          )

          await stakeInProjectPool(
            project.slug,
            ltxlockedPool.id,
            activeWallet.ethereum.account,
            new Decimal(tokens['ltx-locked']).plus(
              allocatedLtxlockedBalanceInPool
            ),
            walletOwnershipToken
          )
        }

        stakeInPoolOperationProgressToasts.progress(
          'Staked successfully',
          'success',
          15000
        )

        requestProjectUser(project.slug)
        requestUserAllocation(activeWallet.ethereum.account)
        requestProject(project.slug)
      }
    )
  )

  return (
    <div className={styles.stakingPoolContainer}>
      <PoolTabs
        tabs={pools.map(
          (pool) =>
            `${
              pool.limit
                ? formatNumber(pool.limit, NumberFormat.MILLIFY)
                : t('views.Launchpad.componenrs.StakingPool.no', 'No')
            } ${t('views.Launchpad.componenrs.StakingPool.limit', 'limit')}`
        )}
        selectTab={setActivePool}
        activeTab={activePool}
      />
      <div className={styles.stakingPoolBody}>
        <div className={styles.stakingSection}>
          <StakedInfoCard
            stakeInfo={{
              label: 'Total staked in pool',
              stakedToken: {
                amount:
                  formatNumber(
                    Object.values(pools[activePool].currencyPools).reduce(
                      (total, cpool) => total + cpool.totalStaked,
                      0
                    ),
                    NumberFormat.WHOLE
                  ) ?? '--',
                ticker: 'LTX',
              },
              className: {
                container: styles.badgeContainer,
                badgeText: styles.badgeText,
                amountText: styles.amountText,
              },
            }}
          />
        </div>
        <div className={styles.stakingSection}>
          <StakingDetailsCard
            stakeDetails={{
              label: 'Your staking details',
              helpInfo: '',
              address: shortenAddress(
                activeWallet.ethereum.account,
                5,
                4,
                '•••'
              ),
              stakeStatuses: [
                {
                  ticker: 'LTX',
                  balance: formatNumber(
                    availableLtxBalance,
                    NumberFormat.WHOLE
                  ),
                  stakedAmount: formatNumber(
                    allocatedLtxBalanceInPool,
                    NumberFormat.WHOLE
                  ),
                  stakedPercentage: formatNumber(
                    (allocatedLtxBalanceInPool /
                      Object.values(pools[activePool].currencyPools).reduce(
                        (total, cpool) => total + cpool.totalStaked,
                        0
                      )) *
                      100,
                    NumberFormat.DECIMALS
                  ),
                  revoked: false,
                },
                {
                  ticker: 'Locked',
                  balance: formatNumber(
                    availableLtxlockedBalance,
                    NumberFormat.WHOLE
                  ),
                  stakedAmount: formatNumber(
                    allocatedLtxlockedBalanceInPool,
                    NumberFormat.WHOLE
                  ),
                  stakedPercentage: formatNumber(
                    (allocatedLtxlockedBalanceInPool /
                      Object.values(pools[activePool].currencyPools).reduce(
                        (total, cpool) => total + cpool.totalStaked,
                        0
                      )) *
                      100,
                    NumberFormat.DECIMALS
                  ),
                  revoked: false,
                },
              ],
            }}
          />
        </div>
        {project.status === 'live' && (
          <div className={styles.stakingSection}>
            <StakeActionCard
              handleStake={stakeInPool}
              label={'Stake LTX and/or Locked LTX'}
              showHelp={''}
              info={
                'By soft staking in Launchpad, your tokens will stay in your wallet. If your balance falls below your staked amount at any time during the staking period, your project token allocation will be revoked.'
              }
              learnMoreLink={'#'}
              poolLimit={pools[activePool].limit}
              ltxBalance={availableLtxBalance}
              ltxlockedBalance={availableLtxlockedBalance}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { StakingPool }
