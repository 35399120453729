import React, { useState } from 'react'

import {
  IAirdrop,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { Awaitable, signMessageByAlternatives } from '@lattice/utils'

import { generateDAGBasedAirdropClaimMessage } from '../../utils'

import {
  DagBasedAirdropClaimModal,
  DagBasedAirdropClaimModalFormValues,
} from './component'

type DagBasedAirdropClaimResolution = null | {
  message: string
  signature: string
}

/**
 * @exotic
 * As the modal needed for this operation will
 * need to be executed in another call, this hook
 * will turn the modal into a callable operation
 * that will await for the modal resolution
 */
const useDagBasedAirdropClaimModal = () => {
  const { userDbData } = useUserProvider()
  const { activeWallet } = useWalletProvider()
  const [currentProcess, setCurrentProcess] = useState<{
    request: { airdrop: IAirdrop }
    response: Awaitable<DagBasedAirdropClaimResolution>
  } | null>(null)

  const requestDagBasedAirdropClaimMessage = (
    airdrop: IAirdrop
  ): Promise<DagBasedAirdropClaimResolution> => {
    const process = new Awaitable()

    setCurrentProcess({ request: { airdrop }, response: process })

    return process
  }

  const requestMessageSignature = async (
    values: DagBasedAirdropClaimModalFormValues
  ) => {
    if (!currentProcess) {
      throw new Error('Inconsistent hook state')
    }

    try {
      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Wallet is not active')
      }

      if (!userDbData.resource) {
        throw new Error('No user data available')
      }

      const message = generateDAGBasedAirdropClaimMessage(
        activeWallet.ethereum.account,
        values.type === 'softnode' ? values.nodeSelected : values.address,
        currentProcess.request.airdrop,
        userDbData.resource.email
      )

      const signer = activeWallet.ethereum.library.getSigner()

      const signature = await signMessageByAlternatives(
        signer,
        message,
        activeWallet.ethereum.account,
        activeWallet.ethereum.connector
      )

      currentProcess.response.resolve({ message, signature })
    } catch (e) {
      currentProcess.response.reject(e)
      throw e
    }
    setCurrentProcess(null)
  }

  const modal = (
    <DagBasedAirdropClaimModal
      closed={!currentProcess}
      onClose={() => {
        currentProcess && currentProcess.response.resolve(null)
        setCurrentProcess(null)
      }}
      onApproveClaim={requestMessageSignature}
    />
  )

  return [modal, requestDagBasedAirdropClaimMessage] as const
}

export { useDagBasedAirdropClaimModal }
