import React from 'react'
import { useTranslation } from 'react-i18next'

//import { ReactComponent as Question } from '@lattice/assets/images/svgs/Question.svg'
import { BaseCard, Button } from '@lattice/common/components'
import { ReactComponent as Plus } from '@lattice/assets/images/svgs/Plus.svg'

import styles from './component.module.scss'

const AddNodeCard = ({
  title,
  info,
  onActionClick,
}: {
  title: string
  info: string
  onActionClick: () => void
}) => {
  const { t } = useTranslation()

  return (
    <BaseCard variants={['wide']} className={{ root: styles.baseCardRoot }}>
      <div className={styles.cardContainer}>
        <div className={styles.cardSection}>
          <div className={styles.infoLabel}>
            {title}
            {/* <div className={styles.help}>
              <Question />
            </div> */}
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.infoSubText}>{info}</div>
          </div>
          <Button className={styles.button} onClick={() => onActionClick()}>
            <div className={styles.addNodeButton}>
              <div>{t('views.Nodes.texts.addNode', 'Add Node')}</div>
              <Plus />
            </div>
          </Button>
        </div>
      </div>
    </BaseCard>
  )
}

export { AddNodeCard }
