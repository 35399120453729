import React from 'react'
import cls from 'classnames'

import styles from './Badge.module.scss'

enum BadgeColors {
  yellow = 'yellow',
  orange = 'orange',
  gray = 'gray',
  green = 'green',
  blue = 'blue',
  purple = 'purple',
  teal = 'teal',
  red = 'red',
}

type BadgeColorsType =
  | 'yellow'
  | 'orange'
  | 'gray'
  | 'green'
  | 'blue'
  | 'purple'
  | 'teal'
  | 'red'

const Badge = ({
  text,
  color,
  className,
}: {
  text: string
  color: BadgeColors | BadgeColorsType
  className?: { container?: string; text?: string }
}) => {
  return (
    <div
      className={cls(
        styles.badgeContainer,
        {
          [styles.yellow]: color === 'yellow',
          [styles.orange]: color === 'orange',
          [styles.gray]: color === 'gray',
          [styles.green]: color === 'green',
          [styles.blue]: color === 'blue',
          [styles.purple]: color === 'purple',
          [styles.teal]: color === 'teal',
          [styles.red]: color === 'red',
        },
        className?.container
      )}
    >
      <div className={cls(className?.text)}>{text}</div>
    </div>
  )
}

export { Badge, BadgeColors, BadgeColorsType }
