import React from 'react'
import Decimal from 'decimal.js'

import {
  useFetchableOperation,
  useFetchableResource,
} from '@lattice/common/hooks'

import {
  ISoftNodesUserData,
  ISoftNodesProviderContext,
  IUserWalletSoftNode,
  IUserWalletSoftNodeAndPrograms,
  ISoftNodesProjectWithStats,
} from './types'
import {
  requestProject,
  requestProjects,
  requestProjectUser,
  requestUserHistoryProjects,
  requestUserNodes,
  stakeWallet,
} from './utils'

const SoftNodeProviderContext =
  React.createContext<ISoftNodesProviderContext | null>(null)

const SoftNodeProvider = ({ children }: { children: React.ReactNode }) => {
  const projects = useFetchableResource<ISoftNodesProjectWithStats[]>([])
  const project = useFetchableResource<ISoftNodesProjectWithStats | null>(null)
  const projectUser = useFetchableResource<ISoftNodesUserData | null>(null)
  const userHistoryProjects = useFetchableResource<
    IUserWalletSoftNodeAndPrograms[]
  >([])
  const userNodes = useFetchableResource<IUserWalletSoftNode[]>([])
  const stakeWalletOperation = useFetchableOperation()

  const MINIMUM_FRACTIONAL_STAKE_DAG = new Decimal(100 * 1e8)

  const ctx: ISoftNodesProviderContext = {
    projects,
    project,
    projectUser,
    stakeWalletOperation,
    MINIMUM_FRACTIONAL_STAKE_DAG,
    userHistoryProjects,
    userNodes,
    requestProjects: projects.wrappedFetch(requestProjects),
    requestProject: project.wrappedFetch(requestProject),
    requestProjectUser: projectUser.wrappedFetch(requestProjectUser),
    requestUserHistoryProjects: userHistoryProjects.wrappedFetch(
      requestUserHistoryProjects
    ),
    requestUserNodes: userNodes.wrappedFetch(requestUserNodes),
    stakeWallet: stakeWalletOperation.wrappedFetch(stakeWallet),
  }

  return (
    <SoftNodeProviderContext.Provider value={ctx}>
      {children}
    </SoftNodeProviderContext.Provider>
  )
}

export { SoftNodeProvider, SoftNodeProviderContext }
