// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zGFfzPsrpVSPzEwfONJj{display:flex;width:100%;justify-content:center;align-items:center;column-gap:7px;color:rgba(255,255,255,.96);font-weight:300;font-size:16px;line-height:24px;letter-spacing:.002em}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/SoftNodeProgramDetailView/components/ProgramCardTitle/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CAEA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,qBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n\n  justify-content: center;\n  align-items: center;\n  column-gap: 7px;\n\n  color: rgba(#fff, 0.96);\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.002em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `zGFfzPsrpVSPzEwfONJj`
};
export default ___CSS_LOADER_EXPORT___;
