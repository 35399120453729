// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zK1ZpeMlH_xMzX0b64kM{display:flex;width:100%;flex-flow:column nowrap}.ffmTGhlotV3aK_yM2ySP{display:flex;width:100%;height:38px;justify-content:space-between;align-items:center;border-bottom:1px solid rgba(255,255,255,.12)}.HukU6tr5DW9BpnTKs7Pv{color:rgba(255,255,255,.66);font-weight:300;font-size:12px;line-height:18px;letter-spacing:.03em}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/SoftNodeProgramDetailView/components/StatsList/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CAEA,uBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CAEA,6BAAA,CACA,kBAAA,CAEA,6CAAA,CAGF,sBACE,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n}\n\n.contentRow {\n  display: flex;\n  width: 100%;\n  height: 38px;\n\n  justify-content: space-between;\n  align-items: center;\n\n  border-bottom: 1px solid rgba(#fff, 0.12);\n}\n\n.titles {\n  color: rgba(#fff, 0.66);\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `zK1ZpeMlH_xMzX0b64kM`,
	"contentRow": `ffmTGhlotV3aK_yM2ySP`,
	"titles": `HukU6tr5DW9BpnTKs7Pv`
};
export default ___CSS_LOADER_EXPORT___;
