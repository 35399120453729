import { ethers } from 'ethers'
import { Account, Chain, Client, Transport } from 'viem'
import { Config, useConnectorClient } from 'wagmi'
import { useMemo } from 'react'

const clientToProvider = (client: Client<Transport, Chain, Account>) => {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new ethers.providers.Web3Provider(transport, network)
  return provider
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
const useEthersConnectorProvider = ({ chainId }: { chainId?: number } = {}) => {
  const { data: client } = useConnectorClient<Config>({ chainId })
  return useMemo(
    () => (client ? clientToProvider(client) : undefined),
    [client]
  )
}

export { clientToProvider, useEthersConnectorProvider }
