import React, { useState } from 'react'

import { BaseCard, BaseModal, Button } from '@lattice/common/components'
import { useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import styles from './view.module.scss'
import { MFADisableCard, MFAEnableCard } from './components'

export const SecurityView = () => {
  const { userMfaPreference } = useUserProvider()

  const [mfaModalOpen, setMfaModalOpen] = useState(false)

  return (
    <BaseCard className={{ root: styles.card, body: styles.body }}>
      <div className={styles.title}>
        <h2>Two-factor Authentication</h2>
        <span>
          Setup two-factor authentication to provide added security to your
          Lattice Exchange account. This method uses a mobile authenticator app,
          instead of your password, to log in or withdraw assets from your
          wallet.
        </span>
      </div>
      {mfaModalOpen && userMfaPreference.resource === 'NOT_SET' && (
        <BaseModal>
          <MFAEnableCard onCancelClick={() => setMfaModalOpen(false)} />
        </BaseModal>
      )}
      {mfaModalOpen && userMfaPreference.resource === 'SOFTWARE_TOKEN_MFA' && (
        <BaseModal>
          <MFADisableCard onCancelClick={() => setMfaModalOpen(false)} />
        </BaseModal>
      )}
      <Button
        variants={['primary-outlined']}
        onClick={() => setMfaModalOpen(true)}
        loading={userMfaPreference.status === FetchStatus.PENDING}
      >
        {userMfaPreference.resource === 'NOT_SET'
          ? 'Enable 2FA'
          : 'Disable 2FA'}
      </Button>
    </BaseCard>
  )
}
