import React, { useEffect, useMemo, useState } from 'react'
import { Connector, useAccount, useConnect, useDisconnect } from 'wagmi'
import { useStargazerWallet } from '@stardust-collective/web3-react-stargazer-connector'

import { BaseModal, ConnectWalletCard } from '@lattice/common/components'
import {
  AccountBalanceFetchers,
  cachedValue,
  fetchAccountsBalances,
  getEthersProvider,
  IAccount,
  IAccountBalance,
  signMessageByAlternatives,
  useEthersConnectorProvider,
} from '@lattice/utils'
import { useLocalizedValues } from '@lattice/common/hooks'

import { useToastProvider } from '../ToastProvider'

import {
  IActivteWalletContext,
  IConstellationWalletConnection,
  IEthereumWalletConnection,
  IWalletProviderContext,
} from './types'
import { generateWalletOwnershipMessage } from './utils'

const WalletProviderContext =
  React.createContext<IWalletProviderContext | null>(null)

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const { addToast } = useToastProvider()
  const wagmiConnect = useConnect()
  const wagmiAccount = useAccount()
  const wagmiDisconnect = useDisconnect()
  const wagmiConnectorClientProvider = useEthersConnectorProvider()
  const unboundProvider = useMemo(() => getEthersProvider(), [])

  const localizedValues = useLocalizedValues({ generateWalletOwnershipMessage })

  const constellationWeb3State = useStargazerWallet()

  const [connectWalletModalOpen, setConnectWalletModalOpen] = useState(false)
  const [accountsBalances, setAccountsBalance] = useState<IAccountBalance[]>([])
  const accountsNetworkAssets = useMemo(() => {
    const assets = {} as Record<
      IAccount['networkAsset'],
      IAccountBalance | null
    >
    for (const networkAsset of Object.keys(AccountBalanceFetchers)) {
      assets[networkAsset] =
        accountsBalances.find(
          (balance) => balance.networkAsset === networkAsset
        ) ?? null
    }
    return assets
  }, [accountsBalances])

  const constellationConnection: IConstellationWalletConnection =
    constellationWeb3State.active && wagmiConnectorClientProvider
      ? {
          constellation: {
            account: constellationWeb3State.account,
            provider: constellationWeb3State.provider,
            requestWalletOwnershipToken: async () => {
              return cachedValue(
                `lattice-ownership-tokens:${constellationWeb3State.account}`,
                async () => {
                  const [message, expiration] =
                    localizedValues.generateWalletOwnershipMessage()

                  const encondedSignatureRequest = window.btoa(
                    JSON.stringify({
                      content: message,
                      metadata: { expiration },
                    })
                  )

                  const signature = await constellationWeb3State.request({
                    method: 'dag_signMessage',
                    params: [
                      constellationWeb3State.account,
                      encondedSignatureRequest,
                    ],
                  })

                  const publicKey = await constellationWeb3State.request({
                    method: 'dag_getPublicKey',
                    params: [constellationWeb3State.account],
                  })

                  return {
                    value: {
                      network: 'constellation',
                      message: encondedSignatureRequest,
                      signature: [signature, publicKey].join('.'),
                      expiration: expiration.toISOString(),
                      vendorTrace: 'stargazer',
                    },
                    expiration: expiration.valueOf(),
                  }
                }
              )
            },
            disconnect: async () => {
              constellationWeb3State.deactivate()
            },
          },
        }
      : { constellation: false }

  const ethereumConnection: IEthereumWalletConnection =
    wagmiConnectorClientProvider &&
    wagmiAccount.isConnected &&
    wagmiAccount.address &&
    wagmiAccount.connector
      ? {
          ethereum: {
            account: wagmiAccount.address,
            connector: wagmiAccount.connector,
            library: wagmiConnectorClientProvider,
            requestWalletOwnershipToken: async () => {
              return cachedValue(
                `lattice-ownership-tokens:${wagmiAccount.address}`,
                async () => {
                  if (
                    !wagmiConnectorClientProvider ||
                    !wagmiAccount.address ||
                    !wagmiAccount.connector
                  ) {
                    throw new Error('Ethereum Web3 State Invalid')
                  }

                  const [message, expiration] =
                    localizedValues.generateWalletOwnershipMessage()
                  const signer = wagmiConnectorClientProvider.getSigner()
                  const signature = await signMessageByAlternatives(
                    signer,
                    message,
                    wagmiAccount.address,
                    wagmiAccount.connector
                  )
                  const vendorTrace = wagmiAccount.connector?.name ?? 'unknown'

                  return {
                    value: {
                      network: 'ethereum',
                      message,
                      signature,
                      vendorTrace,
                      expiration: expiration.toISOString(),
                    },
                    expiration: expiration.valueOf(),
                  }
                }
              )
            },
            disconnect: async () => {
              wagmiDisconnect.disconnect()
            },
          },
        }
      : { ethereum: false }

  const activeWalletData: IActivteWalletContext = useMemo(
    () =>
      (constellationWeb3State.active || wagmiAccount.isConnected) &&
      wagmiAccount.connector
        ? {
            status: 'connected',
            connector: wagmiAccount.connector!,
            ...ethereumConnection,
            ...constellationConnection,
          }
        : wagmiAccount.isConnecting
          ? { status: 'connecting', connector: wagmiAccount.connector! }
          : { status: 'disconnected', connector: null },
    [
      constellationWeb3State.active,
      constellationWeb3State.active ? constellationWeb3State.account : null,
      wagmiAccount.isConnected,
      wagmiAccount.address,
      wagmiConnectorClientProvider,
    ]
  )

  const fetchWalletAccountsBalances = async () => {
    const accounts: IAccount[] = []

    if (activeWalletData.status === 'connected' && activeWalletData.ethereum) {
      accounts.push({
        networkAsset: 'ethereum:eth',
        address: activeWalletData.ethereum.account,
      })
      accounts.push({
        networkAsset: 'ethereum:ltx',
        address: activeWalletData.ethereum.account,
      })
      accounts.push({
        networkAsset: 'ethereum:ltx-locked',
        address: activeWalletData.ethereum.account,
      })
      accounts.push({
        networkAsset: 'ethereum:veltx',
        address: activeWalletData.ethereum.account,
      })
    }

    if (
      activeWalletData.status === 'connected' &&
      activeWalletData.constellation
    ) {
      accounts.push({
        networkAsset: 'constellation:dag',
        address: activeWalletData.constellation.account,
      })
    }

    setAccountsBalance(await fetchAccountsBalances(accounts))
  }

  const requestConnectorActivation = async (connector?: Connector) => {
    if (!connector) {
      setConnectWalletModalOpen(true)
      return
    }

    wagmiDisconnect.disconnect()
    constellationWeb3State.deactivate()

    const activationErrors: {
      constellation: null | Error
      ethereum: null | Error
    } = { constellation: null, ethereum: null }

    if (connector.id === 'stargazerWalletEIP1193') {
      try {
        await constellationWeb3State.activate()
      } catch (e) {
        console.error(e)
        activationErrors.constellation =
          e instanceof Error
            ? e
            : new Error('ConstellationWeb3: ActivationError')
      }
    }

    try {
      await wagmiConnect.connectAsync({ connector })
      if (!connector.getClient) {
        throw new Error(
          'Unable to get connector provider: no getClient available'
        )
      }
    } catch (e) {
      console.error(e)
      activationErrors.constellation =
        e instanceof Error ? e : new Error('EthereumWeb3: ActivationError')
    }

    if (activationErrors.constellation && activationErrors.ethereum) {
      addToast('Unable to activate wallet by any providers', 'error', null)
      activationErrors.constellation &&
        addToast(String(activationErrors.ethereum), 'error', null)
      activationErrors.ethereum &&
        addToast(String(activationErrors.ethereum), 'error', null)

      throw new Error('Unable to activate wallet by any providers')
    }

    setConnectWalletModalOpen(false)
  }

  useEffect(() => {
    fetchWalletAccountsBalances()
    const iid = window.setInterval(fetchWalletAccountsBalances, 60000)
    return () => {
      window.clearInterval(iid)
    }
  }, [activeWalletData])

  return (
    <WalletProviderContext.Provider
      value={{
        requestConnectorActivation,
        fetchAccountsBalance: fetchWalletAccountsBalances,
        accountsNetworkAssets,
        activeWallet: activeWalletData,
        unboundProvider,
      }}
    >
      {connectWalletModalOpen && (
        <BaseModal
          variants={['primary']}
          onClickOutside={() => setConnectWalletModalOpen(false)}
          style={{
            container: {
              padding: '88px 36px',
              justifyContent: window.innerWidth < 900 ? 'center' : 'flex-end',
              alignItems: 'flex-start',
            },
          }}
        >
          <ConnectWalletCard
            onConnectorSelection={(connector) =>
              requestConnectorActivation(connector)
            }
            onCloseClick={() => setConnectWalletModalOpen(false)}
          />
        </BaseModal>
      )}
      {children}
    </WalletProviderContext.Provider>
  )
}

export { WalletProvider, WalletProviderContext }
