import React, { useEffect, useRef } from 'react'

import { useToastProvider } from '@lattice/common/providers'

import { Button } from '../Button'

import styles from './component.module.scss'

const TextAreaCopy = ({
  label,
  data,
  onCopy,
}: {
  label: string
  data: string | (() => string)
  onCopy?: () => void
}) => {
  const { addToast } = useToastProvider()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const doCopyData = async () => {
    const copyPermission = await navigator.permissions.query({
      name: 'clipboard-write' as any,
    })

    if (copyPermission.state === 'denied') {
      addToast('Unable to copy: permission denied', 'error', 5000)
      return
    }

    if (copyPermission.state === 'prompt') {
      copyPermission.addEventListener('change', doCopyData)
      return
    }

    await navigator.clipboard.writeText(
      typeof data === 'function' ? data() : data
    )

    addToast('Successfully copied', 'success', 5000)
    onCopy && onCopy()
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [data])

  return (
    <>
      <textarea className={styles.textarea} ref={textAreaRef}>
        {typeof data === 'function' ? data() : data}
      </textarea>
      <Button
        variants={['primary', 'full-width']}
        className={styles.button}
        onClick={doCopyData}
      >
        {label}
      </Button>
    </>
  )
}

export { TextAreaCopy }
