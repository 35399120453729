import React from 'react'
import cls from 'classnames'

import { BaseCard, Typography } from '@lattice/common/components'

import styles from './component.module.scss'

const NodeOperatorInfoCard = ({
  title,
  variants,
  primaryContent,
  secondaryContent,
}: {
  title: string
  variants?: ('white-title' | 'yellow-title')[]
  primaryContent?: React.ReactNode
  secondaryContent?: React.ReactNode
}) => {
  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <Typography.HeaderCardTitle variants={['bigger']}>
          <span
            className={cls(
              variants?.includes('yellow-title') && styles.yellowText,
              variants?.includes('white-title') && styles.whiteText
            )}
          >
            {title}
          </span>
        </Typography.HeaderCardTitle>
        {primaryContent && (
          <Typography.HeaderCardTitle>
            {primaryContent}
          </Typography.HeaderCardTitle>
        )}
        {secondaryContent && (
          <Typography.SubtextCard className={styles.centeredText}>
            {secondaryContent}
          </Typography.SubtextCard>
        )}
      </div>
    </BaseCard>
  )
}

export { NodeOperatorInfoCard }
