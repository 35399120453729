import * as jose from 'jose'

const getNodeTokenNodeId = (nodeToken: string) => {
  try {
    const content = jose.decodeJwt(nodeToken)?.content
    return typeof content === 'string' ? content : null
  } catch (e) {
    return null
  }
}

export { getNodeTokenNodeId }
