// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P3lCDCw4qksvZs9EhbCz{grid-column:3}@media(max-width: 1450px){.P3lCDCw4qksvZs9EhbCz{grid-column:1}}.algH34fLaB1vhKOYe39I{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/views/DashboardView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CAEA,0BAHF,sBAII,aAAA,CAAA,CAIJ,sBACE,kBAAA","sourcesContent":[".positionSingleCard {\n  grid-column: 3;\n\n  @media (max-width: 1450px) {\n    grid-column: 1;\n  }\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"positionSingleCard": `P3lCDCw4qksvZs9EhbCz`,
	"nowrap": `algH34fLaB1vhKOYe39I`
};
export default ___CSS_LOADER_EXPORT___;
