import React, { useEffect, useState } from 'react'
import cls from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import {
  useLaunchpadProvider,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import {
  Button,
  Content,
  DetailsDateCard,
  DetailsInfoCard,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  MainProjectCard,
  PendingContent,
  ProgramDetailProjectContainer,
  Tabs,
  Typography,
} from '@lattice/common/components'
import { CountryCodes, FetchStatus } from '@lattice/common/consts'
import { formatNumber, NumberFormat } from '@lattice/utils'
import { getStatusBadgeData } from '@lattice/common/providers/LaunchpadProvider/utils'
import { DetailsBaseCardContainer } from '@lattice/common/components/DetailsBaseCardContainer/component'
import { useSignInRedirect } from '@lattice/common/hooks'

import { StakingPool } from '../../components/StakingPool/component'
import { FormerStakingPool, StakedInfoCard } from '../../components'

import styles from './view.module.scss'

const ProjectDetailsView = () => {
  const params = useParams()
  const { t } = useTranslation()
  const { user, userDbData } = useUserProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()
  const signInUrl = useSignInRedirect()

  const {
    project,
    projectUser,
    userAllocation,
    requestProject,
    requestProjectUser,
    requestUserAllocation,
  } = useLaunchpadProvider()
  const [showSignInAlert, setShowSignInAlert] = useState(false)

  useEffect(() => {
    user ? setShowSignInAlert(false) : setShowSignInAlert(true)
    if (user && params.projectSlug) {
      requestProjectUser(params.projectSlug)
    }
  }, [user])

  useEffect(() => {
    params.projectSlug && requestProject(params.projectSlug)
  }, [params.projectSlug])

  useEffect(() => {
    if (activeWallet.status === 'connected' && activeWallet.ethereum) {
      requestUserAllocation(activeWallet.ethereum.account)
    }
  }, [activeWallet])

  return (
    <>
      {project.status === FetchStatus.DONE && showSignInAlert ? (
        <div className={styles.signInAlert}>
          <span>
            <Trans i18nKey="views.Launchpad.views.ProjectDetails.pleaseSignIn">
              Please{' '}
              <Link to={signInUrl} className={styles.link}>
                {' '}
                sign in{' '}
              </Link>{' '}
              or
              <Link to={'/user/signup'} className={styles.link}>
                {' '}
                register{' '}
              </Link>{' '}
              to participate in projects.
            </Trans>
          </span>
        </div>
      ) : project.status === FetchStatus.DONE &&
        userDbData.status === FetchStatus.DONE &&
        project.resource &&
        userDbData.resource &&
        userDbData.resource.kycStatus !== 'approved' ? (
        <div className={styles.signInAlert}>
          <span>
            <Trans i18nKey="views.Launchpad.views.ProjectDetails.invalidKycState">
              Please <Link to="/user/kyc">complete KYC</Link> to participate in
              projects!
            </Trans>
          </span>
        </div>
      ) : project.status === FetchStatus.DONE &&
        userDbData.status === FetchStatus.DONE &&
        project.resource &&
        userDbData.resource &&
        userDbData.resource.kycCountryCode &&
        project.resource.restricted_countries.find(
          (country) =>
            country.countryCode === userDbData.resource?.kycCountryCode
        ) ? (
        <div className={styles.signInAlert}>
          <span>
            <Trans i18nKey="views.Launchpad.views.ProjectDetails.restrictedCountryUser">
              Users from{' '}
              {{
                restrictedCountryName:
                  CountryCodes[userDbData.resource.kycCountryCode](t),
              }}{' '}
              are restricted from participating in this&nbsp; project&apos;s
              staking pools.
            </Trans>
          </span>
        </div>
      ) : null}
      <Content>
        {project.resource !== null && project.status === FetchStatus.DONE && (
          <>
            <div className={styles.pageTitle}>
              <span>
                <Link to="../" className={styles.link}>
                  {t(
                    'views.Launchpad.views.ProjectDetails.navigation',
                    'Launchpad'
                  )}
                </Link>
                {'  /  '}
                {project.resource.name}
              </span>
            </div>
            <ProgramDetailProjectContainer>
              <DetailsMainCardContainer>
                <MainProjectCard
                  title={project.resource.name}
                  bannerUrl={project.resource.bannerUrl}
                  badge={{
                    text: getStatusBadgeData(project.resource.status).text,
                    color: getStatusBadgeData(project.resource.status).color,
                  }}
                >
                  {project.resource && (
                    <Tabs>
                      {project.resource.overviewText && (
                        <Tabs.Tab
                          label={t(
                            'views.Launchpad.components.MainProjectCard.sections.names.overview',
                            'Overview'
                          )}
                          content={
                            <Typography.MarkdownContent>
                              {project.resource.overviewText}
                            </Typography.MarkdownContent>
                          }
                        />
                      )}
                      {project.resource.tokenomicsText && (
                        <Tabs.Tab
                          label={t(
                            'views.Launchpad.components.MainProjectCard.sections.names.tokenomics',
                            'Tokenomics'
                          )}
                          content={
                            <Typography.MarkdownContent>
                              {project.resource.tokenomicsText}
                            </Typography.MarkdownContent>
                          }
                        />
                      )}
                      {project.resource.roadmapText && (
                        <Tabs.Tab
                          label={t(
                            'views.Launchpad.components.MainProjectCard.sections.names.roadmap',
                            'Roadmap'
                          )}
                          content={
                            <Typography.MarkdownContent>
                              {project.resource.roadmapText}
                            </Typography.MarkdownContent>
                          }
                        />
                      )}
                      {project.resource.teamText && (
                        <Tabs.Tab
                          label={t(
                            'views.Launchpad.components.MainProjectCard.sections.names.team',
                            'Team'
                          )}
                          content={
                            <Typography.MarkdownContent>
                              {project.resource.teamText}
                            </Typography.MarkdownContent>
                          }
                        />
                      )}
                    </Tabs>
                  )}
                </MainProjectCard>
              </DetailsMainCardContainer>
              <DetailsSideCardsContainer>
                <DetailsBaseCardContainer>
                  <StakedInfoCard
                    stakeInfo={{
                      label: t(
                        'views.Launchpad.views.ProjectDetails.stakeInfo.targetRaise',
                        'Target raise'
                      ),
                      helpInfo: '',
                      stakedToken: {
                        amount:
                          formatNumber(
                            project.resource.targetRaiseAmount,
                            NumberFormat.MILLIFY
                          ) ?? '--',
                        ticker: project.resource.targetRaiseCurrency,
                      },
                      className: {
                        container: styles.yellowBadgeContainer,
                        badgeText: cls(styles.badgeText, styles.yellow),
                        amountText: styles.amountText,
                      },
                    }}
                  />
                </DetailsBaseCardContainer>
                <DetailsBaseCardContainer>
                  <DetailsDateCard
                    date={
                      dayjs().isBefore(dayjs(project.resource.startsAt))
                        ? dayjs(project.resource.startsAt).toDate()
                        : dayjs().isBefore(
                              dayjs(project.resource.stakingEndsAt)
                            )
                          ? dayjs(project.resource.stakingEndsAt).toDate()
                          : undefined
                    }
                    label={
                      dayjs().isBefore(dayjs(project.resource.startsAt))
                        ? 'Staking starts in'
                        : dayjs().isBefore(
                              dayjs(project.resource.stakingEndsAt)
                            )
                          ? 'Staking ends in'
                          : undefined
                    }
                  />
                  <StakedInfoCard
                    stakeInfo={{
                      label: 'Total Staked',
                      stakedToken: {
                        amount:
                          formatNumber(
                            project.resource.totalStaked['all'] ?? 0,
                            NumberFormat.WHOLE
                          ) ?? '--',
                        ticker: 'LTX',
                      },
                      className: {
                        container: styles.greenBadgeContainer,
                        badgeText: cls(styles.badgeText, styles.green),
                      },
                    }}
                  />
                </DetailsBaseCardContainer>
                <DetailsInfoCard
                  label={'How does Launchpad work?'}
                  subText={
                    'Launchpad is a funding platform for new projects launching within the Constellation ecosystem. Participants can soft stake their LTX or Locked LTX tokens in order to gain a percentage allocation of the raise amount during a project’s initial token launch (TGE) on an exchange.'
                  }
                  actionElement={
                    <Button.LinkExternal
                      variants={['full-width', 'outlined', 'right-icon']}
                      href="https://latticeexchange.medium.com/lattice-launchpad-walkthrough-dbd235e7921d"
                      target="_blank"
                    >
                      Learn more
                    </Button.LinkExternal>
                  }
                  variants={['full-width']}
                />
                {!project.resource.restricted_countries.find(
                  (country) =>
                    country.countryCode === userDbData.resource?.kycCountryCode
                ) &&
                  userDbData.resource?.kycStatus === 'approved' &&
                  project.resource.projectType !== 'onchain' &&
                  project.resource.status !== 'coming_soon' && (
                    <>
                      {user && activeWallet.status === 'disconnected' && (
                        <DetailsInfoCard
                          label={'Join the staking pool'}
                          actionElement={
                            <Button
                              variants={[
                                'full-width',
                                'secondary',
                                'right-icon',
                              ]}
                              onClick={() => requestConnectorActivation()}
                            >
                              Connect wallet
                            </Button>
                          }
                          variants={['full-width']}
                        />
                      )}
                      {user &&
                        activeWallet.status === 'connected' &&
                        projectUser.status !== FetchStatus.ERROR &&
                        projectUser.status !== FetchStatus.PENDING &&
                        userAllocation.resource && (
                          <StakingPool
                            project={project.resource}
                            projectUser={projectUser.resource}
                            userAllocation={userAllocation.resource}
                          />
                        )}
                    </>
                  )}
                {project.resource.projectType === 'onchain' &&
                  project.resource.status !== 'coming_soon' && (
                    <>
                      {user && activeWallet.status === 'disconnected' && (
                        <DetailsInfoCard
                          label={'Withdraw from the staking pool'}
                          actionElement={
                            <Button
                              variants={[
                                'full-width',
                                'secondary',
                                'right-icon',
                              ]}
                              onClick={() => requestConnectorActivation()}
                            >
                              Connect wallet
                            </Button>
                          }
                          variants={['full-width']}
                        />
                      )}
                      {user &&
                        activeWallet.status === 'connected' &&
                        projectUser.status !== FetchStatus.ERROR &&
                        projectUser.status !== FetchStatus.PENDING &&
                        userAllocation.resource && (
                          <FormerStakingPool
                            project={project.resource}
                            projectUser={projectUser.resource}
                            userAllocation={userAllocation.resource}
                          />
                        )}
                    </>
                  )}
              </DetailsSideCardsContainer>
            </ProgramDetailProjectContainer>
          </>
        )}
        {project.status === FetchStatus.PENDING && <PendingContent />}
      </Content>
    </>
  )
}

export { ProjectDetailsView }
