enum AvailableProtocol {
  CONSTELLATION = 'constellation',
  EVM = 'evm',
}

enum AvailableNetwork {
  CONSTELLATION = 'constellation',
  ETHEREUM = 'ethereum',
  POLYGON = 'polygon',
}

enum AvailableChain {
  ETHEREUM_MAINNET = 'ethereum_mainnet',
  ETHEREUM_SEPOLIA = 'ethereum_sepolia',
  POLYGON_MAINNET = 'polygon_mainnet',
  POLYGON_MUMBAI = 'polygon_mumbai',
  CONSTELLATION_MAINNET = 'constellation_mainnet',
  CONSTELLATION_TESTNET = 'constellation_testnet',
}

enum AvailableTokenProtocol {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
}

export {
  AvailableProtocol,
  AvailableNetwork,
  AvailableChain,
  AvailableTokenProtocol,
}
