// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B_iFlO8WRYdxBUqvVZ3X{max-width:300px;font-family:"TWK Lausanne";font-style:normal;font-weight:300;font-size:12px;line-height:20px;letter-spacing:.01em;color:#fff;white-space:pre-wrap;background-color:#313644;padding:12px 16px;border-radius:4px;opacity:1;border:1px solid rgba(255,255,255,.12)}.zqd8eqVL0SIc5VLQFzMz{border:1px solid rgba(255,255,255,.12)}`, "",{"version":3,"sources":["webpack://./src/common/components/GlobalTooltip/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,eAAA,CAEA,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,UAAA,CAEA,oBAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,SAAA,CACA,sCAAA,CAGF,sBACE,sCAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  max-width: 300px;\n\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.01em;\n  color: #ffffff;\n\n  white-space: pre-wrap;\n  background-color: #313644;\n  padding: 12px 16px;\n  border-radius: 4px;\n  opacity: 1;\n  border: 1px solid rgba(255, 255, 255, 0.12);\n}\n\n.arrow {\n  border: 1px solid rgba(255, 255, 255, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `B_iFlO8WRYdxBUqvVZ3X`,
	"arrow": `zqd8eqVL0SIc5VLQFzMz`
};
export default ___CSS_LOADER_EXPORT___;
