import { DeploymentStage } from '@lattice/common/consts'
import { EnvironmentContext } from '@lattice/runtime'
import { apiRequest, AvailableNetwork, APIError } from '@lattice/utils'

import { IWalletOwnershipToken } from '../WalletProvider'

import { IHgtpMetagraph, IHgtpNode } from './types'

const DeploymentMetagraphs: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'constellation:mainnet:dor',
  [DeploymentStage.STAGING]: 'constellation:integrationnet:dor',
  [DeploymentStage.ALPHA]: 'constellation:integrationnet:dor',
  [DeploymentStage.DELTA]: 'constellation:integrationnet:dor',
  [DeploymentStage.THETA]: 'constellation:integrationnet:dor',
}

const requestMetagraph = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/hgtp-metagraphs/${
        DeploymentMetagraphs[EnvironmentContext.stage]
      }`,
      isAuthenticated: true,
    })
    return data as IHgtpMetagraph
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestNode = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/hgtp-metagraphs/${
        DeploymentMetagraphs[EnvironmentContext.stage]
      }/node`,
      isAuthenticated: true,
    })
    return data as IHgtpNode
  } catch (err) {
    console.log(err)
    if (err instanceof APIError) {
      return null
    }
    throw err
  }
}

const executePostNode = async (body: {
  discordHandle: string
  network: AvailableNetwork
  walletOwnershipToken: IWalletOwnershipToken
  address: string
}) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/hgtp-metagraphs/${
        DeploymentMetagraphs[EnvironmentContext.stage]
      }/node`,
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executeDeleteNode = async () => {
  try {
    await apiRequest({
      method: 'DELETE',
      endpoint: `/hgtp-metagraphs/${
        DeploymentMetagraphs[EnvironmentContext.stage]
      }/node`,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executePostNodeConfirmOperator = async (body: {
  operatorNodeId: string
  network: AvailableNetwork
  walletOwnershipToken: IWalletOwnershipToken
  address: string
}) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/hgtp-metagraphs/${
        DeploymentMetagraphs[EnvironmentContext.stage]
      }/node/confirm-operator`,
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  requestMetagraph,
  requestNode,
  executePostNode,
  executeDeleteNode,
  executePostNodeConfirmOperator,
}
