import React from 'react'
import cls from 'classnames'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as UserAvatarIcon } from '@lattice/assets/icons/carbon/user-avatar.svg'
import {
  BaseCard,
  Button,
  ConnectYourWallet,
  Content,
} from '@lattice/common/components'
import { useSignInRedirect } from '@lattice/common/hooks'
import { useUserProvider, useWalletProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

const GovernanceView = () => {
  const { t } = useTranslation()
  const { user } = useUserProvider()
  const { activeWallet } = useWalletProvider()
  const signInUrl = useSignInRedirect()

  return (
    <Content padding={false}>
      <div className={styles.content}>
        <div className={styles.navtab}>
          <Button.NavLink
            to="stats"
            className={{
              main: ({ isActive }) =>
                cls(styles.tab, isActive && styles.active),
            }}
          >
            <div>{t('views.Governance.stats', 'Stats')}</div>
          </Button.NavLink>
          <Button.NavLink
            to="lock"
            className={{
              main: ({ isActive }) =>
                cls(styles.tab, isActive && styles.active),
            }}
          >
            <div>{t('views.Governance.lock', 'Lock')}</div>
          </Button.NavLink>
          <Button.NavLink
            to="claim"
            className={{
              main: ({ isActive }) =>
                cls(styles.tab, isActive && styles.active),
            }}
          >
            <div>{t('views.Governance.claim', 'Claim')}</div>
          </Button.NavLink>
          <Button.LinkExternal
            href="http://vote.lattice.is/"
            target="_blank"
            className={cls(styles.tab)}
          >
            <div>{t('views.Governance.vote', 'Vote')}</div>
          </Button.LinkExternal>
        </div>
        {user !== null && activeWallet.status === 'connected' && <Outlet />}
        {(!user ||
          activeWallet.status === 'disconnected' ||
          activeWallet.status === 'connecting') && (
          <div className={styles.overrideRoot}>
            <BaseCard
              variants={['bordered', 'full-width']}
              className={{ root: styles.notLoggedInCard, body: styles.body }}
            >
              {!user && (
                <>
                  <div className={styles.userAvatarSignIn}>
                    <UserAvatarIcon width={40} height={40} />
                    <h3>Sign in</h3>
                  </div>
                  <p>
                    Please sign in to your Lattice account to view stats, lock
                    LTX tokens for veLTX, and claim your rewards.
                  </p>
                  <Button.Link to={signInUrl} variants={['outlined']}>
                    Sign in
                  </Button.Link>
                </>
              )}
              {user &&
                (activeWallet.status === 'disconnected' ||
                  activeWallet.status === 'connecting') && (
                  <ConnectYourWallet
                    message={t(
                      'containers.Governance.connectWallet.defaultMessage',
                      'Please connect your wallet to view stats, lock LTX tokens for veLTX, and claim your rewards.'
                    )}
                  />
                )}
            </BaseCard>
          </div>
        )}
      </div>
    </Content>
  )
}

export { GovernanceView }
