import { FetchStatus, isFetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/utils'
import { AvailableNetwork } from '@lattice/utils/networkCurrencies'

import { NodeRewardType } from '../RewardsHistoryProvider'

import {
  IDorApiDtmDevice,
  INodeManagerBasicNode,
  INodeManagerGeneralNode,
  INodeManagerNode,
} from './types/'

const requestBasicNodes = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/node-manager/user/nodes`,
      isAuthenticated: true,
    })
    return data as INodeManagerBasicNode[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestGeneralNodes = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/node-manager/nodes`,
      isAuthenticated: true,
    })
    return data as INodeManagerGeneralNode[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestNodeTypes = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/rewards-history/node-types`,
      isAuthenticated: true,
    })
    return data as NodeRewardType[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestNodes = async () => {
  const oldUserNodes = await requestBasicNodes()
  const newUserNodes = await requestGeneralNodes()

  if (isFetchStatus(oldUserNodes)) {
    return oldUserNodes
  }

  if (isFetchStatus(newUserNodes)) {
    return newUserNodes
  }

  return [...oldUserNodes, ...newUserNodes]
}

const requestNode = async (entityId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/node-manager/nodes/${entityId}`,
      isAuthenticated: true,
    })
    return data as INodeManagerGeneralNode
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const setRewardTargetForNode = async (
  entityId: string,
  network: AvailableNetwork,
  address: string
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/node-manager/nodes/${entityId}/rewardstarget`,
      isAuthenticated: true,
      body: {
        network,
        address,
      },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestDorApiUserDevices = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/dtmnodes/external/dorapi/devices`,
      isAuthenticated: true,
    })
    return data as IDorApiDtmDevice[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const registerDorApiUserDevice = async (
  entityId: string,
  deviceSerial: string
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/dtmnodes/registerdtm/${entityId}`,
      isAuthenticated: true,
      body: { devices: [deviceSerial] },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const unregisterDorApiUserDevice = async (
  entityId: string,
  deviceSerial: string
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/dtmnodes/unregisterdtm/${entityId}`,
      isAuthenticated: true,
      body: { devices: [deviceSerial] },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const isNodeManagerBasicNode = (
  value: INodeManagerNode
): value is INodeManagerBasicNode => !('slug' in value)

const isNodeManagerGeneralNode = (
  value: INodeManagerNode
): value is INodeManagerGeneralNode => 'slug' in value

export {
  requestBasicNodes,
  requestGeneralNodes,
  requestNodeTypes,
  requestNodes,
  requestNode,
  setRewardTargetForNode,
  requestDorApiUserDevices,
  registerDorApiUserDevice,
  unregisterDorApiUserDevice,
  isNodeManagerBasicNode,
  isNodeManagerGeneralNode,
}
