import React, { useEffect, useState } from 'react'
import cls from 'classnames'

import {
  Content,
  FilterDropdown,
  FilterRow,
  NoContent,
  PendingContent,
  Typography,
} from '@lattice/common/components'
import { NavButton, NavTab } from '@lattice/common/components/NavTab'
import {
  AvailableChain,
  AvailableNetwork,
  ChainInfo,
  DeploymentStage,
  ExecutionContext,
  FetchStatus,
} from '@lattice/common/consts'
import {
  LiquidityStakingProgramStatus,
  useLiquidityStakingProvider,
} from '@lattice/common/providers/LiquidityStakingProvider'
import { useWalletProvider } from '@lattice/common/providers'
import AllChainsImg from '@lattice/assets/images/pngs/all_chains.png'

import styles from './view.module.scss'
import { StakingProgramCard } from './components'

const PoolStatuses: {
  value: LiquidityStakingProgramStatus | null
  content: string
}[] = [
  { value: null, content: 'All pools' },
  { value: LiquidityStakingProgramStatus.OPEN, content: 'Open' },

  { value: LiquidityStakingProgramStatus.CLOSED, content: 'Closed' },
]

const PoolNetworks: {
  value: AvailableNetwork | null
  content: { icon: React.ReactNode; name: string }
}[] = [
  {
    value: null,
    content: {
      icon: (
        <StakingProgramCard.RoundedIcon
          className={styles.icon}
          icon={AllChainsImg}
        />
      ),
      name: 'All chains',
    },
  },
  {
    value: AvailableNetwork.ETHEREUM,
    content: {
      icon: (
        <StakingProgramCard.RoundedIcon
          className={styles.icon}
          icon={
            ExecutionContext.stage === DeploymentStage.PRODUCTION
              ? ChainInfo[AvailableChain.ETHEREUM_MAINNET].chainLogoUrl
              : ChainInfo[AvailableChain.ETHEREUM_SEPOLIA].chainLogoUrl
          }
        />
      ),
      name: 'Ethereum',
    },
  },
  {
    value: AvailableNetwork.POLYGON,
    content: {
      icon: (
        <StakingProgramCard.RoundedIcon
          className={styles.icon}
          icon={
            ExecutionContext.stage === DeploymentStage.PRODUCTION
              ? ChainInfo[AvailableChain.POLYGON_MAINNET].chainLogoUrl
              : ChainInfo[AvailableChain.POLYGON_MUMBAI].chainLogoUrl
          }
        />
      ),
      name: 'Polygon',
    },
  },
]

const StakingPoolsView = () => {
  const { activeWallet } = useWalletProvider()
  const { programs, requestPrograms } = useLiquidityStakingProvider()

  const [selectedProgramStatus, setSelectedProgramStatus] = useState(
    PoolStatuses[1]
  )
  const [selectedProgramNetwork, setSelectedProgramNetwork] = useState(
    PoolNetworks[0]
  )
  const [showStaked, setShowStaked] = useState(false)

  const navs = [
    <NavButton
      key={0}
      onClick={() => {
        setShowStaked((prev) => !prev)
      }}
      text={'All'}
      className={cls(styles.tab, !showStaked && styles.active)}
    />,
    <NavButton
      key={1}
      onClick={() => {
        setShowStaked((prev) => !prev)
      }}
      text={'Staked-only'}
      className={cls(styles.tab, showStaked && styles.active)}
    />,
  ]

  const actionFinished = () => {
    requestPrograms({
      address:
        activeWallet.status === 'connected' && activeWallet.ethereum
          ? activeWallet.ethereum.account
          : undefined,
      stakedOnly: showStaked,
      status:
        selectedProgramStatus.value === null
          ? undefined
          : selectedProgramStatus.value,
      contractNetwork:
        selectedProgramNetwork.value === null
          ? undefined
          : selectedProgramNetwork.value,
    })
  }

  useEffect(() => {
    actionFinished()
  }, [
    activeWallet,
    showStaked,
    selectedProgramStatus.value,
    selectedProgramNetwork.value,
  ])

  return (
    <Content>
      <Typography.PageTitle>Pools</Typography.PageTitle>
      <section>
        <FilterRow variants={['spaced-between']}>
          <FilterRow.SpacedGroup>
            <FilterDropdown
              buttonVariants={['outlined']}
              options={PoolStatuses}
              onOptionClick={(val: string | number) => {
                const selectedOption =
                  PoolStatuses.find((option) => option.value === val) ??
                  PoolStatuses[0]
                setSelectedProgramStatus(selectedOption)
              }}
            >
              {selectedProgramStatus.content}
            </FilterDropdown>
            <FilterDropdown
              buttonVariants={['outlined']}
              options={PoolNetworks.map(({ value, content }) => ({
                value,
                content: (
                  <div className={styles.networkMenuItem}>
                    {content.icon} {content.name}
                  </div>
                ),
              }))}
              onOptionClick={(val: string | number) => {
                const selectedOption =
                  PoolNetworks.find((option) => option.value === val) ??
                  PoolNetworks[0]
                setSelectedProgramNetwork(selectedOption)
              }}
            >
              {selectedProgramNetwork.content.name}
            </FilterDropdown>
          </FilterRow.SpacedGroup>
          <NavTab navs={navs} className={styles.navTab} />
        </FilterRow>
      </section>
      {programs.resource.length ? (
        <div className={styles.stakingPoolCardsContainer}>
          {programs.resource.map((program) => (
            <StakingProgramCard
              key={program.id}
              program={program}
              actionFinished={actionFinished}
            />
          ))}
        </div>
      ) : programs.status === FetchStatus.PENDING ? (
        <PendingContent />
      ) : (
        <NoContent />
      )}
    </Content>
  )
}

export { StakingPoolsView }
