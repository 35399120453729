// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qz9ilDJ3yEVWTp9ELLpK{width:100%;aspect-ratio:1/1}.ucrMFHdNKJzgkOy7zqql{width:100%;aspect-ratio:1/1}.ucrMFHdNKJzgkOy7zqql .D21vWUsN8zIvt0Yzt2fZ{width:100%;aspect-ratio:1/1;padding:20px;border-radius:6px;background-size:contain}.ucrMFHdNKJzgkOy7zqql .D21vWUsN8zIvt0Yzt2fZ span{background-color:#272b37;border-radius:6px;padding:10px;color:rgba(255,255,255,.66);font-family:"IBM Plex Mono";font-size:12px}.z6tLUUmx57vjHFVrTvXg{display:flex;width:100%;justify-content:center;align-items:center;text-align:center;color:rgba(255,255,255,.66);font-size:12px;font-weight:200∏}.pQcTzl7Ovp64BRAuBzeH{display:flex;width:100%;flex-flow:column nowrap;row-gap:20px}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/DtmNodesDetailsView/components/DtmNodeRegisterCard/component.module.scss"],"names":[],"mappings":"AAAA,sBAAA,UACE,CAAA,gBACA,CAAA,sBAGF,UACE,CAAA,gBACA,CAAA,4CAEA,UACE,CAAA,gBACA,CAAA,YAEA,CAAA,iBACA,CAAA,uBACA,CAAA,iDAEA,wBACE,CAAA,iBACA,CAAA,YACA,CAAA,2BAEA,CAAA,2BACA,CAAA,cACA,CAAA,sBAKN,YACE,CAAA,UACA,CAAA,sBAEA,CAAA,kBACA,CAAA,iBACA,CAAA,2BAEA,CAAA,cACA,CAAA,gBACA,CAAA,sBAGF,YACE,CAAA,UACA,CAAA,uBAEA,CAAA,YACA","sourcesContent":[".root {\n  width: 100%;\n  aspect-ratio: 1/1;\n}\n\n.body {\n  width: 100%;\n  aspect-ratio: 1/1;\n\n  .imgHolder {\n    width: 100%;\n    aspect-ratio: 1/1;\n\n    padding: 20px;\n    border-radius: 6px;\n    background-size: contain;\n\n    span {\n      background-color: #272b37;\n      border-radius: 6px;\n      padding: 10px;\n\n      color: rgba(#fff, 0.66);\n      font-family: 'IBM Plex Mono';\n      font-size: 12px;\n    }\n  }\n}\n\n.addressFieldDesc {\n  display: flex;\n  width: 100%;\n\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n\n  color: rgba(#fff, 0.66);\n  font-size: 12px;\n  font-weight: 200∏;\n}\n\n.form {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n  row-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qz9ilDJ3yEVWTp9ELLpK`,
	"body": `ucrMFHdNKJzgkOy7zqql`,
	"imgHolder": `D21vWUsN8zIvt0Yzt2fZ`,
	"addressFieldDesc": `z6tLUUmx57vjHFVrTvXg`,
	"form": `pQcTzl7Ovp64BRAuBzeH`
};
export default ___CSS_LOADER_EXPORT___;
