import { stringify } from 'yaml'

import { makeTaggedTemplate } from '@lattice/utils'

const dagBasedAirdropMessageTaggedTemplate = makeTaggedTemplate`By signing this message you are approving the use of the following address (${({
  addressIdentifier,
}: {
  addressIdentifier: string
  airdropIdentifier: string
  additionalData: Record<any, any>
}) =>
  addressIdentifier}) as the crediting account of the following airdrop allocation claim (${({
  airdropIdentifier,
}) =>
  airdropIdentifier}). Signing this message still does not guarantee you will receive the aforementioned airdrop allocation as we will need to prove you're the rightful user of the selected airdrop allocation, but does guarantee that if you are found to be the rightful receiver of this airdrop you will receive your funds in the given address. 

As a technical requirement we will attach some information bellow related to this request.

----AIRDROP-CLAIM-SIGNED-DATA----

${({ additionalData }) => stringify(additionalData)}
`

export { dagBasedAirdropMessageTaggedTemplate }
