import React, { useEffect, useState } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { ImpulseSpinner } from 'react-spinners-kit'

import { ReactComponent as ArrowsUpDown } from '@lattice/assets/images/svgs/ArrowsDownUp.svg'
import { ReactComponent as CaretDown } from '@lattice/assets/images/svgs/CaretDown.svg'
import { apiRequest } from '@lattice/utils'
import { GeneralColor } from '@lattice/components/common/Consts'
import { ExecutionContext } from '@lattice/common/consts'
import { Button } from '@lattice/common/components'

import styles from '../styles.module.scss'
import { InfoRow } from '../common/InfoRow'
import { ExolixNetwork, ExolixRate } from '../../types'
import { SelectTokenModal } from '../SelectTokenModal'

import { INITIAL_SENDING_CURRENCY, INITIAL_RECEIVING_CURRENCY } from './consts'

type Currency = {
  code: string
  icon: string
  amount?: string
  network: ExolixNetwork
}

const InitialContent = ({
  handleSelectedCurrencies,
}: {
  handleSelectedCurrencies: (
    coinFrom: string,
    coinTo: string,
    rate: ExolixRate,
    receivingNetwork: ExolixNetwork,
    sendingNetwork: ExolixNetwork
  ) => void
}) => {
  const { t } = useTranslation()
  const [rate, setRate] = useState<ExolixRate>()
  const [loading, setLoading] = useState(true)
  const [rateError, setRateError] = useState<string | null>(null)
  const [sendingCurrency, setSendingCurrency] = useState<Currency>({
    code: INITIAL_SENDING_CURRENCY.code,
    amount: '1',
    icon: INITIAL_SENDING_CURRENCY.icon,
    network: INITIAL_SENDING_CURRENCY.network,
  })
  const [sendingAmount, setSendingAmount] = useState('1')
  const [receivingCurrency, setReceivingCurrency] = useState<Currency>(
    INITIAL_RECEIVING_CURRENCY
  )
  const [showModal, setShowModal] = useState<string | undefined>(undefined)
  const [toggle, setToggle] = useState(false)

  const getRate = async (coinFrom: string, coinTo: string, amount: string) => {
    if (!amount) {
      setLoading(false)
      return
    }
    let data
    setRateError(null)
    try {
      ;({ data } = await apiRequest({
        method: 'POST',
        endpoint: '/swapping/rate',
        headers: {
          'x-lattice-api-key': ExecutionContext.infra.sharedKey,
        },
        body: {
          coinFrom: coinFrom,
          coinTo: coinTo,
          amount: amount,
          coinToNetwork: receivingCurrency.network.network,
          coinFromNetwork: sendingCurrency.network.network,
        },
      }))
      if (data.error) {
        setRateError(data.error)
        setRate(undefined)
      } else {
        setRate(data)
        setReceivingCurrency({ ...receivingCurrency, amount: data.toAmount })
      }
      setLoading(false)
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        Number.parseFloat(sendingAmount) &&
        setSendingCurrency({
          ...sendingCurrency,
          amount: sendingAmount,
        }),
      600
    )
    return () => clearTimeout(timeOutId)
  }, [sendingAmount])

  useEffect(() => {
    setLoading(true)
    getRate(
      sendingCurrency.code,
      receivingCurrency.code,
      sendingCurrency.amount!
    )
  }, [sendingCurrency, receivingCurrency.code, receivingCurrency.network])

  const handleTokenSelection = (
    code: string,
    icon: string,
    network: ExolixNetwork
  ) => {
    showModal === 'receiving'
      ? setReceivingCurrency({ code, icon, network: network })
      : setSendingCurrency({ ...sendingCurrency, code, icon, network: network })
    setShowModal(undefined)
  }

  const closeModal = () => {
    setShowModal(undefined)
  }

  const toggleCurrencies = () => {
    const prevSending = sendingCurrency
    setSendingAmount(receivingCurrency.amount ?? '')
    setSendingCurrency({ ...receivingCurrency })
    setReceivingCurrency({ ...prevSending })
    setToggle((t) => !t)
  }

  const disabledSwapButton =
    !rate || !!rate.message || !receivingCurrency.network || loading

  return (
    <>
      <div className={styles.swapInputs}>
        {showModal && (
          <SelectTokenModal
            handleTokenSelection={handleTokenSelection}
            closeModal={closeModal}
          />
        )}
        <div className={styles.dataColumn}>
          <div className={styles.inputLabel}>
            {t('components.ExolixSwap.InitialContent.labels.send', 'You send')}
          </div>
          <div className={styles.inputRow}>
            <input
              className={cls(styles.inputAmount, styles.pointer)}
              type="text"
              pattern="[0-9]+([\.][0-9]+)?"
              placeholder={
                (sendingCurrency.amount && sendingCurrency.amount.toString()) ||
                ''
              }
              value={sendingAmount !== '0' ? sendingAmount : ''}
              onChange={(e) => {
                e.target.value
                  ? setSendingAmount(e.target.value.replace(',', '.'))
                  : setSendingAmount('0')
              }}
            />
            <div
              className={styles.currencySelector}
              onClick={() => setShowModal('sending')}
            >
              <div className={styles.currencySelection}>
                <img
                  className={styles.currencyIcon}
                  src={sendingCurrency.icon}
                  width={'24px'}
                  height={'24px'}
                />
                {sendingCurrency.code}
              </div>
              <div className={styles.caretDown}>
                <CaretDown />
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.exchangeButton}
          onClick={() => toggleCurrencies()}
        >
          <ArrowsUpDown
            stroke={toggle ? '#FFFFFF' : '#FFCC00'}
            color={toggle ? '#FFCC00' : '#FFFFFF'}
          />
        </div>
        <div className={styles.dataColumn}>
          <div className={styles.inputLabel}>
            {t(
              'components.ExolixSwap.InitialContent.labels.receive',
              'You receive'
            )}
          </div>
          <div className={styles.inputRow}>
            <div className={cls(styles.inputAmount, styles.readOnly)}>
              {loading ? (
                <ImpulseSpinner
                  size={24}
                  frontColor={GeneralColor.USC_GOLD.toString()}
                />
              ) : (
                rate && rate.toAmount
              )}
            </div>
            <div
              className={styles.currencySelector}
              onClick={() => setShowModal('receiving')}
            >
              <div className={styles.currencySelection}>
                <img
                  className={styles.currencyIcon}
                  src={receivingCurrency.icon}
                  width={'24px'}
                  height={'24px'}
                />
                {receivingCurrency.code}
              </div>
              <div className={styles.caretDown}>
                <CaretDown />
              </div>
            </div>
          </div>
        </div>
      </div>
      {rate && rate.message && (
        <div
          className={cls(
            styles.inputLabel,
            styles.red,
            styles.errorRateMessage
          )}
        >
          {rate.message}
        </div>
      )}
      {rateError && (
        <div
          className={cls(
            styles.inputLabel,
            styles.red,
            styles.errorRateMessage
          )}
        >
          {rateError.includes('exchange pair is not available')
            ? rateError
            : 'An error occurred'}
        </div>
      )}
      <div className={styles.swapInfo}>
        <InfoRow
          leftSide={t(
            'components.ExolixSwap.InitialContent.labels.rate',
            'Rate'
          )}
          rightSide={
            !rateError
              ? sendingCurrency.amount
                ? `${sendingCurrency.amount} ${sendingCurrency.code} ≈ ${rate?.toAmount} ${receivingCurrency.code}`
                : ''
              : '--'
          }
          loading={loading}
        />
        <InfoRow
          leftSide={t(
            'components.ExolixSwap.InitialContent.labels.minAmount',
            'Min. amount'
          )}
          rightSide={
            !rateError ? `${rate?.minAmount} ${sendingCurrency.code}` : '--'
          }
          loading={loading}
        />
      </div>
      <div className={styles.swapButtonContainer}>
        <Button.Link to=".." className={cls(styles.swapButton, styles.cancel)}>
          Cancel
        </Button.Link>
        <Button
          className={cls(
            styles.swapButton,
            disabledSwapButton && styles.disabled
          )}
          disabled={disabledSwapButton}
          onClick={() =>
            !disabledSwapButton &&
            handleSelectedCurrencies(
              sendingCurrency.code,
              receivingCurrency.code,
              rate,
              receivingCurrency.network,
              sendingCurrency.network
            )
          }
        >
          Swap
        </Button>
      </div>
    </>
  )
}

export { InitialContent }
