import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { dag4 } from '@stardust-collective/dag4'

import {
  BaseCard,
  BaseModal,
  Button,
  InputRow,
  PendingContent,
  Tabs,
} from '@lattice/common/components'
import { useSoftNodesProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import { shortenAddress } from '@lattice/utils'

import styles from './component.module.scss'

type DagBasedAirdropClaimModalFormValues = {
  type: 'softnode' | 'usergiven'
  address: string
  nodeSelected: string
}

const DagBasedAirdropClaimModal = ({
  closed,
  onClose,
  onApproveClaim,
}: {
  closed: boolean
  onClose: () => void
  onApproveClaim: (
    values: DagBasedAirdropClaimModalFormValues
  ) => void | Promise<void>
}) => {
  const { userNodes, requestUserNodes } = useSoftNodesProvider()

  const userDagBasedAirdropClaimForm = useForm<{
    type: 'softnode' | 'usergiven'
    address: string
    nodeSelected: string
  }>({
    mode: 'onChange',
    defaultValues: { type: 'softnode', address: '', nodeSelected: '' },
  })
  const userDagBasedAirdropClaimFormValues =
    userDagBasedAirdropClaimForm.watch()

  const doRequestSignedClaimMessage =
    userDagBasedAirdropClaimForm.handleSubmit(onApproveClaim)

  useEffect(() => {
    requestUserNodes()
  }, [])

  return closed ? null : (
    <BaseModal
      onClickOutside={() => {
        onClose()
        userDagBasedAirdropClaimForm.reset()
      }}
    >
      <BaseCard
        className={{
          root: styles.approveAirdropClaimCard,
          body: styles.body,
        }}
        variants={['header-title']}
        header={<h5>Claim Airdrop</h5>}
      >
        <form onSubmit={doRequestSignedClaimMessage}>
          <Tabs
            variants={['no-padded-content']}
            activeTabId={userDagBasedAirdropClaimFormValues.type}
            onTabClick={(key) =>
              userDagBasedAirdropClaimForm.setValue('type', key as any)
            }
          >
            <Tabs.Tab
              id="softnode"
              label="Soft node"
              content={
                <div className={styles.addressSelector}>
                  <p>
                    Select a soft node to use for claiming your airdrop. You
                    will be required to sign a message with the Ethereum wallet
                    containing your veLTX to approve this claim.
                  </p>
                  {userNodes.status === FetchStatus.PENDING ? (
                    <PendingContent variants={['min-height']} />
                  ) : (
                    <Controller
                      name="nodeSelected"
                      rules={{
                        required:
                          userDagBasedAirdropClaimFormValues.type ===
                          'softnode',
                        validate: (value) =>
                          userDagBasedAirdropClaimFormValues.type !==
                            'softnode' ||
                          dag4.keyStore.validateDagAddress(value) ||
                          'Invalid DAG address format',
                      }}
                      control={userDagBasedAirdropClaimForm.control}
                      render={({ field: { ref: __, ...rest }, fieldState }) => (
                        <InputRow.Select
                          variants={['full-width']}
                          error={fieldState.error?.message}
                          placeholder="Select soft node"
                          options={userNodes.resource.map((userNode) => ({
                            value: userNode.address,
                            leftContent: userNode.alias,
                            rightContent: shortenAddress(
                              userNode.address,
                              5,
                              5,
                              '...'
                            ),
                          }))}
                          {...rest}
                        />
                      )}
                    />
                  )}
                </div>
              }
            />
            <Tabs.Tab
              id="usergiven"
              label="External address"
              content={
                <div className={styles.addressSelector}>
                  <p>
                    Provide an external DAG address to use for claiming your
                    airdrop. You will be required to sign a message with the
                    Ethereum wallet containing your veLTX to approve this claim.
                  </p>
                  <InputRow
                    variants={['full-width']}
                    error={
                      userDagBasedAirdropClaimForm.formState.errors.address
                        ?.message
                    }
                    {...userDagBasedAirdropClaimForm.register('address', {
                      required:
                        userDagBasedAirdropClaimFormValues.type === 'usergiven',
                      validate: (value) =>
                        userDagBasedAirdropClaimFormValues.type !==
                          'usergiven' ||
                        dag4.keyStore.validateDagAddress(value) ||
                        'Invalid DAG address format',
                    })}
                  />
                </div>
              }
            />
          </Tabs>
          <div className={styles.buttons}>
            <Button
              variants={['secondary', 'full-width']}
              onClick={() => {
                onClose()
                userDagBasedAirdropClaimForm.reset()
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variants={['primary', 'full-width']}
              disabled={!userDagBasedAirdropClaimForm.formState.isValid}
            >
              Approve claim
            </Button>
          </div>
        </form>
      </BaseCard>
    </BaseModal>
  )
}

export { DagBasedAirdropClaimModal, DagBasedAirdropClaimModalFormValues }
