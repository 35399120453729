import React from 'react'

import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'

import { NodeRewardType } from '../RewardsHistoryProvider'

import {
  INodeManagerProviderContext,
  INodeManagerGeneralNode,
  INodeManagerNode,
  IDorApiDtmDevice,
} from './types'
import {
  registerDorApiUserDevice,
  requestDorApiUserDevices,
  requestNode,
  requestNodeTypes,
  requestNodes,
  setRewardTargetForNode,
  unregisterDorApiUserDevice,
} from './utils'

const NodeManagerProviderContext =
  React.createContext<INodeManagerProviderContext | null>(null)

const NodeManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const nodeTypes = useFetchableResource<NodeRewardType[]>([])
  const userNodes = useFetchableResource<INodeManagerNode[] | null>(null)
  const userNode = useFetchableResource<INodeManagerGeneralNode | null>(null)

  const dorApiOperations = useFetchableOperations([
    'registerUserDevice',
    'unregisterUserDevice',
  ])
  const dorApiUserDevices = useFetchableResource<IDorApiDtmDevice[]>([])

  const context: INodeManagerProviderContext = {
    nodeTypes,
    userNodes,
    userNode,
    requestNodeTypes: nodeTypes.wrappedFetch(requestNodeTypes),
    requestUserNodes: userNodes.wrappedFetch(requestNodes),
    requestUserNode: userNode.wrappedFetch(requestNode),
    setRewardTargetForNode,
    integrations: {
      dorapi: {
        operations: dorApiOperations,
        userDevices: dorApiUserDevices,
        requestUserDevices: dorApiUserDevices.wrappedFetch(
          requestDorApiUserDevices
        ),
        registerUserDevice: dorApiOperations.registerUserDevice.wrappedFetch(
          registerDorApiUserDevice
        ),
        unregisterDorApiUserDevice:
          dorApiOperations.unregisterUserDevice.wrappedFetch(
            unregisterDorApiUserDevice
          ),
      },
    },
  }

  return (
    <NodeManagerProviderContext.Provider value={context}>
      {children}
    </NodeManagerProviderContext.Provider>
  )
}

export { NodeManagerProvider, NodeManagerProviderContext }
