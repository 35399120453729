// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aOyZkDjmRSecpx5jYe5j{display:flex;justify-content:space-between;align-items:center;box-sizing:border-box;width:100%;height:86px;padding:16px 24px;border-bottom:1px solid #1b1e29}`, "",{"version":3,"sources":["webpack://./src/common/components/CardRow/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,+BAAA","sourcesContent":[".row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  width: 100%;\n  height: 86px;\n  padding: 16px 24px;\n  border-bottom: 1px solid #1b1e29;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `aOyZkDjmRSecpx5jYe5j`
};
export default ___CSS_LOADER_EXPORT___;
