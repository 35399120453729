import { EnvironmentContext } from '@lattice/runtime/environment_context'
import { createResourcesProvider } from '@lattice/utils'

import { UpholdTopperBaseApiUrls } from './consts'
import { IUpholdTopperExchangeData } from './types'
import {
  IUpholdTopperAvailableCurrenciesResponse,
  IUpholdTopperSimulationsResponse,
} from './types/api'

const [BuyTopperProvider, useBuyTopperProvider] = createResourcesProvider(
  'BuyTopperProvider',
  {
    currencies: {
      apiUrl: `${
        UpholdTopperBaseApiUrls[EnvironmentContext.stage]
      }/assets/crypto-onramp`,
      initialState: null,
      queryParams: {},
      resource: {} as IUpholdTopperAvailableCurrenciesResponse,
      pathParams: {},
      responseProcessor: (response) => response.data,
    },
    exchange: {
      apiUrl: `${EnvironmentContext.EcosystemApiUrl}/uphold/topper/exchange`,
      initialState: null,
      queryParams: {},
      resource: {} as { token: { jti: string; jwt: string } },
      pathParams: {},
      method: 'post',
      requestData: {} as IUpholdTopperExchangeData,
    },
    simulate: {
      apiUrl: `${EnvironmentContext.EcosystemApiUrl}/uphold/topper/simulate`,
      initialState: null,
      queryParams: {},
      resource: {} as IUpholdTopperSimulationsResponse,
      pathParams: {},
      method: 'post',
      requestData: {} as IUpholdTopperExchangeData,
    },
  }
)

export { BuyTopperProvider, useBuyTopperProvider }
