import React from 'react'
import cls from 'classnames'
import { Link } from 'react-router-dom'

import { BaseCard, ConditionalWrapper } from '@lattice/common/components'
import { ReactComponent as CheckmarkOutlineIcon } from '@lattice/assets/icons/carbon/checkmark-outline.svg'
import { slugify } from '@lattice/utils'

import styles from './component.module.scss'

const DtmNodeCard = ({
  name,
  imageUrl,
  redeemStatus,
  showCheckmark,
  onClick,
  className,
  devicesLinkedQuantity,
  disableLink,
}: {
  name: string
  imageUrl: string
  redeemStatus?: string
  showCheckmark?: boolean
  onClick?: () => void
  className?: {
    root?: string
    body?: string
    footer?: string
    badge?: string
  }
  showLinkedNode?: boolean
  linkedNode?: boolean
  devicesLinkedQuantity: number | null
  disableLink: boolean
}) => {
  return (
    <ConditionalWrapper
      condition={!disableLink}
      wrapper={(children) => (
        <Link to={`/nodes/dtm-nodes/${slugify(name)}`}>{children}</Link>
      )}
    >
      <BaseCard
        variants={['bordered']}
        className={{
          root: cls(styles.root, className?.root),
          body: cls(styles.body, className?.body),
          footer: cls(styles.footer, className?.footer),
        }}
        footer={
          <div>
            <span>{name}</span>
            {devicesLinkedQuantity != null && (
              <span>
                <br />
                Linked devices:
                {devicesLinkedQuantity}
              </span>
            )}
          </div>
        }
      >
        <div
          style={{ backgroundImage: `url("${imageUrl}")` }}
          className={styles.imgHolder}
          onClick={onClick}
        >
          <div className={styles.badgeRow}>
            {redeemStatus && (
              <span className={cls(styles.badge, className?.badge)}>
                {redeemStatus}
              </span>
            )}
            {!redeemStatus && showCheckmark && (
              <div className={styles.checkMark}>
                <CheckmarkOutlineIcon color={'#ffcc00'} />
              </div>
            )}
          </div>
        </div>
      </BaseCard>
    </ConditionalWrapper>
  )
}

export { DtmNodeCard }
