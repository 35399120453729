// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kGhNF3aFqzWItr5Xmd4a{width:350px}.ZlrYZpJIdQjdzFwQDa_D{display:flex;height:50px;justify-content:flex-start;align-items:center;padding:0px 20px;color:#fff}.kmZHGdWkUDSe590jT8iv{display:flex;flex-flow:column nowrap;row-gap:20px;padding:20px}`, "",{"version":3,"sources":["webpack://./src/common/components/DebugDataModal/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,WAAA,CAGF,sBACE,YAAA,CAEA,WAAA,CAEA,0BAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CAEA,uBAAA,CACA,YAAA,CACA,YAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.root {\n  width: 350px;\n}\n\n.header{\n  display: flex;\n  \n  height: 50px;\n\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0px 20px;\n  color: #fff;\n}\n\n.body{\n  display: flex;\n\n  flex-flow: column nowrap;\n  row-gap: 20px;\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `kGhNF3aFqzWItr5Xmd4a`,
	"header": `ZlrYZpJIdQjdzFwQDa_D`,
	"body": `kmZHGdWkUDSe590jT8iv`
};
export default ___CSS_LOADER_EXPORT___;
