import React from 'react'
import cls from 'classnames'

import { SpacedGroup } from './components'
import styles from './component.module.scss'

const FilterRow = Object.assign(
  ({
    variants,
    children,
    className,
  }: {
    variants?: 'spaced-between'[]
    children: React.ReactNode
    className?: string
  }) => {
    return (
      <div
        className={cls(
          styles.main,
          variants?.includes('spaced-between') && styles.spaced,
          className
        )}
      >
        {children}
      </div>
    )
  },
  { SpacedGroup }
)

export { FilterRow }
