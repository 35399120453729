// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FW0om1cs9C5gq4Po5wYw{display:flex;min-height:46px;min-width:100%;width:max-content;flex-flow:row nowrap;align-items:flex-start;padding:14px 16px;color:rgba(255,255,255,.96);font-weight:300;font-size:14px;line-height:20px}.FW0om1cs9C5gq4Po5wYw:nth-child(1){border-radius:6px 6px 0px 0px}.FW0om1cs9C5gq4Po5wYw:last-child{border-radius:0px 0px 6px 6px}.FW0om1cs9C5gq4Po5wYw:hover{background-color:rgba(255,255,255,.08)}.FW0om1cs9C5gq4Po5wYw.Te7kmIBBITVxlplr1V6y :nth-child(1){margin-right:16px}`, "",{"version":3,"sources":["webpack://./src/common/components/Dropdown/components/DropdownListItem/components/DropdownListItemBase/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CAEA,cAAA,CACA,iBAAA,CAEA,oBAAA,CACA,sBAAA,CACA,iBAAA,CAEA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,mCACE,6BAAA,CAGF,iCACE,6BAAA,CAGF,4BACE,sCAAA,CAGF,yDACE,iBAAA","sourcesContent":[".main {\n  display: flex;\n  min-height: 46px;\n\n  min-width: 100%;\n  width: max-content;\n\n  flex-flow: row nowrap;\n  align-items: flex-start;\n  padding: 14px 16px;\n\n  color: rgba(#fff, 0.96);\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 20px;\n\n  &:nth-child(1) {\n    border-radius: 6px 6px 0px 0px;\n  }\n\n  &:last-child {\n    border-radius: 0px 0px 6px 6px;\n  }\n\n  &:hover {\n    background-color: rgba(#fff, 0.08);\n  }\n\n  &.icon :nth-child(1) {\n    margin-right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `FW0om1cs9C5gq4Po5wYw`,
	"icon": `Te7kmIBBITVxlplr1V6y`
};
export default ___CSS_LOADER_EXPORT___;
