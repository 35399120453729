import { createProviderStateHook } from '@lattice/utils'

import { AirdropsProvider, AirdropsProviderContext } from './provider'

const useAirdropsProvider = createProviderStateHook(
  AirdropsProvider,
  AirdropsProviderContext
)

export { useAirdropsProvider }
