// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uESaxneI1R6EXxj7FL3k{font-family:"TWK Lausanne";font-style:normal;font-weight:300;font-size:12px;line-height:18px;letter-spacing:.03em;color:rgba(255,255,255,.66)}`, "",{"version":3,"sources":["webpack://./src/common/components/Typography/components/SubtextCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,2BAAA","sourcesContent":[".subtext {\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: rgba(#fff, 0.66);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtext": `uESaxneI1R6EXxj7FL3k`
};
export default ___CSS_LOADER_EXPORT___;
