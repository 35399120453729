import React from 'react'

import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'

import {
  ITestnetValidatorStats,
  ITestnetRewardsUserData,
  ITestnetValidatorsProviderContext,
} from './types'
import {
  getTestnetRewardsStats,
  getTestnetRewardsUser,
  setTestnetRewardsNode,
  setTestnetRewardsUser,
} from './utils'

const TestnetValidatorsProviderContext =
  React.createContext<ITestnetValidatorsProviderContext | null>(null)

const TestnetValidatorsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const tnrStats = useFetchableResource<ITestnetValidatorStats | null>(null)
  const tnrUser = useFetchableResource<ITestnetRewardsUserData | null>(null)
  const operations = useFetchableOperations([
    'setTestnetRewardsUser',
    'setTestnetRewardsNode',
  ])

  const ctx: ITestnetValidatorsProviderContext = {
    operations,
    tnrStats,
    tnrUser,
    getTestnetRewardsStats: tnrStats.wrappedFetch(getTestnetRewardsStats),
    getTestnetRewardsUser: tnrUser.wrappedFetch(getTestnetRewardsUser),
    setTestnetRewardsUser: operations.setTestnetRewardsUser.wrappedFetch(
      setTestnetRewardsUser
    ),
    setTestnetRewardsNode: operations.setTestnetRewardsNode.wrappedFetch(
      setTestnetRewardsNode
    ),
  }

  return (
    <TestnetValidatorsProviderContext.Provider value={ctx}>
      {children}
    </TestnetValidatorsProviderContext.Provider>
  )
}

export { TestnetValidatorsProvider, TestnetValidatorsProviderContext }
