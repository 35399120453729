import React from 'react'
import { useRouteError } from 'react-router-dom'

import { TextAreaCopy } from '@lattice/common/components'
import { useDebugDataProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

const RuntimeErrorView = () => {
  const { getEncodedDebugData } = useDebugDataProvider()
  const routeError = useRouteError()

  const errorData = [
    ...(routeError instanceof Error
      ? [routeError.name, routeError.message, routeError.stack]
      : [String(routeError)]),
    '---',
    getEncodedDebugData(),
  ].join('\n')

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.errorCode}>500</div>
        <div className={styles.errorMessage}>
          {'Whoa, this '} <s className={styles.party}>party</s>{' '}
          {'page broke \nthe network! Please submit this error log to our team'}
        </div>
        <TextAreaCopy label="Copy Log" data={errorData} />
      </div>
    </div>
  )
}

export { RuntimeErrorView }
