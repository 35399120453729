import { IUserMfaRequiredFunction } from './types'

const isUserMfaRequiredFunction = (
  value: any
): value is IUserMfaRequiredFunction =>
  typeof value === 'function' &&
  'isMfaRequired' in value &&
  value.isMfaRequired &&
  'challengeName' in value &&
  typeof value.challengeName === 'string'

export { isUserMfaRequiredFunction }
