import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  IWalletOwnershipToken,
  useUserProvider,
} from '@lattice/common/providers'

import {
  getUserWallets,
  registerUserWallet,
  unlinkUserWallet,
  verifyUserWallet,
} from '../user'

export const useUserWalletsQueries = () => {
  const queryClient = useQueryClient()
  const { user } = useUserProvider()

  const wallets = useQuery({
    queryKey: ['user', 'wallets'],
    queryFn: async (qfnContext) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      return getUserWallets({ qfnContext })
    },
    enabled: !!user,
  })

  const walletRegisterMut = useMutation({
    onMutate: async () => {},
    mutationFn: async (values: {
      address: string
      network: string
      primary: boolean
    }) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await registerUserWallet(
        values.address,
        values.network,
        values.primary,
        {}
      )
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'wallets'] })
    },
    onError: async (error) => {
      console.log(error)
    },
  })

  const walletVerifyMut = useMutation({
    onMutate: async () => {},
    mutationFn: async (values: {
      address: string
      network: string
      ownershipToken: IWalletOwnershipToken
    }) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await verifyUserWallet(
        values.address,
        values.network,
        values.ownershipToken,
        {}
      )
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'wallets'] })
    },
    onError: async (error) => {
      console.log(error)
    },
  })

  const walletUnlinkMut = useMutation({
    onMutate: async () => {},
    mutationFn: async (values: { address: string; network: string }) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await unlinkUserWallet(values.address, values.network, {})
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'wallets'] })
    },
    onError: async (error) => {
      console.log(error)
    },
  })

  return { wallets, walletRegisterMut, walletVerifyMut, walletUnlinkMut }
}
