import { useMemo } from 'react'

import { IContextToast, useToastProvider } from '../providers'

type IProgressToastHookState = {
  progress: (
    ...args: Parameters<ReturnType<typeof useToastProvider>['addToast']>
  ) => void
  wrappedErrors: <T extends (...args: any[]) => any>(fn: T) => T
  wrappedErrorsAsync: <T extends (...args: any[]) => Promise<any>>(fn: T) => T
  reset: () => void
}

const encodeError = (e: any): string => {
  if (e instanceof Error) {
    return String(e)
  }

  try {
    return JSON.stringify(e).substring(0, 150)
  } catch (e) {
    console.dir(e)
    return 'Unknown error'
  }
}

const useProgressToasts = (): IProgressToastHookState => {
  const { addToast } = useToastProvider()
  const toastMemo = useMemo<{ memo: IContextToast | null }>(
    () => ({ memo: null }),
    []
  )

  const state = {
    progress: (...args: Parameters<typeof addToast>) => {
      state.reset()
      const toast = addToast(...args)
      toastMemo.memo = toast
      return toast
    },
    wrappedErrors: <T extends (...args: any[]) => any>(fn: T): T => {
      return ((...args) => {
        try {
          return fn(...args)
        } catch (e) {
          state.progress(encodeError(e), 'error', null)
          throw e
        }
      }) as T
    },
    wrappedErrorsAsync: <T extends (...args: any[]) => Promise<any>>(
      fn: T
    ): T => {
      return (async (...args) => {
        try {
          return await fn(...args)
        } catch (e) {
          state.progress(encodeError(e), 'error', null)
          throw e
        }
      }) as T
    },
    reset: () => {
      if (toastMemo.memo) {
        toastMemo.memo.remove()
        toastMemo.memo = null
      }
    },
  }

  return state
}

export { useProgressToasts }
