import axios from 'axios'

import { TRACKING_TOKEN_IDS } from './consts'
import { ITokenPrices } from './types'

const { REACT_APP_ECOSYSTEM_API_URL } = process.env

const fetchTokenPrices = async () => {
  const tokenPrices: ITokenPrices = {}

  try {
    const response = await axios.get(
      REACT_APP_ECOSYSTEM_API_URL + '/coin-prices',
      {
        params: {
          ids: Object.values(TRACKING_TOKEN_IDS).join(','),
          vs_currencies: 'usd',
          include_market_cap: true,
          include_24hr_vol: true,
          include_24hr_change: true,
          include_last_updated_at: true,
          token: 'lattice',
        },
      }
    )
    const { data } = response.data
    for (const [key, tokenId] of Object.entries(TRACKING_TOKEN_IDS)) {
      tokenPrices[key] = data[tokenId]
    }
  } catch (e) {
    console.error('Error fetching token prices', e)
  }
  return tokenPrices
}

export { fetchTokenPrices }
