// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oE7dkpw01p7OQ4CuWxN1{position:relative}.oE7dkpw01p7OQ4CuWxN1 svg,.oE7dkpw01p7OQ4CuWxN1 svg *{fill:none !important}`, "",{"version":3,"sources":["webpack://./src/common/components/Dropdown/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CAEA,sDAEE,oBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.root {\n  position: relative;\n\n  svg,\n  svg * {\n    fill: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `oE7dkpw01p7OQ4CuWxN1`
};
export default ___CSS_LOADER_EXPORT___;
