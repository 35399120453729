import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const HeaderCardTitle = ({
  children,
  variants,
}: {
  variants?: ('normal' | 'bigger')[]
  children: React.ReactNode
}) => {
  return (
    <div
      className={cls(
        styles.headerCardTitle,
        variants?.includes('bigger') && styles.bigger
      )}
    >
      {children}
    </div>
  )
}

export { HeaderCardTitle }
