import axios from 'axios'
import { useEffect } from 'react'

import { getTypedContract, isAxiosError } from '@lattice/utils'

import { FetchStatus } from '../consts'

import { useFetchableResource } from './useFetchableResource'

const useNFTAttributeField = (
  name: string | null,
  tokenId: string | number | null,
  contractAddress: string
) => {
  const attribute = useFetchableResource<JSONScalar>(null)

  const getAttributeField = attribute.wrappedFetch(async () => {
    if (!(name && tokenId && contractAddress)) {
      return
    }

    const contract = getTypedContract('DorTrafficMinerNFT', contractAddress)

    const metadataUri = await contract.tokenURI(tokenId)

    let data: { attributes: { value: any; trait_type: string }[] }
    try {
      data = (await axios.get(metadataUri)).data
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.code === '404') {
          return FetchStatus.NOT_FOUND
        }
      }

      throw e
    }

    const attribute = data.attributes.find((attr) => attr.trait_type === name)

    if (!attribute) {
      return FetchStatus.NOT_FOUND
    }

    return attribute.value
  })

  useEffect(() => {
    if (name && tokenId && contractAddress) {
      getAttributeField()
    }
  }, [name, tokenId, contractAddress])

  return attribute
}

export { useNFTAttributeField }
