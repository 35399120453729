// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._H0nAgtZ4NTW10AArxdz{color:#55c555}._jEwru9BAf64Ib98tTkw{color:#fec84b}.jyQrRQMR7FdkWtPnQnh1{color:#5397fd}.C6isEx9QRzVQUjCHX2sW{color:#f5735f}.Pjp8qM_T2dDG1003oSFS{color:rgba(255,255,255,.66);cursor:pointer}.vJnGCy0zXERoV2jTcBUl{transition:all 500ms}.fIsGOrJ5X0NM8H3u_WWC{display:grid;min-height:80px;grid-template-columns:24px 1fr 24px;place-items:center;place-content:center;column-gap:12px;padding:20px 16px}.SN6_3_2uiB5dZ7sOLR7w{display:flex;width:100%;color:#fff;font-size:14px;line-height:20px;font-weight:300}`, "",{"version":3,"sources":["webpack://./src/common/components/ToastCard/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,2BAAA,CACA,cAAA,CAGF,sBACE,oBAAA,CAGF,sBACE,YAAA,CACA,eAAA,CAEA,mCAAA,CAEA,kBAAA,CACA,oBAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,UAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.success {\n  color: #55c555;\n}\n\n.warning {\n  color: #fec84b;\n}\n\n.info {\n  color: #5397fd;\n}\n\n.error {\n  color: #f5735f;\n}\n\n.close {\n  color: rgba(#fff, 0.66);\n  cursor: pointer;\n}\n\n.root {\n  transition: all 500ms;\n}\n\n.body {\n  display: grid;\n  min-height: 80px;\n\n  grid-template-columns: 24px 1fr 24px;\n\n  place-items: center;\n  place-content: center;\n  column-gap: 12px;\n  padding: 20px 16px;\n}\n\n.container {\n  display: flex;\n  width: 100%;\n\n  color: #fff;\n  font-size: 14px;\n  line-height: 20px;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `_H0nAgtZ4NTW10AArxdz`,
	"warning": `_jEwru9BAf64Ib98tTkw`,
	"info": `jyQrRQMR7FdkWtPnQnh1`,
	"error": `C6isEx9QRzVQUjCHX2sW`,
	"close": `Pjp8qM_T2dDG1003oSFS`,
	"root": `vJnGCy0zXERoV2jTcBUl`,
	"body": `fIsGOrJ5X0NM8H3u_WWC`,
	"container": `SN6_3_2uiB5dZ7sOLR7w`
};
export default ___CSS_LOADER_EXPORT___;
