import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
dayjs.extend(dayjsDuration)

const useTimer = (targetDate: Date | Dayjs | undefined) => {
  const [duration, setDuration] = useState<dayjsDuration.Duration | null>(null)

  useEffect(() => {
    if (dayjs().isBefore(targetDate)) {
      setTimeout(
        () => setDuration(dayjs.duration(dayjs(targetDate).diff(dayjs()))),
        1000
      )
    } else {
      setDuration(null)
    }
  })

  return {
    days: duration ? Math.floor(duration?.as('days')) : 0,
    hours: duration ? duration?.hours() : 0,
    minutes: duration ? duration?.minutes() : 0,
    seconds: duration ? duration?.seconds() : 0,
  }
}

export { useTimer }
