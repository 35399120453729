import React from 'react'

import { BaseCard } from '../BaseCard'
import { DetailsCardWrapper } from '../DetailsCardWrapper'

import styles from './component.module.scss'

const DetailsBaseCardContainer = ({
  children,
  variants,
}: {
  children: React.ReactNode
  variants?: (
    | 'narrow'
    | 'wide'
    | 'medium'
    | 'section-bar'
    | 'full-width'
    | 'bordered'
  )[]
}) => {
  return (
    <BaseCard variants={variants} className={{ root: styles.baseCardRoot }}>
      <DetailsCardWrapper>{children}</DetailsCardWrapper>
    </BaseCard>
  )
}

export { DetailsBaseCardContainer }
