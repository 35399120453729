import React, { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ReactComponent as ViewOffIcon } from '@lattice/assets/icons/carbon/view-off.svg'
import {
  BaseCard,
  Button,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { buildRegisterField } from '@lattice/utils'
import {
  getUserAttributes,
  setUserAttributes,
  setUserPassword,
} from '@lattice/common/queries'
import { useToastProvider, useUserProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

const PasswordSchema = z
  .string()
  .min(8, 'Password must be at least 8 characters long')
  .regex(
    /[A-Z]+/,
    'Password must contain an uppercase letter, number, and a special character'
  )
  .regex(
    /[a-z]+/,
    'Password must contain an uppercase letter, number, and a special character'
  )
  .regex(
    /[0-9]+/,
    'Password must contain an uppercase letter, number, and a special character'
  )
  .regex(
    /[@$!%*#?&]+/,
    'Password must contain an uppercase letter, number, and a special character'
  )

const UserPasswordChangeSchema = z
  .object({
    newPassword: PasswordSchema,
    newPasswordConfirm: PasswordSchema,
    currentPassword: PasswordSchema,
  })
  .refine((val) => val.newPassword === val.newPasswordConfirm, {
    message: 'Passwords must be the same',
    path: ['newPasswordConfirm'],
  })

const UserSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z
      .string()
      .regex(/^(?:\+[1-9]\d{1,14})?$/, 'Invalid phone number'),
    newPassword: z.string(),
    newPasswordConfirm: z.string(),
    currentPassword: z.string(),
    show_newPassword: z.boolean(),
    show_newPasswordConfirm: z.boolean(),
    show_currentPassword: z.boolean(),
  })
  .superRefine((val, ctx) => {
    if (val.newPassword.length > 0) {
      const result = UserPasswordChangeSchema.safeParse({ ...val })
      if (!result.success) {
        result.error.errors.forEach((issue) => ctx.addIssue(issue))
      }
    }
  })

type IUserSchema = z.infer<typeof UserSchema>

export const ProfileSettingsView = () => {
  const { addToast } = useToastProvider()
  const queryClient = useQueryClient()
  const { user } = useUserProvider()

  const userAttributes = useQuery({
    queryKey: ['user', 'attributes'],
    queryFn: async (qfnContext) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      return getUserAttributes(user, { qfnContext })
    },
    enabled: !!user,
  })

  const userForm = useForm<IUserSchema>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      newPassword: '',
      newPasswordConfirm: '',
      currentPassword: '',
      show_newPassword: false,
      show_newPasswordConfirm: false,
      show_currentPassword: false,
    },
    resolver: zodResolver(UserSchema),
  })

  const register = buildRegisterField(
    userForm.register,
    userForm.formState,
    userForm.control
  )

  const [show_newPassword, show_newPasswordConfirm, show_currentPassword] =
    userForm.watch([
      'show_newPassword',
      'show_newPasswordConfirm',
      'show_currentPassword',
    ])

  const userAttributesMut = useMutation({
    onMutate: async () => {
      const toast = addToast('Saving information', 'info', null)

      return { toast }
    },
    mutationFn: async (data: IUserSchema) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await setUserAttributes(
        user,
        {
          name: `${data.firstName} ${data.lastName}`,
          given_name: data.firstName,
          family_name: data.lastName,
          phone_number: data.phoneNumber,
        },
        {}
      )
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
      context.toast.remove()
    },
    onSuccess: async () => {
      addToast('Successfully saved', 'success')
      queryClient.invalidateQueries({ queryKey: ['user', 'attributes'] })
    },
    onError: async (error) => {
      console.log(error)
      addToast('Error while saving', 'error')
    },
  })

  const userPasswordMut = useMutation({
    onMutate: async () => {
      const toast = addToast('Changing password', 'info', null)

      return { toast }
    },
    mutationFn: async (data: IUserSchema) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await setUserPassword(
        user,
        data.currentPassword,
        data.newPasswordConfirm,
        {}
      )
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
      context.toast.remove()
    },
    onSuccess: async () => {
      addToast('Successfully changed password', 'success')
      userForm.resetField('newPassword')
      userForm.resetField('newPasswordConfirm')
      userForm.resetField('currentPassword')
      userForm.resetField('show_newPassword')
      userForm.resetField('show_newPasswordConfirm')
      userForm.resetField('show_currentPassword')
    },
    onError: async (error) => {
      console.log(error)
      addToast('Error while changing password', 'error')
    },
  })

  const onSubmit = userForm.handleSubmit(
    async (data) => {
      const dirtyFields = userForm.formState.dirtyFields

      if (
        dirtyFields.firstName ||
        dirtyFields.lastName ||
        dirtyFields.phoneNumber
      ) {
        await userAttributesMut.mutateAsync(data)
      }

      if (dirtyFields.currentPassword && dirtyFields.newPasswordConfirm) {
        await userPasswordMut.mutateAsync(data)
      }
    },
    (errors) => {
      console.log(errors)
      addToast('Error while submitting data', 'error')
    }
  )

  useEffect(() => {
    if (userAttributes.data) {
      userForm.setValue(
        'firstName',
        String(userAttributes.data.given_name ?? ''),
        {
          shouldDirty: false,
        }
      )
      userForm.setValue(
        'lastName',
        String(userAttributes.data.family_name ?? ''),
        {
          shouldDirty: false,
        }
      )
      userForm.setValue(
        'phoneNumber',
        String(userAttributes.data.phone_number ?? ''),
        {
          shouldDirty: false,
        }
      )
    }
  }, [userAttributes.data])

  return (
    <section>
      <form onSubmit={onSubmit} className={styles.main}>
        <BaseCard
          variants={['header-title']}
          header="Update basic information"
          className={{ root: styles.formCard, body: styles.body }}
        >
          {userAttributes.isFetching ? (
            <PendingContent variants={['available-height']} />
          ) : (
            <>
              <InputRow
                variants={['full-width']}
                label={'First Name'}
                {...register('firstName')}
              />
              <InputRow
                variants={['full-width']}
                label={'Last name'}
                {...register('lastName')}
              />
              <InputRow
                variants={['full-width']}
                label={'Phone number'}
                {...register('phoneNumber')}
              />
            </>
          )}
        </BaseCard>
        <BaseCard
          variants={['header-title']}
          header="Change account password"
          className={{ root: styles.formCard, body: styles.body }}
        >
          {userAttributes.isFetching ? (
            <PendingContent variants={['available-height']} />
          ) : (
            <>
              <InputRow
                variants={['full-width']}
                label={'New password'}
                type={show_newPassword ? 'text' : 'password'}
                icon={
                  <ViewOffIcon
                    width={16}
                    height={16}
                    onClick={() =>
                      userForm.setValue('show_newPassword', !show_newPassword)
                    }
                  />
                }
                {...register('newPassword')}
              />
              <InputRow
                variants={['full-width']}
                label={'Confirm password'}
                type={show_newPasswordConfirm ? 'text' : 'password'}
                icon={
                  <ViewOffIcon
                    width={16}
                    height={16}
                    onClick={() =>
                      userForm.setValue(
                        'show_newPasswordConfirm',
                        !show_newPasswordConfirm
                      )
                    }
                  />
                }
                {...register('newPasswordConfirm')}
              />
              <InputRow
                variants={['full-width']}
                label={'Current password'}
                type={show_currentPassword ? 'text' : 'password'}
                icon={
                  <ViewOffIcon
                    width={16}
                    height={16}
                    onClick={() =>
                      userForm.setValue(
                        'show_currentPassword',
                        !show_currentPassword
                      )
                    }
                  />
                }
                {...register('currentPassword')}
              />
            </>
          )}
        </BaseCard>
        <div className={styles.buttonContainer}>
          <Button
            variants={['primary']}
            type="submit"
            loading={userForm.formState.isSubmitting}
          >
            Save changes
          </Button>
        </div>
      </form>
    </section>
  )
}
