import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '@lattice/components/common/Button'
import { ReactComponent as Checkmark } from '@lattice/assets/images/svgs/checkmark.svg'
import { shortenAddress } from '@lattice/utils'

import commonStyles from '../styles.module.scss'
import { InfoRow } from '../common/InfoRow'
import { ExolixTransactionResponse } from '../../types'

import styles from './component.module.scss'

const LastContent = ({
  data,
  handleLastStep,
}: {
  data: ExolixTransactionResponse
  handleLastStep: () => void
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={commonStyles.swapInputs}>
        <div className={commonStyles.dataColumn}>
          <div className={styles.statusRow}>
            <div className={styles.step}>
              {data.status === 'success' && (
                <Checkmark fill={'#55C555'} stroke={'#55C555'} />
              )}
            </div>
            <div className={styles.statusTitle}>
              {t(
                'components.ExolixSwap.LastContent.labels.txComplete',
                'Transaction complete!'
              )}
            </div>
          </div>
          <div className={styles.statusMessage}>
            <span className={styles.bold}>
              {data.amountTo} {data.coinTo.coinCode}
            </span>
            {t(
              'components.ExolixSwap.LastContent.text.info',
              ' is being sent to your wallet. Please note that funding can take from 15 minutes to 2 hours.'
            )}
          </div>
        </div>
      </div>
      <div className={commonStyles.swapInfo}>
        <div>
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.sent',
              'You sent'
            )}
            rightSide={`${data.amount} ${data.coinFrom.coinCode}`}
            className={{ root: styles.topRow, font: styles.font }}
          />
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.inputHash',
              'Input hash'
            )}
            linkTo={data.hashIn.link ?? undefined}
            rightSide={
              data.hashIn.hash
                ? shortenAddress(data.hashIn.hash, 7, 7, '•••')
                : '--'
            }
            className={{ root: styles.midRow, font: styles.font }}
            copyValue={true}
          />
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.depositAddress',
              `Deposit ${data.coinFrom.coinCode} address`,
              { coinCode: data.coinFrom.coinCode }
            )}
            rightSide={shortenAddress(data.depositAddress, 7, 7, '•••')}
            className={{ root: styles.bottomRow, font: styles.font }}
            copyValue={true}
          />
        </div>
        <div>
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.receive',
              'You receive'
            )}
            rightSide={`${data.amountTo} ${data.coinTo.coinCode}`}
            className={{ root: styles.topRow, font: styles.font }}
          />
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.outputHash',
              'Output hash'
            )}
            rightSide={
              data.hashOut.hash
                ? shortenAddress(data.hashOut.hash, 7, 7, '•••')
                : '--'
            }
            className={{ root: styles.midRow, font: styles.font }}
            copyValue={true}
          />
          <InfoRow
            leftSide={t(
              'components.ExolixSwap.LastContent.labels.depositAddress',
              `Deposit ${data.coinTo.coinCode} address`,
              { coinCode: data.coinTo.coinCode }
            )}
            rightSide={shortenAddress(data.withdrawalAddress, 7, 7, '•••')}
            copyValue={true}
            className={{ root: styles.bottomRow, font: styles.font }}
          />
        </div>
        <InfoRow
          leftSide={t(
            'components.ExolixSwap.LastContent.labels.txId',
            'Transaction ID'
          )}
          rightSide={data.id}
          copyValue={true}
          className={{
            root: styles.aloneRow,
            font: styles.font,
          }}
        />
      </div>
      <div className={commonStyles.swapButtonContainer}>
        <Button
          className={cls(commonStyles.swapButton)}
          onClick={() => handleLastStep()}
        >
          {t('components.ExolixSwap.LastContent.labels.newSwap', 'New swap')}
        </Button>
      </div>
    </>
  )
}

export { LastContent }
