import React from 'react'

import styles from './component.module.scss'

const ProjectDetailsManageSection = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <div className={styles.main}>{children}</div>
}

export { ProjectDetailsManageSection }
