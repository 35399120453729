// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dDr4CTBEflEwQkYYbQk0{background-color:rgba(0,0,0,0)}.YVvlm3TcxbcSouLEzvaq{display:flex;gap:8px;font-weight:200;font-size:12px;line-height:18px;letter-spacing:.03em;color:rgba(255,255,255,.66)}.EmxO7XLQr79XLDp0tgIY{font-weight:200;font-size:12px;line-height:18px;letter-spacing:.03em;color:rgba(255,255,255,.96)}.EmxO7XLQr79XLDp0tgIY a{color:#fc0;text-decoration:none}.EmxO7XLQr79XLDp0tgIY a:hover{text-decoration:underline}div .OXm0YaiuENj26wg6h4B4{background:rgba(39,174,96,.15);height:20px}.BChfE8UaYnY_g41yHZlJ{margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/views/NodeManagerView/components/NodeDetailsCard/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,8BAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,2BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,2BAAA,CAEA,wBACE,UAAA,CACA,oBAAA,CAEA,8BACE,yBAAA,CAKN,0BACE,8BAAA,CACA,WAAA,CAGF,sBACE,eAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.baseCardRoot {\n  background-color: transparent;\n}\n\n.leftSideFont {\n  display: flex;\n  gap: 8px;\n  font-weight: 200;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: rgba(#fff, 0.66);\n}\n\n.rightSideFont {\n  font-weight: 200;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: rgba(255, 255, 255, 0.96);\n\n  a {\n    color: #ffcc00;\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n\ndiv .greenBadgeContainer {\n  background: rgba($green, 0.15);\n  height: 20px;\n}\n\n.buttonMargin {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseCardRoot": `dDr4CTBEflEwQkYYbQk0`,
	"leftSideFont": `YVvlm3TcxbcSouLEzvaq`,
	"rightSideFont": `EmxO7XLQr79XLDp0tgIY`,
	"greenBadgeContainer": `OXm0YaiuENj26wg6h4B4`,
	"buttonMargin": `BChfE8UaYnY_g41yHZlJ`
};
export default ___CSS_LOADER_EXPORT___;
