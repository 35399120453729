import { TFunction } from 'i18next'
import * as Yup from 'yup'
import * as jose from 'jose'
import { dag4 } from '@stardust-collective/dag4'
import dayjs from 'dayjs'

const passwordValidator = (t: TFunction) =>
  Yup.string()
    .test(
      'password-length',
      t(
        'utils.inputvalidators.passwordValidator.passwordMustBe',
        'Password must be at least 8 characters long'
      ) ?? 'Password must be at least 8 characters long',
      (value: any) => {
        return value && value.length >= 8
      }
    )
    .test(
      'password-contents',
      t(
        'utils.inputvalidators.passwordValidator.passwordMustContain',
        'Password must contain an uppercase letter, number, and a special character'
      ) ??
        'Password must contain an uppercase letter, number, and a special character',
      (value: any) => {
        if (!value) return false

        return (
          value.match(/[A-Z]+/) &&
          value.match(/[a-z]+/) &&
          value.match(/[0-9]+/) &&
          value.match(/[@$!%*#?&]+/)
        )
      }
    )

const emailValidator = (t: TFunction) =>
  Yup.string().test(
    'email-syntax',
    t(
      'utils.inputvalidators.emailValidator.invalidEmail',
      'Invalid email address'
    ) ?? 'Invalid email address',
    (value: any) => {
      return !!(
        typeof value === 'string' &&
        value.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      )
    }
  )

const dagAddressValidator = (t: TFunction) =>
  Yup.string().test(
    'dagAddress-syntax',
    t(
      'utils.inputvalidators.dagAddressValidator.invalidDagAddress',
      'Invalid dag address'
    ) ?? 'Invalid dag address',
    (value: any) => {
      return dag4.keyStore.validateDagAddress(value)
    }
  )

const jwtTokenValidator = (t: TFunction) =>
  Yup.string().test(
    'jwt-parsable',
    t(
      'utils.inputvalidators.jwtTokenValidator.invalidToken',
      'Invalid token'
    ) ?? 'Invalid token',
    (value: any) => {
      try {
        const claims = jose.decodeJwt(value)
        return (
          (!claims.exp || dayjs().isBefore(dayjs.unix(claims.exp))) &&
          (!claims.nbf || dayjs().isAfter(dayjs.unix(claims.nbf)))
        )
      } catch (e) {
        return false
      }
    }
  )

const phoneNumberValidator = (t: TFunction) =>
  Yup.string().test(
    'phoneNumber-syntax',
    t(
      'utils.inputvalidators.phoneNumberValidator.invalidPhoneNumber',
      'Invalid phone number'
    ) ?? 'Invalid phone number',
    (value: any) => {
      return !!(typeof value === 'string' && value.match(/^\+[1-9]\d{1,14}$/g))
    }
  )

const notNullValidator = (t: TFunction) =>
  Yup.string().test(
    'empty-field',
    t(
      'utils.inputvalidators.notNullValidator.notEmptyField',
      'Field cannot be empty'
    ) ?? 'Field cannot be empty',
    (value: any) => {
      if (!value) return false

      return value.match(/[a-z]*[A-Z]*/)
    }
  )

const yupSchemaToFormValidate = <T extends Yup.BaseSchema>(schema: T) => {
  return async (value: any) => {
    try {
      await schema.validate(value)
      return true
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        return e.message
      }
      if (e instanceof Error) {
        return e.message
      }
      return String(e)
    }
  }
}

export {
  passwordValidator,
  emailValidator,
  yupSchemaToFormValidate,
  notNullValidator,
  phoneNumberValidator,
  jwtTokenValidator,
  dagAddressValidator,
}
