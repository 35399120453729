import React from 'react'
import { Outlet } from 'react-router-dom'

import styles from './view.module.scss'

const UserView = () => {
  return (
    <div className={styles.content}>
      <Outlet />
    </div>
  )
}

export { UserView }
