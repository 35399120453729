// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DLTZbpbbepE6Csb0wnzB{display:flex;flex-direction:column;align-items:center;gap:8px}.oZe_yH2bv3liK_34g9xW>div{font-size:20px}.jdI5ZBYzJYj5WaQ4ZaR3>p{font-size:14px;line-height:20px;letter-spacing:.02em;color:rgba(255,255,255,.77);display:flex;align-items:center;justify-content:center;margin-bottom:16px;margin-top:8px}.IzNB33IOJE46O03oz9e_{opacity:.66}`, "",{"version":3,"sources":["webpack://./src/common/components/ConnectYourWallet/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,OAAA,CAGF,0BACE,cAAA,CAGF,wBACE,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,2BAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,sBACE,WAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n}\n\n.titleContainer > div {\n  font-size: 20px;\n}\n\n.message > p {\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0.02em;\n  color: rgba(255, 255, 255, 0.77);\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 16px;\n  margin-top: 8px;\n}\n\n.icon {\n  opacity: 0.66;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DLTZbpbbepE6Csb0wnzB`,
	"titleContainer": `oZe_yH2bv3liK_34g9xW`,
	"message": `jdI5ZBYzJYj5WaQ4ZaR3`,
	"icon": `IzNB33IOJE46O03oz9e_`
};
export default ___CSS_LOADER_EXPORT___;
