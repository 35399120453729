import { ILiquidityStakingStrategyMethods } from './types'

enum LiquidityStakingProgramStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  CLOSED = 'closed',
}

const LiquidityStakingStrategyMethodNames: (keyof ILiquidityStakingStrategyMethods)[] =
  [
    'userStake',
    'userWithdraw',
    'userClaimRewards',
    'userAvailableRewards',
    'userTotalStaked',
    'apy',
    'totalStaked',
    'minStakingAmount',
    'minRewardAmount',
  ]

export { LiquidityStakingProgramStatus, LiquidityStakingStrategyMethodNames }
