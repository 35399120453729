// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hhWRF0fGhwFrOVizuao_{background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/common/components/DetailsBaseCardContainer/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA","sourcesContent":[".baseCardRoot {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseCardRoot": `hhWRF0fGhwFrOVizuao_`
};
export default ___CSS_LOADER_EXPORT___;
