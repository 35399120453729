import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CognitoUser } from 'amazon-cognito-identity-js'

import { ReactComponent as ViewOffIcon } from '@lattice/assets/icons/carbon/view-off.svg'
import {
  BaseCard,
  Button,
  CheckboxField,
  InputRow,
} from '@lattice/common/components'
import {
  emailValidator,
  passwordValidator,
  yupSchemaToFormValidate,
} from '@lattice/utils'
import { useLocalizedValues } from '@lattice/common/hooks'
import { useUserProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

type ISignUpFormData = {
  username: string
  passwordA: string
  passwordB: string
  acceptedTos: boolean
}

const SignUpView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, doUserSignUp } = useUserProvider()
  const [createdUser, setCreatedUser] = useState<CognitoUser | null>(null)

  const tfn = useLocalizedValues({ emailValidator, passwordValidator })

  const userSignUpForm = useForm<ISignUpFormData>({ mode: 'onTouched' })

  const [showPasswordA, setShowPasswordA] = useState(false)
  const [showPasswordB, setShowPasswordB] = useState(false)

  const doSignUp = userSignUpForm.handleSubmit(async (data) => {
    setCreatedUser(await doUserSignUp(data.username, data.passwordA))
  })

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={
        <>
          {!createdUser
            ? t('views.User.views.SignUp.title.createAccount', 'Create account')
            : t(
                'views.User.views.SignUp.title.checkYourEmail',
                'Check your email'
              )}
        </>
      }
    >
      {!createdUser ? (
        <form onSubmit={doSignUp}>
          <div className={styles.formSection}>
            <InputRow
              variants={['full-width']}
              label={t('views.User.views.SignUp.emailAddress', 'Email address')}
              error={userSignUpForm.formState.errors.username?.message}
              {...userSignUpForm.register('username', {
                required: true,
                validate: yupSchemaToFormValidate(tfn.emailValidator),
              })}
            />
            <InputRow
              variants={['full-width']}
              label={t('views.User.views.SignUp.password', 'Password')}
              type={showPasswordA ? 'text' : 'password'}
              icon={
                <ViewOffIcon
                  width={16}
                  height={16}
                  className={styles.viewOffIcon}
                  onClick={() => setShowPasswordA((s) => !s)}
                />
              }
              error={userSignUpForm.formState.errors.passwordA?.message}
              {...userSignUpForm.register('passwordA', {
                required: true,
                validate: yupSchemaToFormValidate(tfn.passwordValidator),
              })}
            />
            <InputRow
              variants={['full-width']}
              label={t(
                'views.User.views.SignUp.confirmPassword',
                'Confirm password'
              )}
              type={showPasswordB ? 'text' : 'password'}
              icon={
                <ViewOffIcon
                  width={16}
                  height={16}
                  className={styles.viewOffIcon}
                  onClick={() => setShowPasswordB((s) => !s)}
                />
              }
              error={userSignUpForm.formState.errors.passwordB?.message}
              {...userSignUpForm.register('passwordB', {
                required: true,
                validate: {
                  passwordSchema: yupSchemaToFormValidate(
                    tfn.passwordValidator
                  ),
                  passwordMatch: (value) =>
                    userSignUpForm.getValues().passwordA === value ||
                    String(
                      t(
                        'views.User.views.SignUp.passwordsMustBe',
                        'Passwords must be the same'
                      )
                    ),
                },
              })}
            />
            <span>
              <CheckboxField
                onClick={() => {
                  userSignUpForm.setFocus('passwordA')
                  userSignUpForm.setFocus('username')
                }}
                {...userSignUpForm.register('acceptedTos', {
                  required: true,
                })}
              />{' '}
              <Trans i18nKey="views.User.views.SignUp.iHaveRead">
                <span>
                  I have read and accept the{' '}
                  <Link to="/terms-of-service">Terms of Service</Link>
                </span>
              </Trans>
            </span>
          </div>
          <div className={styles.actionSection}>
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={!userSignUpForm.formState.isValid}
            >
              {t(
                'views.User.views.SignUp.button.createAccount',
                'Create account'
              )}
            </Button>
            <Trans i18nKey={'views.User.views.SignUp.alreadyRegistered'}>
              <span>
                Already registered? <Link to="../signin">Sign in here</Link>
              </span>
            </Trans>
          </div>
        </form>
      ) : (
        <div className={styles.createdMessage}>
          {t(
            'views.User.views.SignUp.pleaseFollowThe',
            'Please follow the verification link in your email to complete your registration.'
          )}
          <Button
            variants={['primary', 'full-width']}
            onClick={() => navigate('../signin')}
          >
            {t('views.User.views.SignUp.signIn', 'Sign In')}
          </Button>
        </div>
      )}
    </BaseCard>
  )
}

export { SignUpView }
