import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ReactComponent as ViewOffIcon } from '@lattice/assets/icons/carbon/view-off.svg'
import { BaseCard, Button, InputRow } from '@lattice/common/components'
import {
  passwordValidator,
  emailValidator,
  yupSchemaToFormValidate,
} from '@lattice/utils'
import { useLocalizedValues } from '@lattice/common/hooks'
import { useToastProvider, useUserProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

type IResetPasswordConfirmFormData = {
  username: string
  verificationcode: string
  passwordA: string
  passwordB: string
}

const ResetPasswordConfirmView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addToast } = useToastProvider()

  const [searchParams] = useSearchParams()
  const { user, doUserPasswordResetConfirm } = useUserProvider()

  const [passwordChanged, setPasswordChanged] = useState(false)

  const tfn = useLocalizedValues({ passwordValidator, emailValidator })

  const userResetPasswordConfirmForm = useForm<IResetPasswordConfirmFormData>({
    defaultValues: { username: '', verificationcode: '' },
    mode: 'onTouched',
  })

  const [showPasswordA, setShowPasswordA] = useState(false)
  const [showPasswordB, setShowPasswordB] = useState(false)

  const doSignUp = userResetPasswordConfirmForm.handleSubmit(async (data) => {
    await doUserPasswordResetConfirm(
      data.username,
      data.verificationcode,
      data.passwordA
    )
    setPasswordChanged(true)
  })

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    const username = searchParams.get('email')
    const verificationcode = searchParams.get('code')

    if (!username || !verificationcode) {
      addToast(
        t(
          'views.User.views.ResetPasswordConfirm.thisLinkSeems',
          'This link seems to not be valid'
        ),
        'error',
        15000
      )
      navigate('/dashboard')
      return
    }

    userResetPasswordConfirmForm.setValue('username', username)
    userResetPasswordConfirmForm.setValue('verificationcode', verificationcode)
  }, [searchParams])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t(
        'views.User.views.ResetPasswordConfirm.title.resetPassword',
        'Reset password'
      )}
    >
      {!passwordChanged ? (
        <form onSubmit={doSignUp}>
          <div className={styles.formSection}>
            {userResetPasswordConfirmForm.register('username', {
              required: true,
            }) && null}
            {userResetPasswordConfirmForm.register('verificationcode', {
              required: true,
            }) && null}
            <InputRow
              variants={['full-width']}
              label={t(
                'views.User.views.ResetPasswordConfirm.password',
                'Enter new password'
              )}
              type={showPasswordA ? 'text' : 'password'}
              icon={
                <ViewOffIcon
                  width={16}
                  height={16}
                  className={styles.viewOffIcon}
                  onClick={() => setShowPasswordA((s) => !s)}
                />
              }
              error={
                userResetPasswordConfirmForm.formState.errors.passwordA?.message
              }
              {...userResetPasswordConfirmForm.register('passwordA', {
                required: true,
                validate: yupSchemaToFormValidate(tfn.passwordValidator),
              })}
            />
            <InputRow
              variants={['full-width']}
              label={t(
                'views.User.views.ResetPasswordConfirm.confirmPassword',
                'Confirm password'
              )}
              type={showPasswordB ? 'text' : 'password'}
              icon={
                <ViewOffIcon
                  className={styles.viewOffIcon}
                  onClick={() => setShowPasswordB((s) => !s)}
                />
              }
              error={
                userResetPasswordConfirmForm.formState.errors.passwordB?.message
              }
              {...userResetPasswordConfirmForm.register('passwordB', {
                required: true,
                validate: {
                  passwordSchema: yupSchemaToFormValidate(
                    tfn.passwordValidator
                  ),
                  passwordMatch: (value) =>
                    userResetPasswordConfirmForm.getValues().passwordA ===
                      value ||
                    String(
                      t(
                        'views.User.views.ResetPasswordConfirm.passwordsMustBe',
                        'Passwords must be the same'
                      )
                    ),
                },
              })}
            />
          </div>
          <div className={styles.actionSection}>
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={!userResetPasswordConfirmForm.formState.isValid}
            >
              {t(
                'views.User.views.ResetPasswordConfirm.button.submit',
                'Submit'
              )}
            </Button>
          </div>
        </form>
      ) : (
        <div className={styles.message}>
          {t(
            'views.User.views.ResetPasswordConfirm.instructionsWere',
            'You have successfully reset your password.'
          )}
          <Button.Link
            variants={['outlined', 'right-icon', 'full-width']}
            to="../signin"
          >
            {t(
              'views.User.views.ResetPasswordConfirm.backto',
              'Back to Sign In'
            )}
          </Button.Link>
        </div>
      )}
    </BaseCard>
  )
}

export { ResetPasswordConfirmView }
