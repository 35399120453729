import { DeploymentStage } from './deployment'

const getEnv = <T = string>(env: string): T => {
  const value = process.env[env]

  if (!value) {
    throw new Error(env + ' env must be defined')
  }

  return value as T
}

/**
 * @deprecated use EnvironmentContext instead
 */
const ExecutionContext = {
  nodeEnv: process.env.NODE_ENV,
  stage: getEnv<DeploymentStage>('REACT_APP_STAGE'),
  infra: {
    baseApiUrl: getEnv('REACT_APP_API_BASE_URL'),
    sharedKey: getEnv('REACT_APP_SHARED_KEY'),
  },
  cognito: {
    userPoolId: getEnv('REACT_APP_COGNITO_USER_POOL_ID'),
    userPoolClientId: getEnv('REACT_APP_COGNITO_WEB_CLIENT_ID'),
  },
  WalletConnectProjectId: getEnv('REACT_APP_WALLET_CONNECT_PROJECT_ID'),
  apis: {
    fortmaticKey: getEnv('REACT_APP_FORTMATIC_KEY'),
    portisKey: getEnv('REACT_APP_PORTIS_ID'),
    veriffUrl: getEnv('REACT_APP_VERIFF_BASE_URL'),
    veriffKey: getEnv('REACT_APP_VERIFF_API_KEY'),
  },
}

export { ExecutionContext }
