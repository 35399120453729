import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  BaseCard,
  Button,
  RadioSelectCards,
  Typography,
} from '@lattice/common/components'
import TopperIcon from '@lattice/assets/icons/custom/topper.svg'
import SimplexIcon from '@lattice/assets/icons/custom/simplex.svg'

import styles from './view.module.scss'

const MethodsAndLinks = {
  topper: '../topper',
  simplex: '../simplex',
}

const BuySelectMethodView = () => {
  const navigate = useNavigate()
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)

  const onNextButtonClick = () => {
    if (selectedMethod) {
      navigate(MethodsAndLinks[selectedMethod])
    }
  }

  return (
    <BaseCard
      className={{ root: styles.main, body: styles.body }}
      variants={['bordered']}
    >
      <div className={styles.descHolder}>
        <Typography.Title order={2}>Select a provider</Typography.Title>
        <Typography.Text>
          Please choose from this list of Lattice-trusted providers to buy your
          tokens from.
        </Typography.Text>
      </div>
      <RadioSelectCards
        value={selectedMethod}
        onValueChange={setSelectedMethod}
        options={[
          {
            value: 'topper',
            title: 'Topper',
            imgSrc: TopperIcon,
          },
          {
            value: 'simplex',
            title: 'Simplex',
            imgSrc: SimplexIcon,
          },
        ]}
      />
      <Button
        variants={['full-width', 'right-icon', 'primary']}
        onClick={onNextButtonClick}
        disabled={selectedMethod === null}
      >
        Next
      </Button>
    </BaseCard>
  )
}

export { BuySelectMethodView }
