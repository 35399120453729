// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c8pIk1lMscEZXiIJCnNQ{display:flex;height:32px;justify-content:center;align-items:center;padding:0px 16px;color:#6e717f;border-radius:48px;background-color:#272b37}.XL3XuE2AsaCPxVTa6mPj{cursor:pointer}.XL3XuE2AsaCPxVTa6mPj:hover{background-color:#313644}.VmG9VCjOm55gJhGZvmY6{color:#fc0;background-color:#313644}`, "",{"version":3,"sources":["webpack://./src/common/components/StepArrowButton/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,aAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,cAAA,CAEA,4BACE,wBAAA,CAIJ,sBACE,UAAA,CACA,wBAAA","sourcesContent":[".main {\n  display: flex;\n  height: 32px;\n\n  justify-content: center;\n  align-items: center;\n  padding: 0px 16px;\n\n  color: #6e717f;\n  border-radius: 48px;\n  background-color: #272b37;\n}\n\n.clickable {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #313644;\n  }\n}\n\n.selected {\n  color: #ffcc00;\n  background-color: #313644;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `c8pIk1lMscEZXiIJCnNQ`,
	"clickable": `XL3XuE2AsaCPxVTa6mPj`,
	"selected": `VmG9VCjOm55gJhGZvmY6`
};
export default ___CSS_LOADER_EXPORT___;
