import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'

import {
  BaseCard,
  Button,
  CheckboxField,
  InputRow,
} from '@lattice/common/components'
import { formatNumberAndCurrency, NumberFormat } from '@lattice/utils'

import { ActionCardProps } from '../../types'

import styles from './component.module.scss'

type IActionStakeFormValues = { amount: number; claimRewards: boolean }

const Stake = ({
  program,
  stakingTokenBalance,
  onSubmit,
  onCancel,
}: ActionCardProps & {
  onSubmit: (values: IActionStakeFormValues) => Promise<any>
}) => {
  const stakeForm = useForm<IActionStakeFormValues>({
    defaultValues: { amount: 0, claimRewards: false },
  })

  const onFormSubmit = stakeForm.handleSubmit(async (values) => {
    await onSubmit(values)
    stakeForm.reset()
  })

  return (
    <BaseCard
      className={{ root: styles.root, body: styles.body }}
      variants={['bordered']}
    >
      <div className={styles.title}>Stake tokens</div>
      <form className={styles.form} onSubmit={onFormSubmit}>
        <div className={styles.walletBalance}>
          <span>Wallet balance</span>
          <span>
            {formatNumberAndCurrency(
              stakingTokenBalance,
              program.stakingTokenSymbol,
              NumberFormat.DECIMALS
            )}
          </span>
        </div>
        <Controller
          name="amount"
          rules={{
            required: true,
            validate: {
              notZero: (value) =>
                value > 0 || String('Amount must be greater than 0'),
              notUnderMin: (value) =>
                new Decimal(program.addressContext?.totalStaked ?? 0)
                  .plus(value)
                  .gte(program.programContext.minStakingAmount) ||
                `Final staked amount must be greater than program min limit`,
            },
          }}
          control={stakeForm.control}
          render={({ field: { ref: __, ...rest }, fieldState }) => (
            <InputRow.Numeric
              variants={['full-width']}
              icon={
                <span
                  className={styles.inputMax}
                  onClick={() =>
                    stakeForm.setValue('amount', stakingTokenBalance.toNumber())
                  }
                >
                  MAX
                </span>
              }
              placeholder={'0 ' + program.stakingTokenSymbol}
              error={fieldState.error?.message}
              allowNegative={false}
              suffix={' ' + program.stakingTokenSymbol}
              disabled={stakeForm.formState.isSubmitting}
              {...rest}
            />
          )}
        />
        {new Decimal(program.addressContext?.totalStaked ?? 0).gt(0) && (
          <div className={styles.field}>
            <CheckboxField
              {...stakeForm.register('claimRewards')}
              disabled={stakeForm.formState.isSubmitting}
            />
            <span>Claim rewards?</span>
          </div>
        )}
        <div className={styles.actionsContainer}>
          <Button variants={['secondary', 'full-width']} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variants={['primary', 'full-width']}
            type="submit"
            loading={stakeForm.formState.isSubmitting}
            disabled={stakeForm.formState.isSubmitting}
          >
            Stake tokens
          </Button>
        </div>
      </form>
    </BaseCard>
  )
}

export { Stake }
