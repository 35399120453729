import React from 'react'

import { useDebugDataProvider } from '@lattice/common/providers'

import { BaseModal } from '../BaseModal'
import { BaseCard } from '../BaseCard'
import { TextAreaCopy } from '../TextAreaCopy'

import styles from './component.module.scss'

const DebugDataModal = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const { getEncodedDebugData } = useDebugDataProvider()

  return (
    <BaseModal onClickOutside={onClose}>
      <BaseCard
        header={'Copy Debug Data'}
        className={{
          root: styles.root,
          header: styles.header,
          body: styles.body,
        }}
      >
        <TextAreaCopy
          label="Copy data"
          data={getEncodedDebugData}
          onCopy={onClose}
        />
      </BaseCard>
    </BaseModal>
  )
}

export { DebugDataModal }
