import React, { ComponentProps } from 'react'

import { Dropdown, DropdownOptionType } from '@lattice/common/components'

const FilterDropdown = ({
  buttonVariants,
  selectedOption,
  options,
  children,
  onOptionClick,
  className,
}: {
  buttonVariants?: ComponentProps<typeof Dropdown>['buttonVariants']
  selectedOption?: any
  options: DropdownOptionType<any>[]
  onOptionClick?: (value: any) => void
  children?: React.ReactNode
  className?: { button: string }
}) => {
  return (
    <Dropdown
      buttonVariants={buttonVariants}
      className={className}
      options={options}
      onOptionClick={onOptionClick}
    >
      {children ?? options.find((opt) => opt.value === selectedOption)?.content}
    </Dropdown>
  )
}

export { FilterDropdown }
