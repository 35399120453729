import dayjs from 'dayjs'
import Decimal from 'decimal.js'

import { IUserWalletSoftNodeAndPrograms } from '@lattice/common/providers'
import { NetworkCurrencies } from '@lattice/utils/networkCurrencies'

const formatFracPercentage = (initial: string, current: string) => {
  return `${current} of ${initial}%`
}

const getNodeLifetimeRewards = (softNode: IUserWalletSoftNodeAndPrograms) => {
  const rawRewards = softNode.programs.reduce((totalSum, current) => {
    if (
      dayjs(current.stakingStartsAt).isBefore(dayjs()) &&
      dayjs(current.stakingEndsAt).isAfter(dayjs())
    ) {
      return totalSum
    }
    return current.userTotalAccruedRewards + totalSum
  }, 0)

  return new Decimal(rawRewards).div(
    Decimal.pow(10, NetworkCurrencies['constellation:dag'].decimals)
  )
}

export { getNodeLifetimeRewards, formatFracPercentage }
