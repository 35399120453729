import React from 'react'

import { Typography } from '@lattice/common/components'

import { text } from '../policies/2022-06-06'

/**
 * Latest terms of service converted from markdown.
 * Import this component rather than importing versions directly.
 * @constructor
 */
const LatestPolicy = (): JSX.Element | null => {
  return (
    <Typography.MarkdownContent options={{ wrapper: 'article' }}>
      {text}
    </Typography.MarkdownContent>
  )
}

export { LatestPolicy }
