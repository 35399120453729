// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qPYYBIWcnflmkOZ3O6YP{width:100%}.FFoGqqBGBqeGXNNaJcDH{display:flex;width:100%;flex-flow:row nowrap;column-gap:50px}.FFoGqqBGBqeGXNNaJcDH .vbp5g2eLtXeM_FRKqN1x{display:flex;height:56px;justify-content:center;align-items:center;cursor:pointer;font-family:"TWK Lausanne";font-style:normal;font-weight:600;font-size:14px;line-height:16px;letter-spacing:.01em;color:rgba(255,255,255,.66)}.FFoGqqBGBqeGXNNaJcDH .vbp5g2eLtXeM_FRKqN1x.TF33UnJgjOpgu0CB_bPd{color:#fc0;box-shadow:0 1px 0 #fc0}.L97Ul3bZqM0ZbPkrJFWE{width:100%;height:1px;background-color:rgba(255,255,255,.12)}.CB1WTasqCNvL4sXdz7R_:not(.TLKUnE2lCyUkATc_N6go){padding-top:50px}`, "",{"version":3,"sources":["webpack://./src/common/components/Tabs/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,oBAAA,CACA,eAAA,CAEA,4CACE,YAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,2BAAA,CAEA,iEACE,UAAA,CACA,uBAAA,CAKN,sBACE,UAAA,CACA,UAAA,CACA,sCAAA,CAGF,iDACE,gBAAA","sourcesContent":[".tabsContainer {\n  width: 100%;\n}\n\n.tabsLabels {\n  display: flex;\n  width: 100%;\n\n  flex-flow: row nowrap;\n  column-gap: 50px;\n\n  .tabLabel {\n    display: flex;\n    height: 56px;\n\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n\n    font-family: 'TWK Lausanne';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    letter-spacing: 0.01em;\n    color: rgba(255, 255, 255, 0.66);\n\n    &.selected {\n      color: #ffcc00;\n      box-shadow: 0 1px 0 #fc0;\n    }\n  }\n}\n\n.horizontalBar {\n  width: 100%;\n  height: 1px;\n  background-color: rgba(#fff, 0.12);\n}\n\n.tabContent:not(.noPaddedContent) {\n  padding-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `qPYYBIWcnflmkOZ3O6YP`,
	"tabsLabels": `FFoGqqBGBqeGXNNaJcDH`,
	"tabLabel": `vbp5g2eLtXeM_FRKqN1x`,
	"selected": `TF33UnJgjOpgu0CB_bPd`,
	"horizontalBar": `L97Ul3bZqM0ZbPkrJFWE`,
	"tabContent": `CB1WTasqCNvL4sXdz7R_`,
	"noPaddedContent": `TLKUnE2lCyUkATc_N6go`
};
export default ___CSS_LOADER_EXPORT___;
