import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/utils'

import { IOnChainStakingProject } from './types'

const requestProjects = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/staking-projects',
    })
    return data as IOnChainStakingProject[]
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestProject = async (projectId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/staking-projects/${projectId}`,
    })
    return data as IOnChainStakingProject
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

export { requestProjects, requestProject }
