import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as UserAvatarIcon } from '@lattice/assets/icons/custom/useravatar.svg'
import { ReactComponent as CircleWavyCheckIcon } from '@lattice/assets/icons/custom/wavy-circle-check.svg'
import { ReactComponent as RewardsIcon } from '@lattice/assets/images/svgs/rewardsIcon.svg'
import { ReactComponent as SignOutIcon } from '@lattice/assets/icons/custom/sign-out.svg'
import { useUserProvider, useWalletProvider } from '@lattice/common/providers'
import {
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import { useSignInRedirect } from '@lattice/common/hooks'

import { NavigationHeader } from '../NavigationHeader'
import { Button } from '../Button'
import { BaseModal } from '../BaseModal'
import { DropdownList, DropdownListItem } from '../Dropdown/components'

import styles from './component.module.scss'

const Header = () => {
  const { t } = useTranslation()
  const { user, doUserSignOut } = useUserProvider()
  const { requestConnectorActivation, activeWallet, accountsNetworkAssets } =
    useWalletProvider()
  const signInUrl = useSignInRedirect()

  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const walletList: {
    tickerName: keyof typeof accountsNetworkAssets
    account: string
  }[] = []

  if (activeWallet.status === 'connected' && activeWallet.constellation) {
    walletList.push({
      tickerName: 'constellation:dag',
      account: activeWallet.constellation.account,
    })
  }

  if (activeWallet.status === 'connected' && activeWallet.ethereum) {
    walletList.push({
      tickerName: 'ethereum:eth',
      account: activeWallet.ethereum.account,
    })
  }

  return (
    <div className={styles.header}>
      <NavigationHeader />
      <div className={styles.sectionLeft}>
        {activeWallet.status === 'connected' && (
          <div
            className={styles.accountsBalances}
            onClick={() => requestConnectorActivation()}
          >
            {walletList.map(({ tickerName, account }, index, array) => (
              <React.Fragment key={account}>
                {shortenAddress(account)}:{' '}
                <span>
                  {accountsNetworkAssets[tickerName]
                    ? formatNumberAndCurrency(
                        accountsNetworkAssets[tickerName]!.balance,
                        accountsNetworkAssets[tickerName]!.symbol,
                        NumberFormat.MILLIFY
                      )
                    : '--'}
                </span>
                {index === array.length - 1 ? '' : ' | '}
              </React.Fragment>
            ))}
          </div>
        )}
        {activeWallet.status !== 'connected' && (
          <Button
            variants={['outlined']}
            onClick={() => requestConnectorActivation()}
            className={styles.button}
          >
            {t('commmon.components.Header.connectWallet', 'Connect wallet')}
          </Button>
        )}
        {!user ? (
          <Button.Link
            variants={['outlined']}
            className={styles.button}
            to={signInUrl}
          >
            {t('commmon.components.Header.signIn', 'Sign in')}
          </Button.Link>
        ) : (
          <>
            {/* <NotificationIcon /> */}
            <UserAvatarIcon onClick={() => setUserMenuOpen(true)} />
          </>
        )}
        {user && userMenuOpen && (
          <BaseModal
            overlay={false}
            style={{ main: { top: '80px', right: '32px', width: '192px' } }}
            onClickOutside={() => setUserMenuOpen(false)}
          >
            <DropdownList positionStatic>
              <DropdownListItem.Link
                to="/user/myaccount"
                icon={<UserAvatarIcon height={18} width={18} />}
              >
                {t('commmon.components.Header.account', 'Account')}
              </DropdownListItem.Link>
              <DropdownListItem.Link
                to="/user/kyc"
                icon={<CircleWavyCheckIcon height={18} width={18} />}
              >
                {t('commmon.components.Header.account', 'KYC')}
              </DropdownListItem.Link>
              <DropdownListItem.Link
                to="/user/rewards"
                icon={<RewardsIcon height={18} width={18} />}
              >
                {t('commmon.components.Header.rewards', 'Rewards')}
              </DropdownListItem.Link>
              <DropdownListItem
                onClick={() => {
                  setUserMenuOpen(false)
                  doUserSignOut()
                }}
                icon={<SignOutIcon height={18} width={18} />}
              >
                {t('commmon.components.Header.account', 'Sign out')}
              </DropdownListItem>
            </DropdownList>
          </BaseModal>
        )}
      </div>
    </div>
  )
}

export { Header }
