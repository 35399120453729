import React from 'react'
import cls from 'classnames'

import { ReactComponent as InfoIcon } from '@lattice/assets/icons/custom/information-filled.svg'

import styles from './component.module.scss'

const LabeledValue = ({
  tooltip,
  label,
  value,
  variants,
  className,
}: {
  tooltip?: string
  label: string
  value: string
  variants?: 'text-direction-left'[]
  className?: string
}) => {
  return (
    <div
      className={cls(
        styles.column,
        variants?.includes('text-direction-left') && styles.textDirectionLeft,
        className
      )}
    >
      <div className={styles.label}>
        {label}
        {tooltip && (
          <InfoIcon
            className={styles.tooltipQuestionIcon}
            data-tooltip-id="lattice"
            data-tooltip-content={tooltip}
            data-tooltip-place="bottom"
          />
        )}
      </div>
      <div className={styles.value}>{value}</div>
    </div>
  )
}

export { LabeledValue }
