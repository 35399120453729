import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FetchStatus } from '@lattice/common/consts'
import {
  IDtmNode,
  IWalletOwnershipToken,
  useDtmNodesProvider,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import {
  Button,
  NoContentContainer,
  PendingContent,
} from '@lattice/common/components'
import { useSignInRedirect } from '@lattice/common/hooks'

import { DtmNodeCardGrid } from '../../../../components'

import styles from './component.module.scss'

const NftSelectionSection = ({
  handleRedeemData,
}: {
  handleRedeemData: (
    nodes: IDtmNode[],
    ownershipToken: IWalletOwnershipToken,
    address: string,
    blacklistedCountries: Record<string, string> | null,
    taxIdNotRequiredCountries: Record<string, string> | null,
    taxIdRequiredCountries: Record<string, string> | null
  ) => void
}) => {
  const { t } = useTranslation()
  const { dtmUser, dtmNftsInfo, requestDtmUser, requestUserDtmNftData } =
    useDtmNodesProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()
  const signInUrl = useSignInRedirect()

  const { userSession, user } = useUserProvider()
  const [nodesToRedeem, setNodesToRedeem] = useState<IDtmNode[]>([])
  const [pendingVerification, setPendingVerification] = useState<boolean>(false)
  const [userRedeemedNfts, setUserRedeemedNfts] = useState<string[]>([])
  const [blacklistedCountries, setBlacklistedCountries] = useState<Record<
    string,
    string
  > | null>(null)
  const [taxIdNotRequiredCountries, setTaxIdNotRequiredCountries] =
    useState<Record<string, string> | null>(null)
  const [taxIdRequiredCountries, setTaxIdRequiredCountries] = useState<Record<
    string,
    string
  > | null>(null)
  const [ownershipToken, setOwnershipToken] =
    useState<IWalletOwnershipToken | null>(null)

  const selectNodeToRedeem = (node: IDtmNode) => {
    const idsToRedeem = nodesToRedeem.map((node) => node.id)
    if (idsToRedeem.includes(node.id)) {
      setNodesToRedeem((selectedNodes) => {
        const filtered = selectedNodes.filter(
          (selectedNode) => selectedNode.id !== node.id
        )
        return [...filtered]
      })
    } else {
      setNodesToRedeem((nodes) => {
        return [...nodes, node]
      })
    }
  }

  useEffect(() => {
    if (userSession) {
      requestDtmUser()
      requestUserDtmNftData()
    }
  }, [userSession])

  useEffect(() => {
    if (user) {
      requestDtmUser()
      requestUserDtmNftData()
    }
  }, [])

  useEffect(() => {
    if (dtmNftsInfo.status === FetchStatus.DONE && dtmNftsInfo.resource) {
      const redeemedNfts: string[] = []
      dtmNftsInfo.resource.nfts.map((nft) => {
        const redeemedAttribute = nft.attributes.find(
          (attribute) => attribute.traitType === 'Redeemed'
        )
        if (redeemedAttribute?.jsonValue === 'Yes') {
          redeemedNfts.push(nft.serial.toString())
        }
      })
      setUserRedeemedNfts(redeemedNfts)
      setBlacklistedCountries(dtmNftsInfo.resource.blacklistedCountries)
      setTaxIdNotRequiredCountries(
        dtmNftsInfo.resource.taxIdNotRequiredCountries
      )
      setTaxIdRequiredCountries(dtmNftsInfo.resource.taxIdRequiredCountries)
    }
  }, [dtmNftsInfo])

  useEffect(() => {
    const requestOwnership = async () => {
      if (activeWallet.status === 'connected' && activeWallet.ethereum) {
        const walletOwnershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()
        setOwnershipToken(walletOwnershipToken)
      }
    }
    if (
      pendingVerification &&
      activeWallet.status === 'connected' &&
      activeWallet.ethereum
    ) {
      requestOwnership()
    }
  }, [activeWallet])

  useEffect(() => {
    if (
      ownershipToken &&
      activeWallet.status === 'connected' &&
      activeWallet.ethereum
    ) {
      handleRedeemData(
        nodesToRedeem,
        ownershipToken,
        activeWallet.ethereum.account,
        blacklistedCountries,
        taxIdNotRequiredCountries,
        taxIdRequiredCountries
      )
    }
  }, [ownershipToken])

  return (dtmUser.status === FetchStatus.PENDING && !dtmUser.resource) ||
    (dtmNftsInfo.status === FetchStatus.PENDING && !dtmNftsInfo.resource) ? (
    <PendingContent />
  ) : dtmUser.resource &&
    dtmUser.resource.nodes.length > 0 &&
    dtmNftsInfo.resource &&
    user ? (
    <div className={styles.selectNftsSectionContainer}>
      <DtmNodeCardGrid
        nodes={dtmUser.resource.nodes}
        nodesToRedeem={nodesToRedeem}
        redeemedNfts={userRedeemedNfts}
        selectNode={selectNodeToRedeem}
        disableLinks={true}
      />
      <Button
        variants={['primary']}
        className={styles.verifyButton}
        disabled={nodesToRedeem.length === 0}
        onClick={async () => {
          if (nodesToRedeem.length === 0) {
            return
          }
          if (!(activeWallet.status === 'connected' && activeWallet.ethereum)) {
            await requestConnectorActivation()
            setPendingVerification(true)
          }
          if (activeWallet.status === 'connected' && activeWallet.ethereum) {
            const walletOwnershipToken =
              await activeWallet.ethereum.requestWalletOwnershipToken()
            setOwnershipToken(walletOwnershipToken)
          }
        }}
      >
        Verify Ownership
      </Button>
    </div>
  ) : (
    <NoContentContainer>
      <div className={styles.nftsNeeded}>
        {userSession.resource && user
          ? t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.NftSelection.nftsNeeded',
              'You need to have nfts to access this section'
            )
          : t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.NftSelection.nftsNeeded',
              'You need to sign in to access this section'
            )}
      </div>
      {!userSession.resource && (
        <Button.Link
          to={signInUrl}
          variants={['secondary', 'outlined', 'right-icon']}
        >
          Sign in
        </Button.Link>
      )}
    </NoContentContainer>
  )
}

export { NftSelectionSection }
