import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import {
  BaseCard,
  Button,
  InputRow,
  Typography,
} from '@lattice/common/components'
import {
  APIError,
  dagAddressValidator,
  jwtTokenValidator,
  yupSchemaToFormValidate,
} from '@lattice/utils'
import { useLocalizedValues, useProgressToasts } from '@lattice/common/hooks'
import { useTestnetValidatorsProvider } from '@lattice/common/providers/TestnetValidatorsProvider'
import { FetchStatus } from '@lattice/common/consts'

import styles from './component.module.scss'
import { getNodeTokenNodeId } from './utils'

type ITestnetRewardsFormData = {
  address: string
  nodeToken: string
}

const ManageTestnetRewardsCard = () => {
  const { t } = useTranslation()
  const tfn = useLocalizedValues({ dagAddressValidator, jwtTokenValidator })
  const saveAddressAndNodeProgressToasts = useProgressToasts()
  const {
    operations,
    tnrUser,
    setTestnetRewardsUser,
    setTestnetRewardsNode,
    getTestnetRewardsStats,
    getTestnetRewardsUser,
  } = useTestnetValidatorsProvider()

  const testnetRewardsForm = useForm<ITestnetRewardsFormData>({
    mode: 'onTouched',
    defaultValues: {
      address: '',
      nodeToken: '',
    },
  })

  const isAddressRequired =
    !tnrUser.resource || !tnrUser.resource.dagRewardsAddress
  const isNodeTokenRequired =
    !tnrUser.resource || tnrUser.resource.nodes.length === 0

  const testnetRewardsFormValues = testnetRewardsForm.watch()

  const doSaveAddress = async (address: string) => {
    try {
      saveAddressAndNodeProgressToasts.progress(
        t(
          'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.savingRewardsAddress',
          'Saving rewards address'
        ),
        'info',
        null
      )
      await setTestnetRewardsUser(address)
      saveAddressAndNodeProgressToasts.progress(
        t(
          'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.addressSaved',
          'Your address has been saved.'
        ),
        'success',
        10000
      )
    } catch (e) {
      if (
        e instanceof APIError &&
        e.errorCode === 403 &&
        /User is forbidden to take this action/i.test(e.message)
      ) {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.notAllowed',
            "Oops, it seems you're not allowed to execute this action. Contact support for further details."
          )
        )
      } else {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.anErrorOcurred',
            'An error ocurred'
          )
        )
      }
    }
  }

  const doSaveNodeToken = async (nodeToken: string) => {
    try {
      saveAddressAndNodeProgressToasts.progress(
        t(
          'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.registeringNode',
          'Registering node'
        ),
        'info',
        null
      )
      await setTestnetRewardsNode(nodeToken)
      saveAddressAndNodeProgressToasts.progress(
        t(
          'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.nodeRegistered',
          'Your node has been registered.'
        ),
        'success',
        10000
      )
    } catch (e) {
      if (
        e instanceof APIError &&
        e.errorCode === 403 &&
        /User is forbidden to take this action/i.test(e.message)
      ) {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.notAllowed',
            "Oops, it seems you're not allowed to execute this action. Contact support for further details."
          )
        )
      } else if (
        e instanceof APIError &&
        e.errorCode === 400 &&
        /Unable to verify request/i.test(e.message)
      ) {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.serverUnableToVerifyToken',
            'The server could not verify the node token given.'
          )
        )
      } else if (
        e instanceof APIError &&
        e.errorCode === 400 &&
        /This node id has been claimed already by another user/i.test(e.message)
      ) {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.alreadyClaimed',
            'This node id has been claimed already by another user.'
          )
        )
      } else {
        throw new Error(
          t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.messages.anErrorOcurred',
            'An error ocurred'
          )
        )
      }
    }
  }

  const doSaveAddressAndNode = testnetRewardsForm.handleSubmit(
    saveAddressAndNodeProgressToasts.wrappedErrorsAsync(async (data) => {
      if (!data.address && !data.nodeToken) {
        return
      }

      let tnrUserPresent = !!tnrUser.resource
      if (data.address) {
        await doSaveAddress(data.address)
        tnrUserPresent = true
      }

      if (tnrUserPresent && data.nodeToken) {
        await doSaveNodeToken(data.nodeToken)
      }

      testnetRewardsForm.reset()
      getTestnetRewardsStats()
      getTestnetRewardsUser()
    })
  )

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <Typography.HeaderCardTitle>
          {t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.title.manageTestnetRewards',
            'Manage Testnet rewards'
          )}
        </Typography.HeaderCardTitle>
        <Typography.SubtextCard>
          {t(
            'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.subtitle.enterAddressAndNodeToken',
            'Enter the DAG address where you would like to receive rewards and Node Token issued from Node Garage below. You can change your rewards address or node anytime.'
          )}
        </Typography.SubtextCard>
        <form className={styles.testnetForm} onSubmit={doSaveAddressAndNode}>
          <InputRow
            variants={['full-width']}
            label={t(
              'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.rewardsAddress',
              'Rewards address'
            )}
            placeholder={
              tnrUser && tnrUser.resource && tnrUser.resource.dagRewardsAddress
                ? tnrUser.resource.dagRewardsAddress
                : t('views.Nodes.texts.selectNode', 'DAG address')
            }
            error={testnetRewardsForm.formState.errors.address?.message}
            {...testnetRewardsForm.register('address', {
              required: {
                value: isAddressRequired,
                message: t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.rewardsAddressIsRequired',
                  'Rewards address is required'
                ),
              },
              validate:
                isAddressRequired || testnetRewardsFormValues.address
                  ? yupSchemaToFormValidate(tfn.dagAddressValidator)
                  : undefined,
            })}
          />
          <InputRow
            variants={['full-width']}
            label={t(
              'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.nodeToken',
              'Node Token'
            )}
            placeholder={t(
              'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.pasteToken',
              'Paste token here...'
            )}
            error={testnetRewardsForm.formState.errors.nodeToken?.message}
            {...testnetRewardsForm.register('nodeToken', {
              required: {
                value: isNodeTokenRequired,
                message: t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.nodeTokenIsRequired',
                  'Node token is required'
                ),
              },
              validate:
                isNodeTokenRequired || testnetRewardsFormValues.nodeToken
                  ? yupSchemaToFormValidate(tfn.jwtTokenValidator)
                  : undefined,
            })}
          />
          <InputRow
            variants={['full-width']}
            label={t(
              'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.nodeId',
              'Testnet Node Id'
            )}
            readOnly
            value={
              getNodeTokenNodeId(testnetRewardsFormValues.nodeToken) ??
              tnrUser.resource?.nodes[0].testnetNodeId ??
              String(
                t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.unknownNodeId',
                  'Unknown Node Id'
                )
              )
            }
          />
          <Button
            variants={['primary', 'full-width', 'right-icon']}
            type="submit"
            disabled={
              !testnetRewardsForm.formState.isValid ||
              operations.setTestnetRewardsNode.status === FetchStatus.PENDING ||
              operations.setTestnetRewardsUser.status === FetchStatus.PENDING
            }
            loading={
              operations.setTestnetRewardsNode.status === FetchStatus.PENDING ||
              operations.setTestnetRewardsUser.status === FetchStatus.PENDING
            }
          >
            {t(
              'views.Rewards.views.TestnetValidatorProgramDetail.components.ManageTestnetRewards.button.saveDetails',
              'Save details'
            )}
          </Button>
        </form>
      </div>
    </BaseCard>
  )
}

export { ManageTestnetRewardsCard }
