import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from '@lattice/locales/en.json'
import es from '@lattice/locales/es.json'
import { ExecutionContext } from '@lattice/common/consts'

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

const initializei18nextLib = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: ExecutionContext.nodeEnv === 'development' && false,
      detection: langDetectorOptions,
      interpolation: {
        escapeValue: false, // React already escapes values
      },
      resources: {
        en: {
          // translation is the default namespace
          translation: en,
        },
        es: {
          translation: es,
        },
      },
    })
}

export { initializei18nextLib }
