import { createProviderStateHook } from '@lattice/utils'

import {
  OnChainStakingProvider,
  OnChainStakingProviderContext,
} from './provider'

const useOnChainStakingProvider = createProviderStateHook(
  OnChainStakingProvider,
  OnChainStakingProviderContext
)

export { useOnChainStakingProvider }
