import React, { useEffect } from 'react'

import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'

import {
  IDtmUser,
  IDtmNodesProviderContext,
  IDtmStats,
  INftRedeemResponseData,
} from './types'
import {
  createDtmNode,
  createDtmUser,
  redeemDtmNfts,
  requestDtmStats,
  requestDtmUser,
  requestUserDtmNftData,
} from './utils'

const DtmNodesProviderContext =
  React.createContext<IDtmNodesProviderContext | null>(null)

const DtmNodesProvider = ({ children }: { children: React.ReactNode }) => {
  const dtmStats = useFetchableResource<IDtmStats | null>(null)
  const dtmUser = useFetchableResource<IDtmUser | null>(null)
  const dtmNftsInfo = useFetchableResource<INftRedeemResponseData | null>(null)
  const dtmOperations = useFetchableOperations([
    'createDtmUser',
    'createDtmNode',
    'redeemDtmNfts',
  ])

  const ctx: IDtmNodesProviderContext = {
    dtmOperations,
    dtmStats,
    dtmUser,
    dtmNftsInfo,
    requestDtmStats: dtmStats.wrappedFetch(requestDtmStats),
    requestDtmUser: dtmUser.wrappedFetch(requestDtmUser),
    requestUserDtmNftData: dtmNftsInfo.wrappedFetch(requestUserDtmNftData),
    redeemDtmNfts: dtmOperations.redeemDtmNfts.wrappedFetch(redeemDtmNfts),
    createDtmUser: dtmOperations.createDtmUser.wrappedFetch(createDtmUser),
    createDtmNode: dtmOperations.createDtmNode.wrappedFetch(createDtmNode),
  }

  useEffect(() => {
    dtmStats.fetch(requestDtmStats())
  }, [])

  return (
    <DtmNodesProviderContext.Provider value={ctx}>
      {children}
    </DtmNodesProviderContext.Provider>
  )
}

export { DtmNodesProvider, DtmNodesProviderContext }
