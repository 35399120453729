import React, { useEffect, useState } from 'react'
import cls from 'classnames'
import qrcode from 'qrcode'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Button } from '@lattice/components/common/Button'
import { ReactComponent as Locked } from '@lattice/assets/images/svgs/locked.svg'
import { ReactComponent as QrCode } from '@lattice/assets/images/svgs/qr-code.svg'
import { ReactComponent as Timer } from '@lattice/assets/images/svgs/timer.svg'
import { ReactComponent as Copy } from '@lattice/assets/images/svgs/copy.svg'
import { useTimer } from '@lattice/common/hooks/useTimer'
import { apiRequest } from '@lattice/utils'
import { ExecutionContext } from '@lattice/common/consts'

import styles from '../styles.module.scss'
import { InfoRow } from '../common/InfoRow'
import { ExolixTransactionResponse } from '../../types'
import { QRModal } from '../QRModal'

const handleCopyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
}

const DepositContent = ({
  txData,
  handleDeposit,
  cancelSwap,
}: {
  txData: ExolixTransactionResponse & { overdueDate: string }
  handleDeposit: (updatedData: ExolixTransactionResponse) => void
  cancelSwap: () => void
}) => {
  const { t } = useTranslation()
  const [showQr, setShowQr] = useState<boolean>(false)
  const [qrUrl, setQrUrl] = useState<string>()
  const { minutes, seconds } = useTimer(dayjs(txData.createdAt).add(45, 'm'))
  const [updatedData, setUpdatedData] = useState<ExolixTransactionResponse>()
  const closeQrModal = () => {
    setShowQr(false)
  }

  const getTransaction = async () => {
    let data
    try {
      ;({ data } = await apiRequest({
        method: 'GET',
        headers: {
          'x-lattice-api-key': ExecutionContext.infra.sharedKey,
        },
        endpoint: '/swapping/transactions/' + txData.id,
      }))
    } catch (e) {
      throw e
    }
    setUpdatedData(data)
  }

  useEffect(() => {
    const generateQR = async () => {
      try {
        const qr = await qrcode.toDataURL(txData.depositAddress)
        setQrUrl(qr as string)
      } catch (err) {
        console.error(err)
      }
    }
    generateQR()
  }, [showQr])

  useEffect(() => {
    if (
      (minutes === 0 &&
        seconds === 0 &&
        updatedData &&
        updatedData.status !== 'wait') ||
      seconds % 20 !== 0
    ) {
      return
    }

    getTransaction()
  }, [seconds])

  useEffect(() => {
    if (!updatedData || updatedData.status === 'wait') {
      return
    }
    handleDeposit(updatedData)
  }, [updatedData])

  return (
    <>
      <div className={styles.swapInputs}>
        {showQr && qrUrl && <QRModal closeModal={closeQrModal} data={qrUrl} />}
        <div className={styles.dataColumn}>
          <div className={styles.txInfo}>
            <div className={styles.inputLabel}>
              {t(
                'components.ExolixSwap.DepositContent.labels.sendTx',
                'Send one transaction of'
              )}
            </div>
            <div className={styles.timeInfo}>
              <Timer color={'#FFCC00'} />
              <div className={styles.time}>
                {minutes}:{seconds < 10 ? '0' + seconds : seconds}
              </div>
            </div>
          </div>
          <div className={styles.sendingInfo}>
            <div className={styles.assetContainer}>
              <img
                src={
                  txData.coinFrom.icon.length < 10
                    ? 'https://exolix.com/icons/coins/' + txData.coinFrom.icon
                    : txData.coinFrom.icon
                }
                width={'24px'}
                height={'24px'}
              />
            </div>
            <div className={styles.amountToTransfer}>
              {txData.amount} {txData.coinFrom.coinCode}
            </div>
            <div
              className={styles.copy}
              onClick={() =>
                handleCopyToClipboard(
                  `${txData.amount} ${txData.coinFrom.coinCode}`
                )
              }
            >
              <Copy />
            </div>
          </div>
        </div>
        <div className={styles.dataColumn}>
          <div className={styles.inputLabel}>
            {t(
              'components.ExolixSwap.DepositContent.labels.depositAddress',
              'Deposit address'
            )}
          </div>
          <div className={styles.inputRow}>
            <div className={cls(styles.inputAmount, styles.pointer)}>
              <div className={styles.overflowInput}>
                {txData.depositAddress}
              </div>
              <div
                className={styles.copy}
                onClick={() => handleCopyToClipboard(txData.depositAddress)}
              >
                <Copy />
              </div>
            </div>
            <div
              className={cls(styles.currencySelector, styles.qr)}
              onClick={() => setShowQr(true)}
            >
              <QrCode />
            </div>
          </div>
        </div>
        {txData.depositExtraId && (
          <div className={cls(styles.dataColumn)}>
            <div className={styles.inputLabel}>
              {t(
                'components.ExolixSwap.DepositContent.labels.extraId',
                'Extra deposit ID'
              )}
            </div>
            <div className={styles.inputRow}>
              <div
                className={cls(
                  styles.inputAmount,
                  styles.pointer,
                  styles.depositExtraID
                )}
              >
                <div className={styles.overflowInput}>
                  {txData.depositExtraId}
                </div>
                <div
                  className={styles.copy}
                  onClick={() => handleCopyToClipboard(txData.depositExtraId!)}
                >
                  <Copy />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.swapInfo}>
        <InfoRow
          leftSide={t(
            'components.ExolixSwap.DepositContent.labels.rate',
            'Rate'
          )}
          leftSideIcon={<Locked />}
          rightSide={`${txData.amount} ${txData.coinFrom.coinCode} ≈ ${txData.amountTo} ${txData.coinTo.coinCode}`}
        />
        <InfoRow
          leftSide={t(
            'components.ExolixSwap.DepositContent.labels.txId',
            'Transaction ID'
          )}
          rightSide={txData.id}
          copyValue={true}
        />
      </div>
      <div className={styles.swapButtonContainer}>
        <Button
          className={cls(styles.swapButton, styles.cancel)}
          onClick={() => cancelSwap()}
        >
          {t(
            'components.ExolixSwap.DepositContent.labels.cancelSwap',
            'Cancel swap'
          )}
        </Button>
      </div>
    </>
  )
}

export { DepositContent }
