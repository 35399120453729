// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kqpAyYRP5i4rd5qh8dKF{display:flex;flex-direction:column;align-items:center;width:100%;gap:14px;padding:30px}.PlVXcnjwnqhmD_1gl81g{font-family:TWK Lausanne;font-size:20px;font-weight:300;line-height:28px;letter-spacing:0em;text-align:center;color:rgba(255,255,255,.9215686275)}.qyD4Lfliy8JfIccGiXRz{font-family:TWK Lausanne;font-size:14px;font-weight:250;line-height:20px;letter-spacing:.200000003px;text-align:left;color:rgba(255,255,255,.6588235294)}.UdKrYU7sX6z0tHl5s7J_{margin:0;padding-left:24px}.UdKrYU7sX6z0tHl5s7J_ li{margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/HgtpMetagraphOperatorProgramDetailView/components/NodeOperatorNextStepsCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,YAAA,CAGF,sBAEE,wBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,iBAAA,CACA,mCAAA,CAGF,sBAEE,wBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,2BAAA,CACA,eAAA,CACA,mCAAA,CAGF,sBACE,QAAA,CACA,iBAAA,CAEA,yBACE,kBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 14px;\n  padding: 30px;\n}\n\n.titleText {\n  //styleName: H6;\n  font-family: TWK Lausanne;\n  font-size: 20px;\n  font-weight: 300;\n  line-height: 28px;\n  letter-spacing: 0em;\n  text-align: center;\n  color: #ffffffeb;\n}\n\n.subText {\n  //styleName: Body 2;\n  font-family: TWK Lausanne;\n  font-size: 14px;\n  font-weight: 250;\n  line-height: 20px;\n  letter-spacing: 0.20000000298023224px;\n  text-align: left;\n  color: #ffffffa8;\n}\n\n.stepsList {\n  margin: 0;\n  padding-left: 24px;\n\n  li {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kqpAyYRP5i4rd5qh8dKF`,
	"titleText": `PlVXcnjwnqhmD_1gl81g`,
	"subText": `qyD4Lfliy8JfIccGiXRz`,
	"stepsList": `UdKrYU7sX6z0tHl5s7J_`
};
export default ___CSS_LOADER_EXPORT___;
