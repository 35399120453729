// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VeKhEIYUwI9koBn_R6T2{display:flex;width:100%;flex-flow:column nowrap;justify-content:center;align-items:center;row-gap:24px;margin:24px;padding:16px}.VeKhEIYUwI9koBn_R6T2 svg{width:64px;height:64px}.VeKhEIYUwI9koBn_R6T2>span{color:rgba(255,255,255,.96)}.m0lLU8SRSXwd_hOhrsdf{color:#fc0}.fgeBIaSJczcQAY2xFewj{color:#fec84b}.GPBatc2soS4IpBKQtv0k{color:#55c555}.afhOWapsEADwatr_WEwC{color:#f5735f}`, "",{"version":3,"sources":["webpack://./src/views/UserView/views/KycView/components/KycStatus/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CAEA,uBAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CAEA,0BACE,UAAA,CACA,WAAA,CAGF,2BACE,2BAAA,CAIJ,sBACE,UAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n  row-gap: 24px;\n  margin: 24px;\n  padding: 16px;\n\n  svg {\n    width: 64px;\n    height: 64px;\n  }\n\n  > span {\n    color: rgba(#fff, 0.96);\n  }\n}\n\n.complete {\n  color: #fc0;\n}\n\n.pending {\n  color: #fec84b;\n}\n\n.approved {\n  color: #55c555;\n}\n\n.declined {\n  color: #f5735f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `VeKhEIYUwI9koBn_R6T2`,
	"complete": `m0lLU8SRSXwd_hOhrsdf`,
	"pending": `fgeBIaSJczcQAY2xFewj`,
	"approved": `GPBatc2soS4IpBKQtv0k`,
	"declined": `afhOWapsEADwatr_WEwC`
};
export default ___CSS_LOADER_EXPORT___;
