import React from 'react'
import cls from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { Connector, useAccount, useConnect } from 'wagmi'

import { ReactComponent as CloseIcon } from '@lattice/assets/icons/custom/close.svg'
import { AvailableConnectors, IConnectorInfo } from '@lattice/common/consts'

import { BaseCard } from '../BaseCard'

import { WalletItem } from './components'
import styles from './component.module.scss'

const connectorForConnectorInfo = (
  connectorInfo: IConnectorInfo,
  connectors: readonly Connector[]
) => {
  const result = connectors.find(
    (connector) => connector.id === connectorInfo.connectorId
  )
  if (!result) {
    throw new Error('Unable to find connector for connector info')
  }
  return result
}

const ConnectWalletCard = ({
  onConnectorSelection,
  onCloseClick,
  className,
  components,
}: {
  onConnectorSelection?: (connector: Connector) => void
  onCloseClick?: () => void
  className?: {
    header?: string
    body?: string
    walletList?: string
    newEthereumMsg?: string
  }
  components?: { WalletItem?: typeof WalletItem }
}) => {
  const { t } = useTranslation()
  const { connectors } = useConnect()
  const { connector: activeConnector, isConnecting, isConnected } = useAccount()
  const WalletItemComponent = components?.WalletItem ?? WalletItem

  return (
    <BaseCard
      variants={['bordered', 'section-bar', 'medium']}
      header={
        <>
          {t(
            'common.components.ConnectWalletCard.connectToAWallet',
            'Connect to a wallet'
          )}
          <CloseIcon onClick={onCloseClick} width={16} height={16} />
        </>
      }
      className={{
        header: cls(styles.header, className?.header),
        body: cls(styles.body, className?.body),
      }}
    >
      <div
        id={'connectWallet'}
        className={cls(styles.walletList, className?.walletList)}
      >
        {Object.values(AvailableConnectors).map((connectorInfo) => (
          <WalletItemComponent
            key={connectorInfo.connectorId}
            status={
              activeConnector?.id === connectorInfo.connectorId
                ? isConnecting
                  ? 'connecting'
                  : isConnected
                    ? 'connected'
                    : 'disconnected'
                : 'disconnected'
            }
            onItemClick={() =>
              onConnectorSelection &&
              onConnectorSelection(
                connectorForConnectorInfo(connectorInfo, connectors)
              )
            }
            walletName={
              activeConnector?.id === connectorInfo.connectorId && isConnecting
                ? t(
                    'common.components.ConnectWalletCard.initializing',
                    'Initializing...'
                  )
                : typeof connectorInfo.name === 'function'
                  ? connectorInfo.name(t)
                  : connectorInfo.name
            }
            walletLogoUrl={connectorInfo.logoUrl ?? undefined}
          />
        ))}
        <div className={cls(styles.newEthereumMsg, className?.newEthereumMsg)}>
          <Trans i18nKey={'common.components.ConnectWalletCard.newToEthereum'}>
            New to Ethereum?{' '}
            <a
              href="https://ethereum.org/en/wallets/"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about wallets
            </a>
          </Trans>
        </div>
      </div>
    </BaseCard>
  )
}

export { ConnectWalletCard }
