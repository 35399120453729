import React from 'react'

import { BaseCard } from '../BaseCard'

import styles from './component.module.scss'

const InlineStatsCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <BaseCard className={{ root: styles.root }}>
      <div className={styles.main}>{children}</div>
    </BaseCard>
  )
}

export { InlineStatsCard }
