import React from 'react'

import styles from './component.module.scss'
import { OptionCard } from './components'

type IOption = {
  value: string
  title?: string
  titleExtra?: string
  subTitle?: string
  imgSrc?: string
}

const RadioSelectCards = ({
  options,
  value,
  onValueChange,
}: {
  options: IOption[]
  value: any
  onValueChange: (value: string) => void
}) => {
  return (
    <div className={styles.main}>
      {options.map((opt) => (
        <OptionCard
          key={opt.value}
          title={opt.title}
          titleExtra={opt.titleExtra}
          subTitle={opt.subTitle}
          imgSrc={opt.imgSrc}
          selected={value === opt.value}
          onClick={() => onValueChange(opt.value)}
        />
      ))}
    </div>
  )
}

export { RadioSelectCards }
