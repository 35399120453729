import { TFunction } from 'i18next'

const CountryCodes: Record<string, (t: TFunction) => string> = {
  AF: (t) => t('data.CountryCodes.AF', 'Afghanistan'),
  AX: (t) => t('data.CountryCodes.AX', 'Åland Islands'),
  AL: (t) => t('data.CountryCodes.AL', 'Albania'),
  DZ: (t) => t('data.CountryCodes.DZ', 'Algeria'),
  AS: (t) => t('data.CountryCodes.AS', 'American Samoa'),
  AD: (t) => t('data.CountryCodes.AD', 'Andorra'),
  AO: (t) => t('data.CountryCodes.AO', 'Angola'),
  AI: (t) => t('data.CountryCodes.AI', 'Anguilla'),
  AQ: (t) => t('data.CountryCodes.AQ', 'Antarctica'),
  AG: (t) => t('data.CountryCodes.AG', 'Antigua and Barbuda'),
  AR: (t) => t('data.CountryCodes.AR', 'Argentina'),
  AM: (t) => t('data.CountryCodes.AM', 'Armenia'),
  AW: (t) => t('data.CountryCodes.AW', 'Aruba'),
  AU: (t) => t('data.CountryCodes.AU', 'Australia'),
  AT: (t) => t('data.CountryCodes.AT', 'Austria'),
  AZ: (t) => t('data.CountryCodes.AZ', 'Azerbaijan'),
  BS: (t) => t('data.CountryCodes.BS', 'Bahamas'),
  BH: (t) => t('data.CountryCodes.BH', 'Bahrain'),
  BD: (t) => t('data.CountryCodes.BD', 'Bangladesh'),
  BB: (t) => t('data.CountryCodes.BB', 'Barbados'),
  BY: (t) => t('data.CountryCodes.BY', 'Belarus'),
  BE: (t) => t('data.CountryCodes.BE', 'Belgium'),
  BZ: (t) => t('data.CountryCodes.BZ', 'Belize'),
  BJ: (t) => t('data.CountryCodes.BJ', 'Benin'),
  BM: (t) => t('data.CountryCodes.BM', 'Bermuda'),
  BT: (t) => t('data.CountryCodes.BT', 'Bhutan'),
  BO: (t) => t('data.CountryCodes.BO', 'Bolivia, Plurinational State of'),
  BA: (t) => t('data.CountryCodes.BA', 'Bosnia and Herzegovina'),
  BW: (t) => t('data.CountryCodes.BW', 'Botswana'),
  BV: (t) => t('data.CountryCodes.BV', 'Bouvet Island'),
  BR: (t) => t('data.CountryCodes.BR', 'Brazil'),
  IO: (t) => t('data.CountryCodes.IO', 'British Indian Ocean Territory'),
  BN: (t) => t('data.CountryCodes.BN', 'Brunei Darussalam'),
  BG: (t) => t('data.CountryCodes.BG', 'Bulgaria'),
  BF: (t) => t('data.CountryCodes.BF', 'Burkina Faso'),
  BI: (t) => t('data.CountryCodes.BI', 'Burundi'),
  CV: (t) => t('data.CountryCodes.CV', 'Cabo Verde'),
  KH: (t) => t('data.CountryCodes.KH', 'Cambodia'),
  CM: (t) => t('data.CountryCodes.CM', 'Cameroon'),
  CA: (t) => t('data.CountryCodes.CA', 'Canada'),
  BQ: (t) => t('data.CountryCodes.BQ', 'Caribbean Netherlands'),
  KY: (t) => t('data.CountryCodes.KY', 'Cayman Islands'),
  CF: (t) => t('data.CountryCodes.CF', 'Central African Republic'),
  TD: (t) => t('data.CountryCodes.TD', 'Chad'),
  CL: (t) => t('data.CountryCodes.CL', 'Chile'),
  CN: (t) => t('data.CountryCodes.CN', 'China'),
  CX: (t) => t('data.CountryCodes.CX', 'Christmas Island'),
  CC: (t) => t('data.CountryCodes.CC', 'Cocos (Keeling) Islands'),
  CO: (t) => t('data.CountryCodes.CO', 'Colombia'),
  KM: (t) => t('data.CountryCodes.KM', 'Comoros'),
  CG: (t) => t('data.CountryCodes.CG', 'Congo'),
  CD: (t) => t('data.CountryCodes.CD', 'Congo, the Democratic Republic of the'),
  CK: (t) => t('data.CountryCodes.CK', 'Cook Islands'),
  CR: (t) => t('data.CountryCodes.CR', 'Costa Rica'),
  CI: (t) => t('data.CountryCodes.CI', "Côte d'Ivoire"),
  HR: (t) => t('data.CountryCodes.HR', 'Croatia'),
  CU: (t) => t('data.CountryCodes.CU', 'Cuba'),
  CW: (t) => t('data.CountryCodes.CW', 'Curaçao'),
  CY: (t) => t('data.CountryCodes.CY', 'Cyprus'),
  CZ: (t) => t('data.CountryCodes.CZ', 'Czechia'),
  DK: (t) => t('data.CountryCodes.DK', 'Denmark'),
  DJ: (t) => t('data.CountryCodes.DJ', 'Djibouti'),
  DM: (t) => t('data.CountryCodes.DM', 'Dominica'),
  DO: (t) => t('data.CountryCodes.DO', 'Dominican Republic'),
  EC: (t) => t('data.CountryCodes.EC', 'Ecuador'),
  EG: (t) => t('data.CountryCodes.EG', 'Egypt'),
  SV: (t) => t('data.CountryCodes.SV', 'El Salvador'),
  GQ: (t) => t('data.CountryCodes.GQ', 'Equatorial Guinea'),
  ER: (t) => t('data.CountryCodes.ER', 'Eritrea'),
  EE: (t) => t('data.CountryCodes.EE', 'Estonia'),
  SZ: (t) => t('data.CountryCodes.SZ', 'Eswatini'),
  ET: (t) => t('data.CountryCodes.ET', 'Ethiopia'),
  FK: (t) => t('data.CountryCodes.FK', 'Falkland Islands (Malvinas)'),
  FO: (t) => t('data.CountryCodes.FO', 'Faroe Islands'),
  FJ: (t) => t('data.CountryCodes.FJ', 'Fiji'),
  FI: (t) => t('data.CountryCodes.FI', 'Finland'),
  FR: (t) => t('data.CountryCodes.FR', 'France'),
  GF: (t) => t('data.CountryCodes.GF', 'French Guiana'),
  PF: (t) => t('data.CountryCodes.PF', 'French Polynesia'),
  TF: (t) => t('data.CountryCodes.TF', 'French Southern Territories'),
  GA: (t) => t('data.CountryCodes.GA', 'Gabon'),
  GM: (t) => t('data.CountryCodes.GM', 'Gambia'),
  GE: (t) => t('data.CountryCodes.GE', 'Georgia'),
  DE: (t) => t('data.CountryCodes.DE', 'Germany'),
  GH: (t) => t('data.CountryCodes.GH', 'Ghana'),
  GI: (t) => t('data.CountryCodes.GI', 'Gibraltar'),
  GR: (t) => t('data.CountryCodes.GR', 'Greece'),
  GL: (t) => t('data.CountryCodes.GL', 'Greenland'),
  GD: (t) => t('data.CountryCodes.GD', 'Grenada'),
  GP: (t) => t('data.CountryCodes.GP', 'Guadeloupe'),
  GU: (t) => t('data.CountryCodes.GU', 'Guam'),
  GT: (t) => t('data.CountryCodes.GT', 'Guatemala'),
  GG: (t) => t('data.CountryCodes.GG', 'Guernsey'),
  GN: (t) => t('data.CountryCodes.GN', 'Guinea'),
  GW: (t) => t('data.CountryCodes.GW', 'Guinea-Bissau'),
  GY: (t) => t('data.CountryCodes.GY', 'Guyana'),
  HT: (t) => t('data.CountryCodes.HT', 'Haiti'),
  HM: (t) => t('data.CountryCodes.HM', 'Heard Island and McDonald Islands'),
  VA: (t) => t('data.CountryCodes.VA', 'Holy See'),
  HN: (t) => t('data.CountryCodes.HN', 'Honduras'),
  HK: (t) => t('data.CountryCodes.HK', 'Hong Kong'),
  HU: (t) => t('data.CountryCodes.HU', 'Hungary'),
  IS: (t) => t('data.CountryCodes.IS', 'Iceland'),
  IN: (t) => t('data.CountryCodes.IN', 'India'),
  ID: (t) => t('data.CountryCodes.ID', 'Indonesia'),
  IR: (t) => t('data.CountryCodes.IR', 'Iran, Islamic Republic of'),
  IQ: (t) => t('data.CountryCodes.IQ', 'Iraq'),
  IE: (t) => t('data.CountryCodes.IE', 'Ireland'),
  IM: (t) => t('data.CountryCodes.IM', 'Isle of Man'),
  IL: (t) => t('data.CountryCodes.IL', 'Israel'),
  IT: (t) => t('data.CountryCodes.IT', 'Italy'),
  JM: (t) => t('data.CountryCodes.JM', 'Jamaica'),
  JP: (t) => t('data.CountryCodes.JP', 'Japan'),
  JE: (t) => t('data.CountryCodes.JE', 'Jersey'),
  JO: (t) => t('data.CountryCodes.JO', 'Jordan'),
  KZ: (t) => t('data.CountryCodes.KZ', 'Kazakhstan'),
  KE: (t) => t('data.CountryCodes.KE', 'Kenya'),
  KI: (t) => t('data.CountryCodes.KI', 'Kiribati'),
  KP: (t) =>
    t('data.CountryCodes.KP', "Korea, Democratic People's Republic of"),
  KR: (t) => t('data.CountryCodes.KR', 'Korea, Republic of'),
  KW: (t) => t('data.CountryCodes.KW', 'Kuwait'),
  KG: (t) => t('data.CountryCodes.KG', 'Kyrgyzstan'),
  LA: (t) => t('data.CountryCodes.LA', "Lao People's Democratic Republic"),
  LV: (t) => t('data.CountryCodes.LV', 'Latvia'),
  LB: (t) => t('data.CountryCodes.LB', 'Lebanon'),
  LS: (t) => t('data.CountryCodes.LS', 'Lesotho'),
  LR: (t) => t('data.CountryCodes.LR', 'Liberia'),
  LY: (t) => t('data.CountryCodes.LY', 'Libya'),
  LI: (t) => t('data.CountryCodes.LI', 'Liechtenstein'),
  LT: (t) => t('data.CountryCodes.LT', 'Lithuania'),
  LU: (t) => t('data.CountryCodes.LU', 'Luxembourg'),
  MO: (t) => t('data.CountryCodes.MO', 'Macao'),
  MG: (t) => t('data.CountryCodes.MG', 'Madagascar'),
  MW: (t) => t('data.CountryCodes.MW', 'Malawi'),
  MY: (t) => t('data.CountryCodes.MY', 'Malaysia'),
  MV: (t) => t('data.CountryCodes.MV', 'Maldives'),
  ML: (t) => t('data.CountryCodes.ML', 'Mali'),
  MT: (t) => t('data.CountryCodes.MT', 'Malta'),
  MH: (t) => t('data.CountryCodes.MH', 'Marshall Islands'),
  MQ: (t) => t('data.CountryCodes.MQ', 'Martinique'),
  MR: (t) => t('data.CountryCodes.MR', 'Mauritania'),
  MU: (t) => t('data.CountryCodes.MU', 'Mauritius'),
  YT: (t) => t('data.CountryCodes.YT', 'Mayotte'),
  MX: (t) => t('data.CountryCodes.MX', 'Mexico'),
  FM: (t) => t('data.CountryCodes.FM', 'Micronesia, Federated States of'),
  MD: (t) => t('data.CountryCodes.MD', 'Moldova, Republic of'),
  MC: (t) => t('data.CountryCodes.MC', 'Monaco'),
  MN: (t) => t('data.CountryCodes.MN', 'Mongolia'),
  ME: (t) => t('data.CountryCodes.ME', 'Montenegro'),
  MS: (t) => t('data.CountryCodes.MS', 'Montserrat'),
  MA: (t) => t('data.CountryCodes.MA', 'Morocco'),
  MZ: (t) => t('data.CountryCodes.MZ', 'Mozambique'),
  MM: (t) => t('data.CountryCodes.MM', 'Myanmar'),
  NA: (t) => t('data.CountryCodes.NA', 'Namibia'),
  NR: (t) => t('data.CountryCodes.NR', 'Nauru'),
  NP: (t) => t('data.CountryCodes.NP', 'Nepal'),
  NL: (t) => t('data.CountryCodes.NL', 'Netherlands'),
  NC: (t) => t('data.CountryCodes.NC', 'New Caledonia'),
  NZ: (t) => t('data.CountryCodes.NZ', 'New Zealand'),
  NI: (t) => t('data.CountryCodes.NI', 'Nicaragua'),
  NE: (t) => t('data.CountryCodes.NE', 'Niger'),
  NG: (t) => t('data.CountryCodes.NG', 'Nigeria'),
  NU: (t) => t('data.CountryCodes.NU', 'Niue'),
  NF: (t) => t('data.CountryCodes.NF', 'Norfolk Island'),
  MK: (t) => t('data.CountryCodes.MK', 'North Macedonia'),
  MP: (t) => t('data.CountryCodes.MP', 'Northern Mariana Islands'),
  NO: (t) => t('data.CountryCodes.NO', 'Norway'),
  OM: (t) => t('data.CountryCodes.OM', 'Oman'),
  PK: (t) => t('data.CountryCodes.PK', 'Pakistan'),
  PW: (t) => t('data.CountryCodes.PW', 'Palau'),
  PS: (t) => t('data.CountryCodes.PS', 'Palestine, State of'),
  PA: (t) => t('data.CountryCodes.PA', 'Panama'),
  PG: (t) => t('data.CountryCodes.PG', 'Papua New Guinea'),
  PY: (t) => t('data.CountryCodes.PY', 'Paraguay'),
  PE: (t) => t('data.CountryCodes.PE', 'Peru'),
  PH: (t) => t('data.CountryCodes.PH', 'Philippines'),
  PN: (t) => t('data.CountryCodes.PN', 'Pitcairn'),
  PL: (t) => t('data.CountryCodes.PL', 'Poland'),
  PT: (t) => t('data.CountryCodes.PT', 'Portugal'),
  PR: (t) => t('data.CountryCodes.PR', 'Puerto Rico'),
  QA: (t) => t('data.CountryCodes.QA', 'Qatar'),
  RE: (t) => t('data.CountryCodes.RE', 'Réunion'),
  RO: (t) => t('data.CountryCodes.RO', 'Romania'),
  RU: (t) => t('data.CountryCodes.RU', 'Russian Federation'),
  RW: (t) => t('data.CountryCodes.RW', 'Rwanda'),
  BL: (t) => t('data.CountryCodes.BL', 'Saint Barthélemy'),
  SH: (t) =>
    t('data.CountryCodes.SH', 'Saint Helena, Ascension and Tristan da Cunha'),
  KN: (t) => t('data.CountryCodes.KN', 'Saint Kitts and Nevis'),
  LC: (t) => t('data.CountryCodes.LC', 'Saint Lucia'),
  MF: (t) => t('data.CountryCodes.MF', 'Saint Martin (French part)'),
  PM: (t) => t('data.CountryCodes.PM', 'Saint Pierre and Miquelon'),
  VC: (t) => t('data.CountryCodes.VC', 'Saint Vincent and the Grenadines'),
  WS: (t) => t('data.CountryCodes.WS', 'Samoa'),
  SM: (t) => t('data.CountryCodes.SM', 'San Marino'),
  ST: (t) => t('data.CountryCodes.ST', 'Sao Tome and Principe'),
  SA: (t) => t('data.CountryCodes.SA', 'Saudi Arabia'),
  SN: (t) => t('data.CountryCodes.SN', 'Senegal'),
  RS: (t) => t('data.CountryCodes.RS', 'Serbia'),
  SC: (t) => t('data.CountryCodes.SC', 'Seychelles'),
  SL: (t) => t('data.CountryCodes.SL', 'Sierra Leone'),
  SG: (t) => t('data.CountryCodes.SG', 'Singapore'),
  SX: (t) => t('data.CountryCodes.SX', 'Sint Maarten (Dutch part)'),
  SK: (t) => t('data.CountryCodes.SK', 'Slovakia'),
  SI: (t) => t('data.CountryCodes.SI', 'Slovenia'),
  SB: (t) => t('data.CountryCodes.SB', 'Solomon Islands'),
  SO: (t) => t('data.CountryCodes.SO', 'Somalia'),
  ZA: (t) => t('data.CountryCodes.ZA', 'South Africa'),
  GS: (t) =>
    t('data.CountryCodes.GS', 'South Georgia and the South Sandwich Islands'),
  SS: (t) => t('data.CountryCodes.SS', 'South Sudan'),
  ES: (t) => t('data.CountryCodes.ES', 'Spain'),
  LK: (t) => t('data.CountryCodes.LK', 'Sri Lanka'),
  SD: (t) => t('data.CountryCodes.SD', 'Sudan'),
  SR: (t) => t('data.CountryCodes.SR', 'Suriname'),
  SJ: (t) => t('data.CountryCodes.SJ', 'Svalbard and Jan Mayen'),
  SE: (t) => t('data.CountryCodes.SE', 'Sweden'),
  CH: (t) => t('data.CountryCodes.CH', 'Switzerland'),
  SY: (t) => t('data.CountryCodes.SY', 'Syrian Arab Republic'),
  TW: (t) => t('data.CountryCodes.TW', 'Taiwan, Province of China'),
  TJ: (t) => t('data.CountryCodes.TJ', 'Tajikistan'),
  TZ: (t) => t('data.CountryCodes.TZ', 'Tanzania, United Republic of'),
  TH: (t) => t('data.CountryCodes.TH', 'Thailand'),
  TL: (t) => t('data.CountryCodes.TL', 'Timor-Leste'),
  TG: (t) => t('data.CountryCodes.TG', 'Togo'),
  TK: (t) => t('data.CountryCodes.TK', 'Tokelau'),
  TO: (t) => t('data.CountryCodes.TO', 'Tonga'),
  TT: (t) => t('data.CountryCodes.TT', 'Trinidad and Tobago'),
  TN: (t) => t('data.CountryCodes.TN', 'Tunisia'),
  TR: (t) => t('data.CountryCodes.TR', 'Turkey'),
  TM: (t) => t('data.CountryCodes.TM', 'Turkmenistan'),
  TC: (t) => t('data.CountryCodes.TC', 'Turks and Caicos Islands'),
  TV: (t) => t('data.CountryCodes.TV', 'Tuvalu'),
  UG: (t) => t('data.CountryCodes.UG', 'Uganda'),
  UA: (t) => t('data.CountryCodes.UA', 'Ukraine'),
  AE: (t) => t('data.CountryCodes.AE', 'United Arab Emirates'),
  GB: (t) =>
    t(
      'data.CountryCodes.GB',
      'United Kingdom of Great Britain and Northern Ireland'
    ),
  US: (t) => t('data.CountryCodes.US', 'United States of America'),
  UM: (t) => t('data.CountryCodes.UM', 'United States Minor Outlying Islands'),
  UY: (t) => t('data.CountryCodes.UY', 'Uruguay'),
  UZ: (t) => t('data.CountryCodes.UZ', 'Uzbekistan'),
  VU: (t) => t('data.CountryCodes.VU', 'Vanuatu'),
  VE: (t) => t('data.CountryCodes.VE', 'Venezuela, Bolivarian Republic of'),
  VN: (t) => t('data.CountryCodes.VN', 'Viet Nam'),
  VG: (t) => t('data.CountryCodes.VG', 'Virgin Islands, British'),
  VI: (t) => t('data.CountryCodes.VI', 'Virgin Islands, U.S.'),
  WF: (t) => t('data.CountryCodes.WF', 'Wallis and Futuna'),
  EH: (t) => t('data.CountryCodes.EH', 'Western Sahara'),
  YE: (t) => t('data.CountryCodes.YE', 'Yemen'),
  ZM: (t) => t('data.CountryCodes.ZM', 'Zambia'),
  ZW: (t) => t('data.CountryCodes.ZW', 'Zimbabwe'),
}

export { CountryCodes }
