// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d3cnVsEcGkfHAlq5XuQJ{display:flex;width:100%;flex-flow:column nowrap;row-gap:40px}.sW2ChR4dKAoaRK6nfjtg{display:flex;align-items:center;justify-content:center}.pcuS89rFkJjuI440VEPY{width:20%}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/DtmNodesDetailsView/views/GeneralDetailsView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAEA,uBAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,SAAA","sourcesContent":[".manageDtm {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n  row-gap: 40px;\n}\n\n.redeemButtonFlexContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.redeemButton {\n  width: 20%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageDtm": `d3cnVsEcGkfHAlq5XuQJ`,
	"redeemButtonFlexContainer": `sW2ChR4dKAoaRK6nfjtg`,
	"redeemButton": `pcuS89rFkJjuI440VEPY`
};
export default ___CSS_LOADER_EXPORT___;
