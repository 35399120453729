// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdTsr2rVSnyVt5Tt_98U{display:flex;width:100%;align-items:center;border:1px solid rgba(255,255,255,.12);border-radius:24px;padding:10px 24px;color:rgba(255,255,255,.96);font-weight:300;font-size:20px;line-height:28px}`, "",{"version":3,"sources":["webpack://./src/common/components/TitleBar/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CAEA,kBAAA,CACA,sCAAA,CACA,kBAAA,CACA,iBAAA,CAEA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n\n  align-items: center;\n  border: 1px solid rgba(#fff, 0.12);\n  border-radius: 24px;\n  padding: 10px 24px;\n\n  color: rgba(#fff, 0.96);\n  font-weight: 300;\n  font-size: 20px;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `sdTsr2rVSnyVt5Tt_98U`
};
export default ___CSS_LOADER_EXPORT___;
