import * as ERC20Interface from './ERC20'
import * as LatticeGovernanceTokenInterface from './LatticeGovernanceToken'
import * as LatticeStakingPoolInterface from './LatticeStakingPool'
import * as LatticeAirdropsDistributorInterface from './LatticeAirdropsDistributor'
import * as DorTrafficMinerNFTInterface from './DorTrafficMinerNFT'
import * as AlkimiStakingProgramInterface from './AlkimiStakingProgram'

export type ContractTypes = {
  ERC20: import('./ERC20').ERC20
  LatticeGovernanceToken: import('./LatticeGovernanceToken').LatticeGovernanceToken
  LatticeStakingPool: import('./LatticeStakingPool').LatticeStakingPool
  LatticeAirdropsDistributor: import('./LatticeAirdropsDistributor').LatticeAirdropsDistributor
  DorTrafficMinerNFT: import('./DorTrafficMinerNFT').DorTrafficMinerNFT
  AlkimiStakingProgram: import('./AlkimiStakingProgram').AlkimiStakingProgram
}

export const ContractABIs = {
  ERC20: ERC20Interface.ERC20ABI,
  LatticeGovernanceToken:
    LatticeGovernanceTokenInterface.LatticeGovernanceTokenABI,
  LatticeAirdropsDistributor:
    LatticeAirdropsDistributorInterface.LatticeAirdropsDistributorABI,
  LatticeStakingPool: LatticeStakingPoolInterface.LatticeStakingPoolABI,
  DorTrafficMinerNFT: DorTrafficMinerNFTInterface.DorTrafficMinerNFTABI,
  AlkimiStakingProgram: AlkimiStakingProgramInterface.AlkimiStakingProgramABI,
} as const
