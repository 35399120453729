import React from 'react'

import styles from './component.module.scss'

const DetailsMainCardContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <div className={styles.mainCardContainer}>{children}</div>
}

export { DetailsMainCardContainer }
