import { AvailableChain } from './available'
import { ChainInfo } from './chain_info'

const getAvailableChainByChainId = (chainId: number) => {
  for (const [chain, chaininfo] of Object.entries(ChainInfo) as [
    AvailableChain,
    ChainInfo,
  ][]) {
    if (!chaininfo.rpcAvailable) {
      continue
    }

    if (chaininfo.rpcChainId === chainId) {
      return chain
    }
  }

  return null
}

export { getAvailableChainByChainId }
