import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'
import { Link } from 'react-router-dom'

import { ReactComponent as Copy } from '@lattice/assets/images/svgs/copy.svg'
import { GeneralColor } from '@lattice/components/common/Consts'

import styles from './component.module.scss'

const handleCopyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
}

const InfoRow = ({
  variants,
  leftSide,
  rightSide,
  bottomSide,
  leftSideIcon,
  rightSideIcon,
  loading = false,
  copyValue,
  className,
  linkTo,
}: {
  variants?: 'small-font'[]
  leftSide: React.ReactNode
  rightSide: React.ReactNode
  bottomSide?: React.ReactNode
  leftSideIcon?: JSX.Element
  rightSideIcon?: JSX.Element
  linkTo?: string
  loading?: boolean
  copyValue?: boolean
  className?: {
    root?: string
    font?: string
    leftSide?: string
    rightSide?: string
  }
}) => {
  return (
    <div className={cls(styles.infoRow, className?.root)}>
      <div
        className={cls(
          styles.infoFont,
          styles.leftSide,
          className?.font,
          variants?.includes('small-font') && styles.smallFont
        )}
      >
        <div className={className?.leftSide}>
          {leftSide} {leftSideIcon}
        </div>
      </div>
      <div
        className={cls(
          styles.infoFont,
          styles.rightSide,
          className?.font,
          className?.rightSide,
          variants?.includes('small-font') && styles.smallFont
        )}
      >
        {loading ? (
          <ImpulseSpinner
            size={24}
            frontColor={GeneralColor.USC_GOLD.toString()}
          />
        ) : (
          <>
            <div className={styles.overFlow}>
              {rightSideIcon}
              {linkTo ? (
                <Link to={linkTo}>{rightSide}</Link>
              ) : (
                <div className={className?.rightSide}> {rightSide}</div>
              )}
            </div>
            {copyValue && (
              <div
                className={styles.copy}
                onClick={() => handleCopyToClipboard(String(rightSide))}
              >
                <Copy />
              </div>
            )}
          </>
        )}
      </div>
      {bottomSide && <div className={styles.bottomSide}>{bottomSide}</div>}
    </div>
  )
}

export { InfoRow }
