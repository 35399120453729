import React from 'react'

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  children: React.ReactNode
  wrapper: (children: React.ReactNode) => React.ReactNode
}) => {
  return condition ? <>{wrapper(children)}</> : <>{children}</>
}

export { ConditionalWrapper }
