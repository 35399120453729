import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Content, Typography } from '@lattice/common/components'

import { INodesType } from '../../types'

const NodeDetailsLayoutView = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [nodeType, setNodeType] = useState<string>('')
  const [nodeAlias, setNodeAlias] = useState<string>('')

  useEffect(() => {
    const paths = location.pathname.match('/nodes/(.*)/(.*)')
    if (!paths || paths[2] === '') {
      navigate('/nodes')
    }
    setNodeType(paths![1])
    setNodeAlias(paths![2])
  }, [])

  return (
    <Content>
      <Typography.DetailsTitle
        parentNameTitle={t(
          'views.NodeManager.views.NodeDetails.title.NodeManager',
          'Node Manager'
        )}
        childNameTitle={INodesType[nodeType] + ' / ' + nodeAlias}
      />
      <Outlet />
    </Content>
  )
}

export { NodeDetailsLayoutView }
