// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M8OOnz3H18KoarC90AIf{display:flex;padding:8px;gap:16px;background:#272b37;box-shadow:0px 12px 16px -4px rgba(16,24,40,.12),0px 4px 6px -2px rgba(16,24,40,.03);border-radius:48px}`, "",{"version":3,"sources":["webpack://./src/common/components/NavTab/component.module.scss","webpack://./src/common/styles/mixins.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,WAAA,CACA,QAAA,CAEA,kBAAA,CC4BE,oFAFe,CDxBjB,kBAAA","sourcesContent":["@use 'common/styles/mixins' as *;\n\n.navtab {\n  display: flex;\n  padding: 8px;\n  gap: 16px;\n\n  background: #272b37;\n  @include shadow(lg);\n  border-radius: 48px;\n}\n","@use 'sass:list';\n@use 'sass:color';\n\n$shadows: (\n  'xs': (\n    0px 1px 2px rgba(16, 24, 40, 0.05),\n  ),\n  'sm': (\n    0px 1px 3px rgba(16, 24, 40, 0.1),\n    0px 1px 2px rgba(16, 24, 40, 0.06),\n  ),\n  'md': (\n    0px 4px 8px -2px rgba(16, 24, 40, 0.1),\n    0px 2px 4px -2px rgba(16, 24, 40, 0.06),\n  ),\n  'lg': (\n    0px 12px 16px -4px rgba(16, 24, 40, 0.12),\n    0px 4px 6px -2px rgba(16, 24, 40, 0.03),\n  ),\n  'xl': (\n    0px 20px 24px -4px rgba(16, 24, 40, 0.12),\n    0px 8px 8px -4px rgba(16, 24, 40, 0.03),\n  ),\n  '2xl': (\n    0px 24px 48px -12px rgba(16, 24, 40, 0.24),\n  ),\n  '3xl': (\n    0px 32px 64px -12px rgba(16, 24, 40, 0.24),\n  ),\n);\n\n@mixin shadow($shadow) {\n  @if map-has-key($shadows, $shadow) {\n    $shadow-value: map-get($shadows, $shadow);\n\n    box-shadow: $shadow-value;\n  } @else {\n    @warn 'Invalid shadow: #{$shadow}.';\n  }\n}\n\n@mixin layered-background($colorLayers...) {\n  $background: null;\n\n  @for $i from 1 through (length($colorLayers) - 1) {\n    $colorLayer: nth($colorLayers, $i);\n    $background: append(\n      $background,\n      linear-gradient(0deg, $colorLayer, $colorLayer),\n      comma\n    );\n  }\n\n  background: $background, nth($colorLayers, -1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navtab": `M8OOnz3H18KoarC90AIf`
};
export default ___CSS_LOADER_EXPORT___;
