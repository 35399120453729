import React, { useEffect, useMemo, useState } from 'react'
import Decimal from 'decimal.js'

import { BaseCard, Button, InputRow } from '@lattice/common/components'
import { FetchStatus } from '@lattice/common/consts'
import { EnvironmentContext } from '@lattice/runtime'
import TopperFullIcon from '@lattice/assets/icons/custom/topper-full.svg'

import styles from './view.module.scss'
import { BuyTopperProvider, useBuyTopperProvider } from './provider/provider'
import { UpholdTopperBaseAppUrls } from './provider'

const BuyTopperViewBase = () => {
  const topper = useBuyTopperProvider()

  const [sourceAsset, setSourceAsset] = useState<string | null>(null)
  const [targetAsset, setTargetAsset] = useState<string | null>(null)

  const [sourceAmount, setSourceAmount] = useState(100)
  const [targetAmount, setTargetAmount] = useState(0)

  const [targetAddress, setTargetAddress] = useState('')
  const [targetAddressError, setTargetAddressError] = useState('')
  const [targetNetwork, setTargetNetwork] = useState<{
    code: string
    name: string
  } | null>(null)
  const simulationId = useMemo(() => ({ id: 0 }), [])

  const validSimulation =
    sourceAsset && targetAsset && (sourceAmount > 0 || targetAmount > 0)

  const doExchange = async () => {
    if (targetAddress.trim() === '') {
      setTargetAddressError('Unable to proceed without a wallet address')
      return
    }

    if (!validSimulation) {
      return
    }

    const response = await topper.exchange.send(
      {
        ecosystem: 'lattice',
        sourceAmount: new Decimal(sourceAmount).toFixed(),
        sourceAsset: sourceAsset!,
        targetAsset: targetAsset!,
        targetAddress,
        targetNetwork: targetNetwork?.code ?? '',
      },
      {},
      {}
    )

    window.open(
      `${UpholdTopperBaseAppUrls[EnvironmentContext.stage]}/?bt=${
        response.token.jwt
      }`
    )
  }

  const doSimulate = async (type: 'source' | 'target', amount: number) => {
    const initialSimulationId = ++simulationId.id
    if (!validSimulation) {
      return
    }

    const response = await topper.simulate.send(
      {
        ecosystem: 'lattice',
        sourceAsset,
        sourceAmount:
          type === 'source' ? new Decimal(amount).toFixed() : undefined,
        targetAsset,
        targetAmount:
          type === 'target' ? new Decimal(amount).toFixed() : undefined,
        targetAddress,
        targetNetwork: targetNetwork?.code ?? '',
      },
      {},
      {}
    )

    // Do not override the value if this execution is an outdated simulation
    if (initialSimulationId !== simulationId.id) {
      return
    }

    if (type === 'source') {
      setTargetAmount(
        new Decimal(response.simulation.destination.amount).toNumber()
      )
    }

    if (type === 'target') {
      setSourceAmount(new Decimal(response.simulation.origin.amount).toNumber())
    }
  }

  useEffect(() => {
    topper.currencies.fetch({}, {})
  }, [])

  useEffect(() => {
    if (topper.currencies.resource.resource) {
      const sourceCurrencies = topper.currencies.resource.resource.assets.source
      setSourceAsset(
        sourceCurrencies.find((source) => source.code === 'USD')?.code ??
          sourceCurrencies[0].code
      )

      const targetCurrencies = topper.currencies.resource.resource.assets.target
      setTargetAsset(
        targetCurrencies.find((source) => source.code === 'LTX')?.code ??
          targetCurrencies[0].code
      )
      setTargetNetwork(
        targetCurrencies.find((source) => source.code === 'LTX')?.networks[0] ??
          targetCurrencies[0].networks[0]
      )
    }
  }, [topper.currencies.resource.resource])

  useEffect(() => {
    doSimulate('source', sourceAmount)
  }, [sourceAsset, targetAsset, targetAddress])

  return (
    <BaseCard
      className={{ root: styles.root, body: styles.body }}
      variants={['header-title']}
      header="Buy"
    >
      <span className={styles.partnershipMsg}>
        Our partnership with Topper enables the purchase of DAG, LTX, and other
        cryptocurrencies with credit and debit cards
      </span>
      <div className={styles.exchangeConfiguration}>
        <div className={styles.currencySelector}>
          <InputRow.Numeric
            value={targetAmount}
            onChange={(value) => {
              setTargetAmount(value)
              doSimulate('target', value)
            }}
          />
          <InputRow.Select
            value={targetAsset}
            onChange={(targetCode: string) => {
              setTargetAsset(targetCode)
              topper.currencies.resource.resource &&
                setTargetNetwork(
                  topper.currencies.resource.resource.assets.target.find(
                    (target) => target.code === targetCode
                  )?.networks[0] ?? null
                )
            }}
            options={
              !topper.currencies.resource.resource
                ? []
                : topper.currencies.resource.resource.assets.target.map(
                    (target) => ({ value: target.code, content: target.code })
                  )
            }
          />
        </div>
        <div className={styles.currencySelector}>
          <InputRow.Numeric
            value={sourceAmount}
            onChange={(value) => {
              setSourceAmount(value)
              doSimulate('source', value)
            }}
          />
          <InputRow.Select
            value={sourceAsset}
            onChange={setSourceAsset}
            options={
              !topper.currencies.resource.resource
                ? []
                : topper.currencies.resource.resource.assets.source.map(
                    (source) => ({ value: source.code, content: source.code })
                  )
            }
          />
        </div>
        <InputRow
          placeholder={`${targetNetwork?.name} (${targetAsset}) address`}
          value={targetAddress}
          onChange={(v) => setTargetAddress(v.target.value)}
          error={targetAddressError}
        />
      </div>
      <span className={styles.addressMsg}>
        {targetAsset} address must be yours and under your full control. Don't
        have one?{' '}
        <a href="https://constellationnetwork.io/stargazer-wallet/">
          Download Stargazer Wallet
        </a>
      </span>
      <Button
        variants={['primary', 'full-width']}
        disabled={
          topper.exchange.resource.status === FetchStatus.PENDING ||
          topper.simulate.resource.status === FetchStatus.PENDING ||
          !validSimulation
        }
        loading={
          topper.exchange.resource.status === FetchStatus.PENDING ||
          topper.simulate.resource.status === FetchStatus.PENDING
        }
        onClick={
          topper.exchange.resource.status === FetchStatus.PENDING ||
          topper.simulate.resource.status === FetchStatus.PENDING ||
          !validSimulation
            ? undefined
            : doExchange
        }
      >
        Continue
      </Button>
      <div className={styles.topperIcon}>
        <img src={TopperFullIcon} />
      </div>
    </BaseCard>
  )
}

const BuyTopperView = () => {
  return (
    <BuyTopperProvider>
      <BuyTopperViewBase />
    </BuyTopperProvider>
  )
}

export { BuyTopperView }
