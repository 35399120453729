// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GzRWNWF5eXwSLQwyL0sw{display:flex;width:100%;height:100%;justify-content:center;align-items:center;position:fixed;z-index:100;top:0;left:0;background-color:rgba(0,0,0,.44)}.tfkRNtEJGjaM9S332qFp{z-index:250}.QrThuANXMOeYAv6l6ssT.dIGIF0IeDgzM1wb7I9XZ{position:fixed;z-index:100}`, "",{"version":3,"sources":["webpack://./src/common/components/BaseModal/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CAEA,cAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CAEA,gCAAA,CAGF,sBACE,WAAA,CAIA,2CACE,cAAA,CACA,WAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.container {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n\n  background-color: rgba(#000, 0.44);\n}\n\n.primary {\n  z-index: 250;\n}\n\n.main {\n  &.noOverlay {\n    position: fixed;\n    z-index: 100;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GzRWNWF5eXwSLQwyL0sw`,
	"primary": `tfkRNtEJGjaM9S332qFp`,
	"main": `QrThuANXMOeYAv6l6ssT`,
	"noOverlay": `dIGIF0IeDgzM1wb7I9XZ`
};
export default ___CSS_LOADER_EXPORT___;
