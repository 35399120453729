import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  BaseCard,
  Button,
  RadioSelectCards,
  Typography,
} from '@lattice/common/components'
import LatticeIcon from '@lattice/assets/images/pngs/swapping/lattice.png'
import ConstellationIcon from '@lattice/assets/images/pngs/swapping/constellation.png'
import CrossChainIcon from '@lattice/assets/images/pngs/swapping/cross-chain.png'
import EthereumIcon from '@lattice/assets/images/pngs/swapping/ethereum.png'

import styles from './view.module.scss'

const MethodsAndLinks = {
  ltx: '../lifi',
  dag: '../exolix',
  'cross-chain': '../exolix',
  'eth-cross-chain': '../lifi',
}

const SwapSelectMethodView = () => {
  const navigate = useNavigate()
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)

  const onNextButtonClick = () => {
    if (selectedMethod) {
      navigate(MethodsAndLinks[selectedMethod])
    }
  }

  return (
    <BaseCard
      className={{ root: styles.main, body: styles.body }}
      variants={['bordered']}
    >
      <div className={styles.descHolder}>
        <Typography.Title order={2}>Select swap method</Typography.Title>
        <Typography.Text>
          Choose the best method to swap your assets securely, with low fees.
          Below is a list of Lattice-trusted providers for your swaps.
        </Typography.Text>
      </div>
      <RadioSelectCards
        value={selectedMethod}
        onValueChange={setSelectedMethod}
        options={[
          {
            value: 'ltx',
            title: 'LTX',
            titleExtra: '(Ethereum)',
            subTitle: 'Provider: LI.FI',
            imgSrc: LatticeIcon,
          },
          {
            value: 'dag',
            title: 'DAG',
            titleExtra: '(Constellation)',
            subTitle: 'Provider: Exolix',
            imgSrc: ConstellationIcon,
          },
          {
            value: 'cross-chain',
            title: 'Cross-chain',
            titleExtra: '(DAG,ETH,BTC,SOL, etc.)',
            subTitle: 'Provider: Exolix',
            imgSrc: CrossChainIcon,
          },
          {
            value: 'eth-cross-chain',
            title: 'Ethereum cross-chain',
            titleExtra: '(ARB, OP, Polygon, etc.)',
            subTitle: 'Provider: LI.FI',
            imgSrc: EthereumIcon,
          },
        ]}
      />
      <Button
        variants={['full-width', 'right-icon', 'primary']}
        onClick={onNextButtonClick}
        disabled={selectedMethod === null}
      >
        Next
      </Button>
    </BaseCard>
  )
}

export { SwapSelectMethodView }
