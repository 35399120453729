import { EnvironmentContext } from '@lattice/runtime'

import { DeploymentStage } from '../deployment'

import { IHgtpNetworkUrls, IHgtpSourceNodeInfo } from './types'

enum HgtpNetwork {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
  INTEGRATIONNET = 'integrationnet',
}

const isHgtpNetwork = (value: any): value is HgtpNetwork =>
  Object.values(HgtpNetwork).includes(value)

const DeploymentHgtpNetworks: Record<DeploymentStage, HgtpNetwork> = {
  [DeploymentStage.PRODUCTION]: HgtpNetwork.MAINNET,
  [DeploymentStage.STAGING]: HgtpNetwork.INTEGRATIONNET,
  [DeploymentStage.DELTA]: HgtpNetwork.TESTNET,
  [DeploymentStage.ALPHA]: HgtpNetwork.TESTNET,
  [DeploymentStage.THETA]: HgtpNetwork.TESTNET,
}

const DeploymentHgtpNetwork = DeploymentHgtpNetworks[EnvironmentContext.stage]

enum HgtpNetworkNodeStatus {
  READY = 'Ready',
  SNAPSHOT_CREATION = 'SnapshotCreation',
  OTHER = 'Other',
}

enum HgtpNetworkSourceNode {
  MAINNET_ALNILAM = 'mainnet_alnilam',
  MAINNET_ALNITAK = 'mainnet_alnitak',
  MAINNET_MINTAKA = 'mainnet_mintaka',
  TESTNET_ALNAKAI = 'testnet_alnakai',
  TESTNET_ALNIMARA = 'testnet_alnimara',
  TESNET_MINTARA = 'testnet_mintara',
  INTEGRATIONNET_ALNIKARA = 'integrationnet_alnikara',
  INTEGRATIONNET_ALNATARI = 'integrationnet_alnatari',
  INTEGRATIONNET_MINTARIS = 'integrationnet_mintaris',
}

const HgtpNetworksUrls: Record<HgtpNetwork, IHgtpNetworkUrls> = {
  [HgtpNetwork.MAINNET]: {
    L0ClusterUrl: 'https://l0-lb-mainnet.constellationnetwork.io/cluster/info',
    SnapshotsUrl:
      'https://be-mainnet.constellationnetwork.io/global-snapshots/',
    LastSnaphotUrl:
      'https://be-mainnet.constellationnetwork.io/global-snapshots/latest',
    TransactionsUrl: 'https://be-mainnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-mainnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-mainnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'mainnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-mainnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-mainnet.constellationnetwork.io',
      beUrl: 'https://be-mainnet.constellationnetwork.io',
    },
  },
  [HgtpNetwork.TESTNET]: {
    L0ClusterUrl: 'https://l0-lb-testnet.constellationnetwork.io/cluster/info',
    SnapshotsUrl:
      'https://be-testnet.constellationnetwork.io/global-snapshots/',
    LastSnaphotUrl:
      'https://be-testnet.constellationnetwork.io/global-snapshots/latest',
    TransactionsUrl: 'https://be-testnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-testnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-testnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'testnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-testnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-testnet.constellationnetwork.io',
      beUrl: 'https://be-testnet.constellationnetwork.io',
    },
  },
  [HgtpNetwork.INTEGRATIONNET]: {
    L0ClusterUrl:
      'https://l0-lb-integrationnet.constellationnetwork.io/cluster/info',
    SnapshotsUrl:
      'https://be-integrationnet.constellationnetwork.io/global-snapshots/',
    LastSnaphotUrl:
      'https://be-integrationnet.constellationnetwork.io/global-snapshots/latest',
    TransactionsUrl:
      'https://be-integrationnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-integrationnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-integrationnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'integrationnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-integrationnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-integrationnet.constellationnetwork.io',
      beUrl: 'https://be-integrationnet.constellationnetwork.io',
    },
  },
}

const HgtpNetworkNodeOnlineStatuses = [
  HgtpNetworkNodeStatus.READY,
  HgtpNetworkNodeStatus.SNAPSHOT_CREATION,
]

const HgtpSourceNodes: {
  [K in HgtpNetworkSourceNode]: IHgtpSourceNodeInfo<K>
} = {
  [HgtpNetworkSourceNode.MAINNET_ALNILAM]: {
    id: HgtpNetworkSourceNode.MAINNET_ALNILAM,
    network: HgtpNetwork.MAINNET,
    name: 'Alnilam Foundational',
    host: 'alnilam-node.constellationnetwork.io',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.MAINNET_ALNITAK]: {
    id: HgtpNetworkSourceNode.MAINNET_ALNITAK,
    network: HgtpNetwork.MAINNET,
    name: 'Alnitak Foundational',
    host: 'alnitak-node.constellationnetwork.io',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.MAINNET_MINTAKA]: {
    id: HgtpNetworkSourceNode.MAINNET_MINTAKA,
    network: HgtpNetwork.MAINNET,
    name: 'Mintaka Foundational',
    host: 'mintaka-node.constellationnetwork.io',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.TESTNET_ALNAKAI]: {
    id: HgtpNetworkSourceNode.TESTNET_ALNAKAI,
    network: HgtpNetwork.TESTNET,
    name: 'Alnakai Source Testnet',
    host: '13.57.186.140',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.TESTNET_ALNIMARA]: {
    id: HgtpNetworkSourceNode.TESTNET_ALNIMARA,
    network: HgtpNetwork.TESTNET,
    name: 'Alnimara Source Testnet',
    host: '54.193.165.70',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.TESNET_MINTARA]: {
    id: HgtpNetworkSourceNode.TESNET_MINTARA,
    network: HgtpNetwork.TESTNET,
    name: 'Mintara Source Testnet',
    host: '54.177.255.227',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.INTEGRATIONNET_ALNIKARA]: {
    id: HgtpNetworkSourceNode.INTEGRATIONNET_ALNIKARA,
    network: HgtpNetwork.INTEGRATIONNET,
    name: 'Alnikara Source Integrationnet',
    host: '3.101.147.116',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.INTEGRATIONNET_ALNATARI]: {
    id: HgtpNetworkSourceNode.INTEGRATIONNET_ALNATARI,
    network: HgtpNetwork.INTEGRATIONNET,
    name: 'Alnatari Source Integrationnet',
    host: '52.53.216.201',
    probePorts: [9000, 9010],
  },
  [HgtpNetworkSourceNode.INTEGRATIONNET_MINTARIS]: {
    id: HgtpNetworkSourceNode.INTEGRATIONNET_MINTARIS,
    network: HgtpNetwork.INTEGRATIONNET,
    name: 'Mintaris Source Integrationnet',
    host: '54.67.6.165',
    probePorts: [9000, 9010],
  },
}

export {
  HgtpNetwork,
  isHgtpNetwork,
  HgtpNetworkSourceNode,
  HgtpNetworkNodeStatus,
  HgtpSourceNodes,
  HgtpNetworksUrls,
  HgtpNetworkNodeOnlineStatuses,
  DeploymentHgtpNetwork,
  DeploymentHgtpNetworks,
}
