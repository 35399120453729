// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Bu_u9euQL27Xh6ahpVh{width:100%}.vb9S_Xqcba4kK1S8QwQQ{color:#fc0;cursor:pointer}.Je_vipX1abpTe5Ord5RX{display:grid;width:100%;grid-template-columns:1fr 73px;align-items:center;column-gap:11px}`, "",{"version":3,"sources":["webpack://./src/views/NodeManagerView/views/DtmNodeDetailsView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,UAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,8BAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".nftImage{\n  width: 100%;\n}\n\n.editRewardsAddress{\n  color: #FFCC00;\n  cursor: pointer;\n}\n\n.editRewardsAddressInput{\n  display: grid;\n  width: 100%;\n\n  grid-template-columns: 1fr 73px;\n  align-items: center;\n  column-gap: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nftImage": `_Bu_u9euQL27Xh6ahpVh`,
	"editRewardsAddress": `vb9S_Xqcba4kK1S8QwQQ`,
	"editRewardsAddressInput": `Je_vipX1abpTe5Ord5RX`
};
export default ___CSS_LOADER_EXPORT___;
