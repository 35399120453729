import React from 'react'

import { BaseModal } from '../BaseModal'
import { BaseCard } from '../BaseCard'
import { Button } from '../Button'

import styles from './component.module.scss'

export type IConfirmActionModalProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  positiveButtonContent?: React.ReactNode
  onPositiveButtonClick?: () => void
  negativeButtonContent?: React.ReactNode
  onNegativeButtonClick?: () => void
  onClose: () => void
}

export const ConfirmActionModal = ({
  title,
  description,
  positiveButtonContent,
  onPositiveButtonClick,
  negativeButtonContent,
  onNegativeButtonClick,
  onClose,
}: IConfirmActionModalProps): JSX.Element => {
  return (
    <BaseModal onClickOutside={onClose}>
      <BaseCard
        className={{
          root: styles.root,
          header: styles.header,
          body: styles.body,
        }}
      >
        <h2>{title}</h2>
        <span>{description}</span>
        <div>
          <Button
            variants={['secondary', 'full-width']}
            onClick={onNegativeButtonClick}
          >
            {negativeButtonContent}
          </Button>
          <Button
            variants={['primary', 'full-width']}
            onClick={onPositiveButtonClick}
          >
            {positiveButtonContent}
          </Button>
        </div>
      </BaseCard>
    </BaseModal>
  )
}
