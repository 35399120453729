export enum BaseColor {
  USC_GOLD = '#ffcc00',
  BLACK = '#000000',
  CURSED_BLACK = '#131313',
  NERO = '#252525',
  OFF_BLACK = '#313131',
  CARBON_FIBER = '#2e2e2e',
  BOLTGUN_METAL = '#393939',
  TIN = '#909090',
  PINBALL = '#d3d3d3',
  WHITE = '#ffffff',
  WEATHERED_STONE = '#c4c4c4',
  GUAVA = '#ff9e2d',
  BLUE_COLA = '#0087ea',
  SZOLLOSI_GRAPE = '#8423ff',
  ALGAL_FUEL = '#19c763',
  KHMER_CURRY = '#eb5757',
  GREEN_BLOB = '#22dd11',
  TOMATO_FROG = '#ff4343',
}

export enum GeneralColor {
  USC_GOLD = BaseColor.USC_GOLD,
  BLACK = BaseColor.BLACK,
  CURSED_BLACK = BaseColor.CURSED_BLACK,
  NERO = BaseColor.NERO,
  OFF_BLACK = BaseColor.OFF_BLACK,
  CARBON_FIBER = BaseColor.CARBON_FIBER,
  BOLTGUN_METAL = BaseColor.BOLTGUN_METAL,
  TIN = BaseColor.TIN,
  PINBALL = BaseColor.PINBALL,
  WHITE = BaseColor.WHITE,
}

export enum ProjectStatusColor {
  WEATHERED_STONE = BaseColor.WEATHERED_STONE,
  COMING_SOON = BaseColor.WEATHERED_STONE,
  GUAVA = BaseColor.GUAVA,
  ENROLLING = BaseColor.GUAVA,
  GREEN_BLOB = BaseColor.GREEN_BLOB,
  LIVE = BaseColor.GREEN_BLOB,
  BLUE_COLA = BaseColor.BLUE_COLA,
  DISTRIBUTING = BaseColor.BLUE_COLA,
  SZOLLOSI_GRAPE = BaseColor.SZOLLOSI_GRAPE,
  COMPLETE = BaseColor.SZOLLOSI_GRAPE,
}

export enum BooleanColor {
  ALGAL_FUEL = BaseColor.ALGAL_FUEL,
  TRUE = BaseColor.ALGAL_FUEL,
  KHMER_CURRY = BaseColor.KHMER_CURRY,
  FALSE = BaseColor.KHMER_CURRY,
}

export enum CurencyTickerColor {
  GREEN_BLOB = BaseColor.GREEN_BLOB,
  INCREASE = BaseColor.GREEN_BLOB,
  TOMATO_FROG = BaseColor.TOMATO_FROG,
  DECREASE = BaseColor.TOMATO_FROG,
}
