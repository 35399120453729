import { createProviderStateHook } from '@lattice/utils'

import { LaunchpadProvider, LaunchpadProviderContext } from './provider'

const useLaunchpadProvider = createProviderStateHook(
  LaunchpadProvider,
  LaunchpadProviderContext
)

export { useLaunchpadProvider }
