import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'
import Decimal from 'decimal.js'

import {
  BaseCard,
  BaseModal,
  Button,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as CaretDown } from '@lattice/assets/images/svgs/CaretDown.svg'
import { ReactComponent as CheckFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import {
  ISoftNodesProject,
  ISoftNodesProjectStatus,
  useSoftNodesProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { shortenAddress } from '@lattice/utils'
import { FetchStatus } from '@lattice/common/consts'
import { useProgressToasts } from '@lattice/common/hooks'

import styles from './component.module.scss'

const NODE_TYPES = [
  { key: 'testnetValidator', value: 'Testnet validator' },
  { key: 'dtmNft', value: 'Dor Traffic Miner NFT' },
  { key: 'softNode', value: 'Soft Node' },
] as const

type NodeType = (typeof NODE_TYPES)[number]['key']

const AddNodeModal = ({
  onClickOutside,
  nodeType,
  handleSuccess,
}: {
  onClickOutside: () => void
  nodeType: NodeType | ''
  handleSuccess: () => void
}) => {
  const { t } = useTranslation()
  const {
    projects,
    requestProjects,
    stakeWallet,
    stakeWalletOperation,
    MINIMUM_FRACTIONAL_STAKE_DAG,
  } = useSoftNodesProvider()
  const { activeWallet, requestConnectorActivation, accountsNetworkAssets } =
    useWalletProvider()
  const stakeOperationProgressToasts = useProgressToasts()
  const [selectedType, setSelectedType] = useState<NodeType | ''>(nodeType)
  const [showOptions, setShowOptions] = useState(false)
  const [displayCustomFields, setDisplayCustomFields] = useState<
    NodeType | undefined
  >(undefined)
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [rewardsAddress, setRewardsAddress] = useState<string>('')
  const [stakingProgram, setStakingProgram] = useState<
    ISoftNodesProject | undefined
  >(undefined)
  const [nft, setNft] = useState<string>('')

  const handleSaveNode = async (nodeKey: NodeType) => {
    if (nodeKey === 'softNode') {
      await doStakeWallet()
    }
  }

  const availableBalanceRaw =
    accountsNetworkAssets['constellation:dag']?.balanceRaw ?? new Decimal(0)

  const availableNodesToStake = stakingProgram
    ? availableBalanceRaw.div(stakingProgram.nodePrice).floor().toNumber()
    : 0

  const doStakeWallet = stakeWalletOperation.wrappedFetch(
    stakeOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (activeWallet.status !== 'connected' || !activeWallet.constellation) {
        throw new Error('Wallet is not active')
      }

      if (filteredProjects === undefined) {
        throw new Error('Program is not available')
      }

      if (availableBalanceRaw.lt(MINIMUM_FRACTIONAL_STAKE_DAG)) {
        throw new Error(
          `Wallet does not have at least ${new Decimal(
            MINIMUM_FRACTIONAL_STAKE_DAG
          ).div(1e8)} $DAG to stake`
        )
      }

      stakeOperationProgressToasts.progress(
        'Requesting wallet ownership signature',
        'info',
        null
      )

      const ownershipToken =
        await activeWallet.constellation.requestWalletOwnershipToken()

      stakeOperationProgressToasts.progress('Staking shards', 'info', null)

      await stakeWallet(
        filteredProjects[0].id,
        activeWallet.constellation.account,
        availableNodesToStake,
        ownershipToken
      )

      stakeOperationProgressToasts.progress(
        'Successfully staked',
        'success',
        15000
      )
      handleSuccess()
    })
  )

  const resetState = () => {
    setVerificationCode('')
    setRewardsAddress('')
    setStakingProgram(undefined)
    setNft('')
  }

  const handleNodeTypeSelection = (nodeKey: NodeType) => {
    resetState()
    setDisplayCustomFields(nodeKey)
  }

  useEffect(() => {
    requestProjects()
  }, [])

  const filteredProjects =
    projects.status === FetchStatus.DONE
      ? projects.resource.filter(
          (project) => project.status === ISoftNodesProjectStatus.STAKING_OPEN
        )
      : undefined

  const disabledSaveNode =
    !(activeWallet.status === 'connected' && activeWallet.constellation) ||
    !filteredProjects ||
    filteredProjects.length === 0 ||
    stakeWalletOperation.status === FetchStatus.PENDING

  return (
    <BaseModal>
      <BaseCard
        header={t('views.Nodes.texts.addNode', 'Add Node')}
        variants={['full-width', 'header-title']}
        className={{
          root: styles.cardContainer,
          header: styles.headerContainer,
        }}
      >
        <div className={styles.content}>
          <section className={styles.mainSection}>
            <InputRow
              label={t('views.Nodes.texts.nodeType', 'Node type')}
              placeholder={t('views.Nodes.texts.selectNode', 'Select node')}
              icon={<CaretDown />}
              value={
                NODE_TYPES.find((node) => node.key === selectedType)?.value
              }
              readOnly={true}
              onIconClick={() =>
                false && !displayCustomFields && setShowOptions((opt) => !opt)
              }
              onClick={() =>
                false && !displayCustomFields && setShowOptions((opt) => !opt)
              }
              classNames={{ input: styles.inputContainer }}
            />

            <div
              className={cls(styles.nodeOptions, {
                [styles.active]: showOptions,
              })}
            >
              {NODE_TYPES.map((node) => (
                <div
                  className={styles.nodeOptionContainer}
                  key={node.key}
                  onClick={() => {
                    setSelectedType(node.key)
                    setShowOptions(false)
                  }}
                >
                  <div className={styles.nodeOption}>{node.value}</div>
                </div>
              ))}
            </div>
            {displayCustomFields === 'testnetValidator' && (
              <>
                <InputRow
                  label={t(
                    'views.Nodes.texts.rewardsAddress',
                    'Rewards address'
                  )}
                  placeholder={t(
                    'views.Nodes.texts.dagRewardsAddress',
                    'DAG address to receive rewards'
                  )}
                  value={rewardsAddress}
                  onChange={(e) => setRewardsAddress(e.target.value)}
                />
                <InputRow
                  label={t(
                    'views.Nodes.texts.verificationCode',
                    'Verification code'
                  )}
                  placeholder={t(
                    'views.Nodes.texts.pasteCode',
                    'Paste code here...'
                  )}
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </>
            )}
            {displayCustomFields === 'dtmNft' && (
              <>
                <InputRow
                  label={t('views.Nodes.texts.walletAddress', 'Wallet address')}
                  placeholder={t('views.Nodes.texts.pasteCode', '0x...')}
                  disabled
                  icon={
                    !(
                      activeWallet.status === 'connected' &&
                      activeWallet.ethereum
                    ) ? (
                      <div
                        className={styles.connectWallet}
                        onClick={() => requestConnectorActivation()}
                      >
                        {t('views.Nodes.texts.connectWallet', 'Connect wallet')}
                      </div>
                    ) : (
                      <CheckFilledIcon
                        color="#19BD39"
                        height={'16px'}
                        width={'16px'}
                      />
                    )
                  }
                  value={
                    activeWallet.status === 'connected' && activeWallet.ethereum
                      ? shortenAddress(
                          activeWallet.ethereum.account,
                          5,
                          5,
                          '•••'
                        )
                      : ''
                  }
                />
                <InputRow
                  label={'NFT'}
                  placeholder={'--'}
                  icon={<CaretDown />}
                  value={nft}
                  onChange={(e) => setNft(e.target.value)}
                />
                <InputRow
                  label={t(
                    'views.Nodes.texts.rewardsAddress',
                    'Rewards address'
                  )}
                  placeholder={t(
                    'views.Nodes.texts.dagRewardsAddress',
                    'DAG address to receive rewards'
                  )}
                  value={rewardsAddress}
                  onChange={(e) => setRewardsAddress(e.target.value)}
                />
              </>
            )}
            {displayCustomFields === 'softNode' &&
              (!filteredProjects ? (
                <PendingContent variants={['min-height']} />
              ) : (
                <>
                  <InputRow
                    label={t(
                      'views.Nodes.texts.walletAddress',
                      'Wallet address'
                    )}
                    placeholder={t('views.Nodes.texts.pasteCode', 'DAG...')}
                    disabled
                    icon={
                      !(
                        activeWallet.status === 'connected' &&
                        activeWallet.constellation
                      ) ? (
                        <div
                          className={styles.connectWallet}
                          onClick={() => requestConnectorActivation()}
                        >
                          {t(
                            'views.Nodes.texts.connectWallet',
                            'Connect wallet'
                          )}
                        </div>
                      ) : (
                        <CheckFilledIcon
                          color="#19BD39"
                          height={'16px'}
                          width={'16px'}
                        />
                      )
                    }
                    value={
                      activeWallet.status === 'connected' &&
                      activeWallet.constellation
                        ? shortenAddress(
                            activeWallet.constellation.account,
                            5,
                            5,
                            '•••'
                          )
                        : ''
                    }
                  />
                  <InputRow
                    label={'Staking program'}
                    placeholder={t('views.Nodes.texts.enroll', 'Enroll in...')}
                    value={
                      filteredProjects && filteredProjects[0]
                        ? filteredProjects[0].name
                        : ''
                    }
                    disabled
                  />
                </>
              ))}
            <div className={styles.modalButtons}>
              <Button
                variants={['secondary', 'full-width']}
                onClick={() => onClickOutside()}
              >
                Cancel
              </Button>
              {!displayCustomFields && (
                <Button
                  variants={['primary', 'full-width']}
                  disabled={!selectedType}
                  onClick={() =>
                    selectedType && handleNodeTypeSelection(selectedType)
                  }
                >
                  {t('views.Nodes.texts.continue', 'Continue')}
                </Button>
              )}
              {displayCustomFields === 'testnetValidator' && (
                <Button
                  variants={['primary', 'full-width']}
                  disabled={!verificationCode || !rewardsAddress}
                  onClick={() => handleSaveNode('testnetValidator')}
                >
                  {t('views.Nodes.texts.saveNode', 'Save node')}
                </Button>
              )}
              {displayCustomFields === 'softNode' && (
                <Button
                  variants={['primary', 'full-width']}
                  disabled={disabledSaveNode}
                  onClick={() => !disabledSaveNode && doStakeWallet()}
                >
                  {t('views.Nodes.texts.saveNode', 'Save node')}
                </Button>
              )}
              {displayCustomFields === 'dtmNft' && (
                <Button
                  variants={['primary', 'full-width']}
                  disabled={!nft || !rewardsAddress}
                  onClick={() => handleSaveNode('dtmNft')}
                >
                  {t('views.Nodes.texts.saveNode', 'Save node')}
                </Button>
              )}
            </div>
          </section>
        </div>
      </BaseCard>
    </BaseModal>
  )
}

export { AddNodeModal }
