import React, { useEffect } from 'react'
import cls from 'classnames'
import { PlacesType } from 'react-tooltip'

import { useImperativeProxiedInputValueRef } from '@lattice/common/hooks'

import styles from '../../component.module.scss'

const getGoogleMapsPlacesAutocompleteOptions = (
  countryCode?: string
): google.maps.places.AutocompleteOptions => {
  return {
    types: ['address'],
    fields: ['address_components'],
    componentRestrictions: { country: countryCode ?? null },
  }
}

const IntlAddressInputRow = React.forwardRef<
  HTMLInputElement,
  {
    countryCode?: string
    variants?: ('full-width' | 'disabled')[]
    label?: string
    error?: string
    icon?: React.ReactNode
    labelIcon?: React.ReactNode
    iconTooltip?: string
    tooltipPlace?: PlacesType
    onIconClick?: () => void
    onPlaceChange?: (
      address: string,
      location: {
        locality: string
        postcode: string
        state: string
        address: string
      } | null,
      place: google.maps.places.PlaceResult
    ) => void
    classNames?: {
      root?: string
      label?: string
      input?: string
      inputWrapper?: string
      error?: string
      icon?: string
      labelIcon?: string
    }
  } & JSX.IntrinsicElements['input']
>(
  (
    {
      countryCode,
      variants,
      label,
      error,
      icon,
      labelIcon,
      iconTooltip,
      tooltipPlace,
      onIconClick,
      onPlaceChange,
      classNames,
      type,
      value,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const imperativeProxiedTarget = useImperativeProxiedInputValueRef(
      ref,
      value
    )

    useEffect(() => {
      imperativeProxiedTarget.internalTargetValue.value = value
    }, [value])

    useEffect(() => {
      if (imperativeProxiedTarget.internalRef.current) {
        const googleMapsAutoComplete =
          new window.google.maps.places.Autocomplete(
            imperativeProxiedTarget.internalRef.current,
            getGoogleMapsPlacesAutocompleteOptions(countryCode)
          )
        googleMapsAutoComplete.addListener('place_changed', async () => {
          const place = googleMapsAutoComplete.getPlace()

          const location: {
            locality: string
            postcode: string
            state: string
            address: string
          } = { locality: '', postcode: '', state: '', address: '' }

          if (!place.address_components) {
            location.address = String(place.name)
          } else {
            for (const component of place.address_components) {
              const componentType = component.types[0]

              if (componentType === 'street_number') {
                location.address = `${component.long_name} ${location.address}`
                continue
              }

              if (componentType === 'route') {
                location.address = location.address + ' ' + component.short_name
                continue
              }

              if (componentType === 'postal_code') {
                location.postcode = `${component.long_name}${location.postcode}`
                continue
              }

              if (componentType === 'locality') {
                location.locality = component.long_name
                continue
              }

              if (componentType === 'administrative_area_level_1') {
                location.state = component.short_name
                continue
              }
            }
          }

          onPlaceChange && onPlaceChange(location.address, location, place)
          imperativeProxiedTarget.internalTargetValue.value = location.address
          props.onChange &&
            props.onChange({
              target: imperativeProxiedTarget.internalTargetProxy,
            } as any)
        })
      }
    }, [countryCode, imperativeProxiedTarget.internalRef.current])

    return (
      <div
        className={cls(
          styles.root,
          variants?.includes('full-width') && styles.fullWidth,
          classNames?.root
        )}
      >
        {label && (
          <label className={cls(styles.label, classNames?.label)}>
            {label}
            {labelIcon && (
              <p
                data-tooltip-id="lattice"
                data-tooltip-content={iconTooltip}
                data-tooltip-place={tooltipPlace}
                className={styles.noMargin}
              >
                {labelIcon}
              </p>
            )}
          </label>
        )}
        <div className={cls(styles.inputWrapper, classNames?.inputWrapper)}>
          <input
            {...props}
            {...{ type, value, defaultValue }}
            className={cls(
              styles.input,
              variants?.includes('disabled') && styles.disabled,
              error && cls(styles.error, classNames?.error),
              classNames?.input
            )}
            ref={imperativeProxiedTarget.internalRef}
          />
          <div
            className={cls(styles.icon, classNames?.icon)}
            onClick={(e) => {
              e.stopPropagation()
              onIconClick && onIconClick()
            }}
          >
            {icon}
          </div>
        </div>
        {error && (
          <span className={cls(styles.error, classNames?.error)}>{error}</span>
        )}
      </div>
    )
  }
)

export { IntlAddressInputRow }
