import { apiRequest } from '@lattice/utils'

import { RewardProgram } from './types'

const requestPrograms = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/rewards-programs',
    })
    return data as RewardProgram[]
  } catch (err) {
    throw err
  }
}

export { requestPrograms }
