import React from 'react'

import { ReactComponent as Question } from '@lattice/assets/images/svgs/Question.svg'

import { CardLabelContainer } from '../CardLabelContainer'
import { CardSectionContainer } from '../CardSectionContainer'
import { CardFlexContainer } from '../CardFlexContainer'
import { DetailsBaseCardContainer } from '../DetailsBaseCardContainer/component'

import styles from './component.module.scss'

const DetailsInfoCard = ({
  label,
  subText,
  variants,
  actionElement,
  children,
}: {
  label: string
  subText?: string
  actionElement?: React.ReactNode
  children?: React.ReactNode
  variants?: (
    | 'narrow'
    | 'wide'
    | 'medium'
    | 'section-bar'
    | 'full-width'
    | 'bordered'
  )[]
}) => {
  return (
    <DetailsBaseCardContainer variants={variants}>
      <CardSectionContainer>
        <CardLabelContainer>
          {label}
          <div className={styles.help}>
            <Question />
          </div>
        </CardLabelContainer>
        <CardFlexContainer>
          <div className={styles.infoSubText}>{subText}</div>
        </CardFlexContainer>
        {children}
        <div className={styles.infoButton}>{actionElement}</div>
      </CardSectionContainer>
    </DetailsBaseCardContainer>
  )
}

export { DetailsInfoCard }
