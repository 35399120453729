import React, { useRef, CSSProperties } from 'react'
import cls from 'classnames'

import { useOnClickOutside } from '@lattice/common/hooks'

import styles from './component.module.scss'

/**
 * @uses z-index
 * @key BaseModal
 * @value 250
 */
const BaseModal = ({
  variants,
  overlay = true,
  onClickOutside,
  children,
  className,
  style,
}: {
  variants?: 'primary'[]
  overlay?: boolean
  onClickOutside?: () => void
  children: React.ReactNode
  className?: { container?: string; main?: string }
  style?: { container?: CSSProperties; main?: CSSProperties }
}) => {
  const mainElementRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(mainElementRef, onClickOutside ?? (() => void 0))

  if (!overlay) {
    return (
      <div
        className={cls(styles.main, styles.noOverlay, className?.main)}
        style={style?.main}
        ref={mainElementRef}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      className={cls(
        styles.container,
        className?.container,
        variants?.includes('primary') && styles.primary
      )}
      style={style?.container}
    >
      <div
        className={cls(styles.main, className?.main)}
        style={style?.main}
        ref={mainElementRef}
      >
        {children}
      </div>
    </div>
  )
}

export { BaseModal }
