import React from 'react'
import cls from 'classnames'

import { Badge, BadgeColors, BaseCard } from '@lattice/common/components'
import { StatusColors } from '@lattice/common/consts/programStatus'

import styles from './component.module.scss'

type ITabSection = {
  name: string
  content: React.ReactNode
}

const MainProjectCard = ({
  bannerUrl,
  badge,
  title,
  logoUrl,
  children,
  header,
}: {
  bannerUrl: string
  badge?: { text: string; color: BadgeColors | StatusColors }
  title?: string
  logoUrl?: string
  header?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <BaseCard
      variants={['bordered']}
      header={
        header ? (
          header
        ) : (
          <>
            {logoUrl && <img src={logoUrl} />}
            {title && title}
            {badge && (
              <Badge
                text={badge.text}
                color={badge.color}
                className={{ text: styles.badgeText }}
              />
            )}
          </>
        )
      }
      className={{ body: styles.body, header: cls(!header && styles.header) }}
    >
      <img src={bannerUrl} className={styles.bannerImage} />
      <div className={styles.contentContainer}>
        <div className={styles.mainContent}>{children}</div>
      </div>
    </BaseCard>
  )
}

export { MainProjectCard, ITabSection }
