import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as TreeStructure } from '@lattice/assets/images/svgs/TreeStructure.svg'
import { ReactComponent as PlusCircle } from '@lattice/assets/images/svgs/PlusCircle.svg'
import {
  Button,
  FilterDropdown,
  FilterRow,
  GridContainer,
  Typography,
  Content,
  PendingContent,
  NoContent,
} from '@lattice/common/components'
import {
  useNodeManagerProvider,
  INodeManagerNode,
  useUserProvider,
  useSoftNodesProvider,
  IUserWalletSoftNodeAndPrograms,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import { isNodeManagerGeneralNode } from '@lattice/common/providers/NodeManagerProvider/utils'
import { useSignInRedirect } from '@lattice/common/hooks'

import {
  AddNodeModal,
  NodeCard,
  AddNodeCard,
  UserWalletSoftNodeCard,
} from '../../components'

import styles from './view.module.scss'

const NodesDashboardView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userSession, user } = useUserProvider()
  const signInUrl = useSignInRedirect()
  const { userNodes, requestUserNodes } = useNodeManagerProvider()
  const { userHistoryProjects, requestUserHistoryProjects } =
    useSoftNodesProvider()
  const [filteredNodes, setFilteredNodes] = useState<INodeManagerNode[] | null>(
    null
  )
  const [filteredSoftNodes, setFilteredSoftNodes] = useState<
    IUserWalletSoftNodeAndPrograms[] | null
  >(null)
  const [showAddNodeContent, setShowAddNodeContent] = useState(false)
  const [nodeTypeToAdd, setNodeTypeToAdd] = useState<any>('')
  const [nodesFilter, setNodesFilter] = useState<string | null>(null)
  const [statusFilter, setStatusFilter] = useState<string | null>(null)

  useEffect(() => {
    if (userSession) {
      requestUserNodes()
      requestUserHistoryProjects()
    }
  }, [userSession.status])

  const handleSuccess = () => {
    if (userSession || user) {
      requestUserNodes()
    }
    setShowAddNodeContent(false)
  }

  useEffect(() => {
    const _nodes = userNodes?.resource?.filter((node) => {
      const tests: boolean[] = [true]
      const isGeneralNode = isNodeManagerGeneralNode(node)

      if (!isGeneralNode && node.type === 'softnode') {
        return false
      }

      if (nodesFilter) {
        !isGeneralNode && tests.push(node.type === nodesFilter)
        isGeneralNode && tests.push(node.nodeType === nodesFilter)
      }

      if (statusFilter) {
        !isGeneralNode && tests.push(node.status === statusFilter)
        isGeneralNode && tests.push(statusFilter === 'active')
      }

      return tests.every((t) => t)
    })

    const _softNodes = userHistoryProjects?.resource?.filter((node) => {
      const tests: boolean[] = [true]
      if (nodesFilter) {
        tests.push(nodesFilter === 'softnode')
      }
      if (statusFilter) {
        tests.push(node.programs[0].status === statusFilter)
      }

      return tests.every((t) => t)
    })
    setFilteredSoftNodes(_softNodes || null)
    setFilteredNodes(_nodes || null)
  }, [userNodes, nodesFilter, statusFilter, userHistoryProjects])

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }
  }, [user])

  return (
    <Content>
      <Typography.PageTitle>
        {t('views.Nodes.Dashboard.title', 'Node manager')}
      </Typography.PageTitle>
      <FilterRow>
        <FilterDropdown
          onOptionClick={(e) => setNodesFilter(e)}
          options={[
            { value: null, content: 'All nodes' },
            { value: 'softnode', content: 'Soft Nodes' },
            { value: 'testnetvalidator', content: 'Testnet Validator Nodes' },
            { value: 'dtm_node', content: 'DTM Nodes' },
          ]}
          selectedOption={nodesFilter}
        />
        <FilterDropdown
          onOptionClick={(e) => setStatusFilter(e)}
          options={[
            { value: null, content: 'All statuses' },
            { value: 'active', content: 'Active' },
            { value: 'partial', content: 'Partial' },
            { value: 'inactive', content: 'Inactive' },
            { value: 'complete', content: 'Complete' },
          ]}
          selectedOption={statusFilter}
        />
      </FilterRow>
      {!userSession ? (
        <NoContent
          title="Manage nodes"
          text={'Sign in to manage nodes'}
          icon={<TreeStructure />}
        >
          <Button.Link
            variants={['outlined', 'right-icon']}
            className={styles.button}
            to={signInUrl}
          >
            {t('commmon.components.Header.signIn', 'Sign in')}
          </Button.Link>
        </NoContent>
      ) : userNodes.status === FetchStatus.PENDING ||
        userHistoryProjects.status === FetchStatus.PENDING ? (
        <PendingContent />
      ) : (
        <>
          <section>
            {showAddNodeContent && (
              <AddNodeModal
                onClickOutside={() => setShowAddNodeContent(false)}
                nodeType={nodeTypeToAdd}
                handleSuccess={handleSuccess}
              />
            )}
            {nodesFilter === 'softnode' ? (
              <>
                {filteredSoftNodes && filteredSoftNodes.length > 0 ? (
                  <GridContainer>
                    {filteredSoftNodes.map((node, idx) => (
                      <UserWalletSoftNodeCard key={idx} node={node} />
                    ))}
                  </GridContainer>
                ) : (
                  <NoContent />
                )}
              </>
            ) : nodesFilter !== 'softnode' &&
              userNodes.resource &&
              userNodes.resource.length > 0 ? (
              <>
                <GridContainer>
                  <>
                    {filteredSoftNodes &&
                      filteredSoftNodes.map((node, idx) => (
                        <UserWalletSoftNodeCard key={idx} node={node} />
                      ))}
                    {filteredNodes &&
                      filteredNodes.map((node) => (
                        <NodeCard key={node.name} node={node} />
                      ))}
                  </>
                </GridContainer>
                {filteredNodes?.length === 0 &&
                  filteredSoftNodes?.length === 0 && <NoContent />}
              </>
            ) : (
              <div className={styles.addNodeCardsContainer}>
                <AddNodeCard
                  title={'Soft Node'}
                  info={
                    'Add your soft node of 100 DAG or more to earn monthly rewards.'
                  }
                  onActionClick={() => {
                    setNodeTypeToAdd('softNode')
                    setShowAddNodeContent(true)
                  }}
                />
                <AddNodeCard
                  title={'DTM NFT'}
                  info={'Add your Dor Traffic Miner NFT to earn daily rewards.'}
                  onActionClick={() => navigate('/rewards')}
                />
                <AddNodeCard
                  title={'Testnet Node'}
                  info={
                    'Add your Testnet node to earn daily validator rewards.'
                  }
                  onActionClick={() => navigate('/rewards')}
                />
              </div>
            )}
          </section>
          <div className={styles.addNodeContainer}>
            <Button
              onClick={() => {
                setNodeTypeToAdd('softNode')
                setShowAddNodeContent(true)
              }}
              variants={['primary', 'right-icon']}
              rightIcon={<PlusCircle />}
            >
              {t('views.Nodes.texts.addNode', 'Add Node')}
            </Button>
          </div>
        </>
      )}
    </Content>
  )
}

export { NodesDashboardView }
