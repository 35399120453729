import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  Button,
  Content,
  createProviderStack,
  DetailsInfoCard,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  InfoRowsContainer,
  MainProjectCard,
  NoContent,
  PendingContent,
  ProgramDetailProjectContainer,
  ProgramDetailsTimerCard,
  Tabs,
  Typography,
} from '@lattice/common/components'
import {
  useOnChainStakingProvider,
  useWalletProvider,
  OnChainVendorProviders,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import {
  ProgramStatus,
  StatusColors,
} from '@lattice/common/consts/programStatus'
import { TitledHeader } from '@lattice/common/components/TitledHeaderCard'
import { InfoRow } from '@lattice/views/SwapBuyView/views/SwapExolixView/components/common/InfoRow'
import {
  dateRange,
  formatNumberAndCurrency,
  NumberFormat,
} from '@lattice/utils'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'

import styles from './view.module.scss'
import { AlkimiStakingActions } from './components'

const ViewProviderStack = createProviderStack()
ViewProviderStack.addProvider(OnChainVendorProviders.AlkimiStakingProvider, {})

const StakingProgramDetailView = ViewProviderStack.wrapComponent(() => {
  const params = useParams()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()

  const { project, requestProject } = useOnChainStakingProvider()
  const alkimiState = OnChainVendorProviders.useAlkimiStakingProvider()

  const [stakingPeriod, setStakingPeriod] = useState<string>()
  const [enrollingPeriod, setEnrollingPeriod] = useState<string>()
  const [timerLabel, setTimerLabel] = useState<string>()
  const [endDate, setEndDate] = useState<Date>()
  const [endDateFormated, setEndDateFormated] = useState<string>()
  const userActionOperation = useFetchableOperation()
  const userActionOperationProgressToasts = useProgressToasts()

  useEffect(() => {
    if (params.slug) {
      requestProject(params.slug)
    }
  }, [])

  useEffect(() => {
    if (project.resource?.stakingType) {
      alkimiState.requestProject(project.resource?.contractAddress)
    }
  }, [project.resource?.stakingType])

  useEffect(() => {
    if (
      alkimiState.project.resource &&
      activeWallet.status === 'connected' &&
      activeWallet.ethereum
    ) {
      alkimiState.requestProjectUser(
        alkimiState.project.resource,
        activeWallet.ethereum.account
      )
    }
  }, [alkimiState.project.resource, activeWallet])

  useEffect(() => {
    if (project.resource) {
      const stakingStartsAt = dayjs(project.resource.stakingStartsAt).toDate()
      const stakingWindowEndsAt = dayjs(
        project.resource.stakingWindowEndsAt
      ).toDate()
      const stakingEndsAt = dayjs(project.resource.stakingEndsAt).toDate()

      setStakingPeriod(dateRange(stakingStartsAt, stakingEndsAt))
      setEnrollingPeriod(dateRange(stakingStartsAt, stakingWindowEndsAt))

      if (['complete'].includes(project.resource.status)) {
        setTimerLabel('Staking ended on')
        setEndDate(undefined)
        setEndDateFormated(
          dayjs(project.resource.stakingEndsAt).format('MMM DD, YYYY')
        )
      }
      if (project.resource.status === 'in_progress') {
        setTimerLabel('Staking ends in')
        setEndDate(dayjs(project.resource.stakingEndsAt).toDate())
        setEndDateFormated(undefined)
      }
      if (project.resource.status === 'staking_open') {
        setTimerLabel('Enroll within')
        setEndDate(dayjs(project.resource.stakingWindowEndsAt).toDate())
        setEndDateFormated(undefined)
      }
      if (project.resource.status === 'coming_soon') {
        setTimerLabel('Enrollment starts on')
        setEndDate(undefined)
        setEndDateFormated(
          dayjs(project.resource.stakingStartsAt).format('MMM DD, YYYY')
        )
      }
    }
  }, [project])

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={
          project.resource && project.status === FetchStatus.DONE
            ? project.resource.name
            : ''
        }
        parentNameTitle={'Rewards Programs'}
      />
      {project.status === FetchStatus.PENDING && <PendingContent />}
      {project.status === FetchStatus.DONE && project.resource && (
        <ProgramDetailProjectContainer>
          <DetailsMainCardContainer>
            <MainProjectCard
              title={project.resource.name}
              bannerUrl={project.resource.bannerUrl}
              badge={{
                text: ProgramStatus[project.resource.status],
                color: StatusColors[project.resource.status],
              }}
            >
              <Tabs>
                <Tabs.Tab
                  label="Program info"
                  content={
                    project.resource ? (
                      <Typography.MarkdownContent>
                        {project.resource.summaryText}
                      </Typography.MarkdownContent>
                    ) : (
                      <NoContent />
                    )
                  }
                />
                <Tabs.Tab
                  label="How it works"
                  content={
                    project.resource ? (
                      <Typography.MarkdownContent>
                        {project.resource.howItWorks}
                      </Typography.MarkdownContent>
                    ) : (
                      <NoContent />
                    )
                  }
                />
              </Tabs>
            </MainProjectCard>
          </DetailsMainCardContainer>
          <DetailsSideCardsContainer>
            <ProgramDetailsTimerCard
              dateInfo={{
                endDate: endDate,
                endLabel: timerLabel ?? '',
                formatedEndDate: endDateFormated,
              }}
            >
              <TitledHeader>Program details</TitledHeader>
              <InfoRowsContainer>
                <InfoRow
                  leftSide={'Enrollment window'}
                  rightSide={enrollingPeriod!}
                  className={{
                    leftSide: styles.leftSideFont,
                    rightSide: styles.rightSideFont,
                  }}
                />
                <InfoRow
                  leftSide={'Staking period'}
                  rightSide={stakingPeriod!}
                  className={{
                    leftSide: styles.leftSideFont,
                    rightSide: styles.rightSideFont,
                  }}
                />
                {alkimiState.project.resource && (
                  <InfoRow
                    leftSide={'Lockup period'}
                    rightSide={`${Math.floor(
                      dayjs
                        .duration(
                          dayjs(alkimiState.project.resource.lockupEndsAt).diff(
                            dayjs(
                              alkimiState.project.resource.stakingWindowEndsAt
                            )
                          )
                        )
                        .as('days')
                    )} days`}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                )}
                {alkimiState.project.resource && (
                  <InfoRow
                    leftSide={'Total amount staked'}
                    rightSide={formatNumberAndCurrency(
                      alkimiState.project.resource.totalStaked,
                      alkimiState.project.resource.token.symbol,
                      NumberFormat.MILLIFY_EXPANDED
                    )}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                )}
              </InfoRowsContainer>
            </ProgramDetailsTimerCard>
            {project.resource && (
              <>
                {(activeWallet.status === 'disconnected' ||
                  (activeWallet.status === 'connected' &&
                    !activeWallet.ethereum)) && (
                  <DetailsInfoCard
                    label={'Connect your Wallet'}
                    actionElement={
                      <Button
                        variants={['full-width', 'secondary', 'right-icon']}
                        onClick={() => requestConnectorActivation()}
                      >
                        Connect your wallet
                      </Button>
                    }
                    variants={['full-width']}
                  />
                )}
                {project.resource.stakingType === 'alkimi' &&
                  activeWallet.status === 'connected' &&
                  activeWallet.ethereum && (
                    <AlkimiStakingActions
                      userActionOperation={userActionOperation}
                      userActionOperationProgressToasts={
                        userActionOperationProgressToasts
                      }
                    />
                  )}
              </>
            )}
          </DetailsSideCardsContainer>
        </ProgramDetailProjectContainer>
      )}
    </Content>
  )
})

export { StakingProgramDetailView }
