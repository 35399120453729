import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import cls from 'classnames'

import { ReactComponent as CheckboxOffIcon } from '@lattice/assets/icons/carbon/checkbox-off.svg'
import { ReactComponent as CheckboxOnIcon } from '@lattice/assets/icons/carbon/checkbox-on-filled.svg'

import styles from './component.module.scss'

const CheckboxField = React.forwardRef<
  HTMLInputElement,
  {
    className?: {
      root?: string
      iconHolder?: string
    }
  } & JSX.IntrinsicElements['input']
>(({ disabled, className, ...props }, ref) => {
  const innerRef = useRef<HTMLInputElement>(null)
  const inputname = useMemo(() => window.btoa(String(Math.random())), [])
  const forceRender = useReducer((s) => s + 1, 0)[1]

  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.addEventListener('change', forceRender)
      return () => {
        innerRef.current?.removeEventListener('change', forceRender)
      }
    }
  }, [innerRef.current])

  return (
    <div className={cls(styles.root, className?.root)}>
      <label
        className={cls(
          styles.iconHolder,
          innerRef.current?.checked && styles.on,
          disabled && styles.disabled,
          className?.iconHolder
        )}
        htmlFor={inputname}
      >
        {innerRef.current?.checked ? (
          <CheckboxOnIcon width={20} height={20} />
        ) : (
          <CheckboxOffIcon width={20} height={20} />
        )}
      </label>
      <input
        {...props}
        disabled={disabled}
        className={cls(styles.input)}
        ref={innerRef}
        id={inputname}
        type="checkbox"
      />
    </div>
  )
})

export { CheckboxField }
