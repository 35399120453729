import React from 'react'

import styles from './component.module.scss'

const Title = ({
  order,
  children,
}: {
  order?: 1 | 2 | 3 | 4 | 5 | 6
  children?: React.ReactNode
}) => {
  const HeadComponent = `h${order ?? 1}` as keyof JSX.IntrinsicElements

  return <HeadComponent className={styles.main}>{children}</HeadComponent>
}

export { Title }
