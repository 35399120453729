import React from 'react'
import { QueryClientProvider as TSQueryClientProvider } from '@tanstack/react-query'

import { queryClient } from './config'

const TanStackQueryProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <TSQueryClientProvider client={queryClient}>
      {children}
    </TSQueryClientProvider>
  )
}

export { TanStackQueryProvider }
