// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uv8RpoYD9qa9lcu60PM9{display:flex;gap:8px;color:rgba(255,255,255,.96)}`, "",{"version":3,"sources":["webpack://./src/common/components/CardLabelContainer/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,2BAAA","sourcesContent":[".main {\n  display: flex;\n  gap: 8px;\n  color: rgba(#fff, 0.96);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Uv8RpoYD9qa9lcu60PM9`
};
export default ___CSS_LOADER_EXPORT___;
