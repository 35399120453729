import React, { Dispatch, SetStateAction, useState } from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'
import { useTranslation } from 'react-i18next'

import { Button } from '@lattice/components/common/Button'
import { GeneralColor } from '@lattice/components/common/Consts'
import { CheckboxField } from '@lattice/common/components'

import styles from '../styles.module.scss'
import { InfoRow } from '../common/InfoRow'
import { RecipientData } from '../../types'

const RecipientContent = ({
  data,
  handleRecipientAddress,
  creatingTx,
}: {
  data: RecipientData
  handleRecipientAddress: (address: string, memo: string | undefined) => void
  creatingTx: boolean
}) => {
  const { t } = useTranslation()
  const [recipientAddress, setRecipientAddress] = useState<string | undefined>(
    undefined
  )
  const [recipientMemo, setRecipientMemo] = useState<string | undefined>(
    undefined
  )
  const [userAgreed, setUserAgreed] = useState(false)
  const [isInvalidAddress, setIsInvalidAddress] = useState(false)
  const [isInvalidMemo, setIsInvalidMemo] = useState(false)

  const handleInput = (
    regex: string | null,
    setValue: Dispatch<SetStateAction<string | undefined>>,
    setIsInvalid: Dispatch<SetStateAction<boolean>>,
    value: string
  ) => {
    if (value === '') {
      setValue(undefined)
      setIsInvalid(false)
      return
    }
    if (!regex) {
      setValue(value)
      setIsInvalid(false)
      return
    }

    const isValid = new RegExp(regex)

    if (isValid.test(value)) {
      setValue(value)
      setIsInvalid(false)
    } else {
      setValue(value)
      setIsInvalid(true)
    }
  }

  return (
    <>
      <div className={styles.swapInputs}>
        <div className={styles.dataColumn}>
          <div
            className={cls(styles.inputLabel, isInvalidAddress && styles.red)}
          >
            {t(
              'components.ExolixSwap.RecipientContent.labels.recipientAddress',
              'Recipient address'
            )}{' '}
            {isInvalidAddress && '(invalid)'}
          </div>
          <div className={styles.inputRow}>
            <input
              className={cls(
                styles.inputAmount,
                styles.pointer,
                styles.fullWidth,
                isInvalidAddress && styles.red
              )}
              type={'text'}
              placeholder={t(
                'components.ExolixSwap.RecipientContent.inputs.inputAddress',
                `Enter the recipient's ${data.coinTo} address`,
                {
                  coinCode: data.coinTo,
                }
              )}
              onChange={(e) =>
                handleInput(
                  data.receivingNetwork.addressRegex,
                  setRecipientAddress,
                  setIsInvalidAddress,
                  e.target.value
                )
              }
            />
          </div>
          {data.receivingNetwork.memoNeeded && (
            <>
              <div className={styles.inputLabel}>
                {t(
                  'components.ExolixSwap.RecipientContent.inputs.memoWarning',
                  `This swap may require a memo`
                )}
              </div>
              <div className={styles.inputRow}>
                <input
                  className={cls(
                    styles.inputAmount,
                    styles.pointer,
                    styles.fullWidth,
                    isInvalidMemo && styles.red
                  )}
                  type={'text'}
                  placeholder={t(
                    'components.ExolixSwap.RecipientContent.inputs.inputMemo',
                    `Enter memo`
                  )}
                  onChange={(e) =>
                    handleInput(
                      data.receivingNetwork.memoRegex,
                      setRecipientMemo,
                      setIsInvalidMemo,
                      e.target.value
                    )
                  }
                />
              </div>
              <div className={styles.sendingInfo}>
                <CheckboxField
                  onClick={() => {
                    setUserAgreed((state) => !state)
                  }}
                />
                <span className={styles.inputLabel}>
                  {t(
                    'components.ExolixSwap.RecipientContent.inputs.checkboxWarning',
                    'I agree that the absence of the memo can lead to a loss of funds.'
                  )}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.swapInfo}>
        <InfoRow
          leftSide={'You send'}
          rightSide={`${data.rate.fromAmount} ${data.coinFrom}`}
        />
        <InfoRow
          leftSide={'You receive'}
          rightSide={`${data.rate.toAmount} ${data.coinTo}`}
        />
      </div>
      <div className={styles.swapButtonContainer}>
        <Button
          className={styles.swapButton}
          onClick={() =>
            recipientAddress &&
            !isInvalidAddress &&
            !isInvalidMemo &&
            ((data.receivingNetwork.memoNeeded && userAgreed) ||
              !data.receivingNetwork.memoNeeded) &&
            handleRecipientAddress(recipientAddress, recipientMemo)
          }
        >
          {!creatingTx && 'Swap'}
          {creatingTx && (
            <ImpulseSpinner
              size={24}
              frontColor={GeneralColor.USC_GOLD.toString()}
            />
          )}
        </Button>
      </div>
    </>
  )
}

export { RecipientContent }
