import React from 'react'

import { Button } from '@lattice/common/components/Button'

const NavLink = ({
  to,
  text,
  className,
}: {
  to: string
  text: string
  className?: (isActive: any) => string | string
}) => {
  return (
    <Button.NavLink
      to={to}
      className={{
        main: className,
      }}
    >
      {text}
    </Button.NavLink>
  )
}

export { NavLink }
