import { createProviderStateHook } from '@lattice/utils'

import { DtmNodesProvider, DtmNodesProviderContext } from './provider'

const useDtmNodesProvider = createProviderStateHook(
  DtmNodesProvider,
  DtmNodesProviderContext
)

export { useDtmNodesProvider }
