import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { apiRequest, APIError } from '@lattice/utils'
import { FetchStatus } from '@lattice/common/consts'

import { ISoftNodesProjectStatus } from '../SoftNodesProvider'

import {
  SoftStakingProjectDataType,
  NodeUserDataType,
  ISoftStakingProviderContext,
} from './types'

const SoftStakingProviderContext =
  React.createContext<ISoftStakingProviderContext | null>(null)

const SoftStakingProvider = ({ children }: { children: React.ReactNode }) => {
  const [projects, setProjects] = useState<SoftStakingProjectDataType[]>([])

  const [project, setProject] = useState<SoftStakingProjectDataType | null>(
    null
  )
  const [projectFetchStatus, setProjectFetchStatus] = useState<FetchStatus>(
    FetchStatus.IDLE
  )

  const [nodeUser, setNodeUser] = useState<NodeUserDataType | null>(null)
  const [nodeUserFetchStatus, setNodeUserFetchStatus] = useState<FetchStatus>(
    FetchStatus.IDLE
  )

  const requestProjects = async () => {
    try {
      const { data } = await apiRequest({
        method: 'GET',
        endpoint: '/softstaking-projects',
      })
      setProjects(data)
    } catch (err) {
      console.log(err)
    }
  }

  const requestProject = async (projectId: string) => {
    try {
      setProjectFetchStatus(FetchStatus.PENDING)
      const { data } = await apiRequest({
        method: 'GET',
        endpoint: `/softstaking-projects/${projectId}`,
      })
      setProject(data)
      setProjectFetchStatus(FetchStatus.DONE)
    } catch (err) {
      if (err instanceof APIError) {
        if (err.errorCode === 404) {
          setProjectFetchStatus(FetchStatus.NOT_FOUND)
          return
        }
      }

      setProjectFetchStatus(FetchStatus.SERVER_ERROR)
      throw err
    }
  }

  const requestNodeUser = async (projectId: string) => {
    try {
      setNodeUserFetchStatus(FetchStatus.PENDING)
      const { data } = await apiRequest({
        method: 'GET',
        endpoint: `/softstaking-projects/${projectId}/user`,
        isAuthenticated: true,
      })
      setNodeUser(data)
      setNodeUserFetchStatus(FetchStatus.DONE)
    } catch (err) {
      if (err instanceof APIError) {
        if (err.errorCode === 404) {
          setNodeUserFetchStatus(FetchStatus.NOT_FOUND)
          return
        }
      }

      setNodeUserFetchStatus(FetchStatus.SERVER_ERROR)
      throw err
    }
  }

  const stakeWallet = async (
    projectId: string,
    address: string,
    amountToStake: number,
    signature: {
      hex: string
      signedMessage: string
      type?: 'general' | 'stargazer'
    },
    walletVendorTrace: string
  ) => {
    try {
      const { data } = await apiRequest({
        method: 'POST',
        endpoint: `/softstaking-projects/${projectId}/stake`,
        isAuthenticated: true,
        body: {
          address,
          amountToStake,
          signature,
          walletVendorTrace,
        },
      })
      await requestProject(projectId)
      setNodeUser(data)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  useEffect(() => {
    requestProjects()
  }, [])

  // Window Reload
  useEffect(() => {
    const watches: string[] = []

    const check = () => {
      for (const watchDateIso of watches) {
        if (
          dayjs().isAfter(watchDateIso) &&
          dayjs().diff(watchDateIso, 'd') < 1
        ) {
          window.location.reload()
        }
      }
    }

    const checkerId = setInterval(check, 1000)

    const scheduleProject = (project: SoftStakingProjectDataType) => {
      if (project.status === ISoftNodesProjectStatus.COMING_SOON) {
        watches.push(project.stakingStartsAt)
      }
      if (project.status === ISoftNodesProjectStatus.STAKING_OPEN) {
        watches.push(project.stakingWindowEndsAt)
      }
      if (project.status === ISoftNodesProjectStatus.IN_PROGRESS) {
        watches.push(project.stakingEndsAt)
      }
    }

    if (project) {
      scheduleProject(project)
    } else {
      projects.forEach(scheduleProject)
    }

    return () => {
      window.clearInterval(checkerId)
    }
  }, [project, projects])

  const ctx: ISoftStakingProviderContext = {
    projects,
    project,
    projectFetchStatus,
    nodeUser,
    nodeUserFetchStatus,
    requestProject,
    requestNodeUser,
    stakeWallet,
  }

  return (
    <SoftStakingProviderContext.Provider value={ctx}>
      {children}
    </SoftStakingProviderContext.Provider>
  )
}

export { SoftStakingProvider, SoftStakingProviderContext }
