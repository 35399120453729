import React from 'react'

import { useTokenPrices } from '@lattice/common/providers'

import { CurrencyTicker } from '../CurrencyTicker'

import styles from './component.module.scss'

const FEATURED_PRICES: (keyof ReturnType<typeof useTokenPrices>)[] = [
  'btc',
  'eth',
  'dag',
  'ltx',
]

const NavigationHeader = () => {
  return (
    <div className={styles.navigationHeader}>
      <div className={styles.leftWrapper}>
        <div className={styles.coinList}>
          {FEATURED_PRICES.map((coinId) => (
            <div key={coinId} className={styles.coinWrapper}>
              <CurrencyTicker coinId={coinId} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { NavigationHeader }
