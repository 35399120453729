// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WA2bc7XxyGxQExEsQ5nN{display:flex;height:60px;justify-content:space-between;align-items:center;padding:18px 24px;color:#fff;font-size:18px;line-height:24px}.WA2bc7XxyGxQExEsQ5nN svg{cursor:pointer}.fS1IeXcr5yTK1K8BWgNn{display:flex;padding:24px;flex-flow:column nowrap}.V28aedgeur6JP7MCqwBg{display:flex;width:100%;flex-flow:column nowrap;row-gap:16px}.uyAktc3KXMw6OTeNmm_H{color:#fff;font-weight:200;font-size:12px;line-height:18px;padding:0px 8px}.uyAktc3KXMw6OTeNmm_H a{text-decoration:none;color:#fc0}`, "",{"version":3,"sources":["webpack://./src/common/components/ConnectWalletCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAEA,UAAA,CACA,cAAA,CACA,gBAAA,CAEA,0BACE,cAAA,CAIJ,sBACE,YAAA,CAEA,YAAA,CACA,uBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,uBAAA,CACA,YAAA,CAGF,sBACE,UAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAEA,wBACE,oBAAA,CACA,UAAA","sourcesContent":[".header {\n  display: flex;\n  height: 60px;\n\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 24px;\n\n  color: #fff;\n  font-size: 18px;\n  line-height: 24px;\n\n  svg {\n    cursor: pointer;\n  }\n}\n\n.body {\n  display: flex;\n\n  padding: 24px;\n  flex-flow: column nowrap;\n}\n\n.walletList {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n  row-gap: 16px;\n}\n\n.newEthereumMsg {\n  color: #fff;\n  font-weight: 200;\n  font-size: 12px;\n  line-height: 18px;\n  padding: 0px 8px;\n\n  a {\n    text-decoration: none;\n    color: #fc0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `WA2bc7XxyGxQExEsQ5nN`,
	"body": `fS1IeXcr5yTK1K8BWgNn`,
	"walletList": `V28aedgeur6JP7MCqwBg`,
	"newEthereumMsg": `uyAktc3KXMw6OTeNmm_H`
};
export default ___CSS_LOADER_EXPORT___;
