// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slAKn6gHCmQGUQ58QN_o{display:inline-flex;position:relative;justify-content:center;align-items:center}.vCYj96ItyOQWwDZaC40K{display:flex;justify-content:center;align-items:center;cursor:pointer;color:rgba(255,255,255,.66)}.vCYj96ItyOQWwDZaC40K.Aj9xBQlrhU6iEVtHCnAP{cursor:default;opacity:.66}.vCYj96ItyOQWwDZaC40K.yDCEmF5jIQh3WqzrcH7O{color:#fc0}.grLnRwNEynFdmyXrWThT{position:absolute;width:.1px;height:.1px;visibility:hidden;z-index:-1}`, "",{"version":3,"sources":["webpack://./src/common/components/CheckboxField/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,iBAAA,CAEA,sBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CAEA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,2BAAA,CAEA,2CACE,cAAA,CACA,WAAA,CAGF,2CACE,UAAA,CAIJ,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,UAAA","sourcesContent":[".root {\n  display: inline-flex;\n  position: relative;\n\n  justify-content: center;\n  align-items: center;\n}\n\n.iconHolder {\n  display: flex;\n\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n  color: rgba(#fff, 0.66);\n\n  &.disabled {\n    cursor: default;\n    opacity: 0.66;\n  }\n\n  &.on {\n    color: #fc0;\n  }\n}\n\n.input {\n  position: absolute;\n  width: 0.1px;\n  height: 0.1px;\n  visibility: hidden;\n\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `slAKn6gHCmQGUQ58QN_o`,
	"iconHolder": `vCYj96ItyOQWwDZaC40K`,
	"disabled": `Aj9xBQlrhU6iEVtHCnAP`,
	"on": `yDCEmF5jIQh3WqzrcH7O`,
	"input": `grLnRwNEynFdmyXrWThT`
};
export default ___CSS_LOADER_EXPORT___;
