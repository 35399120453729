import React, { useEffect, useState } from 'react'
import cls from 'classnames'

import { ReactComponent as Checkmark } from '@lattice/assets/images/svgs/checkmark.svg'
import { apiRequest } from '@lattice/utils'
import { ExecutionContext } from '@lattice/common/consts'

import styles from './view.module.scss'
import { InitialContent } from './components/InitialContent'
import { RecipientContent } from './components/RecipientContent'
import { DepositContent } from './components/DepositContent'
import { LastContent } from './components/LastContent'
import {
  ExolixNetwork,
  ExolixRate,
  ExolixTransactionResponse,
  RecipientData,
} from './types'

const SwapExolixView = () => {
  const [step, setStep] = useState(1)
  const [recipientData, setRecipientData] = useState<RecipientData>()
  const [recipientAddress, setRecipientAddress] = useState<string>()
  const [recipientMemo, setRecipientMemo] = useState<string | undefined>()
  const [creatingTx, setCreatingTx] = useState(false)
  const [transactionResponse, setTransactionResponse] = useState<
    ExolixTransactionResponse & { overdueDate: string }
  >()
  const [updatedData, setUpdatedData] = useState<ExolixTransactionResponse>()

  const handleSelectedCurrencies = (
    coinFrom: string,
    coinTo: string,
    rate: ExolixRate,
    receivingNetwork: ExolixNetwork,
    sendingNetwork: ExolixNetwork
  ) => {
    setRecipientData({
      coinFrom,
      coinTo,
      rate,
      receivingNetwork,
      sendingNetwork,
    })
    setStep(2)
  }
  const handleRecipientAddress = (
    address: string,
    memo: string | undefined
  ) => {
    setRecipientAddress(address)
    setRecipientMemo(memo)
  }
  const handleDeposit = (data: ExolixTransactionResponse) => {
    setUpdatedData(data)
    setStep(4)
  }

  const resetState = () => {
    setRecipientData(undefined)
    setRecipientAddress(undefined)
    setRecipientMemo(undefined)
    setCreatingTx(false)
    setTransactionResponse(undefined)
    setStep(1)
  }

  const createTransaction = async (
    recipientData: RecipientData,
    recipientAddress: string,
    recipientMemo: string | undefined
  ) => {
    let data
    try {
      ;({ data } = await apiRequest({
        method: 'POST',
        headers: {
          'x-lattice-api-key': ExecutionContext.infra.sharedKey,
        },
        endpoint: '/swapping/transactions',
        body: {
          coinFrom: recipientData.coinFrom,
          coinTo: recipientData.coinTo,
          amount: recipientData.rate.fromAmount,
          withdrawalAddress: recipientAddress,
          networkFrom: recipientData.sendingNetwork.network,
          networkTo: recipientData.receivingNetwork.network,
          withdrawalExtraId: recipientMemo ?? '',
          rateType: 'fixed',
          refundAddress: null,
          refundExtraId: null,
        },
      }))
      setTransactionResponse(data)
      setCreatingTx(false)
      setStep(3)
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    if (!recipientData || !recipientAddress) {
      return
    }
    setCreatingTx(true)
    createTransaction(recipientData, recipientAddress, recipientMemo)
  }, [recipientAddress])

  return (
    <div className={styles.swapBox}>
      <div className={styles.swapHeader}>
        <div className={styles.swapHeaderRow}>
          <div className={styles.headerTitle}>Swap</div>
          <div className={styles.swapSteps}>
            <div className={cls(styles.step, { [styles.active]: step === 1 })}>
              {step > 1 ? <Checkmark /> : 1}
            </div>
            <div className={styles.separator}></div>
            <div className={cls(styles.step, { [styles.active]: step === 2 })}>
              {step > 2 ? <Checkmark /> : 2}
            </div>
            <div className={styles.separator}></div>
            <div className={cls(styles.step, { [styles.active]: step === 3 })}>
              {step > 3 ? <Checkmark /> : 3}
            </div>
            <div className={styles.separator}></div>
            <div className={cls(styles.step, { [styles.active]: step === 4 })}>
              4
            </div>
          </div>
        </div>
      </div>
      <div className={styles.swapContent}>
        {step === 1 && (
          <InitialContent handleSelectedCurrencies={handleSelectedCurrencies} />
        )}
        {step === 2 && (
          <RecipientContent
            data={recipientData!}
            handleRecipientAddress={handleRecipientAddress}
            creatingTx={creatingTx}
          />
        )}
        {step === 3 && (
          <DepositContent
            txData={transactionResponse!}
            handleDeposit={handleDeposit}
            cancelSwap={resetState}
          />
        )}
        {step === 4 && (
          <LastContent handleLastStep={resetState} data={updatedData!} />
        )}
      </div>
    </div>
  )
}

export { SwapExolixView }
