import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import Decimal from 'decimal.js'

import {
  Badge,
  BadgeColors,
  Button,
  HorizontalBar,
  InlineStat,
  InlineStats,
  NoContentContainer,
  ProjectDetailsManageSection,
} from '@lattice/common/components'
import { formatFracPercentage } from '@lattice/views/NodeManagerView/utils'
import { formatNumber, NumberFormat } from '@lattice/utils'
import { ISoftNodesWalletNodeWithRewards } from '@lattice/common/providers'

import styles from './component.module.scss'

const renderWallet = (
  wallet: ISoftNodesWalletNodeWithRewards,
  nodePrice: number,
  index: number,
  showSmallNodes: boolean,
  t: TFunction,
  goToNode: (alias: string) => void
) => {
  const isNodeActive =
    new Decimal(wallet.node.currentFracBalance).gt(0) ||
    wallet.node.validShards > 0

  const status = isNodeActive ? 'active' : 'inactive'
  const nodeStatus = styles[status]
  const nodeBoxType = styles.nodeBox

  const activeShards = wallet.node.validShards
  const totalShards = wallet.node.totalShards

  const getShardsText = () => {
    return activeShards + ' of ' + totalShards
  }

  const getNodeDetail = (_type: string) => {
    return (
      <div
        data-cy="node-detail"
        className={cls(styles.nodeDetail, styles[_type], nodeStatus)}
        onClick={() => goToNode(wallet.node.alias ?? undefined)}
      >
        <div className={cls(styles.nodeNumber)}>{index + 1}</div>
        <div>
          <div>
            <span>{t('components.SoftNodeHistoryList.alias', 'Alias')}:</span>{' '}
            {wallet.node.alias ?? '--'}
          </div>
          <div>
            <span>{t('components.SoftNodeHistoryList.shards', 'Shards')}:</span>{' '}
            {getShardsText()}
          </div>
          <div>
            <span>
              {t('components.SoftNodeHistoryList.fracShards', 'Frac. Shards')}:
            </span>{' '}
            {formatFracPercentage(
              new Decimal(wallet.node.initialFracBalance)
                .div(nodePrice)
                .mul(100)
                .toNumber()
                .toFixed(2),
              new Decimal(wallet.node.currentFracBalance)
                .div(nodePrice)
                .mul(100)
                .toNumber()
                .toFixed(2)
            )}
          </div>
        </div>
      </div>
    )
  }

  if (!showSmallNodes) {
    return (
      <div
        data-tip={wallet.node.id}
        data-for={wallet.node.id}
        className={cls(styles.node, nodeStatus)}
        key={wallet.node.id}
      >
        {getNodeDetail('big')}
      </div>
    )
  }

  return (
    <div
      data-cy="small-node"
      data-tip={wallet.node.id}
      data-for={wallet.node.id}
      key={wallet.node.id}
      className={cls(nodeBoxType, nodeStatus)}
    >
      {showSmallNodes ? null : index + 1}
    </div>
  )
}

const ManageNodes = ({
  wallets,
  totalShards,
  fracShards,
  rewards,
  nodePrice,
  isInEnrollingPeriod,
}: {
  wallets: ISoftNodesWalletNodeWithRewards[]
  totalShards: number
  fracShards: string
  rewards: number
  nodePrice: number
  isInEnrollingPeriod: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showSmallNodes = wallets.length > 20

  const goToNode = (alias: string) => {
    if (!alias) {
      return
    }
    navigate('/nodes/soft-node/' + alias)
  }

  return (
    <div className={styles.nodeHistoryGrid}>
      <ProjectDetailsManageSection>
        <InlineStats>
          <InlineStat label="Total Nodes" value={wallets.length} />
          <InlineStat label="Total Shards" value={totalShards} />
          <InlineStat
            label="Frac. Shards"
            value={
              <div className={styles.inlineStatFlex}>
                {fracShards === '0.00%' ? '0' : fracShards}
              </div>
            }
          />

          <InlineStat
            label="Accrued Rewards"
            value={
              <div className={styles.inlineStatFlex}>
                {!isInEnrollingPeriod
                  ? formatNumber(rewards / 1e8, NumberFormat.MILLIFY)
                  : '--'}
                <Badge
                  text={'DAG'}
                  color={BadgeColors.green}
                  className={{ container: styles.greenBadgeContainer }}
                />
              </div>
            }
          />
        </InlineStats>
        <HorizontalBar />
      </ProjectDetailsManageSection>
      {!wallets.length && !totalShards && !fracShards && !rewards && (
        <NoContentContainer>
          <div>
            {t(
              'views.RewardsViews.views.SoftNodeProgramDetail.components.ManageNodes.noContentMessage',
              'Add and manage your first soft node in Node Manager.'
            )}
          </div>
          <Button.Link to={'/nodes'} variants={['outlined', 'right-icon']}>
            {t(
              'views.RewardsViews.views.SoftNodeProgramDetail.components.ManageNodes.nodeManager',
              'Node Manager'
            )}
          </Button.Link>
        </NoContentContainer>
      )}
      <div
        className={cls(
          styles.content,
          showSmallNodes ? styles.small : styles.large
        )}
      >
        {wallets.map((wallet, index) => {
          return renderWallet(
            wallet,
            nodePrice,
            index,
            showSmallNodes,
            t,
            goToNode
          )
        })}
      </div>
    </div>
  )
}

export { ManageNodes }
