import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { BaseCard } from '@lattice/common/components'

import styles from './view.module.scss'
import { LatestTerms } from './providers/TermsOfServiceProvider'

const TermsOfService = () => {
  const { t } = useTranslation()
  const header = (
    <div className={styles.header}>
      {t('components.TermsOfServiceModal.panel.title', 'Terms of Service')}
    </div>
  )
  return (
    <div className={styles.termsContainer}>
      <BaseCard
        header={header}
        className={{
          root: styles.rootCard,
          body: cls(styles.bodyCard, styles.content),
        }}
      >
        <LatestTerms />
      </BaseCard>
    </div>
  )
}

export { TermsOfService }
