import React, { useState } from 'react'

import { ConfirmActionModal } from '@lattice/common/components'
import { Awaitable } from '@lattice/utils'

import { IConfirmActionConfig, IConfirmActionProviderContext } from './types'

export const ConfirmActionProviderContext =
  React.createContext<IConfirmActionProviderContext | null>(null)

export const ConfirmActionProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [currentConfirmation, setCurrentConfirmation] = useState<{
    config: IConfirmActionConfig
    process: Awaitable<boolean>
  } | null>()

  const context: IConfirmActionProviderContext = {
    requestConfirmation: (config) => {
      const process = new Awaitable<boolean>()

      setCurrentConfirmation({ config, process })

      return process
    },
    resolveCurrentConfirmation: async (value: boolean) => {
      if (currentConfirmation) {
        currentConfirmation.process.resolve(value)
        setCurrentConfirmation(null)
      }
    },
  }

  return (
    <ConfirmActionProviderContext.Provider value={context}>
      {currentConfirmation && (
        <ConfirmActionModal
          title={currentConfirmation.config.title}
          description={currentConfirmation.config.description}
          negativeButtonContent={
            currentConfirmation.config.negativeButtonContent
          }
          onNegativeButtonClick={() =>
            context.resolveCurrentConfirmation(false)
          }
          positiveButtonContent={
            currentConfirmation.config.positiveButtonContent
          }
          onPositiveButtonClick={() => context.resolveCurrentConfirmation(true)}
          onClose={() => context.resolveCurrentConfirmation(false)}
        />
      )}
      {children}
    </ConfirmActionProviderContext.Provider>
  )
}
