import { createProviderStateHook } from '@lattice/utils'

import { SoftStakingProvider, SoftStakingProviderContext } from './provider'

const useSoftStakingProvider = createProviderStateHook(
  SoftStakingProvider,
  SoftStakingProviderContext
)

export { useSoftStakingProvider }
