import { sepolia, mainnet, polygon, polygonMumbai } from 'wagmi/chains'
import { createConfig, http } from 'wagmi'

import {
  ChainInfo,
  AvailableChain,
  AvailableConnectors,
} from '@lattice/common/consts'

declare module 'wagmi' {
  interface Register {
    config: typeof WagmiConfig
  }
}

const WagmiConfig = createConfig({
  chains: [mainnet, sepolia, polygon, polygonMumbai],
  transports: {
    [mainnet.id]: http(ChainInfo[AvailableChain.ETHEREUM_MAINNET].rpcEndpoint),
    [sepolia.id]: http(ChainInfo[AvailableChain.ETHEREUM_SEPOLIA].rpcEndpoint),
    [polygon.id]: http(ChainInfo[AvailableChain.POLYGON_MAINNET].rpcEndpoint),
    [polygonMumbai.id]: http(
      ChainInfo[AvailableChain.POLYGON_MUMBAI].rpcEndpoint
    ),
  },
  connectors: Object.values(AvailableConnectors).map(
    (connectorInfo) => connectorInfo.connector
  ),
})

export { WagmiConfig }
