import * as ethers from 'ethers'
import { useWeb3React as useWeb3ReactHook } from '@web3-react/core'

const buildWeb3Library = (
  provider:
    | ethers.providers.ExternalProvider
    | ethers.providers.JsonRpcFetchFunc
): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

const useWeb3React = (...args: Parameters<typeof useWeb3ReactHook>) =>
  useWeb3ReactHook<ethers.providers.Web3Provider>(...args)

export { buildWeb3Library, useWeb3React }
