import Decimal from 'decimal.js'

import { FetchStatus, PaginatedFetchStatusValue } from '@lattice/common/consts'
import { APIError, apiRequest, getNetworkTypedContract } from '@lattice/utils'
import { NetworkCurrencies } from '@lattice/utils/networkCurrencies'
import { PaginatedRequestContext } from '@lattice/common/hooks'

import {
  IAirdrop,
  IAirdropAllocation,
  IAirdropAllocationAndAirdrop,
  IAirdropAllocationClaimStatus,
  IAirdropAllocationProof,
} from './types'

const requestAirdrops = async (
  context: PaginatedRequestContext,
  address?: string
) => {
  try {
    const { data, meta } = await apiRequest({
      method: 'GET',
      endpoint: '/airdrops',
      parameters: { address, ...context },
    })

    return {
      status: FetchStatus.DONE,
      value: data,
      total: meta.total,
    } satisfies PaginatedFetchStatusValue<IAirdrop[]>
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestAirdropsAndAvailableAmounts = async (
  context: PaginatedRequestContext,
  address?: string
) => {
  try {
    const { data, meta } = await apiRequest({
      method: 'GET',
      endpoint: '/airdrops',
      parameters: { address, ...context },
    })

    for (const airdrop of data) {
      if (!airdrop.allocations || airdrop.allocations.length === 0) {
        continue
      }

      if (airdrop.type !== 'ethereum-based') {
        continue
      }

      const contract = getNetworkTypedContract(
        'LatticeAirdropsDistributor',
        airdrop.contractAddress,
        NetworkCurrencies[airdrop.rewardCurrency].network
      )

      for (const allocation of airdrop.allocations) {
        const amountAvailable = await contract.amountAvailableToClaim(
          airdrop.contractAirdropId,
          allocation.address,
          new Decimal(allocation.rewardAmount)
            .mul(NetworkCurrencies[airdrop.rewardCurrency].decimalFactor)
            .floor()
            .toFixed(),
          true
        )
        allocation.amountAvailable = new Decimal(amountAvailable.toString())
          .div(NetworkCurrencies[airdrop.rewardCurrency].decimalFactor)
          .toFixed()
      }
    }

    return {
      status: FetchStatus.DONE,
      value: data,
      total: meta.total,
    } satisfies PaginatedFetchStatusValue<IAirdrop[]>

    return data
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestAirdrop = async (airdropId: string, address?: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/airdrops/${airdropId}`,
      parameters: { address },
    })
    return data as IAirdrop
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestAirdropAllocation = async (airdropId: string, address: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/airdrops/${airdropId}/allocation/${address}`,
    })
    return data as IAirdropAllocation
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestAirdropAllocationProof = async (
  airdropId: string,
  address: string
) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/airdrops/${airdropId}/allocation/${address}/proof`,
    })
    return data as IAirdropAllocationProof
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestAirdropAllocationClaim = async (
  airdropId: string,
  address: string,
  signature: string,
  message: string
) => {
  try {
    const { data } = await apiRequest({
      method: 'POST',
      endpoint: `/airdrops/${airdropId}/allocation/${address}/claim`,
      body: { claimSignature: { address, signature, message } },
      isAuthenticated: true,
    })
    return data as IAirdropAllocation
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestAirdropAllocationClaimStatus = async (
  airdropId: string,
  address: string
) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/airdrops/${airdropId}/allocation/${address}/claim-status`,
      isAuthenticated: true,
    })
    return data as IAirdropAllocationClaimStatus
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const airdropsToFullAllocations = (airdrops: IAirdrop[]) => {
  const allocations: IAirdropAllocationAndAirdrop[] = []

  for (const airdrop of airdrops) {
    for (const allocation of airdrop.allocations ?? []) {
      allocations.push(Object.assign({}, allocation, { airdrop }))
    }
  }

  return allocations
}

export {
  requestAirdrops,
  requestAirdropsAndAvailableAmounts,
  requestAirdrop,
  requestAirdropAllocation,
  requestAirdropAllocationProof,
  requestAirdropAllocationClaim,
  requestAirdropAllocationClaimStatus,
  airdropsToFullAllocations,
}
