import { QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError
  }
}

const queryClient = new QueryClient()

export { queryClient }
