import React from 'react'

import styles from './component.module.scss'

const NavButton = ({
  onClick,
  text,
  className,
}: {
  onClick: () => void
  text: string
  className: string
}) => {
  return (
    <div onClick={onClick} className={styles.container}>
      <div className={className}>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  )
}

export { NavButton }
