import { FetchStatus, OAuthProviderURI } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/utils'

import {
  IUserOAuthProviderStatus,
  IUserOAuthProviderStatuses,
  IUserOAuthRequestUrlData,
} from './types'

export const generateRandomState = () => {
  const stateBytes = new Uint8Array(64)
  window.crypto.getRandomValues(stateBytes)
  const state = [...stateBytes]
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
  return state
}

export const requestUserOAuthStatusForProvider = async (
  provider: OAuthProviderURI
) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/users/oauth/' + encodeURIComponent(provider),
      isAuthenticated: true,
    })

    const statuses = { [provider]: data as IUserOAuthProviderStatus }

    return statuses as IUserOAuthProviderStatuses
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const requestUserOAuthUrlForProvider = async (
  provider: OAuthProviderURI
) => {
  try {
    const { data } = await apiRequest({
      method: 'POST',
      endpoint: '/users/oauth/request',
      isAuthenticated: true,
      body: {
        provider,
      },
    })
    return data as IUserOAuthRequestUrlData
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const registerUserOAuthToken = async (
  provider: OAuthProviderURI,
  token: string,
  params: Record<string, string>
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/users/oauth/register',
      isAuthenticated: true,
      body: {
        provider,
        token,
        params,
      },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const unregisterUserOAuthToken = async (provider: OAuthProviderURI) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/users/oauth/unregister',
      isAuthenticated: true,
      body: {
        provider,
      },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}
