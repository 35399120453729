const INITIAL_SENDING_CURRENCY = {
  code: 'ETH',
  icon: 'https://exolix.com/icons/coins/ETH.png',
  network: {
    addressRegex: '^(0x)[0-9A-Fa-f]{40}$',
    blockExplorer: 'https://etherscan.io/tx/',
    depositMinAmount: null,
    isDefault: true,
    memoName: '',
    memoNeeded: false,
    memoRegex: '',
    name: 'Ethereum (ERC20)',
    network: 'ETH',
    notes: '',
    precision: 6,
    shortName: 'ERC20',
  },
}

const INITIAL_RECEIVING_CURRENCY = {
  code: 'DAG',
  icon: 'https://exolix.com/icons/coins/DAG.png',
  network: {
    addressRegex: '^(DAG)[0-9A-Za-z]{30,70}$',
    blockExplorer: 'https://www.dagexplorer.io/search?term=',
    depositMinAmount: null,
    isDefault: true,
    memoName: '',
    memoNeeded: false,
    memoRegex: '',
    name: 'Constellation',
    network: 'DAG',
    notes: '',
    precision: 8,
    shortName: '',
  },
}

export { INITIAL_SENDING_CURRENCY, INITIAL_RECEIVING_CURRENCY }
