import React from 'react'
import cls from 'classnames'
import Decimal from 'decimal.js'

import { ReactComponent as ChevronUpIcon } from '@lattice/assets/icons/feather/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from '@lattice/assets/icons/feather/chevron-down.svg'
import {
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
} from '@lattice/utils'
import { HorizontalBar } from '@lattice/common/components'

import { LabeledValue } from '../LabeledValue'
import { RoundedIcon } from '../RoundedIcon'

import styles from './component.module.scss'

type IStakedInfo = {
  networkName: string
  networkIcon: string
  tokenToStake: string
  tokenToStakeIcon: string
  tokenToReward: string
  tokenToRewardIcon: string
  totalStakedInPool: Decimal
  userTotalStaked: Decimal | undefined
  apy: Decimal
}

const Header = ({
  stakedInfo,
  opened,
  onToggle,
  isWalletConnected,
}: {
  stakedInfo: IStakedInfo
  opened: boolean
  onToggle: () => void
  isWalletConnected: boolean
}) => {
  return (
    <div className={styles.headerContainer} onClick={onToggle}>
      <div
        className={cls(
          styles.headerCell,
          styles.programInfoMobile,
          styles.noDisplayDesktop
        )}
      >
        <div className={styles.networkInfo}>
          <span>{stakedInfo.networkName}</span>
          <RoundedIcon
            className={styles.icon}
            icon={stakedInfo.networkIcon}
            tooltip={stakedInfo.networkName}
          />
        </div>
        <HorizontalBar />
        <div className={styles.exchangeData}>
          <div className={styles.iconPair}>
            <RoundedIcon icon={stakedInfo.tokenToStakeIcon} />
            <RoundedIcon icon={stakedInfo.tokenToRewardIcon} />
          </div>
          <LabeledValue
            label={'Stake'}
            value={stakedInfo.tokenToStake}
            tooltip={[
              `In this pool, you can stake “${stakedInfo.tokenToStake}” and earn rewards proportionate to your stake in “${stakedInfo.tokenToStake}”. APR of total rewards may vary and will change based on your staked amount and total staked amount. There is no lockup period - unstake at any time.`,
            ].join('\n')}
          />
          <LabeledValue
            label={'EARN'}
            value={stakedInfo.tokenToReward}
            tooltip={[
              `Get “${stakedInfo.tokenToReward}” as rewards from staking, a 5% fee is applied for lattice`,
            ].join('\n')}
          />
          <LabeledValue
            className={styles.noDisplaySizeSm}
            variants={['text-direction-left']}
            label={'Your total staked'}
            value={
              isWalletConnected && stakedInfo.userTotalStaked
                ? formatNumberAndCurrency(
                    stakedInfo.userTotalStaked,
                    stakedInfo.tokenToStake,
                    NumberFormat.WHOLE_OR_8_ON_TINY
                  )
                : 'Connect your wallet'
            }
            tooltip={[
              `The amount of tokens you have staked in this staking pool`,
            ].join('\n')}
          />
          <LabeledValue
            className={styles.noDisplaySizeSm}
            tooltip={[
              '• Calculated based on current rates.',
              '• Please note that the figures provided are only estimates for your convenience and do not guarantee any returns.',
            ].join('\n')}
            variants={['text-direction-left']}
            label={'APR'}
            value={
              stakedInfo.apy.eq(-1)
                ? '--'
                : formatNumber(stakedInfo.apy, NumberFormat.DECIMALS_TRIMMED) +
                  '%'
            }
          />
          <div className={cls(styles.chevron)}>
            {opened ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>
      </div>
      <div
        className={cls(styles.headerCell, styles.noDisplayMobile)}
        style={{ width: '72px' }}
      >
        <RoundedIcon
          icon={stakedInfo.networkIcon}
          tooltip={stakedInfo.networkName}
        />
      </div>
      <div
        className={cls(
          styles.headerCell,
          styles.exchangeData,
          styles.noDisplayMobile
        )}
        style={{ width: '114px' }}
      >
        <div className={styles.iconPair}>
          <RoundedIcon icon={stakedInfo.tokenToStakeIcon} />
          <RoundedIcon icon={stakedInfo.tokenToRewardIcon} />
        </div>
      </div>
      <div
        className={cls(
          styles.headerCell,
          styles.exchangeData,
          styles.noDisplayMobile
        )}
        style={{ width: '144px' }}
      >
        <LabeledValue
          label={'Stake'}
          value={stakedInfo.tokenToStake}
          tooltip={[
            `In this pool, you can stake “${stakedInfo.tokenToStake}” and earn rewards proportionate to your stake in “${stakedInfo.tokenToStake}”. APR of total rewards may vary and will change based on your staked amount and total staked amount. There is no lockup period - unstake at any time.`,
          ].join('\n')}
        />
      </div>
      <div
        className={cls(
          styles.headerCell,
          styles.exchangeData,
          styles.noDisplayMobile
        )}
        style={{ width: '112px' }}
      >
        <LabeledValue
          label={'EARN'}
          value={stakedInfo.tokenToReward}
          tooltip={[
            `Get “${stakedInfo.tokenToReward}” as rewards from staking, a 5% fee is applied for lattice`,
          ].join('\n')}
        />
      </div>
      <div className={cls(styles.headerCell, styles.noDisplayMobile)}>
        <LabeledValue
          variants={['text-direction-left']}
          label={'Total Value Staked'}
          value={formatNumberAndCurrency(
            stakedInfo.totalStakedInPool,
            stakedInfo.tokenToStake,
            NumberFormat.WHOLE_OR_8_ON_TINY
          )}
          tooltip={[
            `The total amount of tokens staked in this staking pool by all users`,
          ].join('\n')}
        />
      </div>
      <div className={cls(styles.headerCell, styles.noDisplayMobile)}>
        <LabeledValue
          variants={['text-direction-left']}
          label={'Your total staked'}
          value={
            isWalletConnected && stakedInfo.userTotalStaked
              ? formatNumberAndCurrency(
                  stakedInfo.userTotalStaked,
                  stakedInfo.tokenToStake,
                  NumberFormat.WHOLE_OR_8_ON_TINY
                )
              : 'Connect your wallet'
          }
          tooltip={[
            `The amount of tokens you have staked in this staking pool`,
          ].join('\n')}
        />
      </div>
      <div
        className={cls(styles.headerCell, styles.noDisplayMobile)}
        style={{ width: '96px' }}
      >
        <LabeledValue
          tooltip={[
            '• Calculated based on current rates.',
            '• Please note that the figures provided are only estimates for your convenience and do not guarantee any returns.',
          ].join('\n')}
          variants={['text-direction-left']}
          label={'APR'}
          value={
            stakedInfo.apy.eq(-1)
              ? '--'
              : formatNumber(stakedInfo.apy, NumberFormat.DECIMALS_TRIMMED) +
                '%'
          }
        />
      </div>
      <div
        className={cls(
          styles.headerCell,
          styles.chevron,
          styles.noDisplayMobile
        )}
        style={{ width: '64px' }}
      >
        {opened ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
    </div>
  )
}

export { Header }
