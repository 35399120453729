import millify from 'millify'
import * as ethers from 'ethers'
import { Decimal } from 'decimal.js'

enum NumberFormat {
  MILLIFY,
  WHOLE,
  WHOLE_OR_8_ON_TINY,
  DECIMALS,
  DECIMALS_TRIMMED,
  DECIMALS_OR_8_ON_TINY,
  MILLIFY_WHOLE,
  MILLIFY_EXPANDED,
}

const formatNumber = (
  number: number | Decimal | string | undefined | null,
  format: NumberFormat
): string => {
  if (number instanceof Decimal) {
    number = number.toNumber()
  }

  if (
    number === '' ||
    number === undefined ||
    number === null ||
    isNaN(<number>number)
  ) {
    return ''
  }

  if (typeof number === 'string') {
    number = parseFloat(number)
  }

  if (number === 0) {
    return '0'
  }

  if (format === NumberFormat.MILLIFY) {
    return millify(number)
  }

  if (format === NumberFormat.WHOLE) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    }).format(number)
  }

  if (format === NumberFormat.WHOLE_OR_8_ON_TINY) {
    return number < 1
      ? new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 8,
          minimumFractionDigits: 8,
        }).format(number)
      : new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 0,
        }).format(number)
  }

  if (format === NumberFormat.DECIMALS) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number)
  }

  if (format === NumberFormat.DECIMALS_TRIMMED) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(number)
  }

  if (format === NumberFormat.DECIMALS_OR_8_ON_TINY) {
    return number < 1
      ? new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 8,
          minimumFractionDigits: 8,
        }).format(number)
      : new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(number)
  }

  if (format === NumberFormat.MILLIFY_WHOLE) {
    return millify(number, { precision: 0 })
  }

  if (format === NumberFormat.MILLIFY_EXPANDED) {
    const exponent = Math.floor(Math.log10(<number>number))
    const precision = Math.min(
      Math.max(Math.floor(exponent / 3 /* 3 Zeros */) - 1, 0),
      3
    )
    return millify(number, { precision })
  }

  return '--'
}

enum CurrencyFormat {
  UPPERCASE,
  LOWERCASE,
  DEFAULT,
}

const formatCurrency = (
  currency: string,
  format: CurrencyFormat = CurrencyFormat.DEFAULT
) => {
  if (format === CurrencyFormat.UPPERCASE) {
    return currency.toLocaleUpperCase()
  }
  if (format === CurrencyFormat.LOWERCASE) {
    return currency.toLocaleLowerCase()
  }
  return currency
}

const formatNumberAndCurrency = (
  number: number | Decimal,
  currency: string,
  numberFormat: NumberFormat,
  currencyFormat: CurrencyFormat = CurrencyFormat.DEFAULT
) => {
  return `${formatNumber(number, numberFormat)} ${formatCurrency(
    currency,
    currencyFormat
  )}`
}

const fromBigNumberishToFloat = (
  rawInt: Exclude<ethers.BigNumberish, ethers.Bytes>,
  decimals: number
) => {
  /**
   * Following workaround added in favor of this ticket
   * https://constellationnetwork.atlassian.net/browse/LE-251
   * Replace the use of native numbers with arbitrary-precision numbers
   */
  let normalized: number | string
  if (typeof rawInt === 'bigint') {
    normalized = rawInt.toString()
  } else if (ethers.BigNumber.isBigNumber(rawInt)) {
    normalized = rawInt.toString()
  } else {
    normalized = rawInt
  }

  return new Decimal(normalized).div(new Decimal(10).pow(decimals)).toNumber()
}

const fromBigNumber = (
  amount: string | number | ethers.BigNumber,
  precisionUnits: number,
  decimals = 4
): string => {
  return fromBigNumberishToFloat(amount, precisionUnits).toFixed(decimals)
}

const formatDAGAmount = (rawAmount: number) => {
  const amount = rawAmount / 1e8

  if (amount > 10000000) {
    return formatNumberAndCurrency(amount, 'DAG', NumberFormat.MILLIFY_WHOLE)
  }

  return formatNumberAndCurrency(amount, 'DAG', NumberFormat.MILLIFY)
}

export {
  NumberFormat,
  CurrencyFormat,
  formatNumber,
  formatCurrency,
  formatNumberAndCurrency,
  fromBigNumberishToFloat,
  fromBigNumber,
  formatDAGAmount,
}
