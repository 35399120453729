import { useLocation, useSearchParams } from 'react-router-dom'

const useSignInRedirectBase = () => {
  const { pathname, search, hash } = useLocation()

  const redirectParams = new URLSearchParams()
  redirectParams.set('redirect', `${pathname}${search}${hash}`)

  return `/user/signin?${redirectParams}`
}

const useSignInRedirectConsume = () => {
  const [searchParams] = useSearchParams()

  return searchParams.get('redirect')
}

const useSignInRedirect = Object.assign(useSignInRedirectBase, {
  Consume: useSignInRedirectConsume,
})

export { useSignInRedirect }
