import { AvailableChain } from '@lattice/common/consts'

const EtherscanChainPrefixes = {
  [AvailableChain.ETHEREUM_MAINNET]: '',
  [AvailableChain.ETHEREUM_SEPOLIA]: 'sepolia.',
}

const getEthereumBELink = (
  chainId: AvailableChain,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
) => {
  const prefix = `https://${
    EtherscanChainPrefixes[chainId] ??
    EtherscanChainPrefixes[AvailableChain.ETHEREUM_MAINNET]
  }etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

const getConstellationBELink = (term: string) => {
  return `https://www.dagexplorer.io/search/?term=${term}`
}

export { getEthereumBELink, getConstellationBELink }
