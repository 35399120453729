import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'

import { ReactComponent as ArrowRightIcon } from '@lattice/assets/icons/custom/arrow-right.svg'

import styles from './component.module.scss'

type BaseButtonProps<RenderElementProps = JSX.IntrinsicElements['button']> = {
  loading?: boolean
  rightIcon?: React.ReactNode
  variants?: (
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'right-icon'
    | 'full-width'
    | 'slim'
    | 'invisible'
    | 'primary-outlined'
  )[]
  className?: { main?: string; iconHolder?: string }
  children?: React.ReactNode
} & RenderElementProps

const BaseButton = ({
  loading,
  rightIcon = <ArrowRightIcon width={16} height={16} />,
  variants,
  disabled,
  className,
  children,
  ...props
}: BaseButtonProps) => {
  return (
    <button
      className={cls(
        styles.main,
        disabled && styles.disabled,
        variants?.includes('right-icon') && styles.rightIcon,
        variants?.includes('primary') && styles.primary,
        variants?.includes('secondary') && styles.secondary,
        variants?.includes('outlined') && styles.outlined,
        variants?.includes('full-width') && styles.fullWidth,
        variants?.includes('slim') && styles.slim,
        variants?.includes('invisible') && styles.invisible,
        variants?.includes('primary-outlined') && styles.primaryOutlined,
        className
      )}
      {...props}
    >
      {loading ? (
        <ImpulseSpinner
          frontColor={
            variants?.includes('primary')
              ? '#000'
              : variants?.includes('primary-outlined')
                ? '#fc0'
                : '#fff'
          }
        />
      ) : (
        children
      )}
      {variants?.includes('right-icon') && (
        <div className={styles.iconHolder}>{rightIcon}</div>
      )}
    </button>
  )
}

export { BaseButton, BaseButtonProps }
