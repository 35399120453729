// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div .oa0DIpUWJJeyvg8ysBM1{background:rgba(39,174,96,.15);height:20px}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/TestnetValidatorProgramDetailView/view.module.scss"],"names":[],"mappings":"AAEA,0BACE,8BAAA,CACA,WAAA","sourcesContent":["@use 'common/styles/colors' as *;\n\ndiv .greenBadgeContainer {\n  background: rgba($green, 0.15);\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greenBadgeContainer": `oa0DIpUWJJeyvg8ysBM1`
};
export default ___CSS_LOADER_EXPORT___;
