import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getYear } from 'date-fns'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as Logo } from '@lattice/assets/images/svgs/constellation-yellow-logo.svg'
import { ReactComponent as LogoTitle } from '@lattice/assets/images/svgs/constellation-yellow-logo-title.svg'
import { ReactComponent as Telegram } from '@lattice/assets/images/svgs/telegram.svg'
import { ReactComponent as Twitter } from '@lattice/assets/images/svgs/twitter.svg'
import { ReactComponent as Youtube } from '@lattice/assets/images/svgs/youtube.svg'
import { ReactComponent as Reddit } from '@lattice/assets/images/svgs/reddit.svg'
import { ReactComponent as Medium } from '@lattice/assets/images/svgs/medium.svg'
import { ReactComponent as Github } from '@lattice/assets/images/svgs/github.svg'
import { ReactComponent as HackenBadge } from '@lattice/assets/images/svgs/HackenBadge.svg'

import { Content } from '../Content'

import styles from './component.module.scss'

const Footer: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Content padding={false}>
      {location.pathname.includes('/governance') && (
        <div className={styles.hackenContainer}>
          <div className={styles.hackenBadge}>
            <a
              href={'https://hacken.io/audits/#constellation_network'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HackenBadge />
            </a>
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.title}>
          © Lattice Gateway {getYear(new Date())} &nbsp;&nbsp;•&nbsp;
          <a href="/privacy">
            {t('components.Footer.wrapper.privacyPolicy', 'Privacy Policy')}
          </a>
          &nbsp;&nbsp;•&nbsp;
          <Link to="/terms-of-service">
            {t('components.Footer.wrapper.termsOfService', 'Terms of Service')}
          </Link>
        </div>
        <div className={styles.logo}>
          {t('components.Footer.wrapper.poweredBy', 'Powered by')}
          <Logo />
          <LogoTitle />
        </div>
        <div className={styles.social}>
          <a
            href="https://t.me/Lattice_exchange"
            rel="noreferrer"
            target="_blank"
          >
            <Telegram />
          </a>
          <a
            href="https://twitter.com/LatticeExchange"
            rel="noreferrer"
            target="_blank"
          >
            <Twitter />
          </a>
          <a
            href="https://www.youtube.com/channel/UChMBV4al3p_iO4bnfzcIzVQ"
            rel="noreferrer"
            target="_blank"
          >
            <Youtube />
          </a>
          <a href="#" rel="noreferrer" target="_blank">
            <Reddit />
          </a>
          <a
            href="https://latticegateway.medium.com/"
            rel="noreferrer"
            target="_blank"
          >
            <Medium />
          </a>
          <a href="#" rel="noreferrer" target="_blank">
            <Github />
          </a>
        </div>
      </div>
    </Content>
  )
}

export { Footer }
