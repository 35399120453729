import { createProviderStateHook } from '@lattice/utils'

import { UserOAuthProvider, UserOAuthProviderContext } from './provider'

const useUserOAuthProvider = createProviderStateHook(
  UserOAuthProvider,
  UserOAuthProviderContext
)

export { useUserOAuthProvider }
