// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WprdZUZHngMJpsjPxB0F{display:flex;width:100%;height:500px;justify-content:center;align-items:center}.LP11GT63TKlYMqrdNaw7{height:unset}.l4N94venxjTr59GbgNN0{height:100%}`, "",{"version":3,"sources":["webpack://./src/common/components/PendingContent/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,UAAA,CACA,YAAA,CAEA,sBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CAGF,sBACE,WAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n  height: 500px;\n\n  justify-content: center;\n  align-items: center;\n}\n\n.minHeight {\n  height: unset;\n}\n\n.heightAvailable{\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `WprdZUZHngMJpsjPxB0F`,
	"minHeight": `LP11GT63TKlYMqrdNaw7`,
	"heightAvailable": `l4N94venxjTr59GbgNN0`
};
export default ___CSS_LOADER_EXPORT___;
