import React from 'react'
import cls from 'classnames'
import { Link } from 'react-router-dom'

import { ReactComponent as ArrowRightIcon } from '@lattice/assets/icons/custom/arrow-right.svg'

import styles from '../BaseButton/component.module.scss'
import { BaseButtonProps } from '../BaseButton'

const ButtonLink = ({
  rightIcon = <ArrowRightIcon width={16} height={16} />,
  variants,
  className,
  children,
  ...props
}: Omit<BaseButtonProps<React.ComponentProps<typeof Link>>, 'loading'>) => {
  return (
    <Link
      className={cls(
        styles.main,
        variants?.includes('right-icon') && styles.rightIcon,
        variants?.includes('primary') && styles.primary,
        variants?.includes('secondary') && styles.secondary,
        variants?.includes('outlined') && styles.outlined,
        variants?.includes('full-width') && styles.fullWidth,
        variants?.includes('slim') && styles.slim,
        variants?.includes('invisible') && styles.invisible,
        className
      )}
      {...props}
    >
      {children}
      {variants?.includes('right-icon') && (
        <div className={styles.iconHolder}>{rightIcon}</div>
      )}
    </Link>
  )
}

export { ButtonLink }
