import React from 'react'
import cls from 'classnames'

import { Badge, BadgeColors } from '@lattice/common/components'

import styles from '../styles.module.scss'
import { TotalStake } from '../types'
import { PoolBaseCard } from '../PoolBaseCard'

const StakedInfoCard = ({ stakeInfo }: { stakeInfo: TotalStake }) => {
  return (
    <PoolBaseCard label={stakeInfo.label} showHelp={stakeInfo.helpInfo}>
      <div className={styles.flexContainer}>
        <div className={styles.tokenRow}>
          <div
            className={cls(styles.tokenAmount, stakeInfo.className?.amountText)}
          >
            {stakeInfo.stakedToken.amount}
          </div>
          <Badge
            text={stakeInfo.stakedToken.ticker}
            color={BadgeColors.green}
            className={{
              container: stakeInfo.className?.container,
              text: stakeInfo.className?.badgeText,
            }}
          />
        </div>
        {stakeInfo.stakedTokenTwo && (
          <div className={styles.tokenRow}>
            <div
              className={cls(
                styles.tokenAmount,
                stakeInfo.className?.amountText
              )}
            >
              {stakeInfo.stakedTokenTwo.amount}
            </div>
            <Badge
              text={stakeInfo.stakedTokenTwo.ticker}
              color={BadgeColors.green}
              className={{
                container: stakeInfo.className?.container,
                text: stakeInfo.className?.badgeText,
              }}
            />
          </div>
        )}
      </div>
    </PoolBaseCard>
  )
}

export { StakedInfoCard }
