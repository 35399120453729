import React from 'react'
import cls from 'classnames'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import styles from '../../component.module.scss'

const NumericInputRow = ({
  variants,
  label,
  error,
  icon,
  labelIcon,
  valueType = 'floatValue',
  onChange,
  onIconClick,
  classNames,
  ...props
}: {
  variants?: 'full-width'[]
  label?: string
  error?: string
  icon?: React.ReactNode
  labelIcon?: React.ReactNode
  valueType?: keyof NumberFormatValues
  onChange?: (value: any) => void
  onIconClick?: React.MouseEventHandler<HTMLDivElement>
  classNames?: {
    root?: string
    label?: string
    input?: string
    inputWrapper?: string
    error?: string
    icon?: string
    labelIcon?: string
  }
} & React.ComponentProps<typeof NumberFormat>) => {
  return (
    <div
      className={cls(
        styles.root,
        variants?.includes('full-width') && styles.fullWidth,
        classNames?.root
      )}
    >
      {label && (
        <label className={cls(styles.label, classNames?.label)}>
          {label}
          {labelIcon && <p className={styles.noMargin}>{labelIcon}</p>}
        </label>
      )}
      <div className={cls(styles.inputWrapper, classNames?.inputWrapper)}>
        <NumberFormat
          {...props}
          onValueChange={(values) => {
            onChange && onChange(values[valueType])
          }}
          displayType="input"
          className={cls(
            styles.input,
            error && cls(styles.error, classNames?.error),
            classNames?.input
          )}
        />
        <div
          className={cls(styles.icon, classNames?.icon)}
          onClick={onIconClick}
        >
          {icon}
        </div>
      </div>
      {error && (
        <span className={cls(styles.error, classNames?.error)}>{error}</span>
      )}
    </div>
  )
}

export { NumericInputRow }
