import { DeploymentStage } from '../deployment'
import { ExecutionContext } from '../execution_context'

import { AvailableChain, AvailableNetwork } from './available'

const DeploymentStageChains: Record<
  DeploymentStage,
  Record<AvailableNetwork, AvailableChain>
> = {
  [DeploymentStage.PRODUCTION]: {
    [AvailableNetwork.CONSTELLATION]: AvailableChain.CONSTELLATION_MAINNET,
    [AvailableNetwork.ETHEREUM]: AvailableChain.ETHEREUM_MAINNET,
    [AvailableNetwork.POLYGON]: AvailableChain.POLYGON_MAINNET,
  },
  [DeploymentStage.STAGING]: {
    [AvailableNetwork.CONSTELLATION]: AvailableChain.CONSTELLATION_MAINNET,
    [AvailableNetwork.ETHEREUM]: AvailableChain.ETHEREUM_SEPOLIA,
    [AvailableNetwork.POLYGON]: AvailableChain.POLYGON_MUMBAI,
  },
  [DeploymentStage.ALPHA]: {
    [AvailableNetwork.CONSTELLATION]: AvailableChain.CONSTELLATION_MAINNET,
    [AvailableNetwork.ETHEREUM]: AvailableChain.ETHEREUM_SEPOLIA,
    [AvailableNetwork.POLYGON]: AvailableChain.POLYGON_MUMBAI,
  },
  [DeploymentStage.DELTA]: {
    [AvailableNetwork.CONSTELLATION]: AvailableChain.CONSTELLATION_MAINNET,
    [AvailableNetwork.ETHEREUM]: AvailableChain.ETHEREUM_SEPOLIA,
    [AvailableNetwork.POLYGON]: AvailableChain.POLYGON_MUMBAI,
  },
  [DeploymentStage.THETA]: {
    [AvailableNetwork.CONSTELLATION]: AvailableChain.CONSTELLATION_MAINNET,
    [AvailableNetwork.ETHEREUM]: AvailableChain.ETHEREUM_SEPOLIA,
    [AvailableNetwork.POLYGON]: AvailableChain.POLYGON_MUMBAI,
  },
}

const DeploymentStageMasterNetworkChains =
  DeploymentStageChains[ExecutionContext.stage]

const DeploymentStageMasterChain =
  DeploymentStageMasterNetworkChains[AvailableNetwork.ETHEREUM]

export {
  DeploymentStageChains,
  DeploymentStageMasterChain,
  DeploymentStageMasterNetworkChains,
}
