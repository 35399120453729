import React from 'react'
import { i18n, TFunction } from 'i18next'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { HeroCard } from '@lattice/common/components'
import PlaceholderImage from '@lattice/assets/images/pngs/nasa.png'
import {
  RewardProgram,
  RewardProgramStatus,
  RewardProgramType,
} from '@lattice/common/providers'

const statusBadgeColors = {
  [RewardProgramStatus.SOON]: 'yellow',
  [RewardProgramStatus.COMMING_SOON]: 'yellow',
  [RewardProgramStatus.ENROLLING]: 'purple',
  [RewardProgramStatus.LIVE]: 'green',
  [RewardProgramStatus.DISTRIBUTING]: 'blue',
  [RewardProgramStatus.COMPLETE]: 'orange',
}

const extraInfoLabels = {
  [RewardProgramType.SOFT_NODE]: 'Lockup',
  [RewardProgramType.BOUNTY]: 'Commission Type',
  [RewardProgramType.STAKING]: 'Extra Data',
  [RewardProgramType.AIRDROP]: 'Recipent',
}
enum StatusText {
  soon = 'SOON',
  coming_soon = 'SOON',
  live = 'LIVE',
  distributing = 'DISTRIBUTING',
  complete = 'COMPLETE',
  draft = 'DRAFT',
  enrolling = 'ENROLLING',
}

enum ProgramType {
  soft_node = 'Soft Node',
  staking = 'Staking',
  bounty = 'Bounty',
  airdrop = 'Airdrop',
}

const getProgramUrl = (url: string): string => {
  /**
   * @todo
   * Remove when soft staking feature is finished
   */
  if (url.includes('soft-staking')) {
    return ''
  }
  return url
}

const getTimelineText = (
  t: TFunction,
  i18n: i18n,
  timeline: RewardProgram['timeline']
) => {
  if (timeline === 'ongoing') {
    return t('common.RewardsProgramCard.timelineText.ongoing', 'Ongoing')
  } else {
    const startsAt = dayjs(timeline.startsAt)
    const endsAt = dayjs(timeline.endsAt)

    if (dayjs().isBefore(startsAt)) {
      return t(
        'common.RewardsProgramCard.timelineText.programStarts',
        'Starts in {{distance}}',
        {
          distance: dayjs
            .duration(startsAt.diff(dayjs()))
            .locale(i18n.resolvedLanguage!)
            .humanize(),
        }
      )
    }

    if (dayjs().isBefore(endsAt)) {
      return t(
        'common.RewardsProgramCard.timelineText.programEnds',
        'Ends in {{distance}}',
        {
          distance: dayjs
            .duration(endsAt.diff(dayjs()))
            .locale(i18n.resolvedLanguage!)
            .humanize(),
        }
      )
    }

    if (dayjs().isAfter(endsAt)) {
      return t(
        'common.RewardsProgramCard.timelineText.programEnds',
        'Ended {{distance}}',
        {
          distance: dayjs
            .duration(endsAt.diff(dayjs()))
            .locale(i18n.resolvedLanguage!)
            .humanize(true),
        }
      )
    }

    return 'Unknown Timeline'
  }
}

const RewardProgramCard = ({
  rewardProgram,
}: {
  rewardProgram: RewardProgram
}) => {
  const { t, i18n } = useTranslation()

  return (
    <HeroCard
      key={rewardProgram.title}
      headerImage={rewardProgram.imageUrl ?? PlaceholderImage}
      data={{
        title: rewardProgram.title,
        nodeType: rewardProgram.type,
        buttonLabel: 'View program',
        to: getProgramUrl(rewardProgram.url),
        displayInfo: [
          {
            label: 'Program',
            text: ProgramType[rewardProgram.type]
              ? ProgramType[rewardProgram.type]
              : '',
          },
          {
            label: 'Status',
            badge: {
              text: StatusText[rewardProgram.status]
                ? StatusText[rewardProgram.status]
                : '',
              color: statusBadgeColors[rewardProgram.status]
                ? statusBadgeColors[rewardProgram.status]
                : '',
            },
          },

          { label: 'Rewards', text: rewardProgram.rewardsType },
          { label: 'Requirements', text: rewardProgram.requirements },
          {
            label: extraInfoLabels[rewardProgram.type]
              ? extraInfoLabels[rewardProgram.type]
              : '',
            text: rewardProgram.extraInfo,
          },
          {
            label: 'Timeline',
            text: getTimelineText(t, i18n, rewardProgram.timeline),
          },
        ],
      }}
    />
  )
}

export { RewardProgramCard }
