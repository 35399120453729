const TRACKING_TOKEN_IDS = {
  btc: 'bitcoin',
  eth: 'ethereum',
  dag: 'constellation-labs',
  ltx: 'lattice-token',
  $ads: 'alkimi',
  jam: 'geojam',
  dodi: 'doubledice-token',
} as const

export { TRACKING_TOKEN_IDS }
