import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import { IHgtpMetagraph, IHgtpMetagraphProvider, IHgtpNode } from './types'
import {
  requestNode,
  executePostNode,
  executeDeleteNode,
  executePostNodeConfirmOperator,
  requestMetagraph,
} from './utils'

const HgtpMetagraphsProviderContext =
  React.createContext<IHgtpMetagraphProvider | null>(null)

const HgtpMetagraphsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const metagraph = useFetchableResource<IHgtpMetagraph | null>(null)
  const node = useFetchableResource<IHgtpNode | null>(null)

  const ctx: IHgtpMetagraphProvider = {
    metagraph: metagraph,
    node: node,
    requestMetagraph: metagraph.wrappedFetch(requestMetagraph),
    requestNode: node.wrappedFetch(requestNode),
    executePostNode: executePostNode,
    executeDeleteNode: executeDeleteNode,
    executePostNodeConfirmOperator: executePostNodeConfirmOperator,
  }

  return (
    <HgtpMetagraphsProviderContext.Provider value={ctx}>
      {children}
    </HgtpMetagraphsProviderContext.Provider>
  )
}

export { HgtpMetagraphsProvider, HgtpMetagraphsProviderContext }
