import { FetchStatus } from '@lattice/common/consts'

enum RewardsHistoryGraphType {
  time_vs_rewards = 'timevsrewards',
}

enum RewardsHistoryGraphPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

enum RewardsHistoryGraphAggregate {
  avg = 'avg',
  sum = 'sum',
}

enum RewardsHistoryField {
  amount = 'amount',
  nodeType = 'nodeType',
  transactionSentAt = 'transactionSentAt',
}

type NodeRewardType = {
  id: string
  name: string
  uri: string
  currency: string
  status: 'live' | 'draft'
  createdAt: IISODate
  updatedAt: IISODate
}

type NodeRewardDataType = {
  id: string
  network: 'constellation'
  currency: string
  amount: number
  rewardAddress: string
  rewardId: string
  nodeId: string | null
  nodeType: string
  nodeExtraData:
    | { type: 'dtm'; data: { total: number } }
    | { type: 'softnode'; data: { active: number; total: number } }
    | { type: 'testnetrewards'; data: null }
    | null
  transactionStatus: 'scheduled' | 'sending' | 'sent' | 'dropped' | 'confirmed'
  transactionId: string | null
  transactionSentAt: string | null
  transactionExecutionId: string | null
  userId: string
  createdAt: string
  updatedAt: string
}

interface NodeReward_TimeVsRewards_GraphPoint {
  currency: string
  rewardDate: string
  amount: number
}

type IRewardsHistoryContext = {
  nodeTypes: NodeRewardType[]
  nodeRewards: NodeRewardDataType[]
  nodeRewardsFetchStatus: FetchStatus
  nodeRewardsGraphPoints: NodeReward_TimeVsRewards_GraphPoint[]
  nodeRewardsGraphPointsFetchStatus: FetchStatus
  fetchNodeTypes: () => Promise<void>
  fetchGraphPoints: () => Promise<void>
  fetchHistoricalData: () => Promise<void>

  currentPage: number
  hasNextPage: boolean
  nextPage: () => void
  hasPreviousPage: boolean
  previousPage: () => void

  limit: number
  setLimit: (limit: number) => void
  offset: number
  setOffset: (offset: number) => void

  sortedColumn: [RewardsHistoryField, 'ASC' | 'DESC']
  setSortedColumn: (
    column: IRewardsHistoryContext['sortedColumn'][0],
    sort: IRewardsHistoryContext['sortedColumn'][1]
  ) => void
  rewardType: string | null
  setRewardType: (type: IRewardsHistoryContext['rewardType']) => void
  range: string | null
  setRange: (range: IRewardsHistoryContext['range']) => void

  graphPeriod: RewardsHistoryGraphPeriod
  setGraphPeriod: (period: IRewardsHistoryContext['graphPeriod']) => void
}

export {
  RewardsHistoryGraphType,
  RewardsHistoryGraphPeriod,
  RewardsHistoryGraphAggregate,
  RewardsHistoryField,
  NodeRewardType,
  NodeRewardDataType,
  NodeReward_TimeVsRewards_GraphPoint,
  IRewardsHistoryContext,
}
