import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

import {
  BaseCard,
  Button,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './view.module.scss'
import { KycStatus } from './components'
import {
  getGeneralStatusFromKycStatus,
  isResubmissionAvailableFromKycStatus,
} from './utils'

type IKycFormData = {
  firstName: string
  lastName: string
}

const KycView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const signInUrl = useSignInRedirect()
  const {
    operations,
    user,
    userDbData,
    doFetchUserDbData,
    doUserKycVerification,
  } = useUserProvider()
  const [submissionStage, setSubmissionStage] = useState<
    'available' | 'unavailable' | 'complete' | 'submitted'
  >('unavailable')

  const userKycForm = useForm<IKycFormData>({ mode: 'onTouched' })

  const doStartVerification = userKycForm.handleSubmit(async (data) => {
    await doUserKycVerification(data.firstName, data.lastName)
    setSubmissionStage('complete')
    setTimeout(doFetchUserDbData, 5000)
  })

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }
  }, [user])

  useEffect(() => {
    if (userDbData.resource) {
      if (userDbData.resource.kycStatus) {
        setSubmissionStage('submitted')
      } else {
        setSubmissionStage('available')
      }
    }
  }, [userDbData.resource])

  useEffect(() => {
    let iid: number
    if (
      userDbData.resource &&
      userDbData.resource.kycStatus &&
      getGeneralStatusFromKycStatus(userDbData.resource.kycStatus) === 'pending'
    ) {
      iid = window.setInterval(doFetchUserDbData, 10000)
    }
    return () => {
      iid && window.clearInterval(iid)
    }
  }, [userDbData.resource])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={'KYC'}
    >
      {userDbData.status === FetchStatus.PENDING ? (
        <PendingContent />
      ) : userDbData.resource ? (
        <>
          {submissionStage === 'available' && (
            <form onSubmit={doStartVerification}>
              <div className={styles.formSection}>
                <InputRow
                  variants={['full-width']}
                  label={t('views.User.views.Kyc.firstName', 'First name')}
                  error={userKycForm.formState.errors.firstName?.message}
                  {...userKycForm.register('firstName', {
                    required: true,
                  })}
                />
                <InputRow
                  variants={['full-width']}
                  label={t('views.User.views.Kyc.lastName', 'Last name')}
                  error={userKycForm.formState.errors.lastName?.message}
                  {...userKycForm.register('lastName', {
                    required: true,
                  })}
                />
                {operations.kycVerification.error && (
                  <span className={styles.error}>
                    {String(operations.kycVerification.error)}
                  </span>
                )}
              </div>
              <div className={styles.actionSection}>
                <Button
                  variants={['primary', 'full-width']}
                  type="submit"
                  loading={
                    operations.kycVerification.status === FetchStatus.PENDING
                  }
                  disabled={
                    !userKycForm.formState.isValid ||
                    operations.kycVerification.status === FetchStatus.PENDING
                  }
                >
                  {t(
                    'views.User.views.Kyc.button.startVerification',
                    'Start verification'
                  )}
                </Button>
                <Trans i18nKey={'views.User.views.Kyc.veriffIsAn'}>
                  <span>
                    <a href="https://www.veriff.com/">Veriff</a> is an identity
                    verification provider that helps companies connect with
                    customers
                  </span>
                </Trans>
              </div>
            </form>
          )}
          {submissionStage === 'complete' && <KycStatus status="complete" />}
          {submissionStage === 'submitted' && (
            <KycStatus
              status={getGeneralStatusFromKycStatus(
                userDbData.resource.kycStatus
              )}
              isResubmissionAvailable={isResubmissionAvailableFromKycStatus(
                userDbData.resource.kycStatus,
                userDbData.resource.kycLastEventCreatedAt
                  ? dayjs(userDbData.resource.kycLastEventCreatedAt)
                  : null
              )}
              onResubmissionClick={() => setSubmissionStage('available')}
            />
          )}
        </>
      ) : null}
    </BaseCard>
  )
}

export { KycView }
