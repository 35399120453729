import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import { IIntegrationsNetProvider, IIntegrationsNetApplicant } from './types'
import {
  requestApplicant,
  executeApplicantOptIn,
  executeApplicantOptOut,
  executeApplicantConfirmRequirement,
  executeNodeOperatorRegistration,
} from './utils'

const IntegrationsNetProviderContext =
  React.createContext<IIntegrationsNetProvider | null>(null)

const IntegrationsNetProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const applicant = useFetchableResource<IIntegrationsNetApplicant | null>(null)

  const ctx: IIntegrationsNetProvider = {
    applicant,
    requestApplicant: applicant.wrappedFetch(requestApplicant),
    executeApplicantOptIn,
    executeApplicantOptOut,
    executeApplicantConfirmRequirement,
    executeNodeOperatorRegistration,
  }

  return (
    <IntegrationsNetProviderContext.Provider value={ctx}>
      {children}
    </IntegrationsNetProviderContext.Provider>
  )
}

export { IntegrationsNetProvider, IntegrationsNetProviderContext }
