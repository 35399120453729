import { createProviderStateHook } from '@lattice/utils'

import { WalletProvider, WalletProviderContext } from './provider'

const useWalletProvider = createProviderStateHook(
  WalletProvider,
  WalletProviderContext
)

export { useWalletProvider }
