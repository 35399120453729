import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const SubtextCard = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <p className={cls(styles.subtext, className)}>{children}</p>
}

export { SubtextCard }
