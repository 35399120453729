const customReactSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#21242E',
    border: '0.5px solid rgba(255, 255, 255, 0.12);',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);',
    color: 'rgba(255, 255, 255, 0.66);',
  }),

  menu: (styles) => ({
    ...styles,
    backgroundColor: '#21242E',
    border: '0.5px solid rgba(255, 255, 255, 0.12);',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);',
    color: 'rgba(255, 255, 255, 0.66);',
    marginTop: '0px',
  }),

  menuList: (styles) => ({
    ...styles,
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingTop: '0px',
    paddingBottom: '0px',
  }),

  option: (styles, state) => ({
    ...styles,
    padding: '16px 20px;',
    gap: '10px;',
    background: state.isFocused ? '#333744' : '#21242E',
  }),

  multiValue: (styles) => ({
    ...styles,
    background: 'transparent',
  }),

  multiValueLabel: () => ({
    fontFamily: 'IBM Plex Mono',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(255, 255, 255, 0.96)',
  }),
}

export { customReactSelectStyles }
