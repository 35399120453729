import React from 'react'
import { Link } from 'react-router-dom'

import styles from './component.module.scss'

const DetailsTitle = ({
  parentNameTitle,
  childNameTitle,
}: {
  parentNameTitle: string
  childNameTitle: string
}) => {
  return (
    <div className={styles.pageTitle}>
      <span>
        <Link to="../" className={styles.link}>
          {parentNameTitle}
        </Link>
        {'  /  '}
        {childNameTitle}
      </span>
    </div>
  )
}

export { DetailsTitle }
