// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LUG5NAvi0eC4_JZuV8oZ{display:grid;grid-template-columns:repeat(3, 428px);grid-gap:24px;max-width:1200px;padding-bottom:40px}@media(max-width: 1450px){.LUG5NAvi0eC4_JZuV8oZ{grid-template-columns:repeat(2, 428px)}}@media(max-width: 1000px){.LUG5NAvi0eC4_JZuV8oZ{grid-template-columns:repeat(1, 350px)}}`, "",{"version":3,"sources":["webpack://./src/views/DashboardView/components/GeneralInfoContainer/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sCAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CAEA,0BAPF,sBAQI,sCAAA,CAAA,CAGF,0BAXF,sBAYI,sCAAA,CAAA","sourcesContent":[".gridContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 428px);\n  grid-gap: 24px;\n  max-width: 1200px;\n  padding-bottom: 40px;\n\n  @media (max-width: 1450px) {\n    grid-template-columns: repeat(2, 428px);\n  }\n\n  @media (max-width: 1000px) {\n    grid-template-columns: repeat(1, 350px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": `LUG5NAvi0eC4_JZuV8oZ`
};
export default ___CSS_LOADER_EXPORT___;
