import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import { RewardProgram, IRewardsProgramsProviderContext } from './types'
import { requestPrograms } from './utils'

const RewardsProgramsProviderContext =
  React.createContext<IRewardsProgramsProviderContext | null>(null)

const RewardsProgramsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const programs = useFetchableResource<RewardProgram[]>([])

  const context: IRewardsProgramsProviderContext = {
    programs,
    requestPrograms: programs.wrappedFetch(requestPrograms),
  }

  return (
    <RewardsProgramsProviderContext.Provider value={context}>
      {children}
    </RewardsProgramsProviderContext.Provider>
  )
}

export { RewardsProgramsProvider, RewardsProgramsProviderContext }
