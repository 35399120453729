import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Decimal from 'decimal.js'

import { ReactComponent as CheckMarkFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import { ReactComponent as CloseFilledIcon } from '@lattice/assets/icons/custom/close-filled.svg'
import {
  Button,
  MainProjectCard,
  Table,
  Tabs,
} from '@lattice/common/components'
import {
  useNodeManagerProvider,
  useUserOAuthProvider,
  useUserProvider,
} from '@lattice/common/providers'
import { FetchStatus, OAuthProviderURI } from '@lattice/common/consts'
import {
  formatNumberAndCurrency,
  getNodeProperty,
  NodePropertyName,
  NumberFormat,
} from '@lattice/utils'
import {
  NetworkCurrencies,
  NetworkCurrency,
} from '@lattice/utils/networkCurrencies'
import { useProgressToasts } from '@lattice/common/hooks'

import styles from './component.module.scss'

const GeneralDetails = () => {
  const params = useParams()
  const {
    operations,
    userOAuthProviderStatuses,
    doRequestOAuthFlowForProvider,
    requestUserOAuthStatusForProvider,
  } = useUserOAuthProvider()
  const { user } = useUserProvider()
  const {
    nodeTypes,
    userNode,
    requestNodeTypes,
    requestUserNode,
    integrations: {
      dorapi: {
        operations: dorApiOperations,
        requestUserDevices,
        unregisterDorApiUserDevice,
      },
    },
  } = useNodeManagerProvider()
  const unregisterDorApiUserDeviceProgressToasts = useProgressToasts()

  if (!userNode.resource) {
    throw new Error(
      '<GeneralDetailsView/> must only be rendered when a bounty is fetched'
    )
  }

  const doUnregisterDorApiUserDevice =
    unregisterDorApiUserDeviceProgressToasts.wrappedErrorsAsync(
      async (deviceSerial: string) => {
        if (!userNode.resource) {
          throw new Error('Invalid user node state')
        }

        unregisterDorApiUserDeviceProgressToasts.progress(
          'Removing device',
          'info',
          null
        )

        await unregisterDorApiUserDevice(userNode.resource.slug, deviceSerial)

        unregisterDorApiUserDeviceProgressToasts.progress(
          'Successfully removed device',
          'success',
          15000
        )

        requestUserDevices()
        params.slug && requestUserNode(params.slug)
      }
    )

  useEffect(() => {
    requestUserOAuthStatusForProvider(OAuthProviderURI.DOR_DTM)
  }, [user])

  useEffect(() => {
    requestNodeTypes()
  }, [])

  return (
    <MainProjectCard
      title={userNode.resource.name}
      bannerUrl={
        'https://www.lattice.is/static/media/dtmBanner.50a4fd71a19fe5943dad.png'
      }
      header={
        <div className={styles.nodeCardTitle}>
          <div className={styles.titleDuo}>
            <div>{userNode.resource.name}</div>
            <div>DTM Node</div>
          </div>
          <div className={styles.titleDuo}>
            <div>
              {formatNumberAndCurrency(
                new Decimal(
                  userNode.resource.lifetimeRewards['constellation:dag'] ?? 0
                ),
                NetworkCurrencies[NetworkCurrency.CONSTELLATION__DAG].symbol,
                NumberFormat.MILLIFY
              )}
            </div>
            <div>Lifetime Rewards</div>
          </div>
        </div>
      }
    >
      <Tabs>
        <Tabs.Tab
          label="Commissions"
          content={
            <div className={styles.bountiesSection}>
              <div className={styles.bountiesTable}>
                <h4>
                  Active Commissions ({userNode.resource.bountyNodes.length})
                </h4>
                <Table
                  variants={['slim']}
                  className={{ root: styles.table }}
                  data={userNode.resource.bountyNodes.map((bountyNode) => ({
                    bountyId: bountyNode.id,
                    bounty: bountyNode.bounty.name,
                    timeline: 'Ongoing',
                    totalRewards: new Decimal(
                      bountyNode.bounty.lifetimeRewards ?? 0
                    ),
                    currency:
                      nodeTypes.resource.find(
                        (nodeType) =>
                          nodeType.uri === bountyNode.bounty.rewardProvisionUri
                      )?.currency ?? null,
                    detail: (
                      <div className={styles.requirementsDetail}>
                        <h5>Commission Requirements</h5>
                        <div>
                          {bountyNode.bounty.rules.map((rule) => (
                            <div
                              key={rule.id}
                              className={styles.ruleRequirement}
                            >
                              {rule.name}{' '}
                              {rule.evaluatedResult ? (
                                <CheckMarkFilledIcon
                                  width={14}
                                  height={14}
                                  color={'#03BC3D'}
                                />
                              ) : (
                                <CloseFilledIcon
                                  width={14}
                                  height={14}
                                  color={'#E64434'}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <div className={styles.buttonHolder}>
                          <Button.Link
                            to={`/rewards/bounties/${bountyNode.bounty.slug}`}
                            variants={['secondary', 'right-icon']}
                          >
                            View commission program
                          </Button.Link>
                        </div>
                      </div>
                    ),
                  }))}
                  primaryKey={'bountyId'}
                  detailKey={'detail'}
                  emptyState={{
                    bounty: '---',
                    timeline: '---',
                    totalRewards: '---',
                  }}
                  titles={{
                    bounty: {
                      content: 'Commission',
                      sortable: false,
                    },
                    timeline: {
                      content: 'Timeline',
                      sortable: false,
                    },
                    totalRewards: {
                      content: (
                        <div className={styles.totalRewardsHCol}>
                          {'Total Rewards'}
                        </div>
                      ),
                      sortable: false,
                    },
                  }}
                  formatData={{
                    bounty: (value) => (
                      <div className={styles.bountyCol}>
                        {'>'} {value}
                      </div>
                    ),
                    totalRewards: (value, record) => (
                      <div className={styles.totalRewardsCol}>
                        {value.isZero()
                          ? `-- ${
                              record.currency === null
                                ? ''
                                : record.currency === 'constellation:dag'
                                  ? NetworkCurrencies[
                                      NetworkCurrency.CONSTELLATION__DAG
                                    ].symbol
                                  : 'DOR'
                            }`
                          : formatNumberAndCurrency(
                              value,
                              record.currency === null
                                ? ''
                                : record.currency === 'constellation:dag'
                                  ? NetworkCurrencies[
                                      NetworkCurrency.CONSTELLATION__DAG
                                    ].symbol
                                  : 'DOR',
                              NumberFormat.DECIMALS_TRIMMED
                            )}
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={styles.bountiesTable}>
                <h4 className={styles.flexBox}>
                  <span>Past Commissions (0)</span>
                  <Link
                    className={styles.rewardsHistoryLink}
                    to="/user/rewards"
                  >
                    View Rewards History →
                  </Link>
                </h4>
                <Table
                  variants={['slim']}
                  className={{ root: styles.table }}
                  data={
                    [] as {
                      bountyId: string
                      bounty: string
                      timeline: string
                      totalRewards: number
                    }[]
                  }
                  primaryKey={'bountyId'}
                  emptyState={{
                    bounty: '---',
                    timeline: '---',
                    totalRewards: '---',
                  }}
                  titles={{
                    bounty: {
                      content: 'Commission',
                      sortable: false,
                    },
                    timeline: {
                      content: 'Timeline',
                      sortable: false,
                    },
                    totalRewards: {
                      content: 'Total Rewards',
                      sortable: false,
                    },
                  }}
                />
              </div>
            </div>
          }
        />
        <Tabs.Tab
          label="Linked devices"
          content={(() => {
            const property = getNodeProperty(
              userNode.resource,
              NodePropertyName.DTM_NODE__DTM_DEVICES,
              null
            )

            if (!property) {
              return (
                <div className={styles.linkedDevicesSection}>
                  <h2>Link your first device!</h2>
                  {userOAuthProviderStatuses.resource[OAuthProviderURI.DOR_DTM]
                    ?.status === 'not_registered' && (
                    <>
                      <p>
                        To link a device, you'll need to connect to Dor OAuth
                        with the same username and password used in the{' '}
                        <a href="https://www.getdor.com/dtm-app">
                          DTM companion app
                        </a>
                        . Once authorized, you'll be redirected back here to
                        continue.
                      </p>
                      <Button
                        variants={['secondary', 'right-icon']}
                        disabled={
                          operations.doRequestOAuthFlowForProvider.status ===
                            FetchStatus.PENDING ||
                          operations.registerUserOAuthToken.status ===
                            FetchStatus.PENDING
                        }
                        loading={
                          operations.doRequestOAuthFlowForProvider.status ===
                            FetchStatus.PENDING ||
                          operations.registerUserOAuthToken.status ===
                            FetchStatus.PENDING
                        }
                        onClick={() =>
                          doRequestOAuthFlowForProvider(
                            OAuthProviderURI.DOR_DTM
                          )
                        }
                      >
                        Connect to Dor
                      </Button>
                    </>
                  )}
                </div>
              )
            }

            return (
              <div className={styles.bountiesSection}>
                <div className={styles.bountiesTable}>
                  <h4>Linked devices ({property.length})</h4>
                  <Table
                    variants={['slim']}
                    className={{ root: styles.table }}
                    data={property.map((deviceId) => ({
                      deviceId,
                      manage: (
                        <Button
                          className={styles.unlinkButton}
                          variants={['outlined']}
                          loading={
                            dorApiOperations.unregisterUserDevice.status ===
                            FetchStatus.PENDING
                          }
                          onClick={() =>
                            dorApiOperations.unregisterUserDevice.status !==
                            FetchStatus.PENDING
                              ? doUnregisterDorApiUserDevice(deviceId)
                              : () => void 0
                          }
                        >
                          Unlink
                        </Button>
                      ),
                    }))}
                    primaryKey={'deviceId'}
                    emptyState={{
                      deviceId: '---',
                    }}
                    titles={{
                      deviceId: {
                        content: 'Device Id',
                        sortable: false,
                      },
                      manage: {
                        content: 'Manage',
                        sortable: false,
                      },
                    }}
                  />
                </div>
              </div>
            )
          })()}
        />
      </Tabs>
    </MainProjectCard>
  )
}

export { GeneralDetails }
