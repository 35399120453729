import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

export type IChipProps = JSX.IntrinsicElements['span'] & {
  variants?: 'mini'[]
  disabled?: boolean
  active?: boolean
  size?: 'sm' | 'md'
  className?: string
  children?: React.ReactNode
}

const Chip = ({
  variants,
  disabled,
  active,
  size = 'md',
  className,
  children,
  ...props
}: IChipProps) => {
  return (
    <span
      {...props}
      className={cls(
        styles.root,
        styles[size],
        variants?.map((variant) => styles[variant || '']),
        disabled && styles.disabled,
        active && styles.active,
        className
      )}
    >
      {children}
    </span>
  )
}

export { Chip }
