import { createProviderStateHook } from '@lattice/utils'

import {
  RewardsProgramsProvider,
  RewardsProgramsProviderContext,
} from './provider'

const useRewardsProgramsProvider = createProviderStateHook(
  RewardsProgramsProvider,
  RewardsProgramsProviderContext
)

export { useRewardsProgramsProvider }
