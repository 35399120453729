import { createProviderStateHook } from '@lattice/utils'

import { NodeManagerProvider, NodeManagerProviderContext } from './provider'

const useNodeManagerProvider = createProviderStateHook(
  NodeManagerProvider,
  NodeManagerProviderContext
)

export { useNodeManagerProvider }
