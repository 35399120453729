import React from 'react'
import cls from 'classnames'
import { PlacesType } from 'react-tooltip'

import {
  IntlAddressInputRow,
  IntlPhoneInputRow,
  NumericInputRow,
  SelectInputRow,
} from './components'
import styles from './component.module.scss'

const InputRowBase = React.forwardRef<
  HTMLInputElement,
  {
    variants?: ('full-width' | 'disabled')[]
    label?: string
    error?: string
    icon?: React.ReactNode
    labelIcon?: React.ReactNode
    iconTooltip?: string
    tooltipPlace?: PlacesType
    onIconClick?: () => void
    classNames?: {
      root?: string
      label?: string
      input?: string
      inputWrapper?: string
      error?: string
      icon?: string
      labelIcon?: string
    }
  } & JSX.IntrinsicElements['input']
>(
  (
    {
      variants,
      label,
      error,
      icon,
      labelIcon,
      iconTooltip,
      tooltipPlace,
      onIconClick,
      classNames,
      type,
      value,
      defaultValue,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cls(
          styles.root,
          variants?.includes('full-width') && styles.fullWidth,
          classNames?.root
        )}
      >
        {label && (
          <label className={cls(styles.label, classNames?.label)}>
            {label}
            {labelIcon && (
              <p
                data-tooltip-id="lattice"
                data-tooltip-content={iconTooltip}
                data-tooltip-place={tooltipPlace}
                className={styles.noMargin}
              >
                {labelIcon}
              </p>
            )}
          </label>
        )}
        <div className={cls(styles.inputWrapper, classNames?.inputWrapper)}>
          <input
            {...props}
            {...{ type, value, defaultValue }}
            className={cls(
              styles.input,
              variants?.includes('disabled') && styles.disabled,
              error && cls(styles.error, classNames?.error),
              classNames?.input
            )}
            ref={ref}
          />
          <div
            className={cls(styles.icon, classNames?.icon)}
            onClick={(e) => {
              e.stopPropagation()
              onIconClick && onIconClick()
            }}
          >
            {icon}
          </div>
        </div>
        {error && (
          <span className={cls(styles.error, classNames?.error)}>{error}</span>
        )}
      </div>
    )
  }
)

const InputRow = Object.assign(InputRowBase, {
  Numeric: NumericInputRow,
  Select: SelectInputRow,
  IntlPhone: IntlPhoneInputRow,
  IntlAddress: IntlAddressInputRow,
})

InputRow.displayName = 'InputRow'

export { InputRow }
