enum ProgramStatus {
  coming_soon = 'SOON',
  live = 'LIVE',
  pending_distribution = 'PENDING',
  funding = 'FUNDING',
  distributing = 'DISTRIBUTING',
  complete = 'COMPLETE',
  draft = 'DRAFT',
  staking_open = 'STAKING',
  in_progress = 'LIVE',
}

enum StatusColors {
  coming_soon = 'yellow',
  seeding = 'yellow',
  live = 'green',
  open = 'green',
  pending_distribution = 'purple',
  funding = 'teal',
  allocating = 'purple',
  enrolling = 'purple',
  distributing = 'blue',
  complete = 'orange',
  closed = 'orange',
  draft = 'purple',
  staking_open = 'teal',
  in_progress = 'green',
}

export { ProgramStatus, StatusColors }
