import React from 'react'
import cls from 'classnames'

import { ReactComponent as ArrowRightIcon } from '@lattice/assets/icons/custom/arrow-right.svg'

import styles from '../BaseButton/component.module.scss'
import { BaseButtonProps } from '../BaseButton'

const ButtonLinkExternal = ({
  rightIcon = <ArrowRightIcon width={16} height={16} />,
  variants,
  className,
  disabled,
  children,
  ...props
}: Omit<BaseButtonProps<JSX.IntrinsicElements['a']>, 'loading'> & {
  disabled?: boolean
}) => {
  return (
    <a
      className={cls(
        styles.main,
        disabled && styles.disabled,
        variants?.includes('right-icon') && styles.rightIcon,
        variants?.includes('primary') && styles.primary,
        variants?.includes('secondary') && styles.secondary,
        variants?.includes('outlined') && styles.outlined,
        variants?.includes('full-width') && styles.fullWidth,
        variants?.includes('slim') && styles.slim,
        variants?.includes('invisible') && styles.invisible,
        variants?.includes('primary-outlined') && styles.primaryOutlined,
        className
      )}
      {...props}
    >
      {children}
      {variants?.includes('right-icon') && (
        <div className={styles.iconHolder}>{rightIcon}</div>
      )}
    </a>
  )
}

export { ButtonLinkExternal }
