import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const Content = ({
  children,
  padding = true,
}: {
  children: React.ReactNode
  padding?: boolean
}) => {
  return (
    <div className={cls(styles.wrapper, padding && styles.padding)}>
      <div className={cls(styles.content)}>{children}</div>
    </div>
  )
}

export { Content }
