import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as WalletIcon } from '@lattice/assets/icons/feather/WalletIcon.svg'
import { useWalletProvider } from '@lattice/common/providers'
import { Typography } from '@lattice/common/components'

import { Button } from '../Button'

import styles from './component.module.scss'

const ConnectYourWallet = ({ message }: { message?: string }) => {
  const { t } = useTranslation()
  const { requestConnectorActivation } = useWalletProvider()

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <WalletIcon width={40} height={40} />
      </div>
      <div className={styles.titleContainer}>
        <Typography.HeaderCardTitle>
          {t(
            'containers.Governance.connectWallet.title',
            'Connect your Wallet'
          )}
        </Typography.HeaderCardTitle>
      </div>
      <div className={styles.message}>
        <Typography.SubtextCard>{message}</Typography.SubtextCard>
      </div>
      <Button
        variants={['outlined']}
        onClick={() => requestConnectorActivation()}
        className={styles.button}
      >
        {t('commmon.components.Header.connectWallet', 'Connect wallet')}
      </Button>
    </div>
  )
}

export { ConnectYourWallet }
