// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IWsRgmC6ulp1NhtoxvUE{font-family:"TWK Lausanne";font-style:normal;font-weight:300;font-size:12px;line-height:18px;letter-spacing:.03em;color:rgba(255,255,255,.66)}.RivDB2UGANXp1PAOYfrT{display:flex;column-gap:27px;width:100%}.e66oFVR_5RXk17uoRDAA{cursor:pointer;color:rgba(255,255,255,.12)}.e66oFVR_5RXk17uoRDAA:hover{color:#8d93a4}`, "",{"version":3,"sources":["webpack://./src/common/components/DetailsInfoCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,2BAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,2BAAA,CACA,4BACE,aAAA","sourcesContent":[".infoSubText {\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: rgba(#fff, 0.66);\n}\n\n.infoButton {\n  display: flex;\n  column-gap: 27px;\n  width: 100%;\n}\n\n.help {\n  cursor: pointer;\n  color: rgba(#fff, 0.12);\n  &:hover {\n    color: #8d93a4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoSubText": `IWsRgmC6ulp1NhtoxvUE`,
	"infoButton": `RivDB2UGANXp1PAOYfrT`,
	"help": `e66oFVR_5RXk17uoRDAA`
};
export default ___CSS_LOADER_EXPORT___;
