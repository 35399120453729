import React from 'react'
import cls from 'classnames'

import { ReactComponent as StatusDotIcon } from '@lattice/assets/icons/custom/statusdot.svg'

import styles from './component.module.scss'

const WalletItem = ({
  onItemClick,
  status,
  walletName,
  walletLogoUrl,
  className,
}: {
  onItemClick?: () => void
  status: 'disconnected' | 'connecting' | 'connected'
  walletName: string
  walletLogoUrl?: string
  className?: string
}) => {
  return (
    <div
      className={cls(styles.main, styles[status], className)}
      onClick={onItemClick}
    >
      <StatusDotIcon
        width={8}
        height={8}
        className={cls(styles.statusDot, styles[status])}
      />
      <span>{walletName}</span>
      {walletLogoUrl && <img src={walletLogoUrl} />}
    </div>
  )
}

export { WalletItem }
