import React, { useState } from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const RecordEntries = <K extends string, V>(record: Record<K, V>): [K, V][] => {
  return Object.entries(record) as any
}

const Table = <
  DataKeys extends string,
  TitleKeys extends DataKeys,
  SortKeys extends DataKeys & TitleKeys,
  DataRecord extends Record<DataKeys, any>,
>({
  titles,
  data,
  primaryKey,
  detailKey,
  emptyState,
  formatData,
  sortedColumn,
  sortedColumnType,
  onSort,
  variants,
  className,
  sx,
}: {
  titles: Record<
    TitleKeys,
    {
      content: React.ReactNode
      sortable: boolean
    }
  >
  data: DataRecord[]
  primaryKey: keyof DataRecord
  detailKey?: keyof DataRecord
  emptyState?: {
    [Prop in TitleKeys]?: React.ReactNode
  }
  formatData?: Partial<{
    [Prop in TitleKeys]: (
      value: DataRecord[Prop],
      record: DataRecord
    ) => React.ReactNode
  }>
  sortedColumn?: SortKeys
  sortedColumnType?: 'ASC' | 'DESC'
  onSort?: (key: SortKeys, type: 'ASC' | 'DESC') => void
  variants?: 'slim'[]
  className?: {
    root?: string

    header?: string
    headerRow?: string
    headerCell?: string
    headerCellContent?: string

    body?: string
    bodyRow?: string
    bodyCell?: string
    bodyCellContent?: string

    footer?: string

    cells?: {
      header?: Partial<
        Record<TitleKeys, { cell?: string; cellContent?: string }>
      >
      body?: Partial<Record<TitleKeys, { cell?: string; cellContent?: string }>>
    }
  }
  sx?: {
    root?: React.CSSProperties

    header?: React.CSSProperties
    headerRow?: React.CSSProperties
    headerCell?: React.CSSProperties
    headerCellContent?: React.CSSProperties

    body?: React.CSSProperties
    bodyRow?: React.CSSProperties
    bodyCell?: React.CSSProperties
    bodyCellContent?: React.CSSProperties

    footer?: React.CSSProperties

    cells?: {
      header?: Partial<
        Record<
          TitleKeys,
          {
            cell?: React.CSSProperties
            cellContent?: React.CSSProperties
          }
        >
      >
      body?: Partial<
        Record<
          TitleKeys,
          {
            cell?: React.CSSProperties
            cellContent?: React.CSSProperties
          }
        >
      >
    }
  }
}): JSX.Element => {
  const [detailId, setDetailId] = useState<React.ReactNode | null>(null)

  return (
    <table
      className={cls(
        styles.root,
        className?.root,
        variants?.includes('slim') && styles.vSlim
      )}
    >
      <thead
        className={cls(
          styles.header,
          className?.header,
          variants?.includes('slim') && styles.vSlim
        )}
      >
        <tr className={cls(styles.headerRow, className?.headerRow)}>
          {RecordEntries(titles).map(([key, { content, sortable }]) => {
            const headerCellClassNames = className?.cells?.header?.[key]
            const headerCellStyles = sx?.cells?.header?.[key]

            return (
              <th
                className={cls(
                  styles.headerCell,
                  className?.headerCell,
                  headerCellClassNames?.cell
                )}
                style={sx?.headerCell ?? headerCellStyles?.cell}
                key={key}
              >
                <div
                  className={cls(
                    styles.headerCellContent,
                    sortable && styles.sortable,
                    className?.headerCellContent,
                    headerCellClassNames?.cellContent
                  )}
                  style={sx?.headerCellContent ?? headerCellStyles?.cellContent}
                  onClick={() => {
                    if (sortable) {
                      onSort &&
                        onSort(
                          key as any,
                          sortedColumn === (key as any) &&
                            sortedColumnType === 'ASC'
                            ? 'DESC'
                            : 'ASC'
                        )
                    }
                  }}
                >
                  {content}{' '}
                  {sortedColumn === (key as any)
                    ? sortedColumnType === 'ASC'
                      ? '▴'
                      : '▾'
                    : ''}
                </div>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody
        className={cls(
          styles.body,
          className?.body,
          variants?.includes('slim') && styles.vSlim
        )}
      >
        {data.length === 0 && emptyState && (
          <tr className={cls(styles.bodyRow, className?.bodyRow)}>
            {RecordEntries(titles).map(
              ([key, { content: headerCellContent }]) => {
                const headerCellClassNames = className?.cells?.header?.[key]
                const headerCellStyles = sx?.cells?.header?.[key]

                const bodyCellClassNames = className?.cells?.body?.[key]
                const bodyCellStyles = sx?.cells?.body?.[key]

                const content = emptyState[key] ?? ''

                return (
                  <td
                    className={cls(
                      styles.bodyCell,
                      className?.bodyCell,
                      bodyCellClassNames?.cell
                    )}
                    style={sx?.bodyCell ?? bodyCellStyles?.cell}
                    key={key}
                  >
                    <div
                      className={cls(
                        styles.headerCell,
                        className?.headerCell,
                        headerCellClassNames?.cell
                      )}
                      style={sx?.headerCell ?? headerCellStyles?.cell}
                      key={key}
                    >
                      <span
                        className={cls(
                          styles.headerCellContent,
                          className?.headerCellContent,
                          headerCellClassNames?.cellContent
                        )}
                        style={
                          sx?.headerCellContent ?? headerCellStyles?.cellContent
                        }
                      >
                        {headerCellContent}
                      </span>
                    </div>
                    <div
                      className={cls(
                        styles.bodyCellContent,
                        className?.bodyCellContent,
                        bodyCellClassNames?.cellContent
                      )}
                      style={sx?.bodyCellContent ?? bodyCellStyles?.cellContent}
                    >
                      {content}
                    </div>
                  </td>
                )
              }
            )}
          </tr>
        )}
        {data.map((dataRecord, index) => (
          <>
            <tr
              className={cls(
                styles.bodyRow,
                className?.bodyRow,
                detailKey && styles.withDetailKey
              )}
              key={index}
              onClick={
                !detailKey
                  ? () => void 0
                  : () =>
                      setDetailId((currentKey) =>
                        currentKey === dataRecord[primaryKey]
                          ? null
                          : dataRecord[primaryKey]
                      )
              }
            >
              {RecordEntries(titles).map(
                ([key, { content: headerCellContent }]) => {
                  const headerCellClassNames = className?.cells?.header?.[key]
                  const headerCellStyles = sx?.cells?.header?.[key]

                  const bodyCellClassNames = className?.cells?.body?.[key]
                  const bodyCellStyles = sx?.cells?.body?.[key]

                  const formatter = formatData?.[key]

                  const content = formatter
                    ? formatter(dataRecord[key], dataRecord)
                    : dataRecord[key]

                  return (
                    <td
                      className={cls(
                        styles.bodyCell,
                        className?.bodyCell,
                        bodyCellClassNames?.cell
                      )}
                      style={sx?.bodyCell ?? bodyCellStyles?.cell}
                      key={key}
                    >
                      <div
                        className={cls(
                          styles.headerCell,
                          className?.headerCell,
                          headerCellClassNames?.cell
                        )}
                        style={sx?.headerCell ?? headerCellStyles?.cell}
                        key={key}
                      >
                        <span
                          className={cls(
                            styles.headerCellContent,
                            className?.headerCellContent,
                            headerCellClassNames?.cellContent
                          )}
                          style={
                            sx?.headerCellContent ??
                            headerCellStyles?.cellContent
                          }
                        >
                          {headerCellContent}
                        </span>
                      </div>
                      <div
                        className={cls(
                          styles.bodyCellContent,
                          className?.bodyCellContent,
                          bodyCellClassNames?.cellContent
                        )}
                        style={
                          sx?.bodyCellContent ?? bodyCellStyles?.cellContent
                        }
                      >
                        {content}
                      </div>
                    </td>
                  )
                }
              )}
            </tr>
            {detailKey && dataRecord[primaryKey] === detailId && (
              <tr
                className={cls(styles.bodyRow, className?.bodyRow)}
                key={index + '::detailkey'}
              >
                <td
                  className={cls(styles.bodyCell, className?.bodyCell)}
                  style={sx?.bodyCell}
                  colSpan={Object.keys(titles).length}
                >
                  <div
                    className={cls(
                      styles.bodyCellContent,
                      className?.bodyCellContent
                    )}
                    style={sx?.bodyCellContent}
                  >
                    {dataRecord[detailKey]}
                  </div>
                </td>
              </tr>
            )}
          </>
        ))}
      </tbody>
      <tfoot
        className={cls(
          styles.footer,
          className?.footer,
          variants?.includes('slim') && styles.vSlim
        )}
      ></tfoot>
    </table>
  )
}

export { Table }
