import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const RoundedFocusBox = ({
  children,
  label,
}: {
  children: React.ReactNode
  label?: string
}) => {
  return (
    <div className={cls(styles.main, label && styles.labeled)}>
      {label && <span className={styles.label}>{label}</span>}
      {children}
    </div>
  )
}

export { RoundedFocusBox }
