import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, InputRow } from '@lattice/common/components'

import { PoolBaseCard } from '../PoolBaseCard'
import { IStakeFormData } from '../types'

import styles from './component.module.scss'

const StakeActionCard = ({
  label,
  info,
  showHelp,
  poolLimit,
  ltxBalance,
  ltxlockedBalance,
  handleStake,
  learnMoreLink,
}: {
  label: string
  info: string
  poolLimit: number | null
  ltxBalance?: number
  ltxlockedBalance?: number
  learnMoreLink?: string
  showHelp?: string
  handleStake: (data: IStakeFormData) => Promise<void>
}) => {
  const { t } = useTranslation()
  const userStakeForm = useForm<IStakeFormData>({
    mode: 'onTouched',
  })

  const doStake = userStakeForm.handleSubmit(handleStake)

  return (
    <PoolBaseCard
      label={label}
      showHelp={showHelp}
      info={info}
      linkTo={learnMoreLink}
    >
      <form className={styles.infoData} onSubmit={doStake}>
        {!!ltxBalance && (
          <Controller
            name="ltx"
            rules={{
              required: false,
              validate: {
                greaterThanZero: (value) =>
                  value === undefined ||
                  value > 0 ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.greaterThan',
                      'Amount must be greater than 0'
                    )
                  ),
                lessThanAvailable: (value) =>
                  value === undefined ||
                  value <= ltxBalance ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.lessThan',
                      'Amount must be less than your available balance'
                    )
                  ),
                lessThanLimit: (value) =>
                  value === undefined ||
                  poolLimit === null ||
                  value <= poolLimit ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.lessThanLimit',
                      'Amount must be less than this pool limit'
                    )
                  ),
                atLeastOneField: () =>
                  Object.values(userStakeForm.getValues()).some(
                    (value) => value !== undefined
                  ) ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.atLeastOne',
                      'You must specify at least one field'
                    )
                  ),
              },
            }}
            control={userStakeForm.control}
            render={({ field: { ref: __, ...rest }, fieldState }) => (
              <InputRow.Numeric
                variants={['full-width']}
                placeholder={t(
                  'views.Launchpad.components.StakeActionCard.amount',
                  'Amount'
                )}
                icon={
                  <span className={styles.rightSideLabel}>
                    {t('views.Launchpad.components.StakeActionCard.ltx', 'ltx')}
                  </span>
                }
                error={fieldState.error?.message}
                thousandSeparator=","
                decimalScale={0}
                allowNegative={false}
                {...rest}
              />
            )}
          />
        )}
        {!!ltxlockedBalance && (
          <Controller
            name="ltx-locked"
            rules={{
              required: false,
              validate: {
                greaterThanZero: (value) =>
                  value === undefined ||
                  value > 0 ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.greaterThan',
                      'Amount must be greater than 0'
                    )
                  ),
                lessThanAvailable: (value) =>
                  value === undefined ||
                  value <= ltxlockedBalance ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.lessThan',
                      'Amount must be less than your available balance'
                    )
                  ),
                lessThanLimit: (value) =>
                  value === undefined ||
                  poolLimit === null ||
                  value <= poolLimit ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.lessThanLimit',
                      'Amount must be less than this pool limit'
                    )
                  ),
                atLeastOneField: () =>
                  Object.values(userStakeForm.getValues()).some(
                    (value) => value !== undefined
                  ) ||
                  String(
                    t(
                      'views.Launchpad.components.StakeActionCard.atLeastOne',
                      'You must specify at least one field'
                    )
                  ),
              },
            }}
            control={userStakeForm.control}
            render={({ field: { ref: __, ...rest }, fieldState }) => (
              <InputRow.Numeric
                variants={['full-width']}
                placeholder={t(
                  'views.Launchpad.components.StakeActionCard.amount',
                  'Amount'
                )}
                icon={
                  <span className={styles.rightSideLabel}>
                    {t(
                      'views.Launchpad.components.StakeActionCard.locked',
                      'locked'
                    )}
                  </span>
                }
                error={fieldState.error?.message}
                thousandSeparator=","
                decimalScale={0}
                allowNegative={false}
                {...rest}
              />
            )}
          />
        )}
        <Button
          variants={['full-width', 'primary', 'right-icon']}
          type="submit"
          disabled={!userStakeForm.formState.isValid}
        >
          {t(
            'views.Launchpad.components.StakeActionCard.softStakeInPool',
            'Soft stake in pool'
          )}
        </Button>
      </form>
    </PoolBaseCard>
  )
}

export { StakeActionCard }
