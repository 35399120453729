import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import {
  IAlkimiStakingProviderContext,
  IAlkimStakingProject,
  IAlkimStakingProjectUser,
} from './types'
import {
  doUserWithdraw,
  doUserWithdrawRewards,
  requestProject,
  requestProjectUser,
} from './utils'

const AlkimiStakingProviderContext =
  React.createContext<IAlkimiStakingProviderContext | null>(null)

const AlkimiStakingProvider = ({ children }: { children: React.ReactNode }) => {
  const project = useFetchableResource<IAlkimStakingProject | null>(null)
  const projectUser = useFetchableResource<IAlkimStakingProjectUser | null>(
    null
  )

  const ctx: IAlkimiStakingProviderContext = {
    project,
    projectUser,
    requestProject: project.wrappedFetch(requestProject),
    requestProjectUser: projectUser.wrappedFetch(requestProjectUser),
    doUserWithdraw: doUserWithdraw,
    doUserWithdrawRewards: doUserWithdrawRewards,
  }

  return (
    <AlkimiStakingProviderContext.Provider value={ctx}>
      {children}
    </AlkimiStakingProviderContext.Provider>
  )
}

export { AlkimiStakingProvider, AlkimiStakingProviderContext }
