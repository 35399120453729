import dayjs from 'dayjs'

import { IAirdrop } from '@lattice/common/providers'

import { dagBasedAirdropMessageTaggedTemplate } from './templates'

const generateDAGBasedAirdropClaimMessage = (
  ethAddress: string,
  dagAddress: string,
  airdrop: IAirdrop,
  userEmail: string
) => {
  const message = dagBasedAirdropMessageTaggedTemplate({
    addressIdentifier: `constellation:${dagAddress}`,
    airdropIdentifier: `${airdrop.slug}:${airdrop.id.substring(
      airdrop.id.length - 5
    )}`,
    additionalData: {
      requestAt: dayjs().toISOString(),
      ethAddress,
      dagAddress,
      airdropId: airdrop.id,
      userEmail,
    },
  })

  return message
}

export { generateDAGBasedAirdropClaimMessage }
