import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'

import {
  BaseCard,
  Button,
  InputRow,
  Typography,
} from '@lattice/common/components'
import { APIError, AvailableNetwork, NetworkCurrency } from '@lattice/utils'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import {
  OptInCollateralRequirementInDAG,
  useIntegrationsNetProvider,
} from '@lattice/common/providers/IntegrationsNetProvider'
import { useWalletProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import styles from './component.module.scss'

type NodeOperatorOptInConfirmationFormData = {
  walletBalance: number
}

const NodeOperatorOptInConfirmationFormCard = () => {
  const { activeWallet, requestConnectorActivation, accountsNetworkAssets } =
    useWalletProvider()
  const {
    applicant,
    executeApplicantConfirmRequirement,
    executeApplicantOptOut,
    requestApplicant,
  } = useIntegrationsNetProvider()

  const executeApplicantOptInConfirmationOperation = useFetchableOperation()
  const executeApplicantOptInConfirmationProgressToasts = useProgressToasts()

  const nodeOperatorOptInConfirmationForm =
    useForm<NodeOperatorOptInConfirmationFormData>({
      mode: 'onTouched',
      defaultValues: {
        walletBalance: 0,
      },
    })

  const validActiveWalletAddress =
    activeWallet.status === 'connected' &&
    activeWallet.constellation &&
    activeWallet.constellation.account

  const doExecuteApplicantOptInConfirmation =
    nodeOperatorOptInConfirmationForm.handleSubmit(
      executeApplicantOptInConfirmationOperation.wrappedFetch(
        executeApplicantOptInConfirmationProgressToasts.wrappedErrorsAsync(
          async () => {
            try {
              if (
                activeWallet.status !== 'connected' ||
                !activeWallet.constellation
              ) {
                throw new Error('Wallet is not active')
              }

              executeApplicantOptInConfirmationProgressToasts.progress(
                'Requesting wallet ownership signature',
                'info',
                null
              )

              const walletOwnershipToken =
                await activeWallet.constellation.requestWalletOwnershipToken()

              executeApplicantOptInConfirmationProgressToasts.progress(
                'Confirming queue position',
                'info',
                null
              )

              await executeApplicantConfirmRequirement({
                address: activeWallet.constellation.account,
                network: AvailableNetwork.CONSTELLATION,
                walletOwnershipToken,
              })

              executeApplicantOptInConfirmationProgressToasts.progress(
                'Your queue position has been confirmed',
                'success',
                10000
              )

              requestApplicant()
            } catch (e) {
              if (
                e instanceof APIError &&
                e.errorCode === 403 &&
                /User is forbidden to take this action/i.test(e.message)
              ) {
                throw new Error(
                  "Oops, it seems you're not allowed to execute this action. Contact support for further details."
                )
              } else {
                throw new Error('An error ocurred')
              }
            }
          }
        )
      )
    )

  const doExecuteApplicantOptOut =
    executeApplicantOptInConfirmationOperation.wrappedFetch(
      executeApplicantOptInConfirmationProgressToasts.wrappedErrorsAsync(
        async () => {
          try {
            const result = window.confirm(
              'Leaving the queue will remove your spot as a potential IntegrationNet Node Operator. If you choose to rejoin later, you will be placed at the end of the queue.'
            )

            if (!result) {
              return
            }

            executeApplicantOptInConfirmationProgressToasts.progress(
              'Leaving queue',
              'info',
              null
            )

            await executeApplicantOptOut()

            executeApplicantOptInConfirmationProgressToasts.progress(
              'You have opted-out of the program',
              'success',
              10000
            )

            requestApplicant()
          } catch (e) {
            if (
              e instanceof APIError &&
              e.errorCode === 403 &&
              /User is forbidden to take this action/i.test(e.message)
            ) {
              throw new Error(
                "Oops, it seems you're not allowed to execute this action. Contact support for further details."
              )
            } else {
              throw new Error('An error ocurred')
            }
          }
        }
      )
    )

  useEffect(() => {
    nodeOperatorOptInConfirmationForm.setValue(
      'walletBalance',
      accountsNetworkAssets[
        NetworkCurrency.CONSTELLATION__DAG
      ]?.balance.toNumber() ?? 0,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true }
    )
  }, [
    validActiveWalletAddress,
    accountsNetworkAssets[NetworkCurrency.CONSTELLATION__DAG],
  ])

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <div className={styles.titleText}>
          Unfortunately, you weren't selected for IntegrationsNet Node operator.
        </div>
        {applicant.resource && (
          <div className={styles.subtitleText}>
            Your position in the queue: {applicant.resource.queuePosition}/
            {applicant.resource.queueLength ?? 0}
          </div>
        )}
        <Typography.SubtextCard>
          However, you can still claim your spot in the queue for becoming a
          MainNet node operator! To claim your spot in the queue, we need to
          validate the collateral requirement. <br />
          <br />
          To confirm, you will need to connect your Stargazer wallet. The wallet
          address will be the used to verify the required DAG balance to
          participate.
        </Typography.SubtextCard>
        {validActiveWalletAddress && (
          <form
            className={styles.form}
            onSubmit={doExecuteApplicantOptInConfirmation}
          >
            <Controller
              name="walletBalance"
              rules={{
                required: true,
                validate: {
                  programRequirement: (value) =>
                    new Decimal(value).gte(OptInCollateralRequirementInDAG) ||
                    'Current wallet balance is below the required amount.',
                },
              }}
              control={nodeOperatorOptInConfirmationForm.control}
              render={({ field: { ref: __, ...rest }, fieldState }) => (
                <InputRow.Numeric
                  variants={['full-width']}
                  label={'Current wallet balance'}
                  error={fieldState.error?.message}
                  allowNegative={false}
                  suffix={' DAG'}
                  disabled
                  readOnly
                  {...rest}
                />
              )}
            />
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={
                !nodeOperatorOptInConfirmationForm.formState.isValid ||
                executeApplicantOptInConfirmationOperation.status ===
                  FetchStatus.PENDING
              }
              loading={
                executeApplicantOptInConfirmationOperation.status ===
                FetchStatus.PENDING
              }
            >
              Confirm spot in queue
            </Button>
          </form>
        )}
        {!validActiveWalletAddress && (
          <Button
            variants={['outlined', 'full-width']}
            onClick={() => requestConnectorActivation()}
          >
            Connect Stargazer wallet
          </Button>
        )}
        <Button
          variants={['outlined', 'full-width']}
          type="button"
          disabled={
            executeApplicantOptInConfirmationOperation.status ===
            FetchStatus.PENDING
          }
          loading={
            executeApplicantOptInConfirmationOperation.status ===
            FetchStatus.PENDING
          }
          onClick={() => doExecuteApplicantOptOut()}
        >
          Leave the queue
        </Button>
      </div>
    </BaseCard>
  )
}

export { NodeOperatorOptInConfirmationFormCard }
