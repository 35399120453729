import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, InfoCard } from '@lattice/common/components'

import styles from './component.module.scss'

const GettingStartedCard = () => {
  const { t } = useTranslation()

  return (
    <InfoCard
      headerText={t(
        'views.Dashboard.Cards.GettingStarted.title',
        `Getting started`
      )}
      linkTo={'https://latticeexchange.medium.com/872d3ab4f811'}
    >
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.highlighText}>
            {t('views.Dashboard.pathToLiquidity', 'The Path to Liquidity')}
          </div>
          <div className={styles.governanceTextWrapper}>
            <div className={styles.text}>
              {t(
                'views.Dashboard.Cards.GettingStarted.text',
                `Lattice is a full Web3 gateway to Constellation's ecosystem. Explore our new features such as veTokenomics, node management, and various rewards and commission programs.`
              )}
            </div>
          </div>
        </div>
        <div className={styles.linkContainer}>
          <Button.LinkExternal
            href="https://latticeexchange.medium.com/872d3ab4f811"
            target="_blank"
            rel="noopener noreferrer"
            variants={['secondary', 'right-icon']}
            className={styles.button}
          >
            {t('views.Dashboard.howLatticeWorks', 'How Lattice works')}
          </Button.LinkExternal>
        </div>
      </div>
    </InfoCard>
  )
}

export { GettingStartedCard }
