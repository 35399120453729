// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jsAQFNktDHw4kmuE9GxY{display:flex;width:100%;flex-flow:column nowrap;row-gap:12px;margin-bottom:36px}`, "",{"version":3,"sources":["webpack://./src/common/components/RadioSelectCards/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAEA,uBAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n\n  flex-flow: column nowrap;\n  row-gap: 12px;\n  margin-bottom: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `jsAQFNktDHw4kmuE9GxY`
};
export default ___CSS_LOADER_EXPORT___;
