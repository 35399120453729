import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, DropdownMulti } from '@lattice/common/components'
import {
  useBountiesProvider,
  useNodeManagerProvider,
} from '@lattice/common/providers'
import { isNodeManagerGeneralNode } from '@lattice/common/providers/NodeManagerProvider/utils'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { FetchStatus } from '@lattice/common/consts'

import styles from './component.module.scss'

type DropDownOptions = {
  label: string
  value: string
}

const BountyDeviceSelect = () => {
  const params = useParams()
  const { userNodes, requestUserNodes } = useNodeManagerProvider()
  const { bountyWithNodes, requestBountyWithNodes, joinBounty } =
    useBountiesProvider()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const optInBountyOperation = useFetchableOperation()
  const optInBountyOperationProgressToasts = useProgressToasts()

  const dropdownOptions: DropDownOptions[] =
    userNodes.resource
      ?.filter(isNodeManagerGeneralNode)
      .map((node) => ({ label: node.name, value: node.id })) ?? []

  useEffect(() => {
    params.slug && requestBountyWithNodes(params.slug)
    requestUserNodes()
  }, [])

  useEffect(() => {
    bountyWithNodes.resource &&
      setSelectedOptions(
        bountyWithNodes.resource.userNodes.map((node) => node.id)
      )
  }, [bountyWithNodes.resource])

  const handleClick = optInBountyOperation.wrappedFetch(
    optInBountyOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (!bountyWithNodes.resource) {
        throw new Error('Invalid bounty state')
      }

      optInBountyOperationProgressToasts.progress('Adding nodes', 'info', null)

      await joinBounty(bountyWithNodes.resource.slug, selectedOptions)

      optInBountyOperationProgressToasts.progress(
        'Successfully added nodes',
        'success',
        15000
      )

      requestBountyWithNodes(bountyWithNodes.resource.slug)
    })
  )

  return (
    <div className={styles.main}>
      <h3>Connect a node</h3>
      <DropdownMulti
        placeholder={'Select node(s)'}
        noOptionsMessage="No nodes found"
        options={dropdownOptions}
        setOptionSelected={setSelectedOptions}
      />
      <Button
        variants={['primary', 'full-width']}
        disabled={
          selectedOptions.length === 0 ||
          optInBountyOperation.status === FetchStatus.PENDING
        }
        loading={optInBountyOperation.status === FetchStatus.PENDING}
        className={styles.optIntoBounty}
        onClick={
          selectedOptions.length === 0 ||
          optInBountyOperation.status === FetchStatus.PENDING
            ? () => void 0
            : handleClick
        }
      >
        Opt into commission
      </Button>
    </div>
  )
}

export { BountyDeviceSelect }
