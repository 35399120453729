import React from 'react'
import cls from 'classnames'
import Decimal from 'decimal.js'

import TestnetValidatorNodeBgImage from '@lattice/assets/images/pngs/nodes/testnet_validator_node.png'
import SoftNodeBgImage from '@lattice/assets/images/pngs/nodes/soft_node.png'
import { BaseCard, Button } from '@lattice/common/components'
import PlaceholderImage from '@lattice/assets/images/pngs/bannerbg.png'
import { ReactComponent as CheckFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import { ReactComponent as WarningFilledIcon } from '@lattice/assets/icons/custom/warning-filled.svg'
import { ReactComponent as InformationFilledIcon } from '@lattice/assets/icons/custom/information-filled.svg'
import { ReactComponent as ErrorFilledIcon } from '@lattice/assets/icons/custom/error-filled.svg'
import {
  INodeManagerBasicNode,
  INodeManagerNode,
} from '@lattice/common/providers'
import {
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import {
  isNodeManagerBasicNode,
  isNodeManagerGeneralNode,
} from '@lattice/common/providers/NodeManagerProvider/utils'
import {
  NetworkCurrencies,
  NetworkCurrency,
} from '@lattice/utils/networkCurrencies'

import styles from './component.module.scss'

const NODE_TYPE_NAMES_MAP: Record<INodeManagerBasicNode['type'], string> = {
  softnode: 'Soft Node',
  testnetvalidator: 'Testnet Validator',
}

const NodeCard = ({ node }: { node: INodeManagerNode }) => {
  return (
    <BaseCard
      className={{ root: styles.root }}
      variants={['narrow']}
      header={
        <div className={styles.header}>
          <img
            className={cls(isNodeManagerGeneralNode(node) && styles.nftImage)}
            src={
              isNodeManagerGeneralNode(node)
                ? `https://constellation-nfts-assets.s3.amazonaws.com/dtm/${String(
                    node.externalNode.nftSerial
                  ).padStart(4, '0')}.png`
                : isNodeManagerBasicNode(node)
                  ? node.type === 'softnode'
                    ? SoftNodeBgImage
                    : node.type === 'testnetvalidator'
                      ? TestnetValidatorNodeBgImage
                      : PlaceholderImage
                  : PlaceholderImage
            }
          />
          <div className={styles.content}>
            {isNodeManagerBasicNode(node) ? (
              <div>
                <h2>{node.name}</h2>
                <span>{NODE_TYPE_NAMES_MAP[node.type]}</span>{' '}
                <span>
                  {shortenAddress(node.associatedAddress, 5, 5, '•••')}
                </span>
              </div>
            ) : (
              <div>
                <h2>{node.name}</h2>
                <span>DTM</span>{' '}
                <span>{shortenAddress(node.wallet.address, 5, 5, '•••')}</span>
              </div>
            )}
          </div>
        </div>
      }
    >
      <div className={styles.body}>
        {isNodeManagerGeneralNode(node) && (
          <>
            <div className={styles.stats}>
              <div className={styles.stat}>
                <span>Node status</span>
                <span>
                  <CheckFilledIcon width={16} style={{ color: '#55c555' }} />{' '}
                  Active
                </span>
              </div>
              <div className={styles.stat}>
                <span>Lifetime rewards</span>
                <span>
                  {formatNumberAndCurrency(
                    new Decimal(node.lifetimeRewards['constellation:dag'] ?? 0),
                    NetworkCurrencies[NetworkCurrency.CONSTELLATION__DAG]
                      .symbol,
                    NumberFormat.MILLIFY
                  )}
                </span>
              </div>
            </div>
            <div className={styles.stat}>
              <span>Active program</span>
              <span>
                {node.bountyNodes.length > 0
                  ? node.bountyNodes[0].bounty.name +
                    (node.bountyNodes.length > 1
                      ? ` + ${node.bountyNodes.length - 1}`
                      : '')
                  : 'No'}
              </span>
            </div>
          </>
        )}
        {isNodeManagerBasicNode(node) && (
          <>
            <div className={styles.stats}>
              <div className={styles.stat}>
                <span>Node status</span>
                {node.status === 'active' && (
                  <span>
                    <CheckFilledIcon width={16} style={{ color: '#55c555' }} />{' '}
                    Active
                  </span>
                )}
                {node.status === 'partial' && (
                  <span>
                    <WarningFilledIcon
                      width={16}
                      style={{ color: '#fec84b' }}
                    />{' '}
                    Partial
                  </span>
                )}
                {node.status === 'inactive' && (
                  <span>
                    <ErrorFilledIcon width={16} style={{ color: '#f5735f' }} />{' '}
                    Inactive
                  </span>
                )}
                {node.status === 'complete' && (
                  <span>
                    <InformationFilledIcon
                      width={16}
                      style={{ color: '#5397fd' }}
                    />{' '}
                    Complete
                  </span>
                )}
              </div>
              {node.type === 'softnode' && (
                <div className={styles.stat}>
                  <span>Active shards</span>
                  <span>
                    {node.activeShards} of {node.totalShards} active
                  </span>
                </div>
              )}
              {node.type === 'testnetvalidator' && (
                <div className={styles.stat}>
                  <span>Active hours</span>
                  <span>
                    {node.activeHours} of {node.totalHours} active
                  </span>
                </div>
              )}
            </div>
            <div className={styles.stat}>
              <span>Active program</span>
              <span>{node.programName}</span>
            </div>
          </>
        )}
        {isNodeManagerGeneralNode(node) && (
          <Button.Link
            to={'dtm-nodes/' + node.slug}
            variants={['full-width', 'secondary', 'right-icon']}
          >
            View details
          </Button.Link>
        )}
        {isNodeManagerBasicNode(node) && (
          <Button.Link
            to={node.programUrl}
            variants={['full-width', 'secondary', 'right-icon']}
          >
            View details
          </Button.Link>
        )}
      </div>
    </BaseCard>
  )
}
export { NodeCard }
