import React from 'react'

import { Button } from '@lattice/common/components'

import styles from './view.module.scss'

const NotFoundView = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.errorCode}>404</div>
        <div className={styles.errorMessage}>
          {'Whoa, this '} <s className={styles.party}>party</s>{' '}
          {'page is off \nthe chain! Let’s get you'}
        </div>
        <Button.Link
          variants={['outlined', 'right-icon']}
          className={styles.button}
          to="/dashboard"
        >
          Back home
        </Button.Link>
      </div>
    </div>
  )
}

export { NotFoundView }
