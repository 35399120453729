import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'
import { NavButton, NavLink } from './NavItems'

const NavTab = ({
  navs,
  className,
}: {
  navs:
    | React.ReactElement<typeof NavLink>[]
    | React.ReactElement<typeof NavButton>[]
  className?: string
}) => {
  return (
    <div className={cls(className ? className : styles.navtab)}>
      {navs.map((item, index) => {
        return <div key={index}>{item}</div>
      })}
    </div>
  )
}

export { NavTab }
