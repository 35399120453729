import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useLocalizedValues, useProgressToasts } from '@lattice/common/hooks'
import {
  IDtmNode,
  IWalletOwnershipToken,
  useDtmNodesProvider,
  useUserProvider,
} from '@lattice/common/providers'
import {
  emailValidator,
  yupSchemaToFormValidate,
  notNullValidator,
  phoneNumberValidator,
} from '@lattice/utils'
import {
  Button,
  CheckboxField,
  Dropdown,
  InputRow,
} from '@lattice/common/components'
import { ReactComponent as InfoIcon } from '@lattice/assets/images/svgs/information.svg'
import { CountryCodes } from '@lattice/common/consts'

import styles from './component.module.scss'

type UserRedeemNFTsFormValues = {
  firstName: string
  lastName: string
  phoneNumber: string
  shippingLocation: {
    address: string
    addressExtra: string
    countryCode: string
    city: string
    state: string
    zipCode: string
    attn: string
    taxId: string
  }
  consents: {
    tos: boolean
    deviceWiFiRequirement: boolean
    understandsNoNeedForHelium: boolean
  }
}

const RedemptionForm = ({
  nodesToRedeem,
  ownershipToken,
  address,
  blacklistedCountries,
  taxIdNotRequiredCountries,
  taxIdRequiredCountries,
  handleSuccess,
  onCancel,
}: {
  nodesToRedeem: IDtmNode[]
  ownershipToken: IWalletOwnershipToken
  address: string
  blacklistedCountries: Record<string, string> | null
  taxIdNotRequiredCountries: Record<string, string> | null
  taxIdRequiredCountries: Record<string, string> | null
  handleSuccess: () => void
  onCancel: () => void
}) => {
  const { t } = useTranslation()
  const { userDbData } = useUserProvider()
  const { redeemDtmNfts } = useDtmNodesProvider()
  const tfn = useLocalizedValues({
    emailValidator,
    notNullValidator,
    phoneNumberValidator,
  })

  if (!userDbData.resource) {
    throw new Error('Invalid user state => userDbData is not available')
  }

  const formattedReadOnlyValues = {
    selectedNFTs: nodesToRedeem
      .map((node) => node.nftSerial)
      .reduce((value, serial) => value + 'DTM #' + serial + ',', '')
      .slice(0, -1),
    userEmail: userDbData.resource.email,
  }

  const userRedeemNFTsForm = useForm<UserRedeemNFTsFormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      shippingLocation: {
        address: '',
        addressExtra: '',
        countryCode: '',
        city: '',
        state: '',
        attn: '',
        zipCode: '',
        taxId: '',
      },
      consents: {
        tos: false,
        deviceWiFiRequirement: false,
        understandsNoNeedForHelium: false,
      },
    },
  })
  const userRedeemNFTsFormProgressToasts = useProgressToasts()

  const doRedeemNFTs = userRedeemNFTsForm.handleSubmit(
    userRedeemNFTsFormProgressToasts.wrappedErrorsAsync(
      async (values: UserRedeemNFTsFormValues) => {
        userRedeemNFTsFormProgressToasts.progress(
          'Submiting information',
          'info',
          null
        )

        const response = await redeemDtmNfts(
          {
            selectedNfts: formattedReadOnlyValues.selectedNFTs,
            contactEmail: formattedReadOnlyValues.userEmail,

            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,

            address1: values.shippingLocation.address,
            address2: values.shippingLocation.addressExtra,
            country_code: values.shippingLocation.countryCode,
            city: values.shippingLocation.city,
            state: values.shippingLocation.state,
            zipCode: values.shippingLocation.zipCode,
            attn: values.shippingLocation.attn,
            taxId: values.shippingLocation.taxId,

            acceptedTos: values.consents.tos,
            acceptedWifiRequirement: values.consents.deviceWiFiRequirement,
            understandNoNeedForHelium:
              values.consents.understandsNoNeedForHelium,

            country: CountryCodes[values.shippingLocation.countryCode](t),
          },
          nodesToRedeem,
          ownershipToken,
          address
        )

        if (response.data.error) {
          userRedeemNFTsFormProgressToasts.progress(
            response.data.error.message === 'Invalid zipcode'
              ? t(
                  'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.errors.invalidAddress',
                  'Invalid zipcode or city - please double check the information and submit again'
                )
              : response.data.error.message === 'Invalid country'
                ? t(
                    'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.errors.invalidCountry',
                    'Invalid country, please contact support'
                  )
                : response.data.error.message ===
                    'Validation error: Not a valid state'
                  ? t(
                      'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.errors.invalidCountry',
                      'Invalid state or city - please double check the information and submit again '
                    )
                  : t(
                      'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.errors.orderFailed',
                      'Unable to create redemption order'
                    ),
            'error',
            15000
          )
        } else {
          userRedeemNFTsFormProgressToasts.progress(
            'NFTs successfully redeemed',
            'success',
            15000
          )
          handleSuccess()
        }
      }
    )
  )

  userRedeemNFTsForm.watch(['shippingLocation.countryCode'])

  return (
    <form onSubmit={doRedeemNFTs}>
      <div className={styles.formSection}>
        <InputRow
          variants={['full-width', 'disabled']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.selectedNFTs',
            'Selected NFTs'
          )}
          labelIcon={<InfoIcon />}
          iconTooltip={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.tooltips.selectedNFTs',
            'Quantity of DTMs that you will be receiving.'
          )}
          tooltipPlace={'right'}
          value={formattedReadOnlyValues.selectedNFTs}
          disabled={true}
        />
        <InputRow
          variants={['full-width', 'disabled']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.contactEmail',
            'Contact Email'
          )}
          labelIcon={<InfoIcon />}
          iconTooltip={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.tooltips.contactEmail',
            'Email that we will use to contact you regarding your order.'
          )}
          tooltipPlace={'right'}
          value={formattedReadOnlyValues.userEmail}
          disabled={true}
        />
        <div className={styles.doubleInputRow}>
          <InputRow
            variants={['full-width']}
            label={t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.firstName',
              'First name'
            )}
            error={userRedeemNFTsForm.formState.errors.firstName?.message}
            {...userRedeemNFTsForm.register('firstName', {
              required: true,
              validate: yupSchemaToFormValidate(tfn.notNullValidator),
            })}
          />
          <InputRow
            variants={['full-width']}
            label={t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.lastName',
              'Last name'
            )}
            error={userRedeemNFTsForm.formState.errors.lastName?.message}
            {...userRedeemNFTsForm.register('lastName', {
              required: true,
              validate: yupSchemaToFormValidate(tfn.notNullValidator),
            })}
          />
        </div>
        <div className={styles.dropdownContainer}>
          <span className={styles.countryLabel}>Country</span>
          <Dropdown
            options={Object.entries(CountryCodes).map(
              ([countryCode, i18nValue]) => ({
                value: countryCode,
                content: i18nValue(t),
              })
            )}
            onOptionClick={(value) =>
              userRedeemNFTsForm.setValue(
                'shippingLocation.countryCode',
                value,
                {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                }
              )
            }
            className={{
              button: styles.dropdownButton,
              dropdownList: styles.dropdownContent,
            }}
          >
            {userRedeemNFTsForm.register('shippingLocation.countryCode', {
              required: true,
              validate: (value) => {
                if (blacklistedCountries && blacklistedCountries[value]) {
                  return 'Sorry we are unable to ship the DTM to this country'
                }
                return true
              },
            }) && false}
            {!userRedeemNFTsForm.getValues('shippingLocation.countryCode') ? (
              t(
                'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.country',
                'Select your country'
              )
            ) : (
              <span className={styles.countryName}>
                {CountryCodes[
                  userRedeemNFTsForm.getValues('shippingLocation.countryCode')
                ](t)}
              </span>
            )}
          </Dropdown>
          {userRedeemNFTsForm.formState.errors.shippingLocation
            ?.countryCode && (
            <span className={styles.error}>
              {
                userRedeemNFTsForm.formState.errors.shippingLocation
                  ?.countryCode?.message
              }
            </span>
          )}
        </div>
        <InputRow.IntlAddress
          countryCode={userRedeemNFTsForm.getValues(
            'shippingLocation.countryCode'
          )}
          variants={['full-width']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.shippingAddress',
            'Shipping address'
          )}
          labelIcon={<InfoIcon />}
          iconTooltip={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.tooltips.shippingAddress',
            'Please enter your shipping shippingLocation. Shipping times may vary depending on country. We will do our best to accommodate all geographies.'
          )}
          tooltipPlace={'right'}
          disabled={
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
            !!userRedeemNFTsForm.formState.errors.shippingLocation?.countryCode
          }
          placeholder={'Street and number, P.O. box'}
          error={
            userRedeemNFTsForm.formState.errors.shippingLocation?.address
              ?.message
          }
          {...userRedeemNFTsForm.register('shippingLocation.address', {
            required: true,
            validate: yupSchemaToFormValidate(tfn.notNullValidator),
          })}
          onPlaceChange={(address, location) => {
            if (location) {
              userRedeemNFTsForm.setValue(
                'shippingLocation.city',
                location.locality,
                {
                  shouldDirty: true,
                  shouldTouch: true,
                }
              )
              userRedeemNFTsForm.setValue(
                'shippingLocation.zipCode',
                location.postcode,
                {
                  shouldDirty: true,
                  shouldTouch: true,
                }
              )
              userRedeemNFTsForm.setValue(
                'shippingLocation.state',
                location.state,
                {
                  shouldDirty: true,
                  shouldTouch: true,
                }
              )
            }
          }}
        />
        <InputRow
          variants={['full-width']}
          placeholder={'Floor, apartment, suite, unit, building, etc.'}
          disabled={
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
            !!userRedeemNFTsForm.formState.errors.shippingLocation?.countryCode
          }
          {...userRedeemNFTsForm.register('shippingLocation.addressExtra', {
            required: false,
          })}
        />
        <InputRow
          variants={['full-width']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.attn',
            'ATTN: (Optional)'
          )}
          disabled={
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
            !!userRedeemNFTsForm.formState.errors.shippingLocation?.countryCode
          }
          {...userRedeemNFTsForm.register('shippingLocation.attn', {
            required: false,
          })}
        />
        <div className={styles.doubleInputRow}>
          <InputRow
            variants={['full-width']}
            label={t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.city',
              'City'
            )}
            disabled={
              !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
              !!userRedeemNFTsForm.formState.errors.shippingLocation
                ?.countryCode
            }
            error={
              userRedeemNFTsForm.formState.errors.shippingLocation?.city
                ?.message
            }
            {...userRedeemNFTsForm.register('shippingLocation.city', {
              required: true,
              validate: yupSchemaToFormValidate(tfn.notNullValidator),
            })}
          />
          <InputRow
            variants={['full-width']}
            label={t(
              'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.state',
              'State / Province / Region'
            )}
            disabled={
              !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
              !!userRedeemNFTsForm.formState.errors.shippingLocation
                ?.countryCode
            }
            error={
              userRedeemNFTsForm.formState.errors.shippingLocation?.state
                ?.message
            }
            {...userRedeemNFTsForm.register('shippingLocation.state', {
              required: true,
              validate: yupSchemaToFormValidate(tfn.notNullValidator),
            })}
          />
        </div>
        <InputRow
          variants={['full-width']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.zipCode',
            'ZIP / Postal code'
          )}
          disabled={
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
            !!userRedeemNFTsForm.formState.errors.shippingLocation?.countryCode
          }
          error={
            userRedeemNFTsForm.formState.errors.shippingLocation?.zipCode
              ?.message
          }
          {...userRedeemNFTsForm.register('shippingLocation.zipCode', {
            required: true,
            validate: yupSchemaToFormValidate(tfn.notNullValidator),
          })}
        />
        <InputRow.IntlPhone
          variants={['full-width']}
          label={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.phoneNumber',
            'Contact Phone #'
          )}
          labelIcon={<InfoIcon />}
          iconTooltip={t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.tooltips.phoneNumber',
            'Phone number is required for international shipments.'
          )}
          tooltipPlace={'right'}
          disabled={
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
            !!userRedeemNFTsForm.formState.errors.shippingLocation?.countryCode
          }
          defaultCountryCode={userRedeemNFTsForm.getValues(
            'shippingLocation.countryCode'
          )}
          placeholder={'e.g. 702 123 4567 '}
          error={userRedeemNFTsForm.formState.errors.phoneNumber?.message}
          {...userRedeemNFTsForm.register('phoneNumber', {
            required: true,
          })}
        />
        {userRedeemNFTsForm.getValues('shippingLocation.countryCode') &&
          taxIdNotRequiredCountries &&
          taxIdRequiredCountries &&
          !taxIdNotRequiredCountries[
            userRedeemNFTsForm.getValues('shippingLocation.countryCode')
          ] && (
            <InputRow
              variants={['full-width']}
              label={t(
                'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.form.taxId',
                `Tax ID ${
                  !taxIdRequiredCountries[
                    userRedeemNFTsForm.getValues('shippingLocation.countryCode')
                  ]
                    ? '(Optional)'
                    : ''
                }`
              )}
              labelIcon={<InfoIcon />}
              iconTooltip={t(
                'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.tooltips.taxId',
                'Tax ID for customs if your country requires it.'
              )}
              tooltipPlace={'right'}
              disabled={
                !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
                !!userRedeemNFTsForm.formState.errors.shippingLocation
                  ?.countryCode
              }
              error={
                userRedeemNFTsForm.formState.errors.shippingLocation?.taxId
                  ?.message
              }
              {...userRedeemNFTsForm.register('shippingLocation.taxId', {
                required: taxIdRequiredCountries[
                  userRedeemNFTsForm.getValues('shippingLocation.countryCode')
                ]
                  ? true
                  : false,
              })}
            />
          )}
        <div className={styles.checkboxesContainer}>
          <span className={styles.checkboxRow}>
            <CheckboxField
              disabled={
                !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
                !!userRedeemNFTsForm.formState.errors.shippingLocation
                  ?.countryCode
              }
              {...userRedeemNFTsForm.register('consents.tos', {
                required: true,
              })}
            />{' '}
            <Trans i18nKey="views.User.views.SignUp.iHaveRead">
              <div>
                I agree to the &nbsp;
                <Link to="/terms-of-service">Terms and Conditions</Link>
                &nbsp; of operating a DTM mining device
              </div>
            </Trans>
          </span>
          <span className={styles.checkboxRow}>
            <CheckboxField
              disabled={
                !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
                !!userRedeemNFTsForm.formState.errors.shippingLocation
                  ?.countryCode
              }
              {...userRedeemNFTsForm.register(
                'consents.deviceWiFiRequirement',
                {
                  required: true,
                }
              )}
            />{' '}
            <Trans i18nKey="views.User.views.SignUp.iHaveRead">
              <span>
                I understand that running this device requires a WiFi connection
                with access to the internet
              </span>
            </Trans>
          </span>
          <span className={styles.checkboxRow}>
            <CheckboxField
              disabled={
                !userRedeemNFTsForm.getValues('shippingLocation.countryCode') ||
                !!userRedeemNFTsForm.formState.errors.shippingLocation
                  ?.countryCode
              }
              {...userRedeemNFTsForm.register(
                'consents.understandsNoNeedForHelium',
                {
                  required: true,
                }
              )}
            />{' '}
            <Trans i18nKey="views.User.views.SignUp.iHaveRead">
              <span>
                I understand that my device may not have helium support for my
                location and this will not negatively impact my ability to earn
                commissions as long as I have WiFi access
              </span>
            </Trans>
          </span>
        </div>
      </div>
      <div className={styles.actionSection}>
        <Button
          variants={['primary', 'full-width']}
          type="submit"
          disabled={
            !userRedeemNFTsForm.formState.isValid ||
            userRedeemNFTsForm.formState.isSubmitting ||
            !userRedeemNFTsForm.getValues('shippingLocation.countryCode')
          }
        >
          {t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.button.submit',
            'Submit form'
          )}
        </Button>
        <Button
          variants={['secondary', 'full-width']}
          type="reset"
          disabled={userRedeemNFTsForm.formState.isSubmitting}
          onClick={() =>
            !userRedeemNFTsForm.formState.isSubmitting && onCancel()
          }
        >
          {t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.button.cancel',
            'Cancel'
          )}
        </Button>
      </div>
    </form>
  )
}

export { RedemptionForm }
