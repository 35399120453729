import React from 'react'
import cls from 'classnames'

import { useTokenPrices } from '@lattice/common/providers'

import styles from './component.module.scss'

const CurrencyTicker = ({
  coinId,
}: {
  coinId: keyof ReturnType<typeof useTokenPrices>
}) => {
  const priceList = useTokenPrices()

  const coinPrice = priceList[coinId]

  if (!coinPrice) {
    return null
  }

  return (
    <>
      <span className={cls(styles.coin, styles.coinName)}>
        {coinId.toLocaleUpperCase()}
      </span>{' '}
      <span
        className={cls(
          styles.coin,
          typeof coinPrice.usd_24h_change === 'number' &&
            coinPrice.usd_24h_change > 0
            ? styles.coinIncrease
            : styles.coinDecrease
        )}
      >
        {typeof coinPrice.usd === 'number'
          ? `$${coinPrice.usd.toLocaleString('en-US', {
              maximumFractionDigits: 4,
              minimumFractionDigits: 2,
            })} ${coinPrice.usd_24h_change > 0 ? '▴' : '▾'}`
          : 'N/A'}
      </span>
    </>
  )
}

export { CurrencyTicker }
