import React, { useEffect } from 'react'
import { t, TFunction } from 'i18next'
import cls from 'classnames'
import dayjs from 'dayjs'
import { Trans } from 'react-i18next'
import Decimal from 'decimal.js'
import { ImpulseSpinner } from 'react-spinners-kit'
import { useNavigate } from 'react-router-dom'

import {
  IRewardsHistoryContext,
  NodeRewardDataType,
  RewardsHistoryGraphPeriod,
  useRewardsHistoryProvider,
  useTokenPrices,
  useUserProvider,
} from '@lattice/common/providers'
import {
  BaseCard,
  Button,
  Content,
  Dropdown,
  FilterDropdown,
  FilterRow,
  Table,
} from '@lattice/common/components'
import { ReactComponent as ArrowBackIcon } from '@lattice/assets/icons/feather/arrow-left.svg'
import { ReactComponent as ArrowForwardIcon } from '@lattice/assets/icons/feather/arrow-right.svg'
import { ReactComponent as SuccessCircleIcon } from '@lattice/assets/images/svgs/success-circle.svg'
import { ReactComponent as FailureCircleIcon } from '@lattice/assets/images/svgs/failure-circle.svg'
import { ReactComponent as WarningCircleIcon } from '@lattice/assets/images/svgs/warning-circle.svg'
import { ReactComponent as ArrowSquareIcon } from '@lattice/assets/images/svgs/arrow-square.svg'
import { ReactComponent as CalendarIcon } from '@lattice/assets/images/svgs/calendar.svg'
import {
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import { PageTitle } from '@lattice/common/components/Typography/components'
import { GeneralColor } from '@lattice/components/common/Consts'
import { FetchStatus } from '@lattice/common/consts'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './view.module.scss'
import { RewardsHistoryGraph } from './components/graph'

const RewardsHistoryView = () => {
  const { user } = useUserProvider()
  const navigate = useNavigate()
  const tokenPrices = useTokenPrices()
  const signInUrl = useSignInRedirect()
  const {
    nodeTypes,
    nodeRewards,
    nodeRewardsFetchStatus,
    nodeRewardsGraphPoints,
    nodeRewardsGraphPointsFetchStatus,
    fetchNodeTypes,
    fetchGraphPoints,
    fetchHistoricalData,

    limit,
    setLimit,
    offset,

    sortedColumn,
    setSortedColumn,
    rewardType,
    setRewardType,

    range,
    setRange,
    graphPeriod,
    setGraphPeriod,

    hasNextPage,
    nextPage,

    hasPreviousPage,
    previousPage,
  } = useRewardsHistoryProvider()

  useEffect(() => {
    fetchNodeTypes()
  }, [user])

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }
    fetchHistoricalData()
  }, [user, rewardType, sortedColumn, limit, offset, range])

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }
    fetchGraphPoints()
  }, [user, rewardType, range, graphPeriod])

  const graphCurrency =
    nodeTypes.find((nodeType) => nodeType.uri === rewardType)?.currency ??
    'constellation:dag'

  const graphTotalRewards = nodeRewardsGraphPoints
    .filter((graphPoint) => graphPoint.currency === graphCurrency)
    .reduce(
      (total, graphPoint) => total.add(new Decimal(graphPoint.amount)),
      new Decimal(0)
    )
    .div(Decimal.pow(10, 8))
    .toNumber()

  const RewardTypeTitleMap: Record<
    NodeRewardDataType['nodeType'],
    ((t: TFunction) => string) | undefined
  > = nodeTypes.reduce((pv, nodeType) => {
    pv[nodeType.uri] = () => nodeType.name
    return pv
  }, {})

  const RewardRangeMap: Map<
    IRewardsHistoryContext['range'],
    {
      value: (t: TFunction) => string
      period: IRewardsHistoryContext['graphPeriod']
    }
  > = new Map([
    [
      'P7D',
      {
        value: (t) => t('containers.RewardsHistory.rewardsRangeMap.7d', '7D'),
        period: RewardsHistoryGraphPeriod.day,
      },
    ],
    [
      'P30D',
      {
        value: (t) => t('containers.RewardsHistory.rewardsRangeMap.30d', '30D'),
        period: RewardsHistoryGraphPeriod.day,
      },
    ],
    [
      'P90D',
      {
        value: (t) => t('containers.RewardsHistory.rewardsRangeMap.90d', '90D'),
        period: RewardsHistoryGraphPeriod.day,
      },
    ],
    [
      'YTD',
      {
        value: (t) => t('containers.RewardsHistory.rewardsRangeMap.ytd', 'YTD'),
        period: RewardsHistoryGraphPeriod.month,
      },
    ],
    [
      null,
      {
        value: (t) => t('containers.RewardsHistory.rewardsRangeMap.all', 'All'),
        period: RewardsHistoryGraphPeriod.month,
      },
    ],
  ])

  const RewardTypeFilterMap: Map<
    IRewardsHistoryContext['rewardType'],
    (t: TFunction) => string
  > = new Map([
    [
      null,
      (t) =>
        t(
          'containers.RewardsHistory.rewardsTypeFilerMap.allRewards',
          'All Rewards'
        ),
    ],
    ...nodeTypes.map(
      (nodeType) => [nodeType.uri, () => nodeType.name] as const
    ),
  ])

  const LoadingOverlay = (): JSX.Element => {
    return (
      <div className={styles.loadingOverlay}>
        <ImpulseSpinner
          frontColor={GeneralColor.USC_GOLD as unknown as string}
        />
      </div>
    )
  }

  const pagination = (className?: string) => (
    <div className={cls(styles.pagination, className)}>
      <div className={styles.paginationSize}>
        <span className={styles.showText}>
          {t('containers.RewardsHistory.pagination.show', 'Show')}
        </span>
        <Dropdown
          options={[25, 50, 100].map((option) => ({
            value: option,
            content: option,
          }))}
          onOptionClick={(value) => setLimit(value)}
          className={{ button: styles.dropdownButton }}
        >
          {limit}
        </Dropdown>
      </div>
      <div className={styles.paginationButtons}>
        <Button
          disabled={!hasPreviousPage}
          className={styles.pageButton}
          onClick={() => hasPreviousPage && previousPage()}
        >
          <ArrowBackIcon className={styles.arrowIcon} />
        </Button>
        <Button
          disabled={!hasNextPage}
          className={styles.pageButton}
          onClick={() => hasNextPage && nextPage()}
        >
          <ArrowForwardIcon className={styles.arrowIcon} />
        </Button>
      </div>
    </div>
  )

  const header = (
    <FilterRow className={styles.filterRow}>
      <FilterDropdown
        options={[...RewardRangeMap].map(([key, { value, period }]) => ({
          value: { range: key, period },
          content: value(t),
        }))}
        onOptionClick={({ range, period }) => {
          setRange(range)
          setGraphPeriod(period)
        }}
        selectedOption={null}
        className={{ button: styles.dropdownButton }}
      >
        <CalendarIcon className={styles.calendarIcon} />
        {RewardRangeMap.get(range)?.value?.(t) ?? '--'}
      </FilterDropdown>
      <FilterDropdown
        options={[...RewardTypeFilterMap].map(([key, value]) => ({
          value: key,
          content: value(t),
        }))}
        onOptionClick={(value) => {
          setRewardType(value)
        }}
        selectedOption={null}
        className={{ button: styles.dropdownButton }}
      >
        {RewardTypeFilterMap.get(rewardType)?.(t) ?? '--'}
      </FilterDropdown>
    </FilterRow>
  )

  return (
    <Content>
      <PageTitle>Rewards History</PageTitle>
      <BaseCard
        header={header}
        footer={pagination(styles.paginationFooter)}
        className={{ header: styles.headerRow, footer: styles.footer }}
      >
        <div className={styles.primaryWrapper}>
          {(nodeRewardsGraphPointsFetchStatus === FetchStatus.PENDING ||
            nodeRewardsFetchStatus === FetchStatus.PENDING) && (
            <LoadingOverlay />
          )}
          <div className={styles.rewardsTotals}>
            <div>
              <span>
                {formatNumber(graphTotalRewards, NumberFormat.DECIMALS)}
              </span>
              <span>
                {' '}
                {graphCurrency === 'constellation:dag' ? 'DAG' : 'DOR'}
              </span>
            </div>
            {graphCurrency === 'constellation:dag' && (
              <div>
                <span>
                  $
                  {tokenPrices['dag']
                    ? formatNumberAndCurrency(
                        graphTotalRewards * tokenPrices['dag'].usd,
                        'USD',
                        NumberFormat.DECIMALS
                      )
                    : '--'}
                </span>
              </div>
            )}
          </div>
          <div className={styles.graphWrapper}>
            <RewardsHistoryGraph
              data={nodeRewardsGraphPoints
                .filter((graphPoint) => graphPoint.currency === graphCurrency)
                .map((value) => ({
                  currency: value.currency,
                  amount: new Decimal(value.amount)
                    .div(Decimal.pow(10, 8))
                    .toNumber(),
                  amount_formatted: formatNumber(
                    new Decimal(value.amount)
                      .div(Decimal.pow(10, 8))
                      .toNumber(),
                    NumberFormat.MILLIFY
                  ),
                  rewardDate: dayjs(value.rewardDate).utc(),
                  rewardDate_formatted: dayjs(value.rewardDate)
                    .utc()
                    .format(
                      graphPeriod === RewardsHistoryGraphPeriod.month
                        ? 'MMM'
                        : 'DD MMM'
                    ),
                  graphPeriod,
                }))}
              graphPeriod={graphPeriod}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          {nodeRewardsFetchStatus === FetchStatus.PENDING && <LoadingOverlay />}
          <Table
            className={{ root: styles.table }}
            data={nodeRewards}
            primaryKey={'id'}
            emptyState={{
              transactionSentAt: '---',
              rewardAddress: '---',
              nodeType: '---',
              nodeExtraData: '---',
              amount: '---',
            }}
            sortedColumn={sortedColumn[0]}
            sortedColumnType={sortedColumn[1]}
            onSort={(key, type) => setSortedColumn(key, type)}
            titles={{
              transactionSentAt: {
                content: t(
                  'containers.RewardsHistory.table.titles.date',
                  'Date'
                ),
                sortable: true,
              },
              rewardAddress: {
                content: t(
                  'containers.RewardsHistory.table.titles.address',
                  'Address'
                ),
                sortable: false,
              },
              nodeType: {
                content: t(
                  'containers.RewardsHistory.table.titles.rewardType',
                  'Type'
                ),
                sortable: true,
              },
              nodeExtraData: {
                content: t(
                  'containers.RewardsHistory.table.titles.info',
                  'Info'
                ),
                sortable: false,
              },
              amount: {
                content: t(
                  'containers.RewardsHistory.table.titles.value',
                  'Value'
                ),
                sortable: true,
              },
            }}
            formatData={{
              transactionSentAt: (value) =>
                value === null ? '--' : dayjs(value).format('DD MMM, YYYY'),
              rewardAddress: (value) => (
                <a
                  className={styles.links}
                  href={`https://www.dagexplorer.io/search?term=${value}`}
                >
                  {shortenAddress(value)} <ArrowSquareIcon />
                </a>
              ),
              nodeType: (value) => RewardTypeTitleMap[value]?.(t) ?? 'Unknown',
              nodeExtraData: (value) => {
                if (value === null) {
                  return <div className={styles.infoColor}>--</div>
                }
                if (value.type === 'dtm') {
                  return (
                    <div className={styles.infoColor}>
                      {value.data.total} DTMs
                    </div>
                  )
                }
                if (value.type === 'softnode') {
                  return (
                    <div className={cls(styles.softNodeInfo, styles.infoColor)}>
                      {value.data.active === value.data.total ? (
                        <SuccessCircleIcon />
                      ) : value.data.active === 0 ? (
                        <FailureCircleIcon />
                      ) : (
                        <WarningCircleIcon />
                      )}
                      <span>
                        <Trans i18nKey="containers.RewardsHistory.table.extraData.softnodes">
                          {{ active: value.data.active }} of{' '}
                          {{ total: value.data.total }} active shards
                        </Trans>
                      </span>
                    </div>
                  )
                }
                if (value.type === 'testnetrewards') {
                  return t(
                    'containers.RewardsHistory.table.extraData.testnetrewards',
                    'Testnet Node'
                  )
                }
                return '--'
              },
              amount: (value, record) => (
                <a
                  href={`https://www.dagexplorer.io/search?term=${record.transactionId}`}
                >
                  {formatNumberAndCurrency(
                    new Decimal(value).div(Decimal.pow(10, 8)).toNumber(),
                    record.currency === 'constellation:mainnet:dor'
                      ? 'DOR'
                      : 'DAG',
                    NumberFormat.MILLIFY_WHOLE
                  )}{' '}
                  <ArrowSquareIcon />
                </a>
              ),
            }}
          />
        </div>
      </BaseCard>
    </Content>
  )
}

export { RewardsHistoryView }
