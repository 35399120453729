import { useState } from 'react'

import {
  FetchStatus,
  PaginatedFetchStatusValue,
  isFetchStatus,
  isPaginatedFetchStatusValue,
} from '../consts'

type PaginatedRequestContext = {
  limit: number
  offset: number
}

type PaginatedGetPageFunction<
  R,
  T extends (
    ...args: [context: PaginatedRequestContext, ...args: any[]]
  ) => Promise<PaginatedFetchStatusValue<R> | FetchStatus>,
> = (
  page: number,
  ...args: T extends (
    ...args: [context: PaginatedRequestContext, ...infer A]
  ) => Promise<PaginatedFetchStatusValue<R> | FetchStatus>
    ? A
    : never
) => Promise<PaginatedFetchStatusValue<R> | FetchStatus>

type PaginatedFetchableResource<R> = {
  resource: R
  status: FetchStatus
  currentPage: number
  totalPages: number
  setLimit: (limit: number) => void
  fetch: (
    valueWrapped:
      | Promise<PaginatedFetchStatusValue<R> | FetchStatus>
      | PaginatedFetchStatusValue<R>
      | FetchStatus
  ) => Promise<void>
  createGetPage: <
    T extends (
      ...args: [context: PaginatedRequestContext, ...args: any[]]
    ) => Promise<PaginatedFetchStatusValue<R> | FetchStatus>,
  >(
    fetcher: T
  ) => PaginatedGetPageFunction<R, T>
}

const usePaginatedFetchableResource = <R>(
  initialState: R,
  initialLimit: number
) => {
  const [resource, setResource] = useState<R>(initialState)
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.IDLE)
  const [limit, setLimit] = useState<number>(initialLimit)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)

  const fetchableResource: PaginatedFetchableResource<R> = {
    resource,
    status,
    currentPage,
    totalPages: Math.ceil(total / limit),
    setLimit,
    fetch: async (valueWrapped) => {
      try {
        setStatus(FetchStatus.PENDING)
        const value = await valueWrapped
        if (isPaginatedFetchStatusValue(value)) {
          setStatus(value.status)
          setTotal(value.total)
          value.value && setResource(value.value)
        } else if (isFetchStatus(value)) {
          setStatus(value)
        }
      } catch (e) {
        console.error(e)
        setStatus(FetchStatus.ERROR)
        throw e
      }
    },
    createGetPage: (fetcher) => {
      return async (page, ...args) => {
        const totalPages = Math.ceil(total / limit)
        page = Math.min(totalPages, Math.max(0, page))

        const context: PaginatedRequestContext = {
          limit,
          offset: page * limit,
        }

        const result = fetcher(context, ...args)
        await fetchableResource.fetch(result)
        setCurrentPage(page)

        return result
      }
    },
  }

  return fetchableResource
}

export {
  usePaginatedFetchableResource,
  PaginatedFetchableResource,
  PaginatedRequestContext,
  PaginatedGetPageFunction,
}
