import ReactGA from 'react-ga4'

import { ExecutionContext } from '@lattice/common/consts'

const isProduction = ExecutionContext.nodeEnv === 'production'

const initializeReactGaLib = async () => {
  await ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID ?? '', {
    testMode: !isProduction,
  })
}

export { initializeReactGaLib }
