import { createProviderStateHook } from '@lattice/utils'

import { LAST_RECORDED_DATA_STORAGE_KEY } from './consts'
import { DebugDataProvider, DebugDataProviderContext } from './provider'

const useDebugDataProvider = Object.assign(
  createProviderStateHook(DebugDataProvider, DebugDataProviderContext),
  {
    lastRecordedData: () =>
      window.localStorage.getItem(LAST_RECORDED_DATA_STORAGE_KEY),
  }
)

export { useDebugDataProvider }
