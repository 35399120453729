import React, { useEffect } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'

import {
  Button,
  Content,
  DetailsInfoCard,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  NoContent,
  PendingContent,
  ProgramDetailProjectContainer,
  Typography,
} from '@lattice/common/components'
import { useBountiesProvider, useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import { DetailsBaseCardContainer } from '@lattice/common/components/DetailsBaseCardContainer/component'
import { CardSectionContainer } from '@lattice/common/components/CardSectionContainer'
import { CardLabelContainer } from '@lattice/common/components/CardLabelContainer'
import { useSignInRedirect } from '@lattice/common/hooks'

import { BountyDeviceSelect, BountyInformation } from './components'

const BountyDetailsView = () => {
  const params = useParams()
  const { bounty, requestBounty } = useBountiesProvider()
  const { user } = useUserProvider()
  const signInUrl = useSignInRedirect()

  useEffect(() => {
    params.slug && requestBounty(params.slug)
  }, [params.slug])

  return (
    <Content>
      {bounty.status === FetchStatus.PENDING && <PendingContent />}
      {bounty.status === FetchStatus.DONE && bounty.resource && (
        <>
          <Typography.DetailsTitle
            childNameTitle={bounty.resource.name}
            parentNameTitle={'Rewards Programs'}
          />
          <ProgramDetailProjectContainer>
            <DetailsMainCardContainer>
              <Outlet />
            </DetailsMainCardContainer>
            <DetailsSideCardsContainer>
              <DetailsBaseCardContainer variants={['full-width']}>
                <BountyInformation />
                {!user && (
                  <CardSectionContainer>
                    <CardLabelContainer>
                      Sign in to participate
                    </CardLabelContainer>
                    <Button.Link
                      variants={['primary', 'full-width']}
                      to={signInUrl}
                    >
                      Sign in
                    </Button.Link>
                    <sub>
                      Don't have a Lattice account?{' '}
                      <Link to="/user/signup">Register now.</Link>
                    </sub>
                  </CardSectionContainer>
                )}
              </DetailsBaseCardContainer>
              {user && (
                <DetailsBaseCardContainer variants={['full-width']}>
                  <CardSectionContainer>
                    <BountyDeviceSelect />
                  </CardSectionContainer>
                </DetailsBaseCardContainer>
              )}
              <DetailsInfoCard
                label={'Get a Dor Traffic Miner'}
                subText={`Get a DTM device you can place in any doorway, anywhere. While it collects foot traffic data, you earn crypto rewards.`}
                actionElement={
                  <>
                    <Button.LinkExternal
                      variants={['full-width', 'outlined', 'right-icon']}
                      href="https://constellation-network.myshopify.com/"
                      target="_blank"
                    >
                      Get a DTM
                    </Button.LinkExternal>
                    <Button.Link
                      variants={['full-width', 'outlined', 'right-icon']}
                      to="/rewards/bounties/dtm-nft/redeem"
                    >
                      Redeem DTM
                    </Button.Link>
                  </>
                }
                variants={['full-width']}
              />
            </DetailsSideCardsContainer>
          </ProgramDetailProjectContainer>
        </>
      )}
      {bounty.status !== FetchStatus.PENDING && !bounty.resource && (
        <NoContent />
      )}
    </Content>
  )
}

export { BountyDetailsView }
