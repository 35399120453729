import React from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'

import { StepArrowButton } from '@lattice/common/components'

import styles from './view.module.scss'

const BuyGeneralView = () => {
  const navigate = useNavigate()
  const matchOnMethodPath = useMatch('/swap-buy/buy/method')

  return (
    <>
      <Outlet />
      <div className={styles.sliderDots}>
        <StepArrowButton
          direction="left"
          selected={!matchOnMethodPath}
          onClick={!matchOnMethodPath ? () => navigate('./method') : undefined}
        />
        <StepArrowButton direction="right" selected={!!matchOnMethodPath} />
      </div>
    </>
  )
}

export { BuyGeneralView }
