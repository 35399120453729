import dayjs from 'dayjs'
import dayjs_utc from 'dayjs/plugin/utc'
import dayjs_duration from 'dayjs/plugin/duration'
import dayjs_relativetime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/en'
import 'dayjs/locale/es'

const initializeDayjsLib = () => {
  dayjs.extend(dayjs_utc)
  dayjs.extend(dayjs_duration)
  dayjs.extend(dayjs_relativetime)
}

export { initializeDayjsLib }
