import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { dag4 } from '@stardust-collective/dag4'

import { Button, InputRow } from '@lattice/common/components'
import { getTypedContract, shortenAddress } from '@lattice/utils'
import { ReactComponent as StatusDotIcon } from '@lattice/assets/icons/custom/statusdot.svg'
import {
  useDtmNodesProvider,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import {
  useFetchableOperation,
  useFetchableResource,
} from '@lattice/common/hooks'
import {
  DeploymentStageMasterChain,
  FetchStatus,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/consts'
import { useProgressToasts } from '@lattice/common/hooks/useProgressToasts'

import {
  ProgramCard,
  ProgramCardTitle,
  RoundedFocusBox,
  Stat,
  StatsList,
} from '../../../SoftNodeProgramDetailView/components'

import styles from './component.module.scss'

type IRegisterDtmFormData = {
  dagAddress: string
}

const DtmNodeRegisterCard = () => {
  const { user } = useUserProvider()
  const { requestDtmUser, createDtmNode, createDtmUser } = useDtmNodesProvider()
  const { activeWallet } = useWalletProvider()

  const userRegisterDtmForm = useForm<IRegisterDtmFormData>({
    mode: 'onTouched',
    defaultValues: { dagAddress: '' },
  })
  const availableDtms = useFetchableResource<number | null>(null)
  const addDtmOperation = useFetchableOperation()
  const addDtmOperationProgressToasts = useProgressToasts()

  const doRegisterDtmNodes = userRegisterDtmForm.handleSubmit(
    addDtmOperation.wrappedFetch(
      addDtmOperationProgressToasts.wrappedErrorsAsync(async (data) => {
        if (
          !(
            user &&
            activeWallet.status === 'connected' &&
            activeWallet.ethereum
          )
        ) {
          throw new Error('Invalid context')
        }

        addDtmOperationProgressToasts.progress(
          'Saving rewards address',
          'info',
          null
        )

        await createDtmUser(data.dagAddress)

        addDtmOperationProgressToasts.progress(
          'Requesting wallet ownership signature',
          'info',
          null
        )

        const ownershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()

        addDtmOperationProgressToasts.progress('Adding nodes', 'info', null)

        await createDtmNode(activeWallet.ethereum.account, ownershipToken)

        addDtmOperationProgressToasts.progress(
          'Successfully added nodes',
          'success',
          15000
        )

        requestDtmUser()
      })
    )
  )

  const doFetchAvailableDtmsBalance = availableDtms.wrappedFetch(async () => {
    if (!(activeWallet.status === 'connected' && activeWallet.ethereum)) {
      return null
    }

    const dtmNftContract = getTypedContract(
      'DorTrafficMinerNFT',
      RegisteredTokens[RegisteredToken.DTM].instances[
        DeploymentStageMasterChain
      ].address
    )

    const balanceRaw = await dtmNftContract.balanceOf(
      activeWallet.ethereum.account
    )
    const balance = balanceRaw.toNumber()

    return balance
  })

  useEffect(() => {
    if (activeWallet.status === 'connected' && activeWallet.ethereum) {
      doFetchAvailableDtmsBalance()
    }
  }, [activeWallet.status, activeWallet.connector])

  if (!(user && activeWallet.status === 'connected' && activeWallet.ethereum)) {
    return null
  }

  return (
    <ProgramCard>
      <form onSubmit={doRegisterDtmNodes} className={styles.form}>
        <ProgramCardTitle>Connected Wallet</ProgramCardTitle>
        <RoundedFocusBox>
          <StatusDotIcon width={8} height={8} style={{ color: '#03BC3D' }} />
          {shortenAddress(activeWallet.ethereum.account, 5, 5, '•••')}
        </RoundedFocusBox>
        <StatsList>
          <Stat
            title="Total DTMs"
            value={
              availableDtms.status === FetchStatus.DONE &&
              availableDtms.resource !== null
                ? availableDtms.resource
                : '--'
            }
          />
        </StatsList>
        {addDtmOperation.error && (
          <span className={styles.error}>
            {String(addDtmOperation.error.message)}
          </span>
        )}
        <span className={styles.addressFieldDesc}>
          Enter the DAG address where you would like to receive rewards.
          <br />
          You can change this anytime.
        </span>
        <InputRow
          variants={['full-width']}
          placeholder="DAG78hs..."
          error={userRegisterDtmForm.formState.errors.dagAddress?.message}
          {...userRegisterDtmForm.register('dagAddress', {
            required: true,
            validate: (value) =>
              dag4.keyStore.validateDagAddress(value) ||
              'Provided address is not valid',
          })}
        />
        <Button
          type="submit"
          variants={['full-width', 'primary']}
          loading={addDtmOperation.status === FetchStatus.PENDING}
          disabled={
            !userRegisterDtmForm.formState.isValid ||
            addDtmOperation.status === FetchStatus.PENDING ||
            availableDtms.status === FetchStatus.PENDING ||
            !availableDtms.resource ||
            availableDtms.resource === 0
          }
        >
          Add DTMs
        </Button>
      </form>
    </ProgramCard>
  )
}

export { DtmNodeRegisterCard }
