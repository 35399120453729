import React from 'react'
import cls from 'classnames'

import { BaseCard } from '@lattice/common/components'

import styles from './view.module.scss'
import { LatestPolicy } from './providers/PrivacyPolicyProvider'

const PrivacyPolicy = () => {
  const header = <div className={styles.header}>Privacy Policy</div>
  return (
    <div className={styles.termsContainer}>
      <BaseCard
        header={header}
        className={{
          root: styles.rootCard,
          body: cls(styles.bodyCard, styles.content),
        }}
      >
        <LatestPolicy />
      </BaseCard>
    </div>
  )
}

export { PrivacyPolicy }
