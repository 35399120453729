import { createProviderStateHook } from '@lattice/utils'

import { TokenPriceProvider, TokenPriceProviderContext } from './provider'

const useTokenPriceProvider = createProviderStateHook(
  TokenPriceProvider,
  TokenPriceProviderContext
)

const useTokenPrices = () => {
  const { tokenPrices } = useTokenPriceProvider()
  return tokenPrices
}

export { useTokenPriceProvider, useTokenPrices }
