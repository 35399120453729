import React, { useEffect } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'

import { useUserProvider } from '@lattice/common/providers'

import { Footer } from '../Footer'
import { Header } from '../Header'
import { MobileHeader } from '../MobileHeader'
import { Sidebar } from '../Sidebar'

import styles from './component.module.scss'

const AppLayout = () => {
  const navigate = useNavigate()
  const { userAttributes } = useUserProvider()
  const [urlParams] = useSearchParams()

  useEffect(() => {
    if (userAttributes.resource) {
      const emailVerified = userAttributes.resource.find(
        (attr) => attr.Name === 'email_verified'
      )

      if (emailVerified && emailVerified.Value === 'false') {
        if (!urlParams.has('email') || !urlParams.has('code')) {
          navigate('/user/verifyemail')
        }
      }
    }
  }, [userAttributes.resource])

  return (
    <div className={styles.layout}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.content}>
        <Header />
        <MobileHeader />
        <Outlet />
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  )
}

export { AppLayout }
