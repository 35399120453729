import { createProviderStateHook } from '@lattice/utils'

import { BountiesProvider, BountiesProviderContext } from './provider'

const useBountiesProvider = createProviderStateHook(
  BountiesProvider,
  BountiesProviderContext
)

export { useBountiesProvider }
