import { createProviderStateHook } from '@lattice/utils'

import {
  TestnetValidatorsProvider,
  TestnetValidatorsProviderContext,
} from './provider'

const useTestnetValidatorsProvider = createProviderStateHook(
  TestnetValidatorsProvider,
  TestnetValidatorsProviderContext
)

export { useTestnetValidatorsProvider }
