// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uiOMW9rzojRaFftOEDki{grid-area:sideCardsContainer;display:flex;flex-direction:column;width:100%;gap:24px}`, "",{"version":3,"sources":["webpack://./src/common/components/DetailsSideCardsContainer/component.module.scss"],"names":[],"mappings":"AAEA,sBACE,4BAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA","sourcesContent":["@use 'common/styles/colors' as *;\n\n.sideCardsContainer {\n  grid-area: sideCardsContainer;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideCardsContainer": `uiOMW9rzojRaFftOEDki`
};
export default ___CSS_LOADER_EXPORT___;
