import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Question } from '@lattice/assets/images/svgs/Question.svg'

import styles from '../styles.module.scss'

const PoolBaseCard = ({
  label,
  showHelp,
  children,
  info,
  linkTo,
}: {
  label: string
  children: React.ReactNode
  showHelp?: string
  info?: string
  linkTo?: string
}) => {
  return (
    <div className={styles.cardSection}>
      <div className={styles.infoLabel}>
        {label}
        {showHelp && (
          <div className={styles.help}>
            <Question />
          </div>
        )}
      </div>
      <div className={styles.infoData}>
        {info && (
          <div className={styles.flexContainer}>
            <div className={styles.infoParagraph}>
              {info} {linkTo && <Link to={linkTo}>Learn more →</Link>}
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export { PoolBaseCard }
