import React from 'react'
import cls from 'classnames'

import { BaseCard, Button } from '@lattice/common/components'

import styles from './component.module.scss'

const NodeOperatorTicketsCard = ({
  title,
  tickets,
}: {
  title?: string
  tickets: number[]
}) => {
  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        {title ? (
          <div className={cls(styles.titleText, styles.yellowText)}>
            {title}
          </div>
        ) : (
          <div className={cls(styles.titleText)}>Your tickets</div>
        )}

        <div className={cls(styles.normalText)}>
          You have {tickets.length} tickets based on your Constellation soft
          node participation:
        </div>

        <div className={cls(styles.ticketsText)}>
          <b>{tickets.join(', ')}</b>
        </div>

        <Button.LinkExternal
          variants={['full-width', 'primary-outlined', 'right-icon']}
          href="https://github.com/StardustCollective/lattice-random-weighted-sorter/blob/edaa074ca79d122119720015296039f98739ecbe/archive/2023-06-28-lottery/public/applicants.2023-06-28.winning-numbers.participants.l200.csv"
          target="_blank"
          rel="noreferrer"
        >
          Check winning tickets
        </Button.LinkExternal>
      </div>
    </BaseCard>
  )
}

export { NodeOperatorTicketsCard }
