import React, { useState, useEffect } from 'react'
import cls from 'classnames'

import { ReactComponent as UserAvatarIcon } from '@lattice/assets/icons/custom/useravatar.svg'
import { ReactComponent as ListIcon } from '@lattice/assets/icons/custom/list.svg'
import { ReactComponent as CloseIcon } from '@lattice/assets/icons/custom/close.svg'
import { ReactComponent as LatticeLogo } from '@lattice/assets/images/svgs/logo.svg'

import { MobileNavMenu, MobileUserMenu } from './components'
import styles from './component.module.scss'

const MobileHeader = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const openNavMenu = (open: boolean) => {
    setNavMenuOpen(open)
    setUserMenuOpen(false)
  }

  const openUserMenu = (open: boolean) => {
    setUserMenuOpen(open)
    setNavMenuOpen(false)
  }

  useEffect(() => {
    if (navMenuOpen || userMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [navMenuOpen, userMenuOpen])

  return (
    <div
      className={cls(
        styles.mobileHeader,
        (navMenuOpen || userMenuOpen) && styles.fixedHeader
      )}
    >
      <div
        className={cls(styles.sectionLeft, navMenuOpen && styles.closeIcon)}
        onClick={() => openNavMenu(!navMenuOpen)}
      >
        {navMenuOpen ? (
          <CloseIcon width={16} height={16} />
        ) : (
          <ListIcon width={22} height={22} />
        )}

        <div
          className={cls(styles.navMenu, {
            [styles.navMenuOpen]: navMenuOpen,
          })}
        >
          <MobileNavMenu />
        </div>
      </div>
      <div className={styles.sectionMiddle}>
        <LatticeLogo />
      </div>
      <div
        className={styles.sectionRight}
        onClick={() => openUserMenu(!userMenuOpen)}
      >
        <UserAvatarIcon />
        <div
          className={cls(styles.userMenu, {
            [styles.userMenuOpen]: userMenuOpen,
          })}
        >
          <MobileUserMenu />
        </div>
      </div>
    </div>
  )
}

export { MobileHeader }
