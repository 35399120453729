import { dag4 } from '@stardust-collective/dag4'
import Decimal from 'decimal.js'

import {
  DeploymentStageMasterChain,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/consts'

import { getEthersProvider, getTypedContract } from './ethers'

type IAccount = {
  address: string
  networkAsset:
    | 'constellation:dag'
    | 'ethereum:eth'
    | 'ethereum:ltx'
    | 'ethereum:ltx-locked'
    | 'ethereum:veltx'
}

type IAccountBalance = IAccount & {
  symbol: string
  decimals: number
  balanceRaw: Decimal
  balance: Decimal
}

const AccountBalanceFetchers: Record<
  IAccount['networkAsset'],
  (address: string) => Promise<IAccountBalance>
> = {
  'constellation:dag': async (address) => {
    const networkBalance =
      (await dag4.network.getAddressBalance(address))?.balance ?? 0

    const decimals = 8
    const balanceRaw = new Decimal(networkBalance)
    const balance = balanceRaw.div(Decimal.pow(10, decimals))

    return {
      address,
      networkAsset: 'constellation:dag',
      symbol: 'DAG',
      decimals,
      balanceRaw,
      balance,
    }
  },
  'ethereum:eth': async (address) => {
    const provider = getEthersProvider()
    const networkBalance = await provider.getBalance(address)

    const decimals = 18
    const balanceRaw = new Decimal(networkBalance.toString())
    const balance = balanceRaw.div(Decimal.pow(10, decimals))

    return {
      address,
      networkAsset: 'ethereum:eth',
      symbol: 'ETH',
      decimals,
      balanceRaw,
      balance,
    }
  },
  'ethereum:ltx': async (address) => {
    const ltxToken = getTypedContract(
      'ERC20',
      RegisteredTokens[RegisteredToken.LTX].instances[
        DeploymentStageMasterChain
      ].address
    )
    const networkBalance = await ltxToken.balanceOf(address)

    const decimals = RegisteredTokens[RegisteredToken.LTX].decimals
    const balanceRaw = new Decimal(networkBalance.toString())
    const balance = balanceRaw.div(Decimal.pow(10, decimals))

    return {
      address,
      networkAsset: 'ethereum:ltx',
      symbol: 'LTX',
      decimals,
      balanceRaw,
      balance,
    }
  },
  'ethereum:ltx-locked': async (address) => {
    const veltxToken = getTypedContract(
      'LatticeGovernanceToken',
      RegisteredTokens[RegisteredToken.veLTX].instances[
        DeploymentStageMasterChain
      ].address
    )
    const networkBalance = await veltxToken.ltxLockedBalanceOf(address)

    const decimals = RegisteredTokens[RegisteredToken.LTX].decimals
    const balanceRaw = new Decimal(networkBalance.toString())
    const balance = balanceRaw.div(Decimal.pow(10, decimals))

    return {
      address,
      networkAsset: 'ethereum:ltx-locked',
      symbol: 'LTX Locked',
      decimals,
      balanceRaw,
      balance,
    }
  },
  'ethereum:veltx': async (address) => {
    const veltxToken = getTypedContract(
      'ERC20',
      RegisteredTokens[RegisteredToken.veLTX].instances[
        DeploymentStageMasterChain
      ].address
    )
    const networkBalance = await veltxToken.balanceOf(address)

    const decimals = RegisteredTokens[RegisteredToken.veLTX].decimals
    const balanceRaw = new Decimal(networkBalance.toString())
    const balance = balanceRaw.div(Decimal.pow(10, decimals))

    return {
      address,
      networkAsset: 'ethereum:veltx',
      symbol: 'veLTX',
      decimals,
      balanceRaw,
      balance,
    }
  },
}

const fetchAccountsBalances = async (
  accounts: IAccount[]
): Promise<IAccountBalance[]> => {
  return Promise.all(
    accounts.map(async (account) => {
      return AccountBalanceFetchers[account.networkAsset](account.address)
    })
  )
}

export {
  IAccount,
  IAccountBalance,
  AccountBalanceFetchers,
  fetchAccountsBalances,
}
