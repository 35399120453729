import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import {
  Button,
  Content,
  DetailsInfoCard,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  ProgramDetailProjectContainer,
  Typography,
} from '@lattice/common/components'
import {
  useBountiesProvider,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { DetailsBaseCardContainer } from '@lattice/common/components/DetailsBaseCardContainer/component'

import { BountyInformation } from '../BountyDetailsView/components'

import { DtmNodeRegisterCard } from './components'

const DtmNodesDetailsView = () => {
  const location = useLocation()
  const { user } = useUserProvider()
  const { requestBounty } = useBountiesProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()

  useEffect(() => {
    requestBounty('dtm-baseline')
  }, [])

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={
          'Dor Traffic Miner' +
          (location.pathname.includes('redeem') ? ' / Redeem NFTs' : '')
        }
        parentNameTitle={'Rewards Programs'}
      />
      <ProgramDetailProjectContainer>
        <DetailsMainCardContainer>
          <Outlet />
        </DetailsMainCardContainer>
        <DetailsSideCardsContainer>
          <DetailsBaseCardContainer variants={['full-width']}>
            <BountyInformation />
          </DetailsBaseCardContainer>
          <DetailsInfoCard
            label={'Get a Dor Traffic Miner'}
            subText={`Purchase the Dor Traffic Miner, a battery-operated, people-counting hardware device you can place in any doorway, anywhere. Then sit back and relax. While it collects foot traffic data, you earn crypto rewards. It's that simple.`}
            actionElement={
              <Button.LinkExternal
                variants={['full-width', 'outlined', 'right-icon']}
                href="https://constellation-network.myshopify.com/"
                target="_blank"
              >
                Get a DTM
              </Button.LinkExternal>
            }
            variants={['full-width']}
          />
          {user &&
            !(activeWallet.status === 'connected' && activeWallet.ethereum) && (
              <DetailsInfoCard
                label={'Manage DTM rewards'}
                actionElement={
                  <Button
                    variants={['full-width', 'secondary', 'right-icon']}
                    onClick={() => requestConnectorActivation()}
                  >
                    Connect wallet
                  </Button>
                }
                variants={['full-width']}
              />
            )}
          {user &&
            activeWallet.status === 'connected' &&
            activeWallet.ethereum && <DtmNodeRegisterCard />}
        </DetailsSideCardsContainer>
      </ProgramDetailProjectContainer>
    </Content>
  )
}

export { DtmNodesDetailsView }
