import React from 'react'
import cls from 'classnames'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ArrowRightIcon } from '@lattice/assets/icons/custom/arrow-right.svg'

import styles from '../BaseButton/component.module.scss'
import { BaseButtonProps } from '../BaseButton'

const ButtonNavLink = ({
  rightIcon = <ArrowRightIcon width={16} height={16} />,
  variants,
  className,
  children,
  ...props
}: {
  className?: {
    main?: React.ComponentProps<typeof NavLink>['className']
    iconHolder?: string
  }
} & Omit<
  BaseButtonProps<React.ComponentProps<typeof NavLink>>,
  'loading' | 'className'
>) => {
  return (
    <NavLink
      className={(props) =>
        cls(
          styles.main,
          variants?.includes('right-icon') && styles.rightIcon,
          variants?.includes('primary') && styles.primary,
          variants?.includes('secondary') && styles.secondary,
          variants?.includes('outlined') && styles.outlined,
          variants?.includes('full-width') && styles.fullWidth,
          variants?.includes('slim') && styles.slim,
          variants?.includes('invisible') && styles.invisible,
          typeof className?.main === 'function'
            ? className?.main(props)
            : className?.main
        )
      }
      {...props}
    >
      {children}
      {variants?.includes('right-icon') && (
        <div className={styles.iconHolder}>{rightIcon}</div>
      )}
    </NavLink>
  )
}

export { ButtonNavLink }
