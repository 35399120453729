import React from 'react'
import { LiFiWidget, WidgetConfig } from '@lifi/widget'
import { Signer } from 'ethers'

import { BaseModal } from '@lattice/common/components/BaseModal'
import { useWalletProvider } from '@lattice/common/providers'
import { AvailableChain, ChainInfo } from '@lattice/common/consts'

const LiFiWidgetModal = ({
  onClickOutside,
  targetChain,
  targetTokenAddress,
}: {
  onClickOutside: () => void
  targetChain: AvailableChain
  targetTokenAddress: string
}) => {
  const { activeWallet, requestConnectorActivation } = useWalletProvider()

  const checkClickOutsideOrNot = () => {
    const isWalletDropdownOpened = document.querySelectorAll(
      '[role="presentation"]'
    )
    const isWalletConnectOpened = document.getElementById('connectWallet')
    if (isWalletDropdownOpened.length > 0 || isWalletConnectOpened) {
      return
    }

    onClickOutside()
  }

  const widgetConfig: WidgetConfig = {
    integrator: 'lattice-gateway',
    fee: 0.01,
    toChain: ChainInfo[targetChain].rpcChainId,
    toToken: targetTokenAddress,
    walletManagement: {
      signer:
        activeWallet.status === 'connected' && activeWallet.ethereum
          ? (activeWallet.ethereum.library.getSigner() as Signer)
          : undefined,
      connect: async () => {
        if (
          activeWallet.status === 'disconnected' ||
          (activeWallet.status === 'connected' && !activeWallet.ethereum)
        ) {
          await requestConnectorActivation()
        }
        const library =
          activeWallet.status === 'connected' && activeWallet.ethereum
            ? activeWallet.ethereum.library
            : null
        return library?.getSigner() as Signer
      },
      disconnect: async () => {
        activeWallet.status === 'connected' &&
          activeWallet.ethereum &&
          activeWallet.ethereum.disconnect()
      },
    },
    containerStyle: {
      border: `1px solid ${
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'rgb(66, 66, 66)'
          : 'rgb(234, 234, 234)'
      }`,
      borderRadius: '8px',
    },

    theme: {
      palette: {
        primary: { main: '#FC0' },
      },
      shape: {
        borderRadius: 0,
        borderRadiusSecondary: 0,
      },
      typography: {
        fontFamily: 'TWK Lausanne',
      },
    },
    disableAppearance: true,
  }

  return (
    <BaseModal onClickOutside={() => checkClickOutsideOrNot()}>
      <LiFiWidget config={widgetConfig} />
    </BaseModal>
  )
}

export { LiFiWidgetModal }
