import React from 'react'
import { LiFiWidget, WidgetConfig } from '@lifi/widget'
import { Signer } from 'ethers'

import { useWalletProvider } from '@lattice/common/providers'
import {
  AvailableChain,
  ChainInfo,
  RegisteredTokens,
  RegisteredToken,
} from '@lattice/common/consts'

import styles from './view.module.scss'

const SwapLifiView = () => {
  const { activeWallet, requestConnectorActivation } = useWalletProvider()

  const widgetConfig: WidgetConfig = {
    integrator: 'lattice-gateway',
    fee: 0.01,
    toChain: ChainInfo[AvailableChain.ETHEREUM_MAINNET].rpcChainId,
    toToken:
      RegisteredTokens[RegisteredToken.LTX].instances[
        AvailableChain.ETHEREUM_MAINNET
      ].address,
    walletManagement: {
      signer:
        activeWallet.status === 'connected' && activeWallet.ethereum
          ? (activeWallet.ethereum.library.getSigner() as Signer)
          : undefined,
      connect: async () => {
        if (
          activeWallet.status === 'disconnected' ||
          (activeWallet.status === 'connected' && !activeWallet.ethereum)
        ) {
          await requestConnectorActivation()
        }
        const library =
          activeWallet.status === 'connected' && activeWallet.ethereum
            ? activeWallet.ethereum.library
            : null
        return library?.getSigner() as Signer
      },
      disconnect: async () => {
        activeWallet.status === 'connected' &&
          activeWallet.ethereum &&
          activeWallet.ethereum.disconnect()
      },
    },
    containerStyle: {
      border: `1px solid ${
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'rgb(66, 66, 66)'
          : 'rgb(234, 234, 234)'
      }`,
      borderRadius: '8px',
    },
    appearance: 'dark',
    theme: {
      palette: {
        primary: { main: '#FC0' },
        background: { default: '#272B37', paper: '#272B37' },
        grey: {
          '50': '#5A5D6B',
          '100': '#5A5D6B',
          '200': '#5A5D6B',
          '300': '#5A5D6B',
          '400': '#5A5D6B',
          '500': '#5A5D6B',
          '600': '#5A5D6B',
          '700': '#5A5D6B',
          '800': '#5A5D6B',
          '900': '#5A5D6B',
          A100: '#5A5D6B',
          A200: '#5A5D6B',
          A400: '#5A5D6B',
          A700: '#5A5D6B',
        },
      },
      shape: {
        borderRadius: '8px',
        borderRadiusSecondary: '48px',
      },
      typography: {
        fontFamily: 'TWK Lausanne',
      },
    },
    disableAppearance: true,
  }

  return (
    <div className={styles.swapBox}>
      <LiFiWidget config={widgetConfig} />{' '}
    </div>
  )
}

export { SwapLifiView }
