import { createProviderStateHook } from '@lattice/utils'

import {
  HgtpMetagraphsProvider,
  HgtpMetagraphsProviderContext,
} from './provider'

const useHgtpMetagraphsProvider = createProviderStateHook(
  HgtpMetagraphsProvider,
  HgtpMetagraphsProviderContext
)

export { useHgtpMetagraphsProvider }
