import React from 'react'
import cls from 'classnames'

import styles from './index.module.scss'

const ContainerLayoutPrimary = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}): JSX.Element => {
  return <div className={cls(styles.primary, className)}>{children}</div>
}

const ContainerLayoutSecondary = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}): JSX.Element => {
  return <div className={cls(styles.secondary, className)}>{children}</div>
}

const ContainerLayoutBase = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}): JSX.Element => {
  const childrenNodes = React.Children.toArray(children)
  const primaryNode = childrenNodes.find(
    (child) =>
      typeof child === 'object' &&
      'type' in child &&
      Object.is(child.type, ContainerLayoutPrimary)
  )
  const secondaryNode = childrenNodes.find(
    (child) =>
      typeof child === 'object' &&
      'type' in child &&
      Object.is(child.type, ContainerLayoutSecondary)
  )

  return (
    <div className={cls(styles.root, className)}>
      {primaryNode}
      {secondaryNode}
    </div>
  )
}

const ContainerLayout = Object.assign(ContainerLayoutBase, {
  Primary: ContainerLayoutPrimary,
  Secondary: ContainerLayoutSecondary,
})

export { ContainerLayout }
