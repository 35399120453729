import { IBounty } from './types'

enum BountyStatUri {
  TOTAL_REWARDS_DISTRIBUTED = 'lattice.bounties.stats.dynamic.totalRewardsDistributed',
  TIMELINE = 'lattice.bounties.stats.dynamic.timeline',
  LINKED_NODES = 'lattice.bounties.stats.dynamic.linkedNodes',
}

type BountyTypedStats = {
  [BountyStatUri.TOTAL_REWARDS_DISTRIBUTED]: string
  [BountyStatUri.TIMELINE]: 'ongoing' | { startsAt: IISODate; endsAt: IISODate }
  [BountyStatUri.LINKED_NODES]: number
}

const getBountyStat = <P extends string, F>(
  bounty: IBounty,
  uri: P,
  fallback: F
): (P extends keyof BountyTypedStats ? BountyTypedStats[P] : JSONValue) | F => {
  const property = bounty.stats?.find((property) => property.uri === uri)

  if (!property) {
    return fallback
  }

  return property.value as P extends keyof BountyTypedStats
    ? BountyTypedStats[P]
    : JSONValue
}

export { BountyStatUri, BountyTypedStats, getBountyStat }
