import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { useTimer } from '@lattice/common/hooks/useTimer'

import { CardSectionContainer } from '../CardSectionContainer'
import { CardLabelContainer } from '../CardLabelContainer'
import { CardFlexContainer } from '../CardFlexContainer'

import styles from './component.module.scss'

const DetailsDateCard = ({ date, label }: { date?: Date; label?: string }) => {
  const { t } = useTranslation()
  const { days, hours, minutes, seconds } = useTimer(dayjs(date))

  if (!date) {
    return null
  }

  return (
    <CardSectionContainer>
      <CardLabelContainer>{label}</CardLabelContainer>
      <CardFlexContainer>
        <div className={styles.infoDataGroup}>
          <div className={styles.numberTimeLabel}>{days}</div>
          <div className={styles.timeLabel}>{t('', 'days')}</div>
        </div>
        <div className={styles.infoDataGroup}>
          <div className={styles.numberTimeLabel}>
            {hours < 10 ? '0' + hours : hours}
          </div>
          <div className={styles.timeLabel}>{t('', 'hours')}</div>
        </div>
        <div className={styles.infoDataGroup}>
          <div className={styles.numberTimeLabel}>
            {minutes < 10 ? '0' + minutes : minutes}
          </div>
          <div className={styles.timeLabel}>{t('', 'mins')}</div>
        </div>
        <div className={styles.infoDataGroup}>
          <div className={styles.numberTimeLabel}>
            {seconds < 10 ? '0' + seconds : seconds}
          </div>
          <div className={styles.timeLabel}>{t('', 'secs')}</div>
        </div>
      </CardFlexContainer>
    </CardSectionContainer>
  )
}

export { DetailsDateCard }
