import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@lattice/components/common/Button'
import CaretRight from '@lattice/assets/images/svgs/CaretRight.svg'
import ActiveCheck from '@lattice/assets/images/svgs/checkmarkFilled.svg'
import InactiveCheck from '@lattice/assets/images/svgs/closeFilled.svg'
import { RewardsHistoryGraphPeriod } from '@lattice/common/providers'

import { BaseCard } from '../BaseCard'
import { Badge, BadgeColors, BadgeColorsType } from '../Badge'

import styles from './InfoCard.module.scss'

type RowData = {
  title: string
  status?: string
  subData?: [string, string, string | undefined, string]
  linkTo?: string
  subDataIcons?: boolean
  badge?: { text: string; color: BadgeColors | BadgeColorsType }
}

type ChartData = {
  loading: boolean
  changeTimeFrame: (
    frame: string,
    graphPeriod: RewardsHistoryGraphPeriod
  ) => void
  dagAmount: string
  usdEquivalent: number | string
}

const InfoCard = ({
  headerText,
  linkTo,
  rowsData,
  chartData,
  chart,
  children,
}: {
  headerText: string
  rowsData?: RowData[]
  chartData?: ChartData
  linkTo?: string
  chart?: React.ReactNode
  children?: React.ReactNode
}) => {
  const navigate = useNavigate()
  const header = (
    <div className={styles.simpleHeader}>
      <div className={styles.simpleHeaderText}>{headerText}</div>
      {linkTo && (
        <div className={styles.headerLink}>
          {linkTo.startsWith('http') ? (
            <a href={linkTo} target="_blank" rel="noreferrer">
              <img src={CaretRight} />
            </a>
          ) : (
            <Link to={linkTo}>
              <img src={CaretRight} />
            </Link>
          )}
        </div>
      )}
    </div>
  )

  const oldChildren = (
    <div className={styles.content}>
      {rowsData &&
        rowsData.map((rowData, index) => (
          <div
            className={styles.row}
            key={index}
            onClick={() => rowData.linkTo && navigate(rowData.linkTo)}
          >
            {rowData.subData && (
              <div className={styles.doubleRow}>
                <div className={styles.innerRow}>
                  <div className={styles.rowTitle}>
                    {rowData.title}
                    {rowData.badge && (
                      <div className={styles.titleBadge}>
                        <Badge
                          text={rowData.badge.text}
                          color={rowData.badge.color}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.stakedAmount}>
                    {rowData.subData[1]}
                  </div>
                </div>
                <div className={styles.innerRow}>
                  {!rowData.badge && (
                    <div className={styles.statusData}>
                      <img
                        src={
                          rowData.status === 'Active'
                            ? ActiveCheck
                            : InactiveCheck
                        }
                      />
                      <div
                        className={cls({
                          [styles.active]: rowData.status === 'Active',
                          [styles.inactive]: rowData.status === 'Inactive',
                        })}
                      >
                        {rowData.status}
                      </div>
                    </div>
                  )}
                  <div className={styles.subData}>
                    <div className={styles.dataGroup}>
                      <span>{rowData.subData[0]}</span>
                    </div>
                    <div>
                      {rowData.subDataIcons && (
                        <span className={styles.dotSize}>•</span>
                      )}
                    </div>
                    <div className={styles.dataGroup}>
                      <div>{rowData.subData[3]}</div>
                    </div>

                    {rowData.subData[2] && (
                      <>
                        <div>
                          {rowData.subDataIcons && (
                            <span className={styles.dotSize}>•</span>
                          )}
                        </div>
                        <div className={styles.dataGroup}>
                          <div>{rowData.subData[2]}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!rowData.subData && (
              <>
                <div className={styles.rowTitle}>{rowData.title}</div>
                <div className={styles.rowTitle}>
                  <img src={ActiveCheck} width={'24px'} height={'24px'} />
                </div>
              </>
            )}
          </div>
        ))}

      {chartData && (
        <div className={styles.chartContainer}>
          <div className={styles.dagInfo}>
            <div className={styles.dagAmount}>
              {chartData.loading ? (
                <ImpulseSpinner frontColor={'#03bc3d'} />
              ) : (
                <>
                  <div className={styles.dagUnit}>{chartData.dagAmount}</div>
                  <div className={styles.dagLabel}>DAG</div>
                </>
              )}
            </div>
            <div className={styles.usdEquiv}>
              {chartData.loading ? (
                <ImpulseSpinner frontColor={'#fff'} />
              ) : (
                <>
                  <div className={styles.usdUnit}>
                    ${chartData.usdEquivalent}
                  </div>
                  <div className={styles.usdLabel}>USD</div>
                </>
              )}
            </div>
          </div>
          <div>{chart}</div>
          <div className={styles.buttonGroup}>
            <Button
              variant={styles.button}
              onClick={() =>
                chartData.changeTimeFrame('P7D', RewardsHistoryGraphPeriod.day)
              }
            >
              <div>7D</div>
            </Button>

            <Button
              variant={styles.button}
              onClick={() =>
                chartData.changeTimeFrame('P30D', RewardsHistoryGraphPeriod.day)
              }
            >
              <div>30D</div>
            </Button>

            <Button
              variant={styles.button}
              onClick={() =>
                chartData.changeTimeFrame('P90D', RewardsHistoryGraphPeriod.day)
              }
            >
              <div>90D</div>
            </Button>

            <Button
              variant={styles.button}
              onClick={() =>
                chartData.changeTimeFrame(
                  'YTD',
                  RewardsHistoryGraphPeriod.month
                )
              }
            >
              <div>YTD</div>
            </Button>

            <Button
              variant={styles.button}
              onClick={() =>
                chartData.changeTimeFrame(
                  'ALL',
                  RewardsHistoryGraphPeriod.month
                )
              }
            >
              <div>ALL</div>
            </Button>
          </div>
        </div>
      )}
    </div>
  )

  const wrappedChildren = <div className={styles.content}>{children}</div>

  return (
    <BaseCard
      header={header}
      variants={['wide', 'bordered', 'section-bar']}
      className={{ header: styles.header }}
    >
      {children ? wrappedChildren : oldChildren}
    </BaseCard>
  )
}

export { InfoCard, RowData }
