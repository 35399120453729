import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import {
  ILiquidityStakingProgramAndContext,
  ILiquidityStakingProvider,
  ILiquidityStakingStrategyMethods,
} from './types'
import { requestPrograms, requestProgramStrategy } from './utils'
import { LiquidityStakingStrategyMethodNames } from './consts'

const LiquidityStakingProviderContext =
  React.createContext<ILiquidityStakingProvider | null>(null)

const LiquidityStakingProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const programs = useFetchableResource<ILiquidityStakingProgramAndContext[]>(
    []
  )

  const ctx: ILiquidityStakingProvider = {
    programs: programs,
    requestPrograms: programs.wrappedFetch(requestPrograms),
    getProgramStrategy: (program) => {
      return new Proxy(
        {},
        {
          get: (target, property) => {
            if (typeof property === 'symbol') {
              return undefined
            }

            if (
              !LiquidityStakingStrategyMethodNames.includes(property as any)
            ) {
              return undefined
            }

            return async (...params: any[]) =>
              requestProgramStrategy(program.id, property, params)
          },
        }
      ) as ILiquidityStakingStrategyMethods
    },
  }

  return (
    <LiquidityStakingProviderContext.Provider value={ctx}>
      {children}
    </LiquidityStakingProviderContext.Provider>
  )
}

export { LiquidityStakingProvider, LiquidityStakingProviderContext }
