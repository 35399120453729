import React from 'react'
import cls from 'classnames'
import { Outlet } from 'react-router-dom'

import { NavLink, NavTab } from '@lattice/common/components/NavTab'

import styles from './view.module.scss'

const SwapBuyView = () => {
  const navs = [
    <NavLink
      key={0}
      to={'swap'}
      text={'Swap'}
      className={({ isActive }) => cls(styles.tab, isActive && styles.active)}
    />,
    <NavLink
      key={1}
      to={'buy'}
      text={'Buy'}
      className={({ isActive }) => cls(styles.tab, isActive && styles.active)}
    />,
  ]

  return (
    <div className={styles.content}>
      <NavTab navs={navs} />
      <div className={styles.contentWrapper}>
        <Outlet />
      </div>
    </div>
  )
}

export { SwapBuyView }
