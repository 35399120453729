// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MXvpPyKy0xXTe6fbRhKm{display:flex;justify-content:space-between;padding:16px 0}.zBwNEGC5Bx0KCg71WUEK{font-weight:400;font-size:16px;display:flex;align-items:center;gap:8px}.zBwNEGC5Bx0KCg71WUEK.QpbJXPkXSqYXpA8QnUVV{display:flex;justify-content:end;font-family:"IBM Plex Mono";color:rgba(255,255,255,.66)}.zBwNEGC5Bx0KCg71WUEK.uv25ObafDKdBJWYJJVgp{color:#fff}.cRCH5WrKGYH6D2NHNMpO{cursor:pointer}.QTidh0HPcju3XrhP7fhv{display:flex;justify-content:space-between;align-items:center;gap:8px}.Rin9jytyHIV5Clf7ZArf{text-overflow:ellipsis;white-space:nowrap;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/common/components/InfoRow/component.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,6BAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,2CACE,YAAA,CACA,mBAAA,CACA,2BAAA,CACA,2BAnBQ,CAsBV,2CACE,UAAA,CAIJ,sBACE,cAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,sBAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":["$infoColor: rgba(#fff, 0.66);\n\n.infoRow {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px 0;\n}\n\n.infoFont {\n  font-weight: 400;\n  font-size: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  &.rightSide {\n    display: flex;\n    justify-content: end;\n    font-family: 'IBM Plex Mono';\n    color: $infoColor;\n  }\n\n  &.leftSide {\n    color: #ffffff;\n  }\n}\n\n.copy {\n  cursor: pointer;\n}\n\n.flex {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n}\n\n.overFlow {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoRow": `MXvpPyKy0xXTe6fbRhKm`,
	"infoFont": `zBwNEGC5Bx0KCg71WUEK`,
	"rightSide": `QpbJXPkXSqYXpA8QnUVV`,
	"leftSide": `uv25ObafDKdBJWYJJVgp`,
	"copy": `cRCH5WrKGYH6D2NHNMpO`,
	"flex": `QTidh0HPcju3XrhP7fhv`,
	"overFlow": `Rin9jytyHIV5Clf7ZArf`
};
export default ___CSS_LOADER_EXPORT___;
