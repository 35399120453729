// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uy5Dv0XESdO5sZRTRPpX{display:flex;font-size:14px;font-weight:200;color:rgba(255,255,255,.66);margin-bottom:32px}.uy5Dv0XESdO5sZRTRPpX a{color:#fc0;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/common/components/Typography/components/DetailsTitle/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,eAAA,CACA,2BAAA,CACA,kBAAA,CAEA,wBACE,UAAA,CACA,oBAAA","sourcesContent":[".pageTitle {\n  display: flex;\n  font-size: 14px;\n  font-weight: 200;\n  color: rgba(#fff, 0.66);\n  margin-bottom: 32px;\n\n  a {\n    color: #fc0;\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `uy5Dv0XESdO5sZRTRPpX`
};
export default ___CSS_LOADER_EXPORT___;
