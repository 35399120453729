// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JQMXClh8KI_QmSSS7u06{padding:20px 0px 0px 0px}.EwM5e4_hTLHQfZRlI6VX{padding:12px 0px}.xK80J4xwhWQYthhqcCol{padding:0px 0px 20px 0px}.RooMs9WFktsDpRMSB6PB{padding:20px 0px}.Wzqs4YTJssu1EDYxk41y{font-size:14px}.DuPiLP0XbAq2mY8ufDmE{display:flex;align-items:center;gap:14px}.rT1wuuetls4I5X7kMGTy{display:inline-block;color:rgba(255,255,255,.66)}.fo1eaZOHhq1kTv6M96sx{font-weight:200;font-size:28px;line-height:32px;color:#fff}.iCVvn0fTLXZqIX5UiZ8J{color:#fff}.lUFq_AJpPMfojkTMd5dH{display:flex;align-items:center;justify-content:center;width:24px;height:24px;border:2px solid #55c555;border-radius:24px}`, "",{"version":3,"sources":["webpack://./src/views/SwapBuyView/views/SwapExolixView/components/LastContent/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,wBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,cAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,oBAAA,CACA,2BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CAGF,sBACE,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.topRow {\n  padding: 20px 0px 0px 0px;\n}\n\n.midRow {\n  padding: 12px 0px;\n}\n\n.bottomRow {\n  padding: 0px 0px 20px 0px;\n}\n\n.aloneRow {\n  padding: 20px 0px;\n}\n\n.font {\n  font-size: 14px;\n}\n\n.statusRow {\n  display: flex;\n  align-items: center;\n  gap: 14px;\n}\n\n.statusMessage {\n  display: inline-block;\n  color: rgba($white, 0.66);\n}\n\n.statusTitle {\n  font-weight: 200;\n  font-size: 28px;\n  line-height: 32px;\n  color: #ffffff;\n}\n\n.bold {\n  color: white;\n}\n\n.step {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 24px;\n  height: 24px;\n  border: 2px solid #55c555;\n  border-radius: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": `JQMXClh8KI_QmSSS7u06`,
	"midRow": `EwM5e4_hTLHQfZRlI6VX`,
	"bottomRow": `xK80J4xwhWQYthhqcCol`,
	"aloneRow": `RooMs9WFktsDpRMSB6PB`,
	"font": `Wzqs4YTJssu1EDYxk41y`,
	"statusRow": `DuPiLP0XbAq2mY8ufDmE`,
	"statusMessage": `rT1wuuetls4I5X7kMGTy`,
	"statusTitle": `fo1eaZOHhq1kTv6M96sx`,
	"bold": `iCVvn0fTLXZqIX5UiZ8J`,
	"step": `lUFq_AJpPMfojkTMd5dH`
};
export default ___CSS_LOADER_EXPORT___;
