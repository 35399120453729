// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._2qQWnvBqmjlJc03iNIN{font-family:"IBM Plex Mono";font-weight:400;font-size:12px;line-height:18px}.bfDopaqWwtJ2fdmL9R3Q{color:rgba(255,255,255,.96)}.kU3HMy59ydGhgntaIQZU{color:rgba(255,255,255,.33)}.JTRpnZfNlvAB2zydJi9A{color:#fc0}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/SoftNodeProgramDetailView/components/Stat/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,2BAAA,CAGF,sBACE,2BAAA,CAGF,sBACE,UAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.main {\n  font-family: 'IBM Plex Mono';\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 18px;\n}\n\n.primary {\n  color: rgba(#fff, 0.96);\n}\n\n.secondary {\n  color: rgba(#fff, 0.33);\n}\n\n.highlight {\n  color: #fc0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `_2qQWnvBqmjlJc03iNIN`,
	"primary": `bfDopaqWwtJ2fdmL9R3Q`,
	"secondary": `kU3HMy59ydGhgntaIQZU`,
	"highlight": `JTRpnZfNlvAB2zydJi9A`
};
export default ___CSS_LOADER_EXPORT___;
