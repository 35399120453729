import { TFunction } from 'i18next'
import dayjs from 'dayjs'

export const generateWalletOwnershipMessage = (t: TFunction) => () => {
  const expiration = dayjs().add(20, 'minutes')

  const message = [
    t(
      'common.providers.WalletProvider.utils.walletOwnershipMessage',
      'Sign this message to confirm your wallet ownership. Wallet ownership signatures will be used through the site for different actions. You will be requested to sign this message again in 20 minutes.\n\nThis message will not trigger any transaction on the network, therefore it will not spend any funds.'
    ),
    '---',
    JSON.stringify(
      {
        type: 'WalletOwnership',
        exp: expiration.unix(),
      },
      null,
      2
    ),
  ].join('\n')

  return [message, expiration] as const
}
