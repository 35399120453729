import { dag4 } from '@stardust-collective/dag4'
import fetch from 'unfetch'

const { REACT_APP_ECOSYSTEM_API_URL } = process.env

const initializeDag4Lib = () => {
  dag4.di.useFetchHttpClient(fetch)

  dag4.network.config({
    id: 'mainnet',
    networkVersion: '2.0',
    beUrl: 'https://be-mainnet.constellationnetwork.io',
    l0Url: `${REACT_APP_ECOSYSTEM_API_URL}/foundational-nodes/l0`,
    l1Url: `${REACT_APP_ECOSYSTEM_API_URL}/foundational-nodes/l1`,
    testnet: false,
  })
}

export { initializeDag4Lib }
