// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lVslM8PBtqcVAAXqOUkl{width:100%;font-family:"IBM Plex Mono";color:#fff;background-color:rgba(0,0,0,0);border:1px solid rgba(255,255,255,.24);border-radius:8px;resize:none;outline:none;padding:5px}.lVslM8PBtqcVAAXqOUkl:hover{outline:none}`, "",{"version":3,"sources":["webpack://./src/common/components/TextAreaCopy/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,2BAAA,CACA,UAAA,CAEA,8BAAA,CACA,sCAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,WAAA,CAGA,4BACE,YAAA","sourcesContent":[".textarea{\n  width: 100%;\n\n  font-family: 'IBM Plex Mono';\n  color: #fff;\n\n  background-color: rgba(0,0,0,0);\n  border: 1px solid rgba(#fff, 0.24);\n  border-radius: 8px;\n  resize: none;\n  outline: none;\n  padding: 5px;\n  \n\n  &:hover{\n    outline: none;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `lVslM8PBtqcVAAXqOUkl`
};
export default ___CSS_LOADER_EXPORT___;
