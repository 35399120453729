import React from 'react'
import cls from 'classnames'

import { BaseCard } from '@lattice/common/components'

import styles from './component.module.scss'

const ProgramCard = ({
  children,
  variants,
}: {
  children?: React.ReactNode
  variants?: 'with-header'[]
}) => {
  return (
    <BaseCard
      className={{
        root: cls(variants?.includes('with-header') && styles.withHeader),
        body: styles.body,
      }}
    >
      {children}
    </BaseCard>
  )
}

export { ProgramCard }
