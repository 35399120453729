import { createProviderStateHook } from '@lattice/utils'

import { ToastProvider, ToastProviderContext } from './provider'

const useToastProvider = createProviderStateHook(
  ToastProvider,
  ToastProviderContext
)

export { useToastProvider }
