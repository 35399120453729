import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const calcIntervalDuration = (start: Date, end: Date) => {
  const duration = dayjs.duration(dayjs(start).diff(end))

  if (duration.months()) {
    return `${Math.abs(duration.months())} months ${Math.abs(
      duration.days()
    )} days`
  }
  if (duration.days()) {
    return `${Math.abs(duration.days())} days ${Math.abs(
      duration.hours()
    )} hours`
  }
  if (duration.hours()) {
    return `${Math.abs(duration.hours())} hours ${Math.abs(
      duration.minutes()
    )} minutes`
  }
  return `${Math.abs(duration?.minutes()) || '0'} minutes ${Math.abs(
    duration.seconds()
  )} seconds`
}

export { calcIntervalDuration }
