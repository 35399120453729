import React from 'react'

import styles from './component.module.scss'

const InlineStat = ({
  label,
  value,
}: {
  label: string
  value: React.ReactNode
}) => {
  return (
    <div className={styles.main}>
      <span>{value}</span>
      <span>{label}</span>
    </div>
  )
}

export { InlineStat }
