import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SoftNodeBanner from '@lattice/assets/images/pngs/softNodeBanner.png'
import {
  Badge,
  BadgeColors,
  Button,
  ContainerLayout,
  Content,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  BlockStat,
  InlineStatsCard,
  MainProjectCard,
  PendingContent,
  ProgramDetailProjectContainer,
  Typography,
  HorizontalBar,
  InlineStats,
  InlineStat,
  ProjectDetailsManageSection,
  DetailsInfoCard,
  Tabs,
  FlexCenteredContainer,
} from '@lattice/common/components'
import { useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import { useTestnetValidatorsProvider } from '@lattice/common/providers/TestnetValidatorsProvider'
import { formatNumber, NumberFormat } from '@lattice/utils'
import { HeaderCardTitle } from '@lattice/common/components/Typography/components'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './view.module.scss'
import { HowToParticipate } from './content'
import { ManageTestnetRewardsCard } from './components'

const TestnetValidatorProgramDetailView = () => {
  const { t } = useTranslation()
  const { user } = useUserProvider()
  const signInUrl = useSignInRedirect()

  const { tnrUser, tnrStats, getTestnetRewardsUser, getTestnetRewardsStats } =
    useTestnetValidatorsProvider()

  useEffect(() => {
    getTestnetRewardsUser()
    getTestnetRewardsStats()
  }, [])

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={t(
          'views.Rewards.views.TestnetValidatorProgramDetail.title.testnetValidators',
          'Testnet Validators'
        )}
        parentNameTitle={t(
          'views.Rewards.views.TestnetValidatorProgramDetail.title.rewardsPrograms',
          'Rewards Programs'
        )}
      />
      {tnrStats.status === FetchStatus.PENDING ||
      tnrUser.status === FetchStatus.PENDING ? (
        <PendingContent />
      ) : (
        <ProgramDetailProjectContainer>
          <DetailsMainCardContainer>
            <MainProjectCard
              title={t(
                'views.Rewards.views.TestnetValidatorProgramDetail.title.testnetValidators',
                'Testnet Validators'
              )}
              bannerUrl={SoftNodeBanner}
            >
              <Tabs>
                <Tabs.Tab
                  label={t(
                    'views.Rewards.views.TestnetValidatorProgramDetail.sections.overview',
                    'Overview'
                  )}
                  content={
                    <Typography.MarkdownContent>
                      {HowToParticipate}
                    </Typography.MarkdownContent>
                  }
                />
                {tnrUser.status === FetchStatus.DONE && user && (
                  <Tabs.Tab
                    label={t(
                      'views.Rewards.views.TestnetValidatorProgramDetail.sections.myRewardsNodes',
                      'Manage Node'
                    )}
                    content={
                      <ProjectDetailsManageSection>
                        <HorizontalBar />
                        <InlineStats>
                          <InlineStat
                            label="Active nodes"
                            value={tnrUser.resource?.nodes?.length}
                          />
                          <InlineStat
                            label={t(
                              'views.Rewards.views.TestnetValidatorProgramDetail.sectionContent.myNodes.totalRewardsEarned',
                              'Total rewards earned'
                            )}
                            value={
                              tnrUser ? (
                                <div className={styles.flexStat}>
                                  <span>
                                    {formatNumber(
                                      tnrUser.resource?.rewardsEarned,
                                      NumberFormat.MILLIFY
                                    )}
                                  </span>
                                  <Badge
                                    text={'DAG'}
                                    color={BadgeColors.green}
                                    className={{
                                      container: styles.greenBadgeContainer,
                                    }}
                                  />
                                </div>
                              ) : (
                                '--'
                              )
                            }
                          />
                        </InlineStats>
                        <HorizontalBar />
                        <InlineStatsCard>
                          <ContainerLayout.Primary key={0}>
                            <HeaderCardTitle>
                              {t(
                                'views.Rewards.views.TestnetValidatorProgramDetail.title.manageTestnetRewards',
                                'Manage Testnet rewards'
                              )}
                            </HeaderCardTitle>
                            <Button.Link
                              variants={['right-icon', 'outlined']}
                              to={'/nodes'}
                            >
                              Node Manager
                            </Button.Link>
                          </ContainerLayout.Primary>
                        </InlineStatsCard>
                      </ProjectDetailsManageSection>
                    }
                  />
                )}
              </Tabs>
            </MainProjectCard>
          </DetailsMainCardContainer>
          <DetailsSideCardsContainer>
            <InlineStatsCard>
              <BlockStat
                key={0}
                label={t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.sectionContent.overview.nodesRegistered',
                  'Registered nodes'
                )}
                value={tnrStats?.resource?.registeredTesnetRewardsNodes}
              />
              <BlockStat
                key={1}
                label={t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.sectionContent.overview.totalRewardsDistributed',
                  'Total rewards distributed'
                )}
                value={
                  <FlexCenteredContainer>
                    <span>
                      {formatNumber(
                        tnrStats?.resource?.rewardsDistributed,
                        NumberFormat.MILLIFY
                      )}
                    </span>
                    <Badge
                      text={'DAG'}
                      color={BadgeColors.green}
                      className={{ container: styles.greenBadgeContainer }}
                    />
                  </FlexCenteredContainer>
                }
              />
            </InlineStatsCard>
            <DetailsInfoCard
              label={t(
                'views.Rewards.views.TestnetValidatorProgramDetail.HowToParticipateWidget.title.howToParticipate',
                'How to participate'
              )}
              subText={t(
                'views.Rewards.views.TestnetValidatorProgramDetail.HowToParticipateWidget.content.participationInviteOnly',
                'Participation is by invite only and enrollment is now closed until Testnet is opened up to the public at a later date. For more information, please visit our Node management platform at NodeGarage.io.'
              )}
              actionElement={
                <Button.LinkExternal
                  variants={['full-width', 'outlined', 'right-icon']}
                  href="https://nodegarage.io/"
                  target="_blank"
                >
                  {t(
                    'views.Rewards.views.TestnetValidatorProgramDetail.HowToParticipateWidget.action.visitNodeGarage',
                    'Visit Node Garage'
                  )}
                </Button.LinkExternal>
              }
              variants={['full-width']}
            />
            {!user && (
              <DetailsInfoCard
                label={t(
                  'views.Rewards.views.TestnetValidatorProgramDetail.title.manageTestnetRewards',
                  'Manage Testnet rewards'
                )}
                actionElement={
                  <Button.Link
                    variants={['full-width', 'secondary', 'right-icon']}
                    to={signInUrl}
                  >
                    Sign in
                  </Button.Link>
                }
                variants={['full-width']}
              />
            )}
            {user && <ManageTestnetRewardsCard />}
          </DetailsSideCardsContainer>
        </ProgramDetailProjectContainer>
      )}
    </Content>
  )
}

export { TestnetValidatorProgramDetailView }
