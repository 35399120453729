// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YK0GqPcKxQ7avuieGAPK{display:flex;width:100%;height:45px;justify-content:center;align-items:center;background-color:#fc0;text-align:center;font-weight:400;font-size:14px;line-height:20px}`, "",{"version":3,"sources":["webpack://./src/views/StakingPoolsView/components/StakingProgramCard/components/RequiredCondition/component.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CAEA,qBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n\n.main {\n  display: flex;\n  width: 100%;\n  height: 45px;\n\n  justify-content: center;\n  align-items: center;\n\n  background-color: #fc0;\n  text-align: center;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `YK0GqPcKxQ7avuieGAPK`
};
export default ___CSS_LOADER_EXPORT___;
