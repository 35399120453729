import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { routes } from '@lattice/views'

import styles from './component.module.scss'

const MobileNavMenu = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {routes
        .filter((route) => !route.hidden)
        .map((route) => {
          return (
            <NavLink
              to={route.path}
              key={route.id}
              className={({ isActive }) =>
                cls(styles.tile, isActive && styles.active)
              }
            >
              <div className={cls(styles.icon)}>{route.icon && route.icon}</div>
              <div className={cls(styles.routeName)}>
                {route.name && route.name(t)}
              </div>
            </NavLink>
          )
        })}
    </div>
  )
}

export { MobileNavMenu }
