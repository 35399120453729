import Decimal from 'decimal.js'

enum IntegrationsNetApplicantStatus {
  REGISTERED = 'registered',
  SELECTED = 'selected',
  PENDING_REQUIREMENT = 'pending_requirement',
  REMOVED = 'removed',
  IN_QUEUE = 'in_queue',
  OPERATOR_REGISTERED = 'operator_registered',
}

const OptInCollateralRequirementInDAG = new Decimal(250000)

export { IntegrationsNetApplicantStatus, OptInCollateralRequirementInDAG }
