import React from 'react'

import { ReactComponent as CheckFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import { ReactComponent as WarningFilledIcon } from '@lattice/assets/icons/custom/warning-filled.svg'
import { ReactComponent as InformationFilledIcon } from '@lattice/assets/icons/custom/information-filled.svg'
import { ReactComponent as ErrorFilledIcon } from '@lattice/assets/icons/custom/error-filled.svg'
import { ReactComponent as CloseIcon } from '@lattice/assets/icons/custom/close.svg'
import { IToastTypes } from '@lattice/common/providers/ToastProvider'

import { BaseCard } from '../BaseCard'

import styles from './component.module.scss'

const ToastCardIcons: Record<
  IToastTypes,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
> = {
  success: CheckFilledIcon,
  error: ErrorFilledIcon,
  info: InformationFilledIcon,
  warning: WarningFilledIcon,
}

const ToastCard = ({
  type,
  onCloseClicked,
  children,
}: {
  type: IToastTypes
  onCloseClicked?: () => void
  children?: React.ReactNode
}) => {
  const ToastIcon = ToastCardIcons[type]

  return (
    <BaseCard
      variants={['bordered']}
      className={{ root: styles.root, body: styles.body }}
    >
      <ToastIcon className={styles[type]} />
      <div className={styles.container}>{children}</div>
      <CloseIcon
        width={16}
        height={16}
        className={styles.close}
        onClick={onCloseClicked}
      />
    </BaseCard>
  )
}

export { ToastCard }
