// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b0dgMGcXT7Bl7Lvs80df{width:100%;color:rgba(255,255,255,.77);font-weight:200;font-size:16px;line-height:24px;letter-spacing:.02em}.b0dgMGcXT7Bl7Lvs80df a{color:#fc0;text-decoration:none}.b0dgMGcXT7Bl7Lvs80df h1,.b0dgMGcXT7Bl7Lvs80df h2,.b0dgMGcXT7Bl7Lvs80df h3,.b0dgMGcXT7Bl7Lvs80df h4,.b0dgMGcXT7Bl7Lvs80df h5,.b0dgMGcXT7Bl7Lvs80df h6{color:rgba(255,255,255,.96)}`, "",{"version":3,"sources":["webpack://./src/common/components/Typography/components/MarkdownContent/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,2BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,wBACE,UAAA,CACA,oBAAA,CAGF,sJAME,2BAAA","sourcesContent":[".main {\n  width: 100%;\n\n  color: rgba(#fff, 0.77);\n  font-weight: 200;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.02em;\n\n  a {\n    color: #fc0;\n    text-decoration: none;\n  }\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    color: rgba(#fff, 0.96);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `b0dgMGcXT7Bl7Lvs80df`
};
export default ___CSS_LOADER_EXPORT___;
