import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'

import {
  BaseCard,
  Button,
  InputRow,
  Typography,
} from '@lattice/common/components'
import { APIError, AvailableNetwork, NetworkCurrency } from '@lattice/utils'
import { useProgressToasts } from '@lattice/common/hooks'
import {
  OptInCollateralRequirementInDAG,
  useIntegrationsNetProvider,
} from '@lattice/common/providers/IntegrationsNetProvider'
import { useWalletProvider } from '@lattice/common/providers'

import styles from './component.module.scss'

type NodeOperatorRegistrationFormData = {
  walletBalance: number
  operatorNodeId: string
}

const NodeOperatorRegistrationFormCard = () => {
  const doExecuteNodeOperatorRegistationProgressToasts = useProgressToasts()
  const { activeWallet, requestConnectorActivation, accountsNetworkAssets } =
    useWalletProvider()
  const { executeNodeOperatorRegistration, requestApplicant } =
    useIntegrationsNetProvider()

  const nodeOperatorRegistrationForm =
    useForm<NodeOperatorRegistrationFormData>({
      mode: 'onTouched',
      defaultValues: {
        walletBalance: 0,
        operatorNodeId: '',
      },
    })

  const validActiveWalletAddress =
    activeWallet.status === 'connected' &&
    activeWallet.constellation &&
    activeWallet.constellation.account

  const doExecuteNodeOperatorRegistation =
    nodeOperatorRegistrationForm.handleSubmit(
      doExecuteNodeOperatorRegistationProgressToasts.wrappedErrorsAsync(
        async (data: NodeOperatorRegistrationFormData) => {
          try {
            if (
              activeWallet.status !== 'connected' ||
              !activeWallet.constellation
            ) {
              throw new Error('Wallet is not active')
            }

            doExecuteNodeOperatorRegistationProgressToasts.progress(
              'Requesting wallet ownership signature',
              'info',
              null
            )

            const walletOwnershipToken =
              await activeWallet.constellation.requestWalletOwnershipToken()

            doExecuteNodeOperatorRegistationProgressToasts.progress(
              'Submitting details',
              'info',
              null
            )

            await executeNodeOperatorRegistration({
              operatorNodeId: data.operatorNodeId,
              address: activeWallet.constellation.account,
              network: AvailableNetwork.CONSTELLATION,
              walletOwnershipToken,
            })

            doExecuteNodeOperatorRegistationProgressToasts.progress(
              'Details submitted successfully',
              'success',
              10000
            )

            requestApplicant()
          } catch (e) {
            if (
              e instanceof APIError &&
              e.errorCode === 403 &&
              /User is forbidden to take this action/i.test(e.message)
            ) {
              throw new Error(
                "Oops, it seems you're not allowed to execute this action. Contact support for further details."
              )
            } else {
              throw new Error('An error ocurred')
            }
          }
        }
      )
    )

  useEffect(() => {
    nodeOperatorRegistrationForm.setValue(
      'walletBalance',
      accountsNetworkAssets[
        NetworkCurrency.CONSTELLATION__DAG
      ]?.balance.toNumber() ?? 0,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true }
    )
  }, [
    validActiveWalletAddress,
    accountsNetworkAssets[NetworkCurrency.CONSTELLATION__DAG],
  ])

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <Typography.HeaderCardTitle>
          Submit node details
        </Typography.HeaderCardTitle>
        <Typography.SubtextCard>
          To start the onboarding process you will need to connect to the
          Stargazer wallet tied to your node. The wallet address will be the
          used to verify ownership and the required DAG balance to participate.
        </Typography.SubtextCard>
        {validActiveWalletAddress && (
          <form
            className={styles.form}
            onSubmit={doExecuteNodeOperatorRegistation}
          >
            <Controller
              name="walletBalance"
              rules={{
                required: true,
                validate: {
                  programRequirement: (value) =>
                    new Decimal(value).gte(OptInCollateralRequirementInDAG) ||
                    'Current wallet balance is below the required amount.',
                },
              }}
              control={nodeOperatorRegistrationForm.control}
              render={({ field: { ref: __, ...rest }, fieldState }) => (
                <InputRow.Numeric
                  variants={['full-width']}
                  label={'Current wallet balance'}
                  error={fieldState.error?.message}
                  allowNegative={false}
                  suffix={' DAG'}
                  disabled
                  readOnly
                  {...rest}
                />
              )}
            />
            <InputRow
              variants={['full-width']}
              label={'Node ID'}
              placeholder={'Enter your Node ID'}
              error={
                nodeOperatorRegistrationForm.formState.errors.operatorNodeId
                  ?.message
              }
              {...nodeOperatorRegistrationForm.register('operatorNodeId', {
                required: 'Node ID is required',
                validate: {
                  notBlank: (value) =>
                    value.trim() !== '' || 'Node ID cannot be blank',
                  nodeIdValid: (value) =>
                    /^[0-9a-fA-F]{128}$/.test(value) || 'Invalid Node ID',
                },
              })}
            />
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={
                !nodeOperatorRegistrationForm.formState.isValid ||
                nodeOperatorRegistrationForm.formState.isSubmitting
              }
              loading={nodeOperatorRegistrationForm.formState.isSubmitting}
            >
              Submit details
            </Button>
          </form>
        )}
        {!validActiveWalletAddress && (
          <Button
            variants={['outlined', 'full-width']}
            onClick={() => requestConnectorActivation()}
          >
            Connect Stargazer wallet
          </Button>
        )}
      </div>
    </BaseCard>
  )
}

export { NodeOperatorRegistrationFormCard }
