import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

import { BaseCard, Button, InputRow } from '@lattice/common/components'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { useUserProvider } from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import styles from './component.module.scss'

type IDisableMFormData = {
  mfaCode: string
}

const MFADisableCard = ({ onCancelClick }: { onCancelClick?: () => void }) => {
  const { t } = useTranslation()
  const { doVerifyMfaTotpToken, doSetMfaPreference, doFetchUserMfaPreference } =
    useUserProvider()
  const disableMfaOperation = useFetchableOperation()
  const disableMfaOperationProgressToasts = useProgressToasts()

  const userEnableMfaForm = useForm<IDisableMFormData>({
    mode: 'onTouched',
    defaultValues: { mfaCode: '' },
  })

  const doDisableMfa = userEnableMfaForm.handleSubmit(
    disableMfaOperation.wrappedFetch(
      disableMfaOperationProgressToasts.wrappedErrorsAsync(async (data) => {
        await doVerifyMfaTotpToken(data.mfaCode)

        await doSetMfaPreference('NOT_SET')

        await doFetchUserMfaPreference()

        disableMfaOperationProgressToasts.progress(
          '2FA Authentication Disabled',
          'success',
          15000
        )

        onCancelClick && onCancelClick()
      })
    )
  )

  return (
    <BaseCard
      variants={['header-title']}
      header={t(
        'views.User.views.MyAccount.components.MFADisableCard.twoFactor',
        'Two-factor Authentication'
      )}
      className={{ root: styles.root, body: styles.body }}
    >
      <form onSubmit={doDisableMfa}>
        <span>
          {t(
            'views.User.views.MyAccount.components.MFADisableCard.disablingTwo',
            "Disabling two-factor authentication for your account will make it less secure. Are you sure you'd like to disable 2FA?"
          )}
        </span>
        <Controller
          name="mfaCode"
          rules={{
            required: true,
            validate: (value) =>
              value.length === 6 ||
              String(
                t(
                  'views.User.views.SignIn.mfaCodeError',
                  'Code is a 6 digit number'
                )
              ),
          }}
          control={userEnableMfaForm.control}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { ref, ...rest }, fieldState }) => (
            <InputRow.Numeric
              variants={['full-width']}
              placeholder={t(
                'views.User.views.MyAccount.components.MFADisableCard.enterYourVerifiaction',
                'Enter your verification code'
              )}
              error={fieldState.error?.message}
              valueType="value"
              format="### ###"
              {...rest}
            />
          )}
        />
        <Button
          variants={['full-width', 'primary']}
          type="submit"
          disabled={
            !userEnableMfaForm.formState.isValid ||
            disableMfaOperation.status === FetchStatus.PENDING
          }
          loading={disableMfaOperation.status === FetchStatus.PENDING}
        >
          {t(
            'views.User.views.MyAccount.components.MFADisableCard.disable',
            'Disable'
          )}
        </Button>
        <Button
          variants={['full-width', 'secondary']}
          type="button"
          disabled={disableMfaOperation.status === FetchStatus.PENDING}
          onClick={
            disableMfaOperation.status === FetchStatus.PENDING
              ? undefined
              : onCancelClick
          }
        >
          {t(
            'views.User.views.MyAccount.components.MFADisableCard.cancel',
            'Cancel'
          )}
        </Button>
      </form>
    </BaseCard>
  )
}

export { MFADisableCard }
