import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'

import styles from './component.module.scss'

const PendingContent = ({
  variants,
}: {
  variants?: ('min-height' | 'available-height')[]
}) => {
  return (
    <div
      className={cls(
        styles.main,
        variants?.includes('min-height') && styles.minHeight,
        variants?.includes('available-height') && styles.heightAvailable
      )}
    >
      <ImpulseSpinner frontColor="#fc0" />
    </div>
  )
}

export { PendingContent }
