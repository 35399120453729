import React from 'react'
import cls from 'classnames'
import { Trans, useTranslation } from 'react-i18next'

import { ReactComponent as SmileIcon } from '@lattice/assets/icons/feather/smile.svg'
import { ReactComponent as CheckmarkOutlineIcon } from '@lattice/assets/icons/carbon/checkmark-outline.svg'
import { ReactComponent as WarningIcon } from '@lattice/assets/icons/carbon/warning.svg'
import { ReactComponent as RecentlyViewedIcon } from '@lattice/assets/icons/carbon/recently-viewed.svg'
import { Button } from '@lattice/common/components'

import styles from './component.module.scss'

const KycStatus = ({
  status,
  onResubmissionClick,
  isResubmissionAvailable,
}: {
  status: 'complete' | 'approved' | 'pending' | 'declined'
  onResubmissionClick?: () => void
  isResubmissionAvailable?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.main)}>
      {status === 'complete' && (
        <>
          <SmileIcon className={styles[status]} />
          <Trans i18nKey="views.User.views.Kyc.components.KycStatus.complete">
            <span>
              Thanks for completing the verification flow, your KYC status
              should appear shortly
            </span>
          </Trans>
        </>
      )}
      {status === 'approved' && (
        <>
          <CheckmarkOutlineIcon className={styles[status]} />
          <Trans i18nKey="views.User.views.Kyc.components.KycStatus.approved">
            <span>
              Your KYC status is{' '}
              <span className={cls(styles.status, styles[status])}>
                Approved
              </span>
            </span>
          </Trans>
        </>
      )}
      {status === 'pending' && (
        <>
          <RecentlyViewedIcon className={styles[status]} />
          <Trans i18nKey="views.User.views.Kyc.components.KycStatus.pending">
            <span>
              Your KYC status is{' '}
              <span className={cls(styles.status, styles[status])}>
                Pending
              </span>
            </span>
          </Trans>
        </>
      )}
      {status === 'declined' && (
        <>
          <WarningIcon className={styles[status]} />
          <Trans i18nKey="views.User.views.Kyc.components.KycStatus.declined">
            <span>
              Your KYC status is{' '}
              <span className={cls(styles.status, styles[status])}>
                Declined
              </span>
            </span>
          </Trans>
        </>
      )}
      {isResubmissionAvailable && (
        <Button
          onClick={onResubmissionClick}
          variants={['primary', 'full-width']}
        >
          {t(
            'views.User.views.Kyc.components.KycStatus.verifyAgain',
            'Verify again'
          )}
        </Button>
      )}
    </div>
  )
}

export { KycStatus }
