import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const TitleBar = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) => {
  return <div className={cls(styles.main, className)}>{children}</div>
}

export { TitleBar }
