import { apiRequest, AvailableNetwork } from '@lattice/utils'

import { IWalletOwnershipToken } from '../WalletProvider'

import { IIntegrationsNetApplicant } from './types'

const requestApplicant = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/integrations-net/applicant',
      isAuthenticated: true,
    })
    return data as IIntegrationsNetApplicant
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executeApplicantOptIn = async (body: {
  discordHandle: string
  network: AvailableNetwork
  walletOwnershipToken: IWalletOwnershipToken
  address: string
}) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/integrations-net/applicant',
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executeApplicantOptOut = async () => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/integrations-net/applicant/opt-out',
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executeApplicantConfirmRequirement = async (body: {
  network: AvailableNetwork
  walletOwnershipToken: IWalletOwnershipToken
  address: string
}) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/integrations-net/applicant/confirm-requirement',
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const executeNodeOperatorRegistration = async (body: {
  operatorNodeId: string
  network: AvailableNetwork
  walletOwnershipToken: IWalletOwnershipToken
  address: string
}) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/integrations-net/operator',
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  requestApplicant,
  executeApplicantOptIn,
  executeApplicantOptOut,
  executeApplicantConfirmRequirement,
  executeNodeOperatorRegistration,
}
