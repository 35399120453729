import React from 'react'

import { useFetchableResource } from '@lattice/common/hooks'

import {
  ILaunchpadProject,
  ILaunchpadProviderContext,
  ILaunchpadProjectUser,
  ILaunchpadUserAllocation,
} from './types'
import {
  requestProjects,
  requestProject,
  requestProjectUser,
  requestUserAllocation,
  stakeInProjectPool,
} from './utils'

const LaunchpadProviderContext =
  React.createContext<ILaunchpadProviderContext | null>(null)

const LaunchpadProvider = ({ children }: { children: React.ReactNode }) => {
  const projects = useFetchableResource<ILaunchpadProject[]>([])
  const project = useFetchableResource<ILaunchpadProject | null>(null)
  const projectUser = useFetchableResource<ILaunchpadProjectUser | null>(null)
  const userAllocation = useFetchableResource<ILaunchpadUserAllocation | null>(
    null
  )

  const context: ILaunchpadProviderContext = {
    projects,
    project,
    projectUser,
    userAllocation,
    requestProjects: projects.wrappedFetch(requestProjects),
    requestProject: project.wrappedFetch(requestProject),
    requestProjectUser: projectUser.wrappedFetch(requestProjectUser),
    requestUserAllocation: userAllocation.wrappedFetch(requestUserAllocation),
    stakeInProjectPool,
  }

  return (
    <LaunchpadProviderContext.Provider value={context}>
      {children}
    </LaunchpadProviderContext.Provider>
  )
}

export { LaunchpadProvider, LaunchpadProviderContext }
