import React from 'react'

import commonStyles from '../styles.module.scss'

import styles from './component.module.scss'

/**
 * @uses z-index
 * @key QRModal
 * @value 10
 */
const QRModal = ({
  data,
  closeModal,
}: {
  data: string
  closeModal: () => void
}) => {
  return (
    <div className={commonStyles.modalOverlay} onClick={() => closeModal()}>
      <div className={styles.displayQr} onClick={(e) => e.stopPropagation()}>
        <div className={styles.closeButton} onClick={() => closeModal()}>
          X
        </div>
        <img src={data} className={styles.qrImage} />
      </div>
    </div>
  )
}

export { QRModal }
