import { DeploymentStage } from '@lattice/common/consts'

const UpholdTopperBaseAppUrls: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'https://app.topperpay.com',
  [DeploymentStage.STAGING]: 'https://app.sandbox.topperpay.com',
  [DeploymentStage.ALPHA]: 'https://app.sandbox.topperpay.com',
  [DeploymentStage.DELTA]: 'https://app.sandbox.topperpay.com',
  [DeploymentStage.THETA]: 'https://app.sandbox.topperpay.com',
}

const UpholdTopperBaseApiUrls: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'https://api.topperpay.com',
  [DeploymentStage.STAGING]: 'https://api.sandbox.topperpay.com',
  [DeploymentStage.ALPHA]: 'https://api.sandbox.topperpay.com',
  [DeploymentStage.DELTA]: 'https://api.sandbox.topperpay.com',
  [DeploymentStage.THETA]: 'https://api.sandbox.topperpay.com',
}

export { UpholdTopperBaseAppUrls, UpholdTopperBaseApiUrls }
