import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseCard, BaseModal, Button } from '@lattice/common/components'
import { ReactComponent as ChevronRight } from '@lattice/assets/images/svgs/chevronRight.svg'
import { IDtmNode, IWalletOwnershipToken } from '@lattice/common/providers'
import { HeaderCardTitle } from '@lattice/common/components/Typography/components'
import { ReactComponent as CheckmarkOutlineIcon } from '@lattice/assets/icons/carbon/checkmark-outline.svg'

import styles from './view.module.scss'
import { NftSelectionSection, RedemptionForm } from './components'

const RedeemNftView = () => {
  const { t } = useTranslation()
  const [isFirstSectionOpened, setIsFirstSectionOpened] = useState(true)
  const [isSecondSectionOpened, setIsSecondSectionOpened] = useState(false)
  const [nodesToRedeem, setNodesToRedeem] = useState<IDtmNode[] | null>(null)
  const [blacklistedCountries, setBlacklistedCountries] = useState<Record<
    string,
    string
  > | null>(null)
  const [taxIdNotRequiredCountries, setTaxIdNotRequiredCountries] =
    useState<Record<string, string> | null>(null)
  const [taxIdRequiredCountries, setTaxIdRequiredCountries] = useState<Record<
    string,
    string
  > | null>(null)
  const [ownershipToken, setOwnershipToken] =
    useState<IWalletOwnershipToken | null>(null)
  const [address, setAddress] = useState<string | null>(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleRedeemData = (
    nodes: IDtmNode[],
    ownershipToken: IWalletOwnershipToken,
    address: string,
    blacklistedCountries: Record<string, string> | null,
    taxIdNotRequiredCountries: Record<string, string> | null,
    taxIdRequiredCountries: Record<string, string> | null
  ) => {
    setIsFirstSectionOpened(false)
    setIsSecondSectionOpened(true)
    setNodesToRedeem(nodes)
    setOwnershipToken(ownershipToken)
    setAddress(address)
    setBlacklistedCountries(blacklistedCountries)
    setTaxIdNotRequiredCountries(taxIdNotRequiredCountries)
    setTaxIdRequiredCountries(taxIdRequiredCountries)
  }

  const handleCancelButton = () => {
    setIsFirstSectionOpened(true)
    setIsSecondSectionOpened(false)
    setNodesToRedeem(null)
    setOwnershipToken(null)
    setAddress(null)
  }

  const handleSuccessfulRedemption = () => {
    handleCancelButton()
    setShowSuccessModal(true)
  }

  const closeSuccessModal = () => {
    setShowSuccessModal(false)
  }

  return (
    <BaseCard
      variants={['bordered']}
      header={'Redeem NFTs'}
      className={{ header: styles.header }}
    >
      <div className={styles.contentContainer}>
        <Button
          variants={['outlined', 'right-icon', 'full-width']}
          onClick={() => !nodesToRedeem && setIsFirstSectionOpened((s) => !s)}
          disabled={nodesToRedeem !== null}
          rightIcon={
            <ChevronRight
              style={{
                transform: isFirstSectionOpened
                  ? 'rotate(270deg)'
                  : 'rotate(90deg)',
              }}
            />
          }
        >
          1.{' '}
          {t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.selectNfts',
            'Select your NFTs'
          )}
        </Button>
        {isFirstSectionOpened && (
          <NftSelectionSection handleRedeemData={handleRedeemData} />
        )}
        {showSuccessModal && (
          <BaseModal>
            <BaseCard variants={['full-width']}>
              <div className={styles.successModalContainer}>
                <div className={styles.successTitleRow}>
                  <div className={styles.title}>
                    <CheckmarkOutlineIcon color={'#55C555'} />
                    <HeaderCardTitle>
                      {t(
                        'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.successModal.title',
                        'NFT redemption successful'
                      )}
                    </HeaderCardTitle>
                  </div>
                  <div className={styles.close} onClick={closeSuccessModal}>
                    <HeaderCardTitle>X</HeaderCardTitle>
                  </div>
                </div>
                <div className={styles.successMessage}>
                  {t(
                    'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.successModal.message',
                    'You will receive a confirmation email with your order details.'
                  )}
                </div>
              </div>
            </BaseCard>
          </BaseModal>
        )}
        <Button
          variants={['outlined', 'right-icon', 'full-width']}
          onClick={() => nodesToRedeem && setIsSecondSectionOpened((s) => !s)}
          disabled={!nodesToRedeem}
          rightIcon={
            <ChevronRight
              style={{
                transform: isSecondSectionOpened
                  ? 'rotate(270deg)'
                  : 'rotate(90deg)',
              }}
            />
          }
        >
          2.{' '}
          {t(
            'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.redemptionInfo',
            'Redemption information'
          )}
        </Button>
        {isSecondSectionOpened &&
          nodesToRedeem &&
          ownershipToken &&
          address && (
            <RedemptionForm
              nodesToRedeem={nodesToRedeem}
              ownershipToken={ownershipToken}
              address={address}
              blacklistedCountries={blacklistedCountries}
              taxIdNotRequiredCountries={taxIdNotRequiredCountries}
              taxIdRequiredCountries={taxIdRequiredCountries}
              handleSuccess={handleSuccessfulRedemption}
              onCancel={handleCancelButton}
            />
          )}
      </div>
    </BaseCard>
  )
}

export { RedeemNftView }
