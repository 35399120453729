import React, { useEffect, useState } from 'react'
import cls from 'classnames'
import { PlacesType } from 'react-tooltip'
import {
  parsePhoneNumberFromString,
  CountryCode,
  AsYouType,
} from 'libphonenumber-js'

import { useImperativeProxiedInputValueRef } from '@lattice/common/hooks'

import styles from '../../component.module.scss'

const IntlPhoneInputRow = React.forwardRef<
  HTMLInputElement,
  {
    defaultCountryCode?: string
    variants?: ('full-width' | 'disabled')[]
    label?: string
    error?: string
    icon?: React.ReactNode
    labelIcon?: React.ReactNode
    iconTooltip?: string
    tooltipPlace?: PlacesType
    onIconClick?: () => void
    classNames?: {
      root?: string
      label?: string
      input?: string
      inputWrapper?: string
      error?: string
      icon?: string
      labelIcon?: string
    }
  } & JSX.IntrinsicElements['input']
>(
  (
    {
      defaultCountryCode,
      variants,
      label,
      error,
      icon,
      labelIcon,
      iconTooltip,
      tooltipPlace,
      onIconClick,
      classNames,
      type,
      value,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const imperativeProxiedTarget = useImperativeProxiedInputValueRef(
      ref,
      value
    )
    const [internalValue, setInternalValue] = useState('')

    useEffect(() => {
      value &&
        setInternalValue(
          parsePhoneNumberFromString(String(value), {
            defaultCountry: defaultCountryCode as CountryCode,
            extract: false,
          })?.formatInternational() ?? ''
        )
      imperativeProxiedTarget.internalTargetValue.value = value
    }, [value])

    return (
      <div
        className={cls(
          styles.root,
          variants?.includes('full-width') && styles.fullWidth,
          classNames?.root
        )}
      >
        {label && (
          <label className={cls(styles.label, classNames?.label)}>
            {label}
            {labelIcon && (
              <p
                data-tooltip-id="lattice"
                data-tooltip-content={iconTooltip}
                data-tooltip-place={tooltipPlace}
                className={styles.noMargin}
              >
                {labelIcon}
              </p>
            )}
          </label>
        )}
        <div className={cls(styles.inputWrapper, classNames?.inputWrapper)}>
          <input
            {...props}
            {...{ type, value, defaultValue }}
            className={cls(
              styles.input,
              variants?.includes('disabled') && styles.disabled,
              error && cls(styles.error, classNames?.error),
              classNames?.input
            )}
            value={internalValue}
            onChange={(e) => {
              const phoneNumberParser = new AsYouType(
                defaultCountryCode as CountryCode
              )

              setInternalValue(phoneNumberParser.input(e.target.value))

              const parsedPhoneNumber = phoneNumberParser.getNumber()

              if (parsedPhoneNumber) {
                imperativeProxiedTarget.internalTargetValue.value =
                  parsedPhoneNumber.number
                props.onChange &&
                  props.onChange({
                    ...e,
                    target: imperativeProxiedTarget.internalTargetProxy as any,
                  })
                setInternalValue(parsedPhoneNumber.formatInternational())
              }
            }}
            ref={imperativeProxiedTarget.internalRef}
          />
          <div
            className={cls(styles.icon, classNames?.icon)}
            onClick={(e) => {
              e.stopPropagation()
              onIconClick && onIconClick()
            }}
          >
            {icon}
          </div>
        </div>
        {error && (
          <span className={cls(styles.error, classNames?.error)}>{error}</span>
        )}
      </div>
    )
  }
)

export { IntlPhoneInputRow }
