// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WoCjkw5JF4IGsxRsEAa3{display:flex;flex-flow:column nowrap;row-gap:20px;padding:35px 30px}.vgZmha5ym4VWfi_MvX0v{border-top-left-radius:0px;border-top-right-radius:0px}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/SoftNodeProgramDetailView/components/ProgramCard/component.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CAEA,uBAAA,CACA,YAAA,CAEA,iBAAA,CAGF,sBACE,0BAAA,CACA,2BAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.body {\n  display: flex;\n\n  flex-flow: column nowrap;\n  row-gap: 20px;\n\n  padding: 35px 30px;\n}\n\n.withHeader {\n  border-top-left-radius: 0px;\n  border-top-right-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `WoCjkw5JF4IGsxRsEAa3`,
	"withHeader": `vgZmha5ym4VWfi_MvX0v`
};
export default ___CSS_LOADER_EXPORT___;
