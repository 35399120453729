import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import { ethers } from 'ethers'

import { getTypedContract, requestERC20Token } from '@lattice/utils'
import { useProgressToasts } from '@lattice/common/hooks'

import { IAlkimStakingProject, IAlkimStakingProjectUser } from './types'

const APY_DECIMALS = 3

const requestProject = async (contractAddress: string) => {
  const contract = getTypedContract('AlkimiStakingProgram', contractAddress)
  const token = await requestERC20Token(await contract.ADS())

  const stakingStartsAt = dayjs
    .unix((await contract.stakingPeriodStart()).toNumber())
    .toISOString()

  const stakingWindowEndsAt = dayjs
    .unix((await contract.stakingPeriodEnd()).toNumber())
    .toISOString()

  const stakingEndsAt = dayjs
    .unix((await contract.rewardsPeriodEnd()).toNumber())
    .toISOString()

  const lockupEndsAt = dayjs
    .unix((await contract.tokensUnlockedTimestamp()).toNumber())
    .toISOString()

  const cappedPool = new Decimal((await contract.STAKING_CAP()).toString()).div(
    Decimal.pow(10, token.decimals)
  )

  const totalStaked = new Decimal(
    (await contract.totalStaked()).toString()
  ).div(Decimal.pow(10, token.decimals))

  const totalRewards = new Decimal(
    (await contract.rewardAmount()).toString()
  ).div(Decimal.pow(10, token.decimals))

  const project: IAlkimStakingProject = {
    contract,
    token,
    stakingStartsAt,
    stakingWindowEndsAt,
    stakingEndsAt,
    lockupEndsAt,
    cappedPool,
    totalStaked,
    totalRewards,
  }

  return project
}

const requestProjectUser = async (
  project: IAlkimStakingProject,
  account: string
) => {
  const stakingContract = project.contract
  const token = project.token

  const canUserStake = dayjs().isBefore(dayjs(project.stakingWindowEndsAt))
  const canUserWithdraw = dayjs().isAfter(dayjs(project.lockupEndsAt))
  const canUserWithdrawRewards = dayjs().isAfter(dayjs(project.lockupEndsAt))

  // Throws after the staking period ends
  let userCurrentAPY
  try {
    userCurrentAPY = new Decimal(
      (await stakingContract.APY(account)).toString()
    ).div(Decimal.pow(10, APY_DECIMALS))
  } catch (e: any) {
    userCurrentAPY = 0
  }

  const balanceOfUserToken = new Decimal(
    (await token.contract.balanceOf(account)).toString()
  ).div(Decimal.pow(10, token.decimals))

  const balanceOfUserTokenAllowance = new Decimal(
    (
      await token.contract.allowance(account, stakingContract.address)
    ).toString()
  ).div(Decimal.pow(10, token.decimals))

  const balanceOfStake = new Decimal(
    (await stakingContract.balanceOfStake(account)).toString()
  ).div(Decimal.pow(10, token.decimals))

  const balanceOfRewards = new Decimal(
    (await stakingContract.grossEarnings(account)).toString()
  ).div(Decimal.pow(10, token.decimals))

  const balanceOfRewardsReceived = new Decimal(
    (await stakingContract.rewardsReceived(account)).toString()
  ).div(Decimal.pow(10, token.decimals))

  const projectUser: IAlkimStakingProjectUser = {
    canUserStake,
    canUserWithdraw,
    canUserWithdrawRewards,
    userCurrentAPY,
    balanceOfUserToken,
    balanceOfUserTokenAllowance,
    balanceOfStake,
    balanceOfRewards,
    balanceOfRewardsReceived,
  }

  return projectUser
}

const doUserApprove = async (
  amount: Decimal,
  project: IAlkimStakingProject,
  projectUser: IAlkimStakingProjectUser,
  signer: ethers.Signer,
  progressToasts: ReturnType<typeof useProgressToasts>
) => {
  const tokenContract = project.token.contract.connect(signer)

  progressToasts.progress('Requesting transaction approval', 'info', null)

  const trx = await tokenContract.approve(
    project.contract.address,
    amount.mul(Decimal.pow(10, project.token.decimals)).toString()
  )

  progressToasts.progress('Awaiting network confirmation', 'info', null)

  await trx.wait(3)

  progressToasts.progress('Approval successful', 'success', 15000)
}

const doUserStake = async (
  amount: Decimal,
  project: IAlkimStakingProject,
  projectUser: IAlkimStakingProjectUser,
  signer: ethers.Signer,
  progressToasts: ReturnType<typeof useProgressToasts>
) => {
  if (!projectUser.canUserStake) {
    throw new Error('User is unable to stake at this moment')
  }

  if (amount.greaterThan(projectUser.balanceOfUserToken)) {
    throw new Error('User does not have enough balance to stake')
  }

  const stakeContract = project.contract.connect(signer)

  if (amount.greaterThan(projectUser.balanceOfUserTokenAllowance)) {
    await doUserApprove(amount, project, projectUser, signer, progressToasts)
  }

  progressToasts.progress('Requesting transaction approval', 'info', null)

  const trx = await stakeContract.stake(
    amount.mul(Decimal.pow(10, project.token.decimals)).toString()
  )

  progressToasts.progress('Awaiting network confirmation', 'info', null)

  await trx.wait(3)

  progressToasts.progress('Withdraw successful', 'success', 15000)
}

const doUserWithdraw = async (
  project: IAlkimStakingProject,
  projectUser: IAlkimStakingProjectUser,
  signer: ethers.Signer,
  progressToasts: ReturnType<typeof useProgressToasts>
) => {
  if (!projectUser.canUserWithdraw) {
    throw new Error(
      'User is unable to withdraw at this moment, lockup is still active'
    )
  }

  const stakeContract = project.contract.connect(signer)

  progressToasts.progress('Requesting transaction approval', 'info', null)

  const trx = await stakeContract.unstake()

  progressToasts.progress('Awaiting network confirmation', 'info', null)

  await trx.wait(3)

  progressToasts.progress('Withdraw successful', 'success', 15000)
}

const doUserWithdrawRewards = async (
  project: IAlkimStakingProject,
  projectUser: IAlkimStakingProjectUser,
  signer: ethers.Signer,
  progressToasts: ReturnType<typeof useProgressToasts>
) => {
  if (!projectUser.canUserWithdraw) {
    throw new Error(
      'User is unable to withdraw at this moment, lockup is still active'
    )
  }

  const stakeContract = project.contract.connect(signer)

  progressToasts.progress('Requesting transaction approval', 'info', null)

  const trx = await stakeContract.claimReward()

  progressToasts.progress('Awaiting network confirmation', 'info', null)

  await trx.wait(3)

  progressToasts.progress('Withdraw successful', 'success', 15000)
}

export {
  requestProject,
  requestProjectUser,
  doUserStake,
  doUserWithdraw,
  doUserWithdrawRewards,
}
