import { createProviderStateHook } from '@lattice/utils'

import {
  IntegrationsNetProvider,
  IntegrationsNetProviderContext,
} from './provider'

const useIntegrationsNetProvider = createProviderStateHook(
  IntegrationsNetProvider,
  IntegrationsNetProviderContext
)

export { useIntegrationsNetProvider }
