/* TODO: Missing Translation */
const text = `
__Last Updated: June 7th, 2022__

Please read these Terms of Service (“Agreement” or “Terms”) carefully. Your use or access of the Site (as defined below) constitutes your consent to this Agreement.

This Agreement is between you and Lattice Gateway (“Lattice”, “we”, “our” or “us”) concerning your use of (including any access to) Lattice’s websites, including but not limited to https://lattice.exchange and any subdomains, mobile applications, and web applications (collectively with any other materials and services available therein, and successor site(s) or application(s) thereto, “Site”) and the services we provide via the Site (“Services”). This Agreement hereby incorporates by this reference any additional terms and conditions with respect to the Site posted by Lattice to the Site, or otherwise made available to you by Lattice, including without limitation, during the creation, acquisition, transfer, or modification of certain digital assets, our online and/or mobile services, and software provided on or in connection with those services.

Lattice is a product provided to you by Constellation Network, inc. These Terms of Use therefore constitute an agreement between you and Constellation Network, Inc.

By clicking or tapping any button or box marked “accept,” “agree” or “OK” (or a similar term) in connection with this Agreement, or by accessing or using the Site, you agree to be bound by this Agreement and affirm that you are of legal age to enter into this Agreement where you live and have the legal capacity to enter into this Agreement.

If you are an individual accessing or using the Site on behalf of, or for the benefit of, any corporation, partnership or other entity with which you are associated (“Organization”), then you are agreeing to this Agreement on behalf of yourself and such Organization, and you represent and warrant that you have the legal authority to bind such Organization to this Agreement. References to “you” and “your” in this Agreement will refer to both the individual using the Site and to any such Organization.

# General Terms and Conditions
The Site provides users with general information about the Services and multiple related technology platforms, including, but not limited to (i) a technology platform that enables you to create and enter into simple contracts without the need for legal services (“Lattice Gateway), and (ii) a technology platform which permits issuers of Tokens to transact directly with purchasers of Tokens (“Lattice Launchpad”).

# No Advice Provided
Lattice, and its shareholders, directors, officers, Affiliates, employees, contractors, agents, partners, insurers, and attorneys (collectively, “Associates”) do not provide any investment, portfolio management, legal, accounting, tax or other advice, or advice on trading techniques, models, algorithms, or any other schemes.

# Definitions
“Affiliate” means a person that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with the first mentioned person.

“AML” means anti-money laundering, including, but not limited to, Applicable Law prohibiting money laundering or any acts or attempted acts to conceal or disguise the identity or origin of; change the form of; or move, transfer, or transport, illicit proceeds, property, funds, currency, or Tokens, including, but not limited to, the promotion of any unlawful activity such as fraud, tax evasion, embezzlement, insider trading, financial crime, bribery, cyber theft or hack, narcotics trafficking, weapons proliferation, terrorism, or Economic Sanctions violations, which may also require internal controls to detect, prevent, report, and maintain records of suspected money laundering or terrorist financing.

“Anti-Corruption” refers to all Applicable Law applicable to you prohibiting corruption or bribery of government officials, kickbacks, inducements, and other related forms of commercial corruption or bribery.
“Applicable Law” is defined as all laws, statutes, orders, regulations, rules, treaties, and/or official obligations or requirements enacted, promulgated, issued, ratified, enforced, or administered by any government that apply to you or the Site.

“CTF” means counter-terrorist financing.

“Economic Sanctions” refers to financial or economic sanctions, trade embargoes, export or import controls, and restrictive trade measures enacted, administered, enforced, or penalized by any Applicable Law.
“Prohibited Jurisdiction” means the United States.

“Sanctioned Person” refers to any person or Wallet that is (i) specifically listed in any Sanctions List, (ii) directly or indirectly owned 50% or more by any person or group of persons in the aggregate, or a Wallet associated with such person or persons, referred to in any Sanctions List, or government or government official of the Prohibited Jurisdiction, or (iii) that is subject to any government approval or otherwise sanctioned, restricted, or penalized under Applicable Law.

“Sanctions List” is defined as the Specially Designated Nationals and Blocked Persons List (“SDN List”) and other non-SDN Lists, as determined in Lattice’s sole and absolute discretion, which may include, but is not limited to, the Sectoral Sanctions Identifications List, published by the Office of Foreign Assets Control of the U.S. Department of the Treasury, the Section 311 Special Measures for Jurisdictions, Financial Institutions, or International Transactions of Primary Money Laundering Concern published by Financial Crimes Enforcement Network of the U.S. Department of the Treasury, and, any other foreign terrorist organization or other sanctioned, restricted, or debarred party list published by the Financial Investigation Authority of the British Virgin Islands, or under Economic Sanctions, AML, or CTF Laws of or by the United States, the British Virgin Islands (including any sanctioned, restricted, or debarred party list under the Laws of the United Kingdom and applicable in the British Virgin Islands), the Seychelles and the United Nations.

“Token” is defined as a special virtual currency token or how cryptocurrencies are denominated. Tokens function as (i) a medium of exchange; (ii) a unit of account; (iii) a store of value; and/or (iv) other similar digital representations of rights or assets, which is neither issued nor guaranteed by any country or jurisdiction and does not have legal tender status in any country or jurisdiction.

“Wallet” refers to a software application (or other mechanism) that provides a means for holding, storing, and/or transferring Tokens.

# Lattice Launchpad Terms
Lattice operates the Lattice Launchpad, a platform that allows issuers of Tokens to transact directly with purchasers of the Tokens.

If you decide to purchase Tokens through the Site or Services, that purchase (i) may be subject to additional terms you agree upon with an issuer of tokens for the purchase and sale of the Tokens; (ii) may be required to provide, in addition to the information or documents required pursuant to this Agreement,  additional due diligence documents (“Additional KYC”); and (iii) may be subject to limitations described on the Site or Services, including, without limitation, sale restrictions, as determined and set forth by Lattice.

Lattice will provide administrative services to both you and the issuer of Tokens to facilitate purchases of Tokens. Notwithstanding the foregoing, you acknowledge and agree that (i) your purchase of Tokens constitutes a separate agreement by and between you and the issuer of the Tokens; (ii) Lattice is not a party to any purchase of Tokens by you from an issuer; and (iii) Lattice will not enforce the terms of any agreements between you and any issuer related to the purchase of Tokens.

Lattice provides users with a mechanism to allow the transfer Tokens to a Wallet (“Smart Contract”). A Smart Contract provides a method for you to purchase one or more Tokens from an issuer using the Services. To fund the Smart Contract and use the Services, you must (i) follow the procedures set forth on the Site to connect your Wallet to your account (“Integrated Wallet”) and (ii) transact solely from the Integrated Wallet you connected to the Services. Lattice does not permit you to add Tokens to the Smart Contract through any other means or any Wallets other than the Integrated Wallet.

You must transfer sufficient Tokens to the Smart Contract to facilitate payment for the Tokens you wish to purchase from an issuer. Lattice Launchpad will be used to facilitate (i) the transfer the number and type of Tokens you agree upon with the issuer in exchange for the Tokens; (ii) the transfer from the Smart Contract of Tokens to your Integrated Wallet; and (iii) other administrative matters such as execution of any agreements for the purchase of Tokens and confirming receipt and delivery of Tokens, as applicable, by the issuer and you.

You acknowledge and agree that the Smart Contract will hold the Tokens you send to it on the applicable blockchain, which will be chosen by Lattice. While the Tokens that you transfer to the Smart Contract will be recorded by Lattice as owned by you, the Tokens transferred into the Smart contract may be commingled with the Tokens of other users of the Site or Services. You agree and acknowledge that Tokens will generally be allocated on a “first come, first serve” basis.

You acknowledge and agree that you cannot purchase Tokens unless you (i) satisfy the requirements of this Agreement and any Additional KYC requirements; (ii) execute any applicable agreements for the purchase of Tokens; and (iii) transfer a sufficient number of Tokens, as required by Lattice at the time of the transaction. Your purchase of the Tokens may be invalidated and the Tokens you wished to purchase may be allocated to other purchasers if you fail to meet requirements (i)-(iii) listed above.

You agree that you assume and bear the risk of any and all losses related to your purchase of Tokens and that you have no right whatsoever to claim damages, specific performance, or compensation in any form from Lattice or its Associates related to your purchase of Tokens. In the event of any discrepancy in the information reflected in the Smart Contract or elsewhere on the Site and information on the books and records of Lattice, the books and records of Lattice will be final and binding for all purposes, even if this results in losses to you.

# Compliance and Due Diligence

Lattice is committed to providing safe and compliant Services. Lattice is also committed to identifying, detecting, preventing, and reporting on money laundering, terrorist financing, and other improper activities under Applicable Law, AML, CTF, Anti-Corruption, and Economic Sanctions Laws.

Lattice may, in its sole and absolute discretion, conduct a comprehensive and thorough user due diligence process and ongoing analysis and reporting. By agreeing to these Terms of Service and using the Site and/or Services, you affirmatively certify that you are not a Sanctioned Person. You further certify that you have not been suspended or blocked from using the Site or Services by Lattice, (“Prohibited Person”) and are not utilizing the Services for the benefit of a Prohibited Person. You further certify that you will promptly provide all information requested and necessary to satisfy due diligence requirements and obligations as set by Lattice in its sole and absolute discretion. You agree to promptly provide any documentation, information, or records requested by Lattice at any time, including, but not limited to, a self-certification permitting the determination of tax residence and status under Applicable Law. Such information may include, but is not limited to, self-certifications as to beneficial ownership. Lattice may need to retain certain information, documentation, and records pursuant to Applicable Law and its contractual relationships. Lattice expressly reserves the right to keep such information, documentation, and records for legal and compliance recordkeeping.

Lattice may monitor and assess suspicious or sanctionable transactions under Applicable Law, AML, CTF, Anti-Corruption, and Economic Sanctions Laws. Lattice may also provide mandatory reporting to applicable international regulators. This reporting will apply even when you suspend or terminate your relationship with Lattice. Our policies apply to any and all Tokens and other funds or property being exchanged on or through the Site or by any of you or your Affiliates.

# Acknowledgment
YOU ACKNOWLEDGE THAT LATTICE MAY CHECK YOUR BACKGROUND AND VERIFY YOUR IDENTITY IN CONNECTION WITH CERTAIN USES OF THE SITE OR SERVICES. YOU AUTHORIZE US TO, DIRECTLY OR THROUGH THIRD-PARTIES, MAKE ANY INQUIRIES AND CONDUCT ANY INVESTIGATION WE DETERMINE TO BE NECESSARY OR HELPFUL TO VERIFY YOUR IDENTITY AND TO TAKE ANY ACTIONS WE DEEM NECESSARY OR HELPFUL BASED ON THE RESULTS OF SUCH INQUIRIES AND INVESTIGATIONS. YOU FURTHER AUTHORIZE ANY AND ALL THIRD-PARTIES TO WHICH ANY SUCH INQUIRIES OR INVESTIGATIONS MAY BE DIRECTED TO FULLY RESPOND TO SUCH INQUIRIES OR INVESTIGATIONS. YOU ACKNOWLEDGE AND AGREE THAT WE MAY, IN OUR SOLE DISCRETION, DENY YOU THE RIGHT TO USE THE SITE AND/OR SERVICES. LATTICE AND ITS ASSOCIATES WILL HAVE NO LIABILITY TO YOU FOR ANY LIABILITY OR OTHER LOSSES ARISING FROM ANY INQUIRIES OR INVESTIGATIONS ARISING UNDER THIS SECTION.

# Accuracy of Information
It is your sole responsibility to provide accurate information for creation and maintenance of your account. You are solely responsible for ensuring the accuracy and completeness of all information and materials that you provide to use in connection with your use of the Site and Services. This includes, but is not limited to, all information and materials that you provide to us in connection with background checks and identity verification. You represent and warrant that (i) all such information and materials are true, accurate, and complete in all respects, comply with Applicable Law and do not violate or infringe any third-party rights; and (ii) you will immediately notify us about, and correct, and inaccuracy in any such materials or information.

# User Accounts
While you may always browse public-facing portions of the Site without registering an account with us, in order to access certain portions of the Site or Services, Lattice may require you to register an account with us (“Account”).

You are responsible for the security of your Account, and are fully responsible for all activities that occur through the use of your credentials. You agree to notify us immediately at support@lattice.exchange if you suspect or know of any unauthorized use of your log-in credentials or any other breach of security with respect to your Account. We will not be liable for any loss or damage arising from unauthorized use of your credentials. You may be required to create and provide separate log-in credentials to access Third-Party Services.

We reserve the right to disallow, cancel, remove, or reassign certain usernames and other information associated with an Account in appropriate circumstances, as determined by us in our sole and absolute discretion, and may, with or without prior notice, suspend or terminate your Account if activities occur on your Account which would or might constitute a violation of this Agreement, cause damage to or impair the Services or Site, infringe or violate any third-party rights, damage or bring into disrepute the reputation of Lattice, or violate Applicable Law. These determinations will be made in Lattice’s sole and absolute discretion. If messages sent to the email address you provide are returned as undeliverable, then we may terminate your Account immediately without notice to you and without any liability to you or any third-party.

# Eligibility
You may not use this Site or the Services if you are (i) a natural person under the age of 18; (ii) a person located or residing in, or formed under in a Prohibited Jurisdiction; or (iii) a Prohibited Person.

# Wallets
YOU ACKNOWLEDGE AND AGREE THAT (i) LATTICE HAS NO CONTROL OVER ANY WALLET THAT YOU MAY USE IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES; (ii) ALL WALLETS ARE PROVIDED BY THIRD-PARTIES; (iii) LATTICE HAS NO RIGHT OR ABILITY TO CONTROL THIRD-PARTIES WHO PROVIDE WALLETS; (iv) ANY DISPUTE BETWEEN YOU AND ANY THIRD-PARTY WALLET PROVIDER IS BETWEEN YOU AND THAT THIRD-PARTY WALLET PROVIDER; AND (v) THAT LATTICE IS NOT LIABLE FOR ANY ISSUES OR LOSSES CAUSED BY USE OF THIRD-PARTY WALLETS.

# Reservation of Rights
Lattice reserves the right to bar any transactions on the Site or through the Services, for or with, any user with or without cause, at any time, subject to any limitations imposed by Applicable Law. In lieu of refusing access to the Services, Lattice may, in its sole and absolute discretion, perform due diligence. You may be subject to due diligence procedures in your use of the Site and Services. If you decline to provide requested information or otherwise do not reply timely or substantively with the documentation or data requested, Lattice has the absolute discretion to immediately suspend or terminate your Account.

# Additional Terms
We may require you to agree to additional terms and/or policies from time-to-time in connection with your use of the Site or Services (“Additional Terms”). Depending on the Services you use, you may be subject to the additional terms provided in the Additional Terms below. Except as expressly stated otherwise in such Additional Terms, any Additional Terms are hereby incorporated into and subject to this Agreement, and this Agreement will control in the event of any conflict or inconsistency with the Additional Terms to the extent of the conflict or inconsistency.

# Mobile Services
The Site or portions thereof may be accessible via a mobile phone, tablet or other wireless devices (collectively, “Mobile Services”). Your mobile carrier’s normal messaging, data and other rates and fees will apply to your use of the Mobile Services. In addition, downloading, installing or using certain Mobile Services may be prohibited or restricted by your mobile carrier, and not all Mobile Services may work with all carriers or devices. Therefore, you are solely responsible for checking with your mobile carrier to determine if the Mobile Services are available for your mobile device(s), what restrictions, if any, may be applicable to your use of the Mobile Services, and how much they will cost you. Nevertheless, all use of the Site and the related Mobile Services must be in accordance with this Agreement. Lattice and its Associates shall not be liable to any party for any loss or damage resulting in whole or in part from any malfunction in its Site or Service to you to the extent such malfunction arises from, or is in anyway connected to, your mobile carrier’s service.

# Intellectual Property Rights
The Site, Services, and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Lattice, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. Lattice grants you a revocable, non-exclusive, non-transferable, non-sublicensable, royalty-free and worldwide right and license to access and use the Services and Site solely in strict compliance with the provisions of this Agreement and as permitted by the functionalities of the Site and Services available to you.

Lattice’s name and all related names, logos, product and service names, designs, and slogans are trademarks of Lattice, its Associates, or licensors. You must not use such marks without the prior written permission of Lattice. All other names, logos, product and service names, designs, and slogans on this Site are the trademarks of their respective owners.

Lattice reserves all rights not expressly granted to you in this section. Accordingly, nothing in this Agreement or on the Site or Services will be construed as granting to you, by implication, estoppel or otherwise, any additional license rights in and to the Site, Services, or any Lattice intellectual property located or displayed on or within the Site or Services.

# Content
Lattice makes no representation that content and materials on the Site or the Services are appropriate, lawful or available for use in any jurisdiction. Individuals who choose to access or use the Site or Services do so on their own initiative and are responsible for compliance with Applicable Law.

Certain features on the Site may allow you to submit, post, or upload (collectively, “Post”) content to the Site or you may otherwise directly or indirectly provide content to Lattice, such as user profile content or other materials subject to intellectual property or similar Applicable Law (“User Content”). For all User Content that you Post, you grant us (and those third parties we work with) a worldwide license to use, exploit, host, store, transmit, reproduce, modify, create derivative works of (such as those resulting from changes we make so that your User Content works better with our Site), publish, display, and distribute such content; provided that we will not share with other users any User Content that you Post that is not viewable by other users based on any privacy settings available on the Site. The rights you grant in this section are for the purpose of operating, promoting, and improving our Site and Services and this license continues even if you stop using our Site.

We are under no obligation to edit or control User Content that you Post, and will not be in any way responsible or liable for User Content. Lattice may, however, at any time and without prior notice, filter, screen, remove, edit, or block any User Content that, in our sole and absolute discretion, violates this Agreement or is otherwise objectionable. User Content that Lattice determines is or could be interpreted to be abusive, bigoted, defamatory, harassing, harmful, infringing, obscene, offensive, pornographic, racist, threatening, unlawful, vulgar or otherwise inappropriate (collectively, “Offensive Content”) may be screened, removed, edited, or blocked. Further, we may take any action we deem necessary and/or appropriate against any user who Posts Offensive Content, including, but not limited to, suspending or terminating the user’s Account.

# Acceptable Use
In connection with the use of the Site or Services, you must not:

- Post, transmit or otherwise make available through or in connection with the Site or Services any materials that are or may be: (i) threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and dignity of others; (ii) defamatory, libelous, fraudulent or otherwise tortious; (iii) obscene, indecent, pornographic or otherwise objectionable; or (iv) protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.
- Post, transmit or otherwise make available through or in connection with the Site or Services any virus, worm, Trojan horse, Easter egg, time bomb, spyware, ransomware, malware, or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment.
- Use the Site or Services for any commercial purpose except as explicitly provided by this Agreement or any other terms agreed to in writing by us, or for any purpose that is fraudulent or otherwise tortious or unlawful collection of information about users of the Site except as explicitly provided by this Agreement and our [Privacy Policy](https://lattice.exchange/privacy/).
Delete or alter any content or material that Lattice makes available on the Site and/or Services.
- Use the Site to transmit through or in connection with the Site, any spam, chain letters or other unsolicited communications. Interfere with or disrupt the operation of the Site or the servers or networks used to make the Site or Services available, including by hacking or defacing any portion of the Site (including any content available thereby); or violate any requirement, procedure or policy of such servers or networks.
- Restrict or inhibit any other person from legal use of the Site or Services.
- Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Site or Services except as expressly authorized herein, without Lattice’s express prior written consent.
- Reverse engineer, decipher, decompile or disassemble any portion of the Site, except to the extent such restriction is expressly prohibited by applicable law.
- Remove any copyright, trademark or other proprietary rights notice from the Site or Services.
- Frame or mirror any portion of the Site or Services, or otherwise incorporate any portion of the Site into any product or service, without Lattice’s express prior written consent.
- Restrict, discourage or inhibit any person from using the Site or Services.
- Systematically download and store content from the Site or Services.
- Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine”, or otherwise gather content on the Site or Services (including Submissions), or reproduce or circumvent the navigational structure or presentation of the Site, without Lattice’s express prior written consent. Notwithstanding the foregoing, and subject to compliance with applicable law and any instructions posted in the robots.txt file located in the Site’s root directory, Lattice grants to the operators of public search engines permission to use spiders to copy materials from the Site for the sole purpose of (and solely to the extent necessary for) creating publicly available, searchable indices of such materials, but not caches or archives of such materials. Lattice reserves the right to revoke such permission either generally or in specific cases, at any time and without notice.
- Engage in any activity which would violate, or assist in violation of, any law, statute, ordinance, or regulation, sanctions programs administered in any jurisdiction the Site operates, including but not limited to the U.S. Department of Treasury's Office of Foreign Assets Control ("OFAC"), or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any unlawful material or information.
- Create a false identity or impersonate another person or entity in any way.
- Use trading strategies aimed at exploiting errors in prices, conducting transactions at off-market prices, or taking advantage of Internet issues, blockchain network failures, connectivity delays, market events, price feed errors, or other clear defects where prices displayed on the Site do not actually reflect the market rates, or entering into transactions or combinations of transactions which, taken together or separately, are for the purpose of manipulating the Site or Services.

# Privacy
All information we collect on this Site is subject to our Privacy Policy. By using the Site, you consent to all actions taken by us with respect to your information in compliance with our [Privacy Policy](https://lattice.exchange/privacy/).

Our [Privacy Policy](https://lattice.exchange/privacy/) is a part of this Agreement. Please review our [Privacy Policy](https://lattice.exchange/privacy/), which also governs the Site and informs users of our data collection practices.

Please note that it is our policy to comply with all facially valid subpoenas, court orders or binding orders issued to us by law enforcement agencies and other government authorities. This may affect your access to your account, your funds and our services to you. We are not responsible for any losses, whether direct or indirect, that you may incur as a result of our compliance with Applicable Law, the guidance or direction of any regulatory authority or government authority, or any writ of attachment, lien, levy, subpoena, warrant, or other legal order.

# Third-Party Services
Lattice and its Associates may contract with third-parties, including those providing research analysis or other information related to the Services to provide certain data, information, insights, analysis, and articles that are made available through the Site and the Services, and may also provide links to third-party websites (or other online properties that are not owned or controlled by Lattice) or services that are not under the control of Lattice (collectively, “Third-Party Services”). In addition to this Agreement, you may be bound by any additional terms required by providers of Third-Party Services. Lattice and its Associates make no representations about, and accept no liability for, any Third-Party Services. Lattice and its Associates are not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements contained in Third-Party Services, the services offered thereby or for their privacy and security policies and procedures.

# Comments and Questions
If you choose to provide us with input or suggestions regarding problems with or proposed modifications or improvements to the Site or Services (“Feedback”), then you grant to us a non-exclusive, royalty-free, perpetual, irrevocable, transferable, sublicensable, and global right to use and exploit the Feedback in any manner and for any purpose without any restriction, credit, attribution, or fees due to you.

# Limitation of Liability
TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL LATTICE, ITS ASSOCIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES, OR THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, NOTHING IN THESE TERMS EXCLUDES OR LIMITS LIABILITY FOR FRAUD, FRAUDULENT MISREPRESENTATION, OR FOR DEATH OR PERSONAL INJURY CAUSED BY GROSS NEGLIGENCE.
THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

# Disclaimer of Warranties
We have put in a great deal of effort to provide the Site and Services to you. We hope you find them valuable, but there are certain things we can’t promise about them.

YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE, SERVICES, AND ANY CONTENT IS AT YOUR SOLE RISK. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER LATTICE NOR ITS ASSOCIATES MAKE ANY WARRANTY, EITHER EXPRESS OR IMPLIED, ABOUT THE SITE OR SITE CONTENT. THE SITE AND SITE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER LATTICE NOR ITS ASSOCIATES MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE OR SERVICES. WITHOUT LIMITING THE FOREGOING, LATTICE AND ITS ASSOCIATES DO NOT REPRESENT OR WARRANT THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE, SERVICES, OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

THERE MAY BE INADVERTENT TECHNICAL OR FACTUAL INACCURACIES AND TYPOGRAPHICAL ERRORS IN INFORMATION OR MATERIALS ON THE SITE OR SERVICES, AND LATTICE MAKES NO WARRANTIES REGARDING THE ACCURACY, COMPLETENESS OR TIMELINESS OF SUCH INFORMATION OR MATERIALS. LATTICE PROVIDES NO GUARANTEES AGAINST THE POSSIBILITY OF DELETION, MIS-DELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA. LATTICE EXPRESSLY DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION OR MATERIALS CONTAINED ON THE SITE OR SERVICES.

LATTICE MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE LIKELIHOOD OR PROBABILITY THAT YOUR PARTICIPATION ON THE SITE OR SERVICES WILL ACHIEVE A PARTICULAR OUTCOME OR GOAL. PAST PERFORMANCE IS NOT A GUARANTEE OF FUTURE SUCCESS, AND VOLATILITY MEANS THAT PERFORMANCE IN ANY PERIOD MAY BE FAR DIFFERENT FROM THAT OF PREVIOUS PERIODS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT LATTICE MAKES NO GUARANTEES OR OTHER COMMITMENTS ABOUT YOUR ABILITY TO ACCESS OR USE THE SITE OR SERVICES.

TO THE MAXIMUM EXTENT PERMITTED BY LAW, LATTICE AND ITS ASSOCIATES ALSO DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

SOME STATES OR JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO SOME DISCLAIMERS IN THIS SECTION MAY NOT APPLY TO YOU.

# Representations and Warranties
You represent and warrant to Lattice each time you use the Site or Services as follows:

- That, if you are an individual user, you are 18 years of age or older and that you have the capacity to contract under Applicable Law.
- That you are not a Prohibited Person or residing in a Prohibited Jurisdiction.
- That, if you are using the Site on behalf of a legal entity (i) such legal entity is duly organized and validly existing under Applicable Law of the jurisdiction of its organization; and (ii) you are duly authorized by such legal entity to act on its behalf.
- That you understand the risks associated with using the Site and the Services.
- That you are not otherwise prohibited by Applicable Law from using the Site and Services.
- That you have had the opportunity to seek legal, accounting, taxation and other professional advice regarding this Agreement, Site, and Services.
- That you will not use the Site or Services in order to conceal or disguise the origin or nature of proceeds of crime or terrorist financing, or to further, any breach of applicable AML or CTF Laws, or to deal in any unlawful Tokens, property, funds, or proceeds.
- That you will not transact on the Site or use Services with anything other than Tokens that have been legally obtained by you and that belong to you.
- That you will not falsify any Site or Services registration, or administration details provided to Lattice.
- That you are currently in compliance with, and must, at your own cost and expense, comply with Applicable Law that relate to or affect the Services conducted under this Agreement, including, but not limited to, AML, CTF, Anti-Corruption Laws, and Economic Sanctions Laws.
- That neither you nor any of your Affiliates has directly or indirectly offered, promised, given, or authorized any payment, or offered, promised, given, or authorized the giving of anything else of value, including, but not limited to, any Token, to a government official or individual employed by another entity in the private sector in violation of any Applicable Law.
- That you consent to any and all tax and information reporting under AML, CTF, Anti-Corruption, Economic Sanctions, or Applicable Law as Lattice may reasonably determine from time to time.
- That neither you nor any of your Affiliates will use any Tokens in connection with the Services directly or indirectly (i) on behalf of or for the benefit of a Prohibited Person or any person subject to the jurisdiction of a Prohibited Jurisdiction; (ii) in violation of or as prohibited, restricted, or penalized under applicable Economic Sanctions Laws; or (iii) in any way that would violate, be inconsistent with, penalized under, or cause the omission of filing of any report required under applicable AML, CTF, or Economic Sanctions Laws.
- That neither you nor any of your Affiliates is (i) itself or owned (beneficially or of record) or controlled by a Sanctioned Person; (ii) involved in any transaction, transfer, or conduct that is likely to result in you or your Affiliates becoming a Sanctioned Person; (iii) residing or domiciled in, or transferring Tokens, or property to, from, or through any Wallet or other account in; or (iv) engaging in any transaction on the Site or Services from a Prohibited Jurisdiction.
- That you will not falsify or materially omit any information or provide misleading or inaccurate information requested by Lattice in the course of, directly or indirectly relating to, or arising from your activities on the Site or use of the Services, including, but not limited to, at registration or during administration or other due diligence processes, and that if any information provided to Lattice becomes incorrect or outdated, you will promptly provide corrected information to Lattice.
- That you acknowledge and agree that any Wallet you use in connection with the Services, other than a Wallet provided on the Site by Lattice to enable interaction with the Services, is wholly controlled and owned by you and that Lattice does not have any right, title or interest to the Wallet, except as otherwise set forth herein.
- That you acknowledge and agree that any transactions undertaken through your login credentials, from your authorized e-mail address on file with Lattice, or from a Wallet in connection with the Services are deemed to be valid, binding, and conclusive.
- That you will accurately and promptly report all income associated with your activity on the Site and Services pursuant to Applicable Law and pay any and all taxes thereon.
- That (i) you own the User Content Posted by you or otherwise have the right to grant the license set forth in this Agreement; (ii) the posting of your User Content and the use of the same as contemplated in this Agreement does not and will not violate any right of any third-party; (iii) the Submission of your User Content will not require us to pay any amounts or provide any attribution to any third parties; and (iv) the Submission of your User Content does not result in a breach of contract between you and a third-party or infringe the rights of any other person or violate Applicable Law.
- That you will accurately and promptly inform Lattice if you know or have reason to know whether any of the foregoing representations or warranties no longer is correct or becomes incorrect.
- That you are fully aware of and accept the risks associated with buying, trading and using Tokens, including, but not limited to:
  - Market prices for Tokens can be volatile and highly unpredictable. Whether the future market price for a Token will increase, decrease, or even sustain market value is based on speculation.
  - Markets for Tokens can at times become what is known as “illiquid”, which means there may be a scarcity of individuals who are willing to trade at given time. Illiquid markets have potential for increased risk of loss because due to high volatility of prices and in such markets market participants may find it impossible to liquidate market positions, except at unfavorable prices. There are no guarantees that the markets for Tokens will be active and liquid or permit you to liquidate positions in the Tokens at desired or favorable prices.
  - The legality of holding Tokens, trading of them, or buying or selling Tokens may not be clear and may vary under Applicable Law of different jurisdictions. This means that the legality of holding or trading Tokens, or buying or selling Tokens, is not always clear. Whether and on what basis a Token may constitute property or an asset may vary between jurisdictions. You are responsible for knowing and understanding how laws and regulations apply to you with respect to the use of the Site and Services.
  - Tokens transferred into a Smart Contract may be commingled with the Tokens of other users of the Site. Transferring your Tokens into the Smart Contract exposes your Tokens to risks of total loss from, among others things, security breaches from cyberattacks that hack and steal Tokens, electronic or technological failures that impede or prevent market access and market performance, recordkeeping errors, and any insolvency, bankruptcy, or material financial losses of or incurred by Lattice or its Associates.

You acknowledge and agree that Lattice may freeze a Smart Contract in the event that you or any other user of the Services are believed to be engaged in suspicious activity or to be in breach of this Agreement.

You acknowledge and agree that Lattice operates and administers the Site, but Lattice is (i) not a counterparty to any purchase or sale of Tokens by and between you and any third-party, including, but not limited to, the purchase of Tokens and (ii) is not a counterparty to any Smart Contracts you enter into by and between you and any other user of the Services. Lattice has no financial responsibility or liability for any failure of market participants to honor their financial obligations. There is risk that one or more market participants will renege, default, or otherwise fail to honor their financial obligations or will be unwilling or unable to abide by the terms of their agreements. In the event that risk materializes, other market participants can and likely will incur financial losses. You acknowledge and agree that the holding of Tokens is inherently in risk of the potential for Tokens, and/or Wallets, to be lost, stolen, or hacked.

You acknowledge and agree that you have had the opportunity to consult legal counsel in connection with this Agreement even if you chose not to do so. You further acknowledge and agree that this Agreement will not be construed against you or Lattice as drafter.

# Indemnification
You agree to defend, indemnify and hold harmless Lattice, its Associates, licensors, and service providers, and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of this Agreement or your use of the Site, including, but not limited to any use of the Site's content, services and products other than as expressly authorized in this Agreement or your use of any information obtained from the Site.

# Term
The term of this Agreement commences on your first use of the Site or Services and continues until the termination of this Agreement by either you or Lattice.

# Termination and Suspension
These Terms are effective until terminated by us. Lattice, in its sole discretion, has the right to terminate your access to the Site or Services, or any part thereof, immediately at any time. We reserve the right, in our sole and absolute discretion, to restrict, suspend, or terminate your access to all or any part of the Site or Service, or to terminate this Agreement at any time without prior notice or liability if you breach any provision of this Agreement or violate the rights of any third-party on or through the Site. We reserve the right to change, suspend, or discontinue all or any part of the Site at any time without prior notice or liability.

Lattice is not liable to you or any third-party for termination of your access to the Site or Services, or any part thereof. If you object to any of the requirements, statements, or obligations of these Terms, or any subsequent modifications thereto, or become dissatisfied with the Site or Services in any way, your only recourse is to immediately discontinue use of the Site and/or Services. Upon termination of these Terms, you will cease all use of the Site and Services.

You may terminate this Agreement by sending written notification to us at support@lattice.exchange and terminating your use of the Site and Services.

# Relationship of the Parties
You agree that no joint venture, partnership, employment or agency relationship exists between you and Lattice as a result of this Agreement or use of the Site or Services.

# Governing Law and Venue
This Agreement and your access to and use of the Site will be governed by the laws of the State of California, without regard to conflict of law rules or principles that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts of the State of California and the United States, respectively, sitting in San Francisco, California.

# Dispute Resolution
Except as otherwise set forth in this Agreement, any controversy or claim arising out of or relating to this Agreement or the interpretation, breach, termination or validity hereof will be resolved through arbitration in accordance with the American Arbitration Association. The language to be used in the arbitral proceedings will be English and arbitration will take place in San Francisco, California.

# Third-Party Disputes
ANY DISPUTE YOU HAVE WITH ANY THIRD-PARTY, INCLUDING, BUT NOT LIMITED TO, OTHER USERS, IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY SMART CONTRACT OR ANY DISPUTE WITH AN ISSUER OF TOKENS OR DISPUTE RELATED TO THE PURCHASE OF TOKENS IS DIRECTLY BETWEEN YOU AND SUCH THIRD-PARTY. ACCORDINGLY, TO THE FULLEST EXTENT PERMITTED BY LAW, YOU IRREVOCABLY RELEASE LATTICE AND ITS ASSOCIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (DIRECT, INDIRECT, AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.

# Changes to this Agreement
We reserve the right to change this Agreement at any time upon notice. We may give notice by posting the updated Agreement on the Site or by any other reasonable means. You can review the most current version of this Agreement at any time. This Agreement in effect at the time of your use of the Site apply. Updated versions of the Agreement are binding on you with respect to your use of the Site on or after the date indicated in the updated Agreement. If you do not agree to the updated Agreement, you must stop using the Site. Your continued use of the Site after the date of the updated Agreement will constitute your acceptance of the updated Agreement.

# Entire Agreement
This Agreement and the Privacy Policy constitute the sole and entire agreement between you and Lattice with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site. This Agreement does not, and will not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and Lattice.

# Waiver and Severability
No waiver of by Lattice of any term or condition set out in these Terms will be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Lattice to assert a right or provision under these Terms will not constitute a waiver of such right or provision.

If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.

# Assignment
This Agreement will inure to the benefit of our successors and assigns. You may not assign this Agreement or any of the rights or licenses granted hereunder without the prior express written consent of Lattice. Lattice may assign this Agreement, including all its rights hereunder, without restriction.

# Survival
The respective indemnities, representations, warranties and agreements of the parties hereto or made by or on behalf of the parties hereto pursuant to this Agreement will survive the termination of this Agreement indefinitely and will remain in full force and effect and all defined terms used therein will survive the termination of this Agreement indefinitely.

# Transfers
Lattice reserves the right to process and effectuate any transfer from the Smart Contract. In the course of processing and transferring Tokens out of a Smart Contract, Lattice may be required to share your user information with other contractual parties, including, but not limited to, financial institutions, third-parties that provide anti-money laundering services, as required under Applicable Law, or demanded upon a lawful request by any government. You irrevocably grant full permission and authority for Lattice and its Associates to share this information with such third-parties, as required under Applicable Law, or demanded upon a lawful request by any government. You agree to release Lattice from any liability, error, mistake, or negligence related thereto.

# Contact Us
If you would like to contact us for any reason, please reach us at support@lattice.exchange.
`

export { text }
