import React from 'react'

import styles from './component.module.scss'

const Banner = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.text}>
        <p>{children}</p>
      </div>
    </div>
  )
}

export { Banner }
