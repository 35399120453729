// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.fdQXaaWH60vOZ6z3jDe1{color:#e64434;font-size:12px;font-weight:300;line-height:18px;word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/StakingProgramDetailView/components/AlkimiStakingActions/component.module.scss"],"names":[],"mappings":"AAIA,0BACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\nspan.error {\n  color: #e64434;\n  font-size: 12px;\n  font-weight: 300;\n  line-height: 18px;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `fdQXaaWH60vOZ6z3jDe1`
};
export default ___CSS_LOADER_EXPORT___;
