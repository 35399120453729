import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'

import { isFetchStatus } from '@lattice/common/consts'
import {
  cachedValueOrNull,
  deleteCachedValue,
  setCachedValue,
} from '@lattice/utils'
import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'

import { useToastProvider } from '../ToastProvider'

import {
  IUserOAuthProviderStatuses,
  IUserOAuthProviderContext,
  IUserOAuthCachedSession,
} from './types'
import {
  generateRandomState,
  registerUserOAuthToken,
  requestUserOAuthStatusForProvider,
  requestUserOAuthUrlForProvider,
  unregisterUserOAuthToken,
} from './utils'

const UserOAuthProviderContext =
  React.createContext<IUserOAuthProviderContext | null>(null)

const UserOAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { addToast } = useToastProvider()
  const [searchParams, setSearchParams] = useSearchParams()

  const operations = useFetchableOperations([
    'checkAndFinishOAuthFlow',
    'doRequestOAuthFlowForProvider',
    'registerUserOAuthToken',
    'unregisterUserOAuthToken',
  ])

  const userOAuthProviderStatuses =
    useFetchableResource<IUserOAuthProviderStatuses>({})

  const checkAndFinishOAuthFlow =
    operations.checkAndFinishOAuthFlow.wrappedFetch(async () => {
      const providerData = cachedValueOrNull<IUserOAuthCachedSession>(
        'lattice-oauth:pending-token',
        'session'
      )

      if (!providerData) {
        return
      }

      const { provider, state, urlData } = providerData

      const token = searchParams.get('token') ?? searchParams.get('code')
      if (token === null || token === '') {
        return
      }

      if (urlData.options.stateParamCheck) {
        const sentState = searchParams.get('state')
        if (sentState === null || sentState === '') {
          console.log('state check failed, skipping token => no state')
          return
        }

        if (state !== sentState) {
          console.log('state check failed, skipping token => no match')
          return
        }
      }

      searchParams.delete('token')
      searchParams.delete('code')
      searchParams.delete('state')

      setSearchParams(searchParams)
      deleteCachedValue('lattice-oauth:pending-token', 'session')

      await providerContext.registerUserOAuthToken(
        provider,
        token,
        urlData.params
      )

      await providerContext.requestUserOAuthStatusForProvider(provider)

      addToast('Successfully connected account', 'success', 15000)
    })

  const providerContext: IUserOAuthProviderContext = {
    operations,
    userOAuthProviderStatuses,
    doRequestOAuthFlowForProvider:
      operations.doRequestOAuthFlowForProvider.wrappedFetch(
        async (provider) => {
          const urlData = await requestUserOAuthUrlForProvider(provider)

          if (isFetchStatus(urlData)) {
            throw new Error(
              `Unable to request auth url for provider => ${provider}`
            )
          }

          const statuses =
            await providerContext.requestUserOAuthStatusForProvider(provider)

          if (isFetchStatus(statuses)) {
            throw new Error('Invalid OAuth Status Response')
          }

          const status = statuses[provider]

          if (!status) {
            throw new Error('Unable to find OAuth status for provider')
          }

          if (status.status === 'registered') {
            return
          }

          const state = generateRandomState()

          const params = new URLSearchParams()
          params.set('state', state)

          for (const [key, value] of Object.entries(urlData.params)) {
            params.set(key, value)
          }

          const authUrl = urlData.url + '?' + params

          location.href = authUrl

          setCachedValue(
            'lattice-oauth:pending-token',
            'session',
            {
              provider,
              state,
              urlData,
            } satisfies IUserOAuthCachedSession,
            dayjs().add(1, 'hour').valueOf()
          )
        }
      ),
    requestUserOAuthStatusForProvider: userOAuthProviderStatuses.wrappedFetch(
      async (provider) => {
        const newStatuses = await requestUserOAuthStatusForProvider(provider)

        if (isFetchStatus(newStatuses)) {
          return newStatuses
        }

        return { ...userOAuthProviderStatuses.resource, ...newStatuses }
      }
    ),
    registerUserOAuthToken: operations.registerUserOAuthToken.wrappedFetch(
      registerUserOAuthToken
    ),
    unregisterUserOAuthToken: operations.unregisterUserOAuthToken.wrappedFetch(
      unregisterUserOAuthToken
    ),
  }

  useEffect(() => {
    checkAndFinishOAuthFlow()
  }, [])

  return (
    <UserOAuthProviderContext.Provider value={providerContext}>
      {children}
    </UserOAuthProviderContext.Provider>
  )
}

export { UserOAuthProvider, UserOAuthProviderContext }
