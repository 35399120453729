import { createProviderStateHook } from '@lattice/utils'

import { AlkimiStakingProvider, AlkimiStakingProviderContext } from './provider'

const useAlkimiStakingProvider = createProviderStateHook(
  AlkimiStakingProvider,
  AlkimiStakingProviderContext
)

export { useAlkimiStakingProvider }
