// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qGMewUcusbNw3IUddMS3{display:flex;flex-direction:column;align-items:center;width:100%;gap:14px;padding:30px}.TkwIIBJGo6A6ISdHiMfh{display:flex;flex-direction:column;gap:20px;width:100%}.gB5Gx1ZkeZky1jiuYfBQ{display:flex;align-items:center;gap:16px;font-family:"TWK Lausanne";font-style:normal;font-weight:200;font-size:12px;letter-spacing:.03em;color:rgba(255,255,255,.66)}.gB5Gx1ZkeZky1jiuYfBQ>span{font-weight:300;font-size:12px;line-height:18px;display:flex;align-items:center;letter-spacing:.03em;color:rgba(255,255,255,.66)}.gB5Gx1ZkeZky1jiuYfBQ>div>a{color:#fc0}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/HgtpMetagraphOperatorProgramDetailView/components/NodeOperatorConfirmationFormCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,oBAAA,CAEA,2BAAA,CAEA,2BACE,eAAA,CACA,cAAA,CACA,gBAAA,CAGA,YAAA,CACA,kBAAA,CACA,oBAAA,CAEA,2BAAA,CAIA,4BACE,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 14px;\n  padding: 30px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n}\n\n.checkboxRow {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n\n    font-family: 'TWK Lausanne';\n    font-style: normal;\n    font-weight: 200;\n    font-size: 12px;\n\n    letter-spacing: 0.03em;\n\n    color: rgba(255, 255, 255, 0.66);\n\n    > span {\n      font-weight: 300;\n      font-size: 12px;\n      line-height: 18px;\n      /* identical to box height, or 150% */\n\n      display: flex;\n      align-items: center;\n      letter-spacing: 0.03em;\n\n      color: rgba(255, 255, 255, 0.66);\n    }\n\n    > div {\n      > a {\n        color: #ffcc00;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qGMewUcusbNw3IUddMS3`,
	"form": `TkwIIBJGo6A6ISdHiMfh`,
	"checkboxRow": `gB5Gx1ZkeZky1jiuYfBQ`
};
export default ___CSS_LOADER_EXPORT___;
