// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KE0rthuO8TeXVgR63gcY{background-color:rgba(0,0,0,0)}.G1ZwCzZbjt_8khxeXtEi{display:flex;flex-direction:column}.PbYdkm7h4Pi72ZjkglZ1{font-family:"IBM Plex Mono";font-style:normal;font-weight:400;font-size:36px;line-height:47px;text-align:center;color:#fc0}.xXBlaQ3Xe3OuVE_HKpoF{font-family:"TWK Lausanne";font-style:normal;font-weight:300;font-size:12px;line-height:18px;text-align:center;letter-spacing:.03em;color:#8d93a4}`, "",{"version":3,"sources":["webpack://./src/common/components/DetailsDateCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,2BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAEA,UAAA,CAGF,sBACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,oBAAA,CACA,aAAA","sourcesContent":[".baseCardRoot {\n  background-color: transparent;\n}\n\n.infoDataGroup {\n  display: flex;\n  flex-direction: column;\n}\n\n.numberTimeLabel {\n  font-family: 'IBM Plex Mono';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 36px;\n  line-height: 47px;\n  text-align: center;\n\n  color: #ffcc00;\n}\n\n.timeLabel {\n  font-family: 'TWK Lausanne';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 18px;\n  text-align: center;\n  letter-spacing: 0.03em;\n  color: #8d93a4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseCardRoot": `KE0rthuO8TeXVgR63gcY`,
	"infoDataGroup": `G1ZwCzZbjt_8khxeXtEi`,
	"numberTimeLabel": `PbYdkm7h4Pi72ZjkglZ1`,
	"timeLabel": `xXBlaQ3Xe3OuVE_HKpoF`
};
export default ___CSS_LOADER_EXPORT___;
