// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R2jODrr_k2pYJEfeB1AA{font-weight:200;font-size:12px;line-height:18px;letter-spacing:.03em;color:rgba(255,255,255,.66)}.vKSMC4NGjHifnRDkegip{font-weight:200;font-size:12px;line-height:18px;letter-spacing:.03em;color:#fc0}span.d6KqgLE2tQ7KYDkCxYv2{color:#e64434;font-size:12px;font-weight:300;line-height:18px;word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/StakingProgramDetailView/view.module.scss"],"names":[],"mappings":"AAIA,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,2BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAEA,UAAA,CAGF,0BACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.leftSideFont {\n  font-weight: 200;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: rgba(#fff, 0.66);\n}\n\n.rightSideFont {\n  font-weight: 200;\n  font-size: 12px;\n  line-height: 18px;\n  letter-spacing: 0.03em;\n\n  color: #ffcc00;\n}\n\nspan.error {\n  color: #e64434;\n  font-size: 12px;\n  font-weight: 300;\n  line-height: 18px;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftSideFont": `R2jODrr_k2pYJEfeB1AA`,
	"rightSideFont": `vKSMC4NGjHifnRDkegip`,
	"error": `d6KqgLE2tQ7KYDkCxYv2`
};
export default ___CSS_LOADER_EXPORT___;
