import { createProviderStateHook } from '@lattice/utils'

import { UserProvider, UserProviderContext } from './provider'

const useUserProvider = createProviderStateHook(
  UserProvider,
  UserProviderContext
)

export { useUserProvider }
