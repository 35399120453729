import React from 'react'
import cls from 'classnames'

import styles from './component.module.scss'

const BlockStat = ({
  label,
  value,
  variants,
  className,
}: {
  label: string
  value: React.ReactNode
  variants?: 'align-start'[]
  className?: { labelFont?: string }
}) => {
  return (
    <div
      className={cls(
        styles.stats,
        variants?.includes('align-start') && styles.startAligned
      )}
    >
      <span>{value}</span>
      <span className={cls(styles.labelFont, className && className.labelFont)}>
        {label}
      </span>
    </div>
  )
}

export { BlockStat }
