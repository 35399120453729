import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { BaseCard, Button } from '@lattice/common/components'
import { useToastProvider, useUserProvider } from '@lattice/common/providers'
import { useSignInRedirect } from '@lattice/common/hooks'

import styles from './view.module.scss'

const VerifyEmailCodeView = () => {
  const navigate = useNavigate()
  const [urlParams] = useSearchParams()
  const { t } = useTranslation()
  const { addToast } = useToastProvider()
  const signInUrl = useSignInRedirect()
  const { user, userAttributes, confirmSignUp } = useUserProvider()

  const doSendEmailVerificationCode = () => {
    if (user) {
      user.getAttributeVerificationCode('email', {
        onSuccess: () => {
          addToast(
            t('views.User.views.ConfirmEmail.messageSent', 'Message sent'),
            'success'
          )
        },
        onFailure: (err) => {
          console.error(err)
          addToast(
            t(
              'views.User.views.ConfirmEmail.unableToSend',
              'Unable to send confirmation link: ' + err
            ),
            'error',
            30000
          )
        },
      })
    }
  }

  useEffect(() => {
    if (userAttributes.resource) {
      const emailVerified = userAttributes.resource.find(
        (attr) => attr.Name === 'email_verified'
      )

      if (emailVerified && emailVerified.Value === 'true') {
        navigate('/dashboard')
      }
    }
  }, [userAttributes])

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
      return
    }

    const confirm = async (code: string | null) => {
      try {
        await confirmSignUp(code)
        navigate('/dashboard')
      } catch (e: any) {
        navigate('/dashboard')
      }
    }
    const code = urlParams.get('code')
    confirm(code)
  }, [urlParams])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t(
        'views.User.views.ConfirmEmail.title.checkYourEmail',
        'Check your email'
      )}
    >
      <div className={styles.createdMessage}>
        {t(
          'views.User.views.ConfirmEmail.pleaseFollowThe',
          'Please follow the verification link in your email to complete your registration.'
        )}
        <Button
          variants={['primary', 'full-width']}
          onClick={doSendEmailVerificationCode}
        >
          {t(
            'views.User.views.ConfirmEmail.resendConfirmationLink',
            'Resend confirmation link'
          )}
        </Button>
      </div>
    </BaseCard>
  )
}

export { VerifyEmailCodeView }
