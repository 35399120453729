import React, { useEffect, useState } from 'react'

import { DebugDataModal } from '@lattice/common/components'

import { useUserProvider } from '../UserProvider'
import { useWalletProvider } from '../WalletProvider'

import { IDebugDataProviderContext } from './types'
import { LAST_RECORDED_DATA_STORAGE_KEY } from './consts'

const DebugDataProviderContext =
  React.createContext<IDebugDataProviderContext | null>(null)

const DebugDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { userDbData } = useUserProvider()
  const { activeWallet } = useWalletProvider()

  const [debugModalOpen, setDebugModalOpen] = useState(false)

  const providerContext: IDebugDataProviderContext = {
    getEncodedDebugData: () => {
      return btoa(
        JSON.stringify(
          {
            userId: userDbData.resource?.id,
            activeWallet:
              activeWallet.status === 'connected'
                ? {
                    constellation: activeWallet.constellation
                      ? activeWallet.constellation.account
                      : null,
                    ethereum: activeWallet.ethereum
                      ? activeWallet.ethereum.account
                      : null,
                  }
                : null,
            userAgent: window.navigator.userAgent,
            path: window.location.href,
          },
          null,
          2
        )
      )
    },
  }

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'D') {
        setDebugModalOpen((s) => !s)
      }
    }

    window.addEventListener('keyup', listener)

    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [userDbData, activeWallet])

  useEffect(() => {
    window.localStorage.setItem(
      LAST_RECORDED_DATA_STORAGE_KEY,
      providerContext.getEncodedDebugData()
    )
  }, [userDbData, activeWallet])

  return (
    <DebugDataProviderContext.Provider value={providerContext}>
      {children}
      {debugModalOpen && (
        <DebugDataModal onClose={() => setDebugModalOpen(false)} />
      )}
    </DebugDataProviderContext.Provider>
  )
}

export { DebugDataProvider, DebugDataProviderContext }
