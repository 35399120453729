// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._kScI1G_Zk79OoQ3EiyQ{display:flex;flex-flow:row nowrap;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/views/SwapBuyView/views/BuyGeneralView/view.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CAEA,oBAAA,CACA,eAAA","sourcesContent":["@use 'common/styles/breakpoints' as *;\n@use 'common/styles/colors' as *;\n@use 'common/styles/mixins' as *;\n\n.sliderDots {\n  display: flex;\n\n  flex-flow: row nowrap;\n  column-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderDots": `_kScI1G_Zk79OoQ3EiyQ`
};
export default ___CSS_LOADER_EXPORT___;
