import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { BaseCard, Button, InputRow } from '@lattice/common/components'
import { emailValidator, yupSchemaToFormValidate } from '@lattice/utils'
import { useLocalizedValues } from '@lattice/common/hooks'
import { useUserProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

type IResetPasswordFormData = {
  username: string
}

const ResetPasswordView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, doUserPasswordReset } = useUserProvider()
  const [requestSent, setRequestSent] = useState(false)

  const tfn = useLocalizedValues({ emailValidator })

  const userPasswordResetForm = useForm<IResetPasswordFormData>({
    mode: 'onTouched',
  })

  const doResetPassword = userPasswordResetForm.handleSubmit(async (data) => {
    await doUserPasswordReset(data.username)
    setRequestSent(true)
  })

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t(
        'views.User.views.ResetPassword.title.resetPassword',
        'Reset password'
      )}
    >
      {!requestSent ? (
        <form onSubmit={doResetPassword}>
          <div className={styles.formSection}>
            <InputRow
              variants={['full-width']}
              label={t(
                'views.User.views.ResetPassword.emailAddress',
                'Enter your email address'
              )}
              error={userPasswordResetForm.formState.errors.username?.message}
              {...userPasswordResetForm.register('username', {
                required: true,
                validate: yupSchemaToFormValidate(tfn.emailValidator),
              })}
            />
          </div>
          <div className={styles.actionSection}>
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={!userPasswordResetForm.formState.isValid}
            >
              {t('views.User.views.ResetPassword.button.submit', 'Submit')}
            </Button>
          </div>
        </form>
      ) : (
        <div className={styles.message}>
          {t(
            'views.User.views.ResetPassword.instructionsWere',
            'Instructions to reset your account password have been sent to the provided email address..'
          )}
          <Button.Link
            variants={['outlined', 'right-icon', 'full-width']}
            to="../signin"
          >
            {t('views.User.views.ResetPassword.backto', 'Back to Sign In')}
          </Button.Link>
        </div>
      )}
    </BaseCard>
  )
}

export { ResetPasswordView }
