import React, { useState } from 'react'

import { BaseModal, ToastCard } from '@lattice/common/components'

import { IToastProviderContext, IContextToast } from './types'

const ToastProviderContext = React.createContext<IToastProviderContext | null>(
  null
)

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<IContextToast[]>([])

  const context: IToastProviderContext = {
    toasts,
    addToast: (children, type = 'info', timeout = 5000, data = null) => {
      const toast: IContextToast = {
        id: window.btoa(String(Math.random())),
        type,
        children,
        timeout,
        data,
        remove: () => {
          setToasts((toasts) => toasts.filter((ctoast) => ctoast !== toast))
        },
      }

      if (toast.timeout !== null) {
        window.setTimeout(toast.remove, toast.timeout)
      }

      setToasts((toasts) => [...toasts, toast])

      return toast
    },
    removeAll: () => {
      setToasts([])
    },
  }

  return (
    <ToastProviderContext.Provider value={context}>
      {toasts.length > 0 && (
        <BaseModal
          overlay={false}
          style={{
            main: {
              position: 'fixed',
              top: '96px',
              right: '32px',
              display: 'flex',
              flexFlow: 'column nowrap',
              rowGap: '16px',
              zIndex: 300,
            },
          }}
        >
          {toasts.map((toast) => (
            <ToastCard
              key={toast.id}
              type={toast.type}
              onCloseClicked={toast.remove}
            >
              {toast.children}
            </ToastCard>
          ))}
        </BaseModal>
      )}
      {children}
    </ToastProviderContext.Provider>
  )
}

export { ToastProvider, ToastProviderContext }
