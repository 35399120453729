import Decimal from 'decimal.js'

import { BadgeColors } from '@lattice/common/components'
import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/utils'

import { IWalletOwnershipToken } from '../WalletProvider'

import {
  ILaunchpadProject,
  ILaunchpadProjectUser,
  ILaunchpadUserAllocation,
} from './types'

const requestProjects = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/launchpad-projects',
    })
    return data as ILaunchpadProject[]
  } catch (err) {
    throw err
  }
}

const requestProject = async (projectSlug: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/launchpad-projects/${projectSlug}`,
    })
    return data as ILaunchpadProject
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestProjectUser = async (projectSlug: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/launchpad-projects/${projectSlug}/user`,
      isAuthenticated: true,
    })
    return data as ILaunchpadProjectUser
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const requestUserAllocation = async (address: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/launchpad-projects/user-allocation?address=${address}`,
      isAuthenticated: true,
    })
    return data as ILaunchpadUserAllocation
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const stakeInProjectPool = async (
  projectSlug: string,
  poolId: string,
  address: string,
  amount: Decimal,
  walletOwnershipToken: IWalletOwnershipToken
) => {
  try {
    const { data } = await apiRequest({
      method: 'POST',
      endpoint: `/launchpad-projects/${projectSlug}/pool/${poolId}`,
      isAuthenticated: true,
      body: {
        address,
        amount,
        walletOwnershipToken,
      },
    })
    return data
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const getStatusBadgeData = (
  status: ILaunchpadProject['status'] | 'unlisted'
) => {
  if (status === 'coming_soon') {
    return { text: 'SOON', color: BadgeColors.yellow }
  }
  if (status === 'live') {
    return { text: 'LIVE', color: BadgeColors.green }
  }
  if (status === 'allocating') {
    return { text: 'ALLOCATING', color: BadgeColors.purple }
  }
  if (status === 'funding') {
    return { text: 'FUNDING', color: BadgeColors.teal }
  }
  if (status === 'distributing') {
    return { text: 'DISTRIBUTING', color: BadgeColors.blue }
  }
  if (status === 'complete') {
    return { text: 'COMPLETE', color: BadgeColors.orange }
  }

  return { text: 'UNKNOWN', color: BadgeColors.yellow }
}

export {
  requestProjects,
  requestProject,
  requestProjectUser,
  stakeInProjectPool,
  requestUserAllocation,
  getStatusBadgeData,
}
