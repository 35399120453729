import React from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'

import { BaseCard, Button, Chip, InputRow } from '@lattice/common/components'
import {
  IContextToast,
  useToastProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import {
  getTypedContract,
  requestProviderChainUpdateIfNeeded,
} from '@lattice/utils'
import { useFetchableOperation } from '@lattice/common/hooks'
import {
  AvailableNetwork,
  ChainInfo,
  DeploymentStageChains,
  DeploymentStageMasterChain,
  ExecutionContext,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/consts'

import styles from './view.module.scss'

const SECONDS_IN_DAY = 60 * 60 * 24

const LOCKUP_POINTS = [
  [(364 / 2) * SECONDS_IN_DAY, 0.1, '6 months'],
  [365 * SECONDS_IN_DAY, 0.25, '1 year'],
  [365 * 2 * SECONDS_IN_DAY, 0.65, '2 years'],
  [365 * 3 * SECONDS_IN_DAY, 1, '3 years'],
] as const

type ILockTokensFormData = {
  stage: 'approve' | 'confirm' | 'complete'
  lockamount: number
  locktime: number
}

const LockView = () => {
  const { t } = useTranslation()
  const { addToast } = useToastProvider()
  const lockOperation = useFetchableOperation()
  const {
    requestConnectorActivation,
    fetchAccountsBalance,
    activeWallet,
    accountsNetworkAssets,
  } = useWalletProvider()

  const userLockTokensForm = useForm<ILockTokensFormData>({
    mode: 'onTouched',
    defaultValues: {
      stage: 'approve',
      lockamount: 0,
      locktime: LOCKUP_POINTS[0][0],
    },
  })
  const values = userLockTokensForm.watch()

  const doLockTokens = userLockTokensForm.handleSubmit(
    lockOperation.wrappedFetch(async (data) => {
      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        addToast('Wallet is not connected', 'error', 30000)
        return
      }

      await requestProviderChainUpdateIfNeeded(
        activeWallet.ethereum.library,
        ChainInfo[
          DeploymentStageChains[ExecutionContext.stage][
            AvailableNetwork.ETHEREUM
          ]
        ].rpcChainId
      )

      let progressToast: IContextToast | null = null
      const resetProgressToast = () => {
        if (progressToast) {
          progressToast.remove()
          progressToast = null
        }
      }
      const setProgressToast: typeof addToast = (...args) => {
        resetProgressToast()
        progressToast = addToast(...args)
        return progressToast
      }

      if (data.stage === 'approve') {
        const ltxToken = getTypedContract(
          'ERC20',
          RegisteredTokens[RegisteredToken.LTX].instances[
            DeploymentStageMasterChain
          ].address
        ).connect(activeWallet.ethereum.library.getSigner())

        const currentAllowanceRaw = await ltxToken.allowance(
          activeWallet.ethereum.account,
          RegisteredTokens[RegisteredToken.veLTX].instances[
            DeploymentStageMasterChain
          ].address
        )

        const currentAllowance = new Decimal(
          currentAllowanceRaw.toString()
        ).div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))

        if (currentAllowance.greaterThanOrEqualTo(data.lockamount)) {
          addToast(
            t(
              'views.Governance.views.Lock.approveSuccessAlready',
              'Approved successfully already'
            ),
            'success',
            10000
          )

          userLockTokensForm.setValue('stage', 'confirm')
          resetProgressToast()
          return
        }

        setProgressToast(
          t(
            'views.Governance.views.Lock.requestingTransaction',
            'Requesting transaction approval'
          ),
          'info',
          null
        )

        const trx = await ltxToken.approve(
          RegisteredTokens[RegisteredToken.veLTX].instances[
            DeploymentStageMasterChain
          ].address,
          new Decimal(data.lockamount)
            .mul(
              Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals)
            )
            .trunc()
            .toString()
        )

        setProgressToast(
          t(
            'views.Governance.views.Lock.waitingNetwork',
            'Awaiting network confirmation'
          ),
          'info',
          null
        )

        await trx.wait(3)

        addToast(
          t(
            'views.Governance.views.Lock.approveSuccess',
            'Approved successfully'
          ),
          'success',
          10000
        )

        userLockTokensForm.setValue('stage', 'confirm')
        resetProgressToast()
        return
      }

      if (data.stage === 'confirm') {
        const veltxToken = getTypedContract(
          'LatticeGovernanceToken',
          RegisteredTokens[RegisteredToken.veLTX].instances[
            DeploymentStageMasterChain
          ].address
        ).connect(activeWallet.ethereum.library.getSigner())

        setProgressToast(
          t(
            'views.Governance.views.Lock.requestingTransaction',
            'Requesting transaction approval'
          ),
          'info',
          null
        )

        const trx = await veltxToken.lock(
          new Decimal(data.lockamount)
            .mul(
              Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals)
            )
            .trunc()
            .toString(),
          data.locktime
        )

        setProgressToast(
          t(
            'views.Governance.views.Lock.waitingNetwork',
            'Awaiting network confirmation'
          ),
          'info',
          null
        )

        await trx.wait(3)

        addToast(
          t(
            'views.Governance.views.Lock.approveSuccess',
            'Locked successfully'
          ),
          'success',
          10000
        )

        userLockTokensForm.setValue('stage', 'complete')
        fetchAccountsBalance()
        resetProgressToast()

        setTimeout(() => {
          userLockTokensForm.reset()
        }, 5000)

        return
      }

      throw new Error('Unknown flow stage')
    })
  )

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={t('views.Governance.views.Lock.title.lock', 'Lock')}
    >
      <form onSubmit={doLockTokens}>
        <div className={styles.formSection}>
          <Controller
            name="lockamount"
            rules={{
              required: true,
              validate: (value) =>
                value > 0 ||
                String(
                  t(
                    'views.Governance.views.Lock.amountMustBe',
                    'Amount must be greater than 0'
                  )
                ),
            }}
            control={userLockTokensForm.control}
            render={({ field: { ref: __, ...rest }, fieldState }) => (
              <InputRow.Numeric
                variants={['full-width']}
                label={t(
                  'views.Governance.views.Lock.lockAmount',
                  'Lock amount'
                )}
                icon={
                  <span
                    className={styles.max}
                    onClick={() =>
                      userLockTokensForm.setValue(
                        'lockamount',
                        accountsNetworkAssets[
                          'ethereum:ltx'
                        ]?.balance.toNumber() ?? 0
                      )
                    }
                  >
                    {t('views.Governance.views.Lock.max', 'Max')}
                  </span>
                }
                error={fieldState.error?.message}
                allowNegative={false}
                suffix={' LTX'}
                {...rest}
              />
            )}
          />
          <InputRow
            variants={['full-width']}
            label={t('views.Governance.views.Lock.lockUntil', 'Lock until')}
            value={dayjs().add(values.locktime, 'seconds').format('DD/MM/YYYY')}
            readOnly
          />
          <div className={styles.chipContainer}>
            {LOCKUP_POINTS.map((lockupPoint, index) => (
              <Chip
                key={index}
                size="sm"
                active={lockupPoint[0] === values.locktime}
                onClick={() =>
                  userLockTokensForm.setValue('locktime', lockupPoint[0])
                }
              >
                {lockupPoint[2]}
              </Chip>
            ))}
          </div>
          <InputRow.Numeric
            variants={['full-width']}
            label={t(
              'views.Governance.views.Lock.totalVotingEscrow',
              'Total voting escrow'
            )}
            value={
              values.lockamount *
              (LOCKUP_POINTS.find(
                (lockupPoint) => lockupPoint[0] === values.locktime
              )?.[1] ?? 0)
            }
            decimalScale={2}
            suffix={' veLTX'}
            readOnly
          />
          {lockOperation.error && (
            <span className={styles.error}>{String(lockOperation.error)}</span>
          )}
        </div>
        <div className={styles.actionSection}>
          {(activeWallet.status === 'disconnected' ||
            (activeWallet.status === 'connected' &&
              !activeWallet.ethereum)) && (
            <Button
              variants={['primary', 'full-width']}
              onClick={() => requestConnectorActivation()}
              type="button"
            >
              {t(
                'views.Governance.views.Lock.button.connectWallet',
                'Connect Wallet'
              )}
            </Button>
          )}
          {activeWallet.status === 'connecting' && (
            <ImpulseSpinner frontColor={'#fc0'} />
          )}
          {activeWallet.status === 'connected' && activeWallet.ethereum && (
            <div className={styles.approveConfirmSection}>
              <div className={styles.stepsContainer}>
                <span
                  className={cls(values.stage === 'confirm' && styles.success)}
                >
                  1
                </span>
                <div></div>
                <span
                  className={cls(values.stage === 'complete' && styles.success)}
                >
                  2
                </span>
              </div>
              <Button
                variants={['primary', 'full-width']}
                type="submit"
                disabled={values.stage !== 'approve'}
              >
                {t('views.Governance.views.Lock.button.approve', 'Approve')}
              </Button>
              <Button
                variants={['primary', 'full-width']}
                type="submit"
                disabled={values.stage !== 'confirm'}
              >
                {t('views.Governance.views.Lock.button.confirm', 'Confirm')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </BaseCard>
  )
}

export { LockView }
