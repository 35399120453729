import React, { useEffect } from 'react'

import NodeOperatorBanner from '@lattice/assets/images/pngs/integrations_net/node-operator-banner.png'
import {
  Button,
  Content,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  MainProjectCard,
  ProgramDetailProjectContainer,
  Typography,
  DetailsInfoCard,
  Tabs,
} from '@lattice/common/components'
import { useUserProvider } from '@lattice/common/providers'
import { useSignInRedirect } from '@lattice/common/hooks'
import {
  IntegrationsNetApplicantStatus,
  useIntegrationsNetProvider,
} from '@lattice/common/providers/IntegrationsNetProvider'

import { Overview, Requirements } from './content'
import {
  NodeOperatorOptInFormCard,
  NodeOperatorInfoCard,
  NodeOperatorTicketsCard,
  NodeOperatorRegistrationFormCard,
  NodeOperatorQueuePositionCard,
  NodeOperatorOptInConfirmationFormCard,
  NodeOperatorNextStepsCard,
} from './components'
import styles from './view.module.scss'

const NodeOperatorProgramDetailView = () => {
  const { user } = useUserProvider()
  const signInUrl = useSignInRedirect()

  const { applicant, requestApplicant } = useIntegrationsNetProvider()

  useEffect(() => {
    requestApplicant()
  }, [])

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={'IntegrationNet Node Operator Program'}
        parentNameTitle={'Rewards Programs'}
      />
      <ProgramDetailProjectContainer>
        <DetailsMainCardContainer>
          <MainProjectCard
            title={'IntegrationNet Node Operator Program'}
            bannerUrl={NodeOperatorBanner}
          >
            <Tabs>
              <Tabs.Tab
                label={'Overview'}
                content={
                  <Typography.MarkdownContent
                    className={styles.overviewSection}
                  >
                    {Overview}
                  </Typography.MarkdownContent>
                }
              />
              <Tabs.Tab
                label={'Requirements'}
                content={
                  <Typography.MarkdownContent>
                    {Requirements}
                  </Typography.MarkdownContent>
                }
              />
            </Tabs>
          </MainProjectCard>
        </DetailsMainCardContainer>
        <DetailsSideCardsContainer>
          {!user && (
            <DetailsInfoCard
              label={'Please sign in to your account'}
              actionElement={
                <Button.Link
                  variants={['full-width', 'secondary', 'right-icon']}
                  to={signInUrl}
                >
                  Sign in
                </Button.Link>
              }
              variants={['full-width']}
            />
          )}
          {user && !applicant.resource && <NodeOperatorOptInFormCard />}
          {user &&
            applicant.resource &&
            applicant.resource.status ===
              IntegrationsNetApplicantStatus.REGISTERED &&
            applicant.resource.ticketsRange && (
              <NodeOperatorInfoCard
                title="Thank you for opting in!"
                primaryContent="Results: Jun 28, 2023"
                secondaryContent="Return to this page for lottery results and next steps to participate."
              />
            )}

          {user &&
            applicant.resource &&
            applicant.resource.status ===
              IntegrationsNetApplicantStatus.IN_QUEUE && (
              <NodeOperatorQueuePositionCard />
            )}

          {user &&
            applicant.resource &&
            applicant.resource.status ===
              IntegrationsNetApplicantStatus.PENDING_REQUIREMENT && (
              <NodeOperatorOptInConfirmationFormCard />
            )}

          {user &&
            applicant.resource &&
            applicant.resource.ticketsRange &&
            applicant.resource.ticketsRange.length > 0 && (
              <NodeOperatorTicketsCard
                title={
                  applicant.resource.status ===
                  IntegrationsNetApplicantStatus.SELECTED
                    ? "You've been selected to be a IntegrationNet Node operator!"
                    : undefined
                }
                tickets={applicant.resource.ticketsRange}
              />
            )}

          {user &&
            applicant.resource &&
            applicant.resource.status ===
              IntegrationsNetApplicantStatus.SELECTED && (
              <>
                <NodeOperatorNextStepsCard />
                <NodeOperatorRegistrationFormCard />
              </>
            )}

          {user &&
            applicant.resource &&
            applicant.resource.status ===
              IntegrationsNetApplicantStatus.OPERATOR_REGISTERED && (
              <NodeOperatorInfoCard
                variants={['white-title']}
                title="Node details received!"
                secondaryContent="A team lead will reach out to you with onboarding steps through our Constellation Discord soon."
              />
            )}
        </DetailsSideCardsContainer>
      </ProgramDetailProjectContainer>
    </Content>
  )
}

export { NodeOperatorProgramDetailView }
