import React from 'react'
import { Tooltip } from 'react-tooltip'

import styles from './component.module.scss'

const GlobalTooltip = () => {
  return (
    <Tooltip
      id="lattice"
      className={styles.main}
      classNameArrow={styles.arrow}
    />
  )
}

export { GlobalTooltip }
