import { INodeManagerGeneralNode } from '@lattice/common/providers'

enum NodePropertyName {
  DTM_NODE__DTM_DEVICES = 'lattice.nodes.dtmnode::dorapi.dtm.linkedDevices',
}

type NodeTypedProperties = {
  [NodePropertyName.DTM_NODE__DTM_DEVICES]: string[]
}

const getNodeProperty = <P extends string, F>(
  node: INodeManagerGeneralNode,
  name: P,
  fallback: F
):
  | (P extends keyof NodeTypedProperties ? NodeTypedProperties[P] : JSONValue)
  | F => {
  const property = node.properties.find((property) => property.name === name)

  if (!property) {
    return fallback
  }

  return property.value as P extends keyof NodeTypedProperties
    ? NodeTypedProperties[P]
    : JSONValue
}

export { NodePropertyName, getNodeProperty }
